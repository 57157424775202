import { Box, Flex, ScrollArea, SimpleGrid, Text, Tooltip } from '@mantine/core';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { useNowPricing } from './NowPricing.hooks';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import { BNSwitch } from '../../components/Switch/Switch';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { useFlag } from '@unleash/proxy-client-react';
import { BarkerCoreEnumsAdjustmentType } from '../../api';
import { useGlobalState } from '../../data/Global.state';
import { BNButton } from '../../components/Button/Button';
import { SoftFloorInput } from '../../components/SoftFloor/SoftFloor';
import WarningPauseIcon from '../../components/icons/WarningPause';

export const RuleForm = () => {
  const { form, isGroupedListing, isFormReady, setAutoUpdateRuleOnTargetComparableChanges, onSave } = useNowPricing(
    'form',
    'isGroupedListing',
    'isFormReady',
    'setAutoUpdateRuleOnTargetComparableChanges',
    'onSave',
  );

  const { principal } = useGlobalState('principal');

  const hasSoftFloorFeatureEnabled = principal?.settings?.pricerSettings?.featureFlags?.softFloor ?? false;

  const softFloorEnabled = !!form.values.softFloor && form.values.softFloor.value > 0;
  const hasSoftFloor = softFloorEnabled || hasSoftFloorFeatureEnabled;

  const pauseAutoPricingFlag = useFlag('pause-autopricing');

  return (
    <Box pos={pauseAutoPricingFlag || !!form.values.warningNotes ? 'relative' : 'static'}>
      <SimpleGrid cols={2} spacing="sm" my={8}>
        <AdjustmentValueInputDiagonal
          label="Adjustment"
          numInputWidth={65}
          disabled={!isFormReady}
          onChange={(value) => {
            form.getInputProps('adjustmentValue').onChange(value.value);
            form.getInputProps('adjustmentTypeId').onChange(value.type);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          value={form.values.adjustmentValue || 0}
          type={form.values.adjustmentTypeId || 'Amount'}
          error={form.errors.adjustmentValue}
        />

        <BNNumberInput selectOnFocus disabled={!isFormReady} {...form.getInputProps('numComparables')} label="# Comparables" decimalScale={0} min={1} size="xs" defaultValue={1} />
      </SimpleGrid>
      <SimpleGrid mb={8} cols={2} spacing="sm">
        {!hasSoftFloor && (
          <BNNumberInput
            selectOnFocus
            disabled={!isFormReady}
            leftSection={<Text size="xs">$</Text>}
            {...form.getInputProps('floorPrice')}
            onChange={(value) => {
              setAutoUpdateRuleOnTargetComparableChanges(false);
              form.getInputProps('floorPrice').onChange(typeof value === 'string' ? undefined : value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            decimalScale={2}
            fixedDecimalScale
            step={1}
            stepHoldDelay={500}
            stepHoldInterval={100}
            min={0}
            size="xs"
            label="Floor"
          />
        )}
        {hasSoftFloor && (
          <Box pos="relative">
            <Text size="xs" component="label" c="var(--colors-gray-5)" lh="1.25rem">
              Floor
            </Text>
            <Tooltip withArrow label={softFloorEnabled ? 'Disable Soft Floor' : 'Enable Soft Floor'}>
              <Box pos="absolute" top={6} right={0}>
                <BNSwitch
                  size="xxs"
                  label="SOFT"
                  checked={softFloorEnabled}
                  data-pendo-name="Soft floor toggle"
                  onChange={() => {
                    form.setValues({
                      ...form.values,
                      softFloor: softFloorEnabled
                        ? undefined
                        : principal?.settings?.pricerSettings?.softFloor?.value
                          ? principal.settings.pricerSettings.softFloor
                          : { period: '1.00:00:00', typeId: BarkerCoreEnumsAdjustmentType.Percentage, value: 1.01 },
                    });
                  }}
                />
              </Box>
            </Tooltip>
            <BNNumberInput
              selectOnFocus
              disabled={!isFormReady}
              leftSection={<Text size="xs">$</Text>}
              {...form.getInputProps('floorPrice')}
              onChange={(value) => {
                setAutoUpdateRuleOnTargetComparableChanges(false);
                form.getInputProps('floorPrice').onChange(typeof value === 'string' ? undefined : value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onSave();
                }
              }}
              decimalScale={2}
              fixedDecimalScale
              step={1}
              stepHoldDelay={500}
              stepHoldInterval={100}
              min={0}
              size="xs"
            />
          </Box>
        )}
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          {...form.getInputProps('ceilingPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('ceilingPrice').onChange(typeof value === 'string' ? undefined : value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          leftSection={<Text size="xs">$</Text>}
          label="Ceiling"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
      </SimpleGrid>
      {softFloorEnabled && (
        <Box pb={isGroupedListing ? 8 : 0}>
          <SoftFloorInput
            min={0.01}
            disabled={!isFormReady}
            floorError={form.errors['softFloor.value']}
            softFloor={form.values.softFloor}
            onFloorChange={(value) => {
              form.setValues({
                ...form.values,
                softFloor: {
                  ...form.values.softFloor,
                  value: value.value ?? 0,
                  typeId: value.type,
                  period: form.values.softFloor?.period ?? '1.00:00:00',
                },
              });
            }}
            periodError={form.errors['softFloor.period']}
            onPeriodChange={(value) => {
              form.setFieldValue('softFloor.period', value);
            }}
            onSave={onSave}
          />
        </Box>
      )}
      {isGroupedListing && (
        <SimpleGrid mb={8} cols={2} spacing="sm">
          <Box pos="relative">
            <Text size="xs" c="var(--colors-gray-5)" component="label" lh="1.25rem">
              # Active
            </Text>
            <Tooltip withArrow label="Broadcast all listings in the group">
              <Box pos="absolute" top={6} right={0}>
                <BNSwitch
                  checked={form.values.numActive === 2147483647}
                  onChange={() => form.setFieldValue('numActive', form.values.numActive === 2147483647 ? 1 : 2147483647)}
                  size="xxs"
                  label="ALL"
                />
              </Box>
            </Tooltip>
            {!!form.values.numActive && form.values.numActive >= 2147483647 && <BNTextInput disabled size="xs" value="All Listings" />}
            {(form.values.numActive === undefined || form.values.numActive === null || form.values.numActive < 2147483647) && (
              <BNNumberInput disabled={!isFormReady} {...form.getInputProps('numActive')} decimalScale={0} fixedDecimalScale allowDecimal={false} min={0} step={1} size="xs" />
            )}
          </Box>
          <StaggerByValueInput
            numInputWidth={78}
            disabled={!isFormReady}
            label="Stagger By"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            onChange={(value) => {
              form.getInputProps('staggerByValue').onChange(value.value);
              form.getInputProps('staggerByTypeId').onChange(value.type);
            }}
            value={form.values.staggerByValue || 0}
            type={form.values.staggerByTypeId || 'Amount'}
          />
        </SimpleGrid>
      )}
      {!!form.values.warningNotes && (
        <Flex
          pos="absolute"
          direction="column"
          top={4}
          bottom={-4}
          left={0}
          right={0}
          bg="var(--colors-paper)"
          style={{ border: '1px solid var(--colors-yellow-5)', boxShadow: 'var(--mantine-shadow-md)', borderRadius: 3, zIndex: 1, overflow: 'hidden' }}
        >
          <Flex align="center" h={30} px={6} bg="var(--colors-yellow-0)" c="var(--colors-yellow-warning)" fz={12} style={{ borderRadius: '3px 3px 0 0' }}>
            <WarningPauseIcon /> &nbsp;Auto-Price Warning Note
          </Flex>
          <ScrollArea type="always" scrollbars="y" flex={1}>
            <Box px="xs" py={4} pr="md">
              <Text>{form.values.warningNotes}</Text>
            </Box>
          </ScrollArea>
          <Flex py={8} px="xs" style={{ borderTop: '1px solid var(--colors-gray-2)' }}>
            <BNButton
              variant="filled"
              color="green"
              size="compact-xs"
              flex={1}
              onClick={() => {
                form.setFieldValue('warningNotes', null);
              }}
            >
              I Understand, Dismiss This Warning
            </BNButton>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
