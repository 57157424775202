import { ActionIcon, Collapse, Group, Paper, Text } from '@mantine/core';
import CloseIcon from '../../components/icons/Close';
import { useGetApiAccountingPlansCurrent, useGetApiAccountingPlansFuture } from '../../api';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { useLocalStorage } from '@mantine/hooks';
import WarningIcon from '../../components/icons/Warning';
import { auth } from '../../data/atoms.auth';
import { isDtiHosted, supportEmail } from '../../utils/whitelabel-consts';
import { useGlobalState } from '../../data/Global.state';

export function PlanExpirationBanner() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { selectedTenantIds } = useGlobalState('selectedTenantIds');
  const { data: currentPlans } = useGetApiAccountingPlansCurrent({
    query: {
      enabled: !!apiToken && !isDtiHosted && selectedTenantIds?.length > 0, // Don't show the banner if we're in DTI or if we don't have any selected tenants
      select: (data) => data.data,
    },
  });

  const { data: futurePlans } = useGetApiAccountingPlansFuture({
    query: {
      enabled: !!apiToken && selectedTenantIds?.length > 0,
      select: (data) => data.data,
    },
  });

  const [snoozedBanners, setSnoozedBanners] = useLocalStorage<{ id: string; until: Date }[]>({
    key: 'snoozed-plan-expiration-banners',
    getInitialValueInEffect: false,
    defaultValue: [],
  });

  const plans = currentPlans?.map((plan) => {
    const futurePlan = futurePlans?.find((future) => future.tenantId === plan.tenantId);

    if (futurePlan) {
      return futurePlan;
    }

    return plan;
  });

  if (plans === undefined) {
    return null;
  }

  if (plans.some((plan) => plan.expiresAt && dayjs(plan.expiresAt).isAfter(dayjs().add(14, 'days')))) {
    return null;
  }

  if (plans.length === 0) {
    return (
      <Collapse in w="100%" ta="center">
        <Paper w="100%" bg="var(--colors-red-error)" c="rgba(255,255,255,1)">
          <Group mih={36} align="center" justify="center" gap="xs">
            <WarningIcon size={20} color="rgba(255,255,255,1)" />
            <Text size="xs" c="rgba(255,255,255,1)">
              Your subscription plan has expired. Your data is saved, but the auto-pricer will not run until you reactivate. Please contact{' '}
              <Text component="a" href={`mailto:${supportEmail}`} td="underline">
                {supportEmail}
              </Text>{' '}
              for assistance.
            </Text>
          </Group>
        </Paper>
      </Collapse>
    );
  }

  const unSnoozedPlans = plans.filter((plan) => !snoozedBanners.some((banner) => banner.id === plan.tenantId && dayjs(banner.until).isAfter(dayjs())));

  if (unSnoozedPlans.length === 0) {
    return null;
  }

  const earliestPlanExpiring = unSnoozedPlans.sort((a, b) => dayjs(a.expiresAt).diff(dayjs(b.expiresAt)))[0];

  const daysToExpiration = earliestPlanExpiring.expiresAt ? dayjs(earliestPlanExpiring.expiresAt).diff(dayjs(), 'days') : 0;

  const bannerColor = daysToExpiration <= 7 ? 'var(--colors-red-error)' : 'var(--colors-yellow-5)';
  const textColor = daysToExpiration <= 7 ? 'rgba(255,255,255,1)' : 'rgba(23,23,23,1)';

  return (
    <Collapse in w="100%" ta="center">
      <Paper w="100%" bg={bannerColor} c={textColor}>
        <Group mih={36} align="center" justify="center" gap="xs">
          <WarningIcon size={20} color={textColor} />
          <Text size="xs" c={textColor}>
            Your current plan is set to expire in <strong>{daysToExpiration} days</strong>. Be sure to contact{' '}
            <Text component="a" href={`mailto:${supportEmail}`} td="underline">
              {supportEmail}
            </Text>{' '}
            soon to avoid any disruption in service.
          </Text>
          <ActionIcon
            c={textColor}
            onClick={() => {
              setSnoozedBanners([...snoozedBanners, { id: earliestPlanExpiring.tenantId, until: dayjs().add(1, 'day').toDate() }]);
            }}
            pos="absolute"
            right={16}
          >
            <CloseIcon size={20} />
          </ActionIcon>
        </Group>
      </Paper>
    </Collapse>
  );
}
