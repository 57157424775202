import { Outlet } from 'react-router-dom';
import { useGlobalState } from '../../data/Global.state';
import { WaitingRoom } from '../../pages/WaitingRoom';
import { AccountDisabled } from '../../pages/AccountDisabled';
import LogoLoader from '../../components/Loading/LogoLoader';

export function TenantGuard() {
  const { tenants, principal } = useGlobalState('tenants', 'principal');

  if (principal?.isDisabled) {
    return <AccountDisabled />;
  }

  if (tenants === undefined) {
    return <LogoLoader />;
  }

  if (tenants.length === 0) {
    return <WaitingRoom />;
  }

  return <Outlet />;
}
