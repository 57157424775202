import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTokenRefresh } from './components/hooks/useTokenRefresh';
import { useGlobalState } from './data/Global.state';
import { useQueryParam } from './utils/use-query-param';
import { isDtiHosted } from './utils/whitelabel-consts';

export function WrapperProviders() {
  const { isAuthenticated, user } = useAuth0();
  const updateUnleashContext = useUnleashContext();
  const { handleAccessTokenSilently } = useTokenRefresh();
  const dti = useQueryParam<'yes' | 'no' | undefined>('dti', undefined);
  const navigate = useNavigate();
  const { tenants } = useGlobalState('tenants');

  useEffect(() => {
    if (dti && dti !== localStorage.getItem('use-dti-login')) {
      localStorage.setItem('use-dti-login', `${dti}`);
      navigate(window.location.pathname);
    }
  }, [dti, navigate]);

  useEffect(() => {
    document.documentElement.setAttribute('data-color-theme', isDtiHosted ? 'dti' : 'bn');
    return () => {
      document.documentElement.removeAttribute('data-color-theme');
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      updateUnleashContext({ userId: user?.email });
      handleAccessTokenSilently();
      datadogRum.setUser({
        id: user?.sub,
        email: user?.email,
      });
    }
  }, [user, isAuthenticated, updateUnleashContext, handleAccessTokenSilently]);

  if (tenants !== undefined && tenants.length > 0 && isMobile && !location.pathname.startsWith('/mobile') && !location.pathname.startsWith('/auth')) {
    if (location.search) {
      navigate(`/mobile${location.search}`);
    } else {
      navigate('/mobile');
    }
  }
  return <Outlet />;
}
