import { Box, List, Modal, ScrollArea, Stack } from '@mantine/core';
import { BNSwitch } from '../../components/Switch/Switch';
import { useGlobalState } from '../../data/Global.state';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import classes from '../../pages/CompanyPicker.Mobile.module.css';
import { useMediaQuery } from '@mantine/hooks';

interface CompanyModalProps {
  opened: boolean;
  setOpened: (val: boolean) => void;
}

export default function CompanyModal({ opened, setOpened }: CompanyModalProps) {
  return (
    <Modal
      padding={0}
      centered
      size="sm"
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      title="Companies"
      closeButtonProps={{ size: 'md' }}
      classNames={{ body: classes.modalBody }}
    >
      <ScrollArea.Autosize type="hover" bg="var(--colors-paper)" mah={248}>
        <Stack gap={1} bg="var(--colors-paper)" className={classes.companyPickerWrapper}>
          <CompanyPickerList />
        </Stack>
      </ScrollArea.Autosize>
    </Modal>
  );
}

export function CompanyPickerList() {
  const { tenants, setStoredSelectedTenants, principal, selectedTenantIds, setSelectedTenantIds } = useGlobalState(
    'tenants',
    'setStoredSelectedTenants',
    'principal',
    'selectedTenantIds',
    'setSelectedTenantIds',
  );
  const queryClient = useQueryClient();

  useEffect(
    () => () => {
      // On unmount, invalidate the query client so everything will be refetched with new selected tenants
      queryClient.invalidateQueries();
    },
    // queryClient is a dependency, but we don't want to invalidate on every render
    // eslint-disable-next-line
    [],
  );

  const getTenantColorClass = (tenantColor: string | undefined) => {
    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'tenant-brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'tenant-red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'tenant-orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'tenant-yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'tenant-green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'tenant-cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'tenant-blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'tenant-grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'tenant-pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'tenant-black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'tenant-gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'tenant-gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'tenant-gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'tenant-gray1';
      }
    }
    return 'tenant-brandcolor';
  };

  // LWW 2/23/24 - This isMobile prop is a total hack because I don't know how to use media queries and whatever else, at least we have a shared component now
  // TODO: Someone should replace this with a proper media query

  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <List listStyleType="none">
      {tenants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((tenant) => (
          <List.Item key={tenant.tenantId} className={getTenantColorClass(principal?.settings?.tenantColors[tenant.tenantId])}>
            <Box className={classes.tenantColorDot} bg="var(--tenantColor)" />
            <BNSwitch
              size={isMobile ? 'md' : 'sm'}
              className={classes.companySwitch}
              colorLabel
              label={tenant.name}
              labelPosition="left"
              w="100%"
              checked={selectedTenantIds.includes(tenant.tenantId)}
              onChange={(e) => {
                const tenantIds = e.currentTarget.checked ? [...selectedTenantIds, tenant.tenantId] : selectedTenantIds.filter((id) => id !== tenant.tenantId);
                if (e.currentTarget.checked) {
                  setSelectedTenantIds(tenantIds);
                  setStoredSelectedTenants(tenantIds);
                } else {
                  setSelectedTenantIds(tenantIds);
                  setStoredSelectedTenants(tenantIds);
                }
              }}
            />
          </List.Item>
        ))}
    </List>
  );
}
