import { Box, Center, Group, MultiSelect, Textarea, Tooltip } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import { BNSelect } from '../../../components/Select/Select';
import { BNTextInput } from '../../../components/TextInput/TextInput';
import { stock_options, disclosures_options, mapStringToDisclosures, location_options } from '../../Purchase/Purchase.hooks';
import { DtiProperties, DtiPropertyKeys, useDTIInventory } from '../../DTI/DTI.Inventory.hooks';
import { useMemo, useState } from 'react';
import AlternateEmailIcon from '../../../components/icons/AlternateEmail';
import CreditCardIcon from '../../../components/icons/CreditCard';
import ExportNotesIcon from '../../../components/icons/ExportNotes';
import LocalActivityIcon from '../../../components/icons/LocalActivity';
import RuleIcon from '../../../components/icons/Rule';
import StickyNoteIcon from '../../../components/icons/StickyNote';
import TableRowsIcon from '../../../components/icons/TableRows';
import ViewWeekIcon from '../../../components/icons/ViewWeek';
import { useBulkState } from '../../../data/Bulk.state';
import { useForm } from '@mantine/form';
import { useGlobalState } from '../../../data/Global.state';
import { BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal } from '../../../api';
import WarningIcon from '../../../components/icons/Warning';

type PropertySelectIconProps = {
  iconValue?: string | null;
  color?: string;
};

export function EditProperties({ onClose }: { onClose: () => void }) {
  const { selectedListing, allMarkedListings } = useBulkState('selectedListing', 'allMarkedListings');
  const { editPropertiesBulk } = useDTIInventory('editPropertiesBulk');

  const doesDisclosuresMatch = useMemo(() => {
    const externalNotes = allMarkedListings.map((x) => mapStringToDisclosures(x.externalNotes!).disclosures);
    const firstNotesSet = new Set(externalNotes[0]);
    return externalNotes.length > 0 && externalNotes.every((notes) => notes.every((note) => firstNotesSet.has(note)));
  }, [allMarkedListings]);

  const doesExternalNotesMatch = useMemo(() => {
    const externalNotes = allMarkedListings.map((x) => mapStringToDisclosures(x.externalNotes!).additionalText);
    return externalNotes.length > 0 && externalNotes.every((notes) => notes === externalNotes[0]);
  }, [allMarkedListings]);

  const isBroadcasting = useMemo(() => allMarkedListings.some((x) => x.isBroadcasting), [allMarkedListings]);

  const form = useForm({
    initialValues: {
      section: '',
      row: '',
      location_id: '',
      disclosures: selectedListing && selectedListing.externalNotes && doesDisclosuresMatch ? mapStringToDisclosures(selectedListing.externalNotes).disclosures : ([] as string[]),
      external_notes: doesExternalNotesMatch ? (selectedListing && selectedListing.externalNotes ? mapStringToDisclosures(selectedListing.externalNotes).additionalText : '') : '',
      cc_info_card: '',
      cc_info_purchaser: '',
      internal_notes: '',
    } satisfies Record<DtiPropertyKeys, string | string[]>,
  });

  const [editPropertyValue, setEditPropertyValue] = useState<DtiPropertyKeys>('section');
  const vendorProperties = selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal | undefined;
  const { dtiAccounts } = useGlobalState('dtiAccounts');
  const permissions = dtiAccounts.find((a) => a.id === vendorProperties?.ownerId)?.perms;
  const canEditCreditCard = permissions === undefined || permissions.cc_info.enabled === '1';
  const options = useMemo(() => DtiProperties.filter((x) => canEditCreditCard || (x.label !== 'CC Info' && x.label !== 'Purchaser')), [canEditCreditCard]);
  const [isLoading, setIsLoading] = useState(false);

  const showTooltip = false;

  return (
    <Group wrap="wrap" gap="sm" align="flex-end" justify="right">
      <Box>
        <BNSelect
          value={editPropertyValue}
          data={options}
          onChange={(v) => setEditPropertyValue(v as DtiPropertyKeys)}
          w={236}
          maxDropdownHeight={240}
          size="xs"
          placeholder="Section Name"
          leftSection={<PropertySelectIcon iconValue={editPropertyValue} color="var(--colors-selectedBorder)" />}
          aria-label="Section"
          renderOption={({ option: { label, value } }) => (
            <Group>
              <PropertySelectIcon iconValue={value} color="var(--colors-iconFill)" /> {label}
            </Group>
          )}
        />
      </Box>
      {editPropertyValue === 'section' && (
        <Box pos="relative">
          <BNTextInput w={236} size="xs" placeholder="Section Name" aria-label="Section" {...form.getInputProps('section')} />
        </Box>
      )}
      {editPropertyValue === 'row' && (
        <Box pos="relative">
          <BNTextInput w={236} size="xs" placeholder="Row Name" aria-label="Row" {...form.getInputProps('row')} />
        </Box>
      )}
      {editPropertyValue === 'location_id' && (
        <Box pos="relative">
          <BNSelect
            w={236}
            size="xs"
            placeholder="Select Location"
            data={location_options.filter((x) => x.primary)}
            aria-label="Stock"
            {...form.getInputProps('location_id')}
            disabled={isBroadcasting}
          />
        </Box>
      )}
      {editPropertyValue === 'disclosures' && (
        <Box pos="relative">
          <MultiSelect
            w={236}
            size="xs"
            placeholder="Select Disclosures"
            data={disclosures_options}
            aria-label="Disclosures"
            {...form.getInputProps('disclosures')}
            disabled={isBroadcasting}
          />
          {showTooltip && (
            <Tooltip label="Selected listings have varying disclosure values that may be overwritten." multiline maw={260}>
              <Center h={20} w={20} pos="absolute" top="calc(50% - 10px)" left={-28}>
                <WarningIcon size={20} color="var(--colors-yellow-warning)" />{' '}
              </Center>
            </Tooltip>
          )}
        </Box>
      )}
      {editPropertyValue === 'external_notes' && (
        <Box pos="relative">
          <Textarea w={236} size="xs" placeholder="External Notes" aria-label="External Notes" {...form.getInputProps('external_notes')} disabled={isBroadcasting} />
        </Box>
      )}
      {editPropertyValue === 'cc_info_card' && (
        <Box pos="relative">
          <BNTextInput w={236} size="xs" placeholder="CC Info" type="number" maxLength={4} clearable aria-label="CC Info" {...form.getInputProps('cc_info_card')} />
        </Box>
      )}
      {editPropertyValue === 'cc_info_purchaser' && (
        <Box pos="relative">
          <BNTextInput w={236} size="xs" placeholder="purchaser@email.com" aria-label="Purchaser" {...form.getInputProps('cc_info_purchaser')} />
        </Box>
      )}
      {editPropertyValue === 'internal_notes' && (
        <Box pos="relative">
          <Textarea w={236} size="xs" placeholder="Internal Notes" aria-label="Internal Notes" {...form.getInputProps('internal_notes')} />
        </Box>
      )}
      <Group gap="sm" w={236} align="flex-end" justify="right">
        <BNButton fullWidth variant="default" size="xs" onClick={() => onClose()} disabled={isLoading}>
          Cancel
        </BNButton>
        <BNButton
          fullWidth
          loading={isLoading}
          variant="filled"
          color="green"
          size="xs"
          onClick={() =>
            form.onSubmit(async (values) => {
              setIsLoading(true);
              if (selectedListing && editPropertyValue) {
                await editPropertiesBulk(selectedListing, editPropertyValue, values[editPropertyValue]);
              }
              onClose();
              setIsLoading(false);
            })()
          }
        >
          Apply
        </BNButton>
      </Group>
    </Group>
  );
}

const PropertySelectIcon = ({ iconValue, color }: PropertySelectIconProps) => {
  switch (iconValue) {
    case 'section':
      return <ViewWeekIcon color={color} />;
    case 'row':
      return <TableRowsIcon color={color} />;
    case 'location_id':
      return <LocalActivityIcon color={color} />;
    case 'disclosures':
      return <RuleIcon color={color} />;
    case 'externalNotes':
      return <ExportNotesIcon color={color} />;
    case 'cc_info_card':
      return <CreditCardIcon color={color} />;
    case 'cc_info_purchaser':
      return <AlternateEmailIcon color={color} />;
    case 'internalNotes':
      return <StickyNoteIcon color={color} />;
    default:
      return <ViewWeekIcon color={color} />;
  }
};
