import { ActionIcon, Center, Divider, Flex, FocusTrap, Text } from '@mantine/core';
import classes from '../../../pages/ListingDetails.Mobile.module.css';
import { BNButton } from '../../../components/Button/Button';
import { useRef } from 'react';
import DoNotDisturbIcon from '../../../components/icons/DoNotDisturb';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import AddCircleIcon from '../../../components/icons/AddCircle';
import { MobileModalProps } from '../../../components/MobilePriceUpdateForm/MobilePriceUpdateForm';
import { useNowPricing } from '../../NowPricing/NowPricing.hooks';
import { useForm } from '@mantine/form';
import { useViewportPosition } from '../../../utils/use-viewport-position';
import { BNSwitch } from '../../../components/Switch/Switch';

export function NumActiveUpdateForm({ closeModal }: MobileModalProps) {
  const { form } = useNowPricing('form');
  const {
    getInputProps,
    setFieldValue,
    onSubmit,
    values: { numActive },
  } = useForm({
    initialValues: {
      numActive: form.values.numActive || 0,
    },
  });

  const ref = useRef<HTMLInputElement | null>(null);
  useViewportPosition(ref, [numActive]);

  return (
    <FocusTrap>
      <form
        onSubmit={onSubmit((values) => {
          form.setFieldValue('numActive', values.numActive);
          closeModal();
        })}
      >
        <Flex p={0} h="100%" justify="space-between">
          <Flex py="sm" px="md" gap="xs" align="center" justify="start" style={{ flex: 1 }}>
            <Text size="md" fw={600}>
              # Active
            </Text>
          </Flex>
          <Flex py="sm" px="md" gap="xs" align="center" style={{ flex: 1 }} justify="end">
            <BNSwitch
              label="ALL"
              size="sm"
              checked={numActive >= 2147483647}
              onChange={() => {
                setFieldValue('numActive', numActive >= 2147483647 ? 1 : 2147483647);
              }}
            />
          </Flex>
        </Flex>
        <Divider color="var(--colors-gray-0)" />
        {numActive < 2147483647 && (
          <Flex py="xl" px="xl" w="100%" align="start" justify="center" gap="md">
            <ActionIcon
              className={classes.priceInputButton}
              onClick={() => {
                setFieldValue('numActive', numActive - 1);
              }}
            >
              <Center h={36} w={36} bg="var(--colors-gray-0)" style={{ borderRadius: 100 }}>
                <DoNotDisturbIcon size={30} color="var(--colors-selectedBorder)" />
              </Center>
            </ActionIcon>
            <BNNumberInput
              {...getInputProps('numActive')}
              ref={ref}
              errorPosition="bottom"
              className={classes.priceInput}
              size="lg"
              w="calc(100% - 100px)"
              hideControls
              data-autofocus
              decimalScale={0}
              fixedDecimalScale
              autoFocus
              selectOnFocus
              inputMode="decimal"
            />
            <ActionIcon
              className={classes.priceInputButton}
              onClick={() => {
                setFieldValue('numActive', numActive + 1);
              }}
            >
              <Center h={36} w={36} bg="var(--colors-gray-0)" style={{ borderRadius: 100 }}>
                <AddCircleIcon size={30} color="var(--colors-selectedBorder)" />
              </Center>
            </ActionIcon>
          </Flex>
        )}
        <Divider color="var(--colors-gray-0)" />
        <Flex gap="sm" w="100%" pt="xl" pb={numActive < 2147483647 ? 'xl' : 40} px="md">
          <BNButton fullWidth variant="default" onClick={() => closeModal()}>
            Cancel
          </BNButton>
          <BNButton fullWidth variant="filled" color="gray" type="submit">
            Done
          </BNButton>
        </Flex>
      </form>
    </FocusTrap>
  );
}
