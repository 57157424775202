import { Box, Center } from '@mantine/core';
import classes from './SeatingChart.CollapseOverlay.module.css';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import MenuOpenIcon from '../../components/icons/MenuOpen';

export function CollapseOverlay() {
  return (
    <Box className={classes.collapseOverlay}>
      <BNEmptyState
        title="Release to collapse this column"
        description="or widen to display the full seating chart"
        icon={
          <Center style={{ transform: 'rotate(180deg)' }}>
            <MenuOpenIcon size={24} />
          </Center>
        }
        border={false}
      />
    </Box>
  );
}
