import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreModelsAdministrativeInvoice,
  BarkerCoreModelsAdministrativeInvoiceDetails,
  BarkerCoreModelsAdministrativeInvoiceItem,
  BarkerCoreModelsAdministrativeTenantPlan,
  GetApiAccountingInvoicesParams,
} from './models';

export const getApiAccountingInvoicesCurrent = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeInvoiceItem[]>> => {
  return axios.get(`/api/Accounting/Invoices/Current`, options);
};

export const getGetApiAccountingInvoicesCurrentQueryKey = () => {
  return [`/api/Accounting/Invoices/Current`] as const;
};

export const getGetApiAccountingInvoicesCurrentQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>, TError = AxiosError<void>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiAccountingInvoicesCurrentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>> = ({ signal }) => getApiAccountingInvoicesCurrent({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiAccountingInvoicesCurrentQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>>;
export type GetApiAccountingInvoicesCurrentQueryError = AxiosError<void>;

export const useGetApiAccountingInvoicesCurrent = <TData = Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>, TError = AxiosError<void>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesCurrent>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiAccountingInvoicesCurrentQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiAccountingInvoices = (
  params?: GetApiAccountingInvoicesParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeInvoiceDetails[]>> => {
  return axios.get(`/api/Accounting/Invoices`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiAccountingInvoicesQueryKey = (params?: GetApiAccountingInvoicesParams) => {
  return [`/api/Accounting/Invoices`, ...(params ? [params] : [])] as const;
};

export const getGetApiAccountingInvoicesQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountingInvoices>>, TError = AxiosError<void>>(
  params?: GetApiAccountingInvoicesParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoices>>, TError, TData>>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiAccountingInvoicesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountingInvoices>>> = ({ signal }) => getApiAccountingInvoices(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoices>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiAccountingInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountingInvoices>>>;
export type GetApiAccountingInvoicesQueryError = AxiosError<void>;

export const useGetApiAccountingInvoices = <TData = Awaited<ReturnType<typeof getApiAccountingInvoices>>, TError = AxiosError<void>>(
  params?: GetApiAccountingInvoicesParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoices>>, TError, TData>>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiAccountingInvoicesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiAccountingInvoicesInvoiceId = (invoiceId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeInvoice>> => {
  return axios.get(`/api/Accounting/Invoices/${invoiceId}`, options);
};

export const getGetApiAccountingInvoicesInvoiceIdQueryKey = (invoiceId: string) => {
  return [`/api/Accounting/Invoices/${invoiceId}`] as const;
};

export const getGetApiAccountingInvoicesInvoiceIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>, TError = AxiosError<void>>(
  invoiceId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>, TError, TData>>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiAccountingInvoicesInvoiceIdQueryKey(invoiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>> = ({ signal }) =>
    getApiAccountingInvoicesInvoiceId(invoiceId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!invoiceId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiAccountingInvoicesInvoiceIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>>;
export type GetApiAccountingInvoicesInvoiceIdQueryError = AxiosError<void>;

export const useGetApiAccountingInvoicesInvoiceId = <TData = Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>, TError = AxiosError<void>>(
  invoiceId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceId>>, TError, TData>>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiAccountingInvoicesInvoiceIdQueryOptions(invoiceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiAccountingInvoicesInvoiceIdPdf = (invoiceId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/Accounting/Invoices/${invoiceId}/Pdf`, options);
};

export const getGetApiAccountingInvoicesInvoiceIdPdfQueryKey = (invoiceId: string) => {
  return [`/api/Accounting/Invoices/${invoiceId}/Pdf`] as const;
};

export const getGetApiAccountingInvoicesInvoiceIdPdfQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>, TError = AxiosError<void>>(
  invoiceId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>, TError, TData>>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiAccountingInvoicesInvoiceIdPdfQueryKey(invoiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>> = ({ signal }) =>
    getApiAccountingInvoicesInvoiceIdPdf(invoiceId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!invoiceId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiAccountingInvoicesInvoiceIdPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>>;
export type GetApiAccountingInvoicesInvoiceIdPdfQueryError = AxiosError<void>;

export const useGetApiAccountingInvoicesInvoiceIdPdf = <TData = Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>, TError = AxiosError<void>>(
  invoiceId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingInvoicesInvoiceIdPdf>>, TError, TData>>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiAccountingInvoicesInvoiceIdPdfQueryOptions(invoiceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiAccountingInvoicesInvoiceIdResend = (invoiceId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/Accounting/Invoices/${invoiceId}/Resend`, undefined, options);
};

export const getPostApiAccountingInvoicesInvoiceIdResendMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiAccountingInvoicesInvoiceIdResend>>, TError, { invoiceId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiAccountingInvoicesInvoiceIdResend>>, TError, { invoiceId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAccountingInvoicesInvoiceIdResend>>, { invoiceId: string }> = (props) => {
    const { invoiceId } = props ?? {};

    return postApiAccountingInvoicesInvoiceIdResend(invoiceId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiAccountingInvoicesInvoiceIdResendMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAccountingInvoicesInvoiceIdResend>>>;

export type PostApiAccountingInvoicesInvoiceIdResendMutationError = AxiosError<void>;

export const usePostApiAccountingInvoicesInvoiceIdResend = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiAccountingInvoicesInvoiceIdResend>>, TError, { invoiceId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiAccountingInvoicesInvoiceIdResend>>, TError, { invoiceId: string }, TContext> => {
  const mutationOptions = getPostApiAccountingInvoicesInvoiceIdResendMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiAccountingPlansCurrent = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantPlan[]>> => {
  return axios.get(`/api/Accounting/Plans/Current`, options);
};

export const getGetApiAccountingPlansCurrentQueryKey = () => {
  return [`/api/Accounting/Plans/Current`] as const;
};

export const getGetApiAccountingPlansCurrentQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>, TError = AxiosError<void>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiAccountingPlansCurrentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>> = ({ signal }) => getApiAccountingPlansCurrent({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiAccountingPlansCurrentQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>>;
export type GetApiAccountingPlansCurrentQueryError = AxiosError<void>;

export const useGetApiAccountingPlansCurrent = <TData = Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>, TError = AxiosError<void>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingPlansCurrent>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiAccountingPlansCurrentQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiAccountingPlansFuture = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantPlan[]>> => {
  return axios.get(`/api/Accounting/Plans/Future`, options);
};

export const getGetApiAccountingPlansFutureQueryKey = () => {
  return [`/api/Accounting/Plans/Future`] as const;
};

export const getGetApiAccountingPlansFutureQueryOptions = <TData = Awaited<ReturnType<typeof getApiAccountingPlansFuture>>, TError = AxiosError<void>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingPlansFuture>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiAccountingPlansFutureQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAccountingPlansFuture>>> = ({ signal }) => getApiAccountingPlansFuture({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingPlansFuture>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiAccountingPlansFutureQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAccountingPlansFuture>>>;
export type GetApiAccountingPlansFutureQueryError = AxiosError<void>;

export const useGetApiAccountingPlansFuture = <TData = Awaited<ReturnType<typeof getApiAccountingPlansFuture>>, TError = AxiosError<void>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAccountingPlansFuture>>, TError, TData>>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiAccountingPlansFutureQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
