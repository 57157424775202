import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import LogoLoader from '../../components/Loading/LogoLoader';
import { useMemo } from 'react';

export function AuthGuard() {
  const Component = useMemo(
    () =>
      withAuthenticationRequired(Outlet, {
        onRedirecting: () => <LogoLoader />,
      }),
    [],
  );

  return <Component />;
}
