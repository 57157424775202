import { Flex, Tooltip } from '@mantine/core';
import PictureAsPDFIcon from '../../../components/icons/PictureAsPDF';
import FlashOnIcon from '../../../components/icons/FlashOn';
import QrCodeScannerIcon from '../../../components/icons/QrCodeScanner';
import LocalActivityIcon from '../../../components/icons/LocalActivity';
import OpenInPhoneIcon from '../../../components/icons/OpenInPhone';
import WarningIcon from '../../../components/icons/Warning';
import PersonPinIcon from '../../../components/icons/PersonPin';
import { IconPropsBase } from '../../../components/icons/IconPropsBase';

export function LocationProperty({ location }: { location: string }) {
  const friendlyName = getLocationFriendlyName(location);

  return (
    <Tooltip label={friendlyName} withinPortal withArrow>
      <Flex w={22} h={22} bg="var(--colors-paper)" align="center" justify="center" style={{ borderRadius: 28 }} c="currentcolor">
        <LocationIcon location={location} size={16} />
      </Flex>
    </Tooltip>
  );
}

export function getLocationFriendlyName(location: string) {
  return location === 'MOBILE_XFR' || location === 'TicketmasterMobile'
    ? 'Mobile Transfer'
    : location === 'MD'
      ? 'Managed Delivery'
      : location === 'FLASH' || location === 'AxsMobile'
        ? 'AXS Mobile ID'
        : location === 'TF' || location === 'Pdf'
          ? 'PDF'
          : location === 'MOBILE' || location === 'Qr'
            ? 'Mobile QR'
            : location === 'HARD' || location === 'Hard'
              ? 'Hard Stock'
              : location === 'WILL CALL'
                ? 'Will Call'
                : 'Unknown';
}

export function LocationIcon({ location, ...props }: IconPropsBase & { location: string }) {
  return location === 'MOBILE_XFR' || location === 'TicketmasterMobile' ? (
    <OpenInPhoneIcon {...props} />
  ) : location === 'TF' || location === 'PDF' ? (
    <PictureAsPDFIcon {...props} />
  ) : location === 'FLASH' || location === 'AxsMobile' ? (
    <FlashOnIcon {...props} />
  ) : location === 'MOBILE' || location === 'Qr' ? (
    <QrCodeScannerIcon {...props} />
  ) : location === 'HARD' || location === 'Hard' ? (
    <LocalActivityIcon {...props} />
  ) : location === 'WILL CALL' ? (
    <PersonPinIcon {...props} />
  ) : (
    <WarningIcon {...props} />
  );
}
