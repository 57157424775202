import { useMemo } from 'react';
import { Flex, Tooltip } from '@mantine/core';
import pluralize from 'pluralize';
import MotionPhotosPausedFillIcon from '../../components/icons/MotionPhotosPausedFill';

export type InventoryOnHoldProps = {
  quantityReserved: number;
  quantityRemaining: number;
};

export const InventoryOnHold = (props: InventoryOnHoldProps) => {
  const { quantityReserved, quantityRemaining } = props;

  return <InventoryOnHoldImpl {...props} quantityReserved={quantityReserved} quantityRemaining={quantityRemaining} />;
};

const InventoryOnHoldImpl = ({ quantityReserved, quantityRemaining }: InventoryOnHoldProps) => {
  const ticketsOnHold = quantityReserved > 0;
  const tooltipText = useMemo(
    () =>
      ticketsOnHold && quantityReserved === quantityRemaining
        ? 'All tickets on hold'
        : ticketsOnHold && quantityReserved < quantityRemaining
          ? `${quantityReserved.toLocaleString()}${pluralize(' ticket', quantityReserved)} on hold`
          : '',
    [quantityRemaining, quantityReserved, ticketsOnHold],
  );
  return (
    ticketsOnHold && (
      <Tooltip label={tooltipText} withinPortal withArrow>
        <Flex w={22} h={22} align="center" justify="center" c="var(--colors-yellow-warning)" bg="var(--colors-paper)" style={{ borderRadius: 28 }}>
          <MotionPhotosPausedFillIcon size={20} />
        </Flex>
      </Tooltip>
    )
  );
};
