import { IconPropsBase } from './IconPropsBase';

export default function WarningPauseIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" style={{ }}>
        <path
          fill={color}
          d="M1.87,19.75L12,2.25l10.13,17.5H1.87ZM4.45,18.25h15.1l-7.55-13-7.55,13ZM9.5,16.78v-5.53h1.5v5.53h-1.5ZM13,16.78v-5.53h1.5v5.53h-1.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" style={{  }}>
        <path
          fill={color}
          d="M1.72,16.04L10,2.29l8.28,13.75H1.72ZM3.65,14.96h12.71l-6.35-10.58L3.65,14.96ZM8.17,13.76v-4.4h1.08v4.4h-1.08ZM10.75,13.76v-4.4h1.08v4.4h-1.08Z"
        />
      </svg>
    );
  }
}
