/* eslint-disable @typescript-eslint/no-loop-func */
import {
  CellClickedEvent,
  ColDef,
  ColumnEvent,
  ColumnState,
  ComponentStateChangedEvent,
  DetailGridInfo,
  GetDetailRowDataParams,
  GridReadyEvent,
  ICellRendererParams,
  IDetailCellRendererParams,
  ModuleRegistry,
  RowNode,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDidUpdate, useLocalStorage, useToggle } from '@mantine/hooks';
import { InventorySeasonLocationExtended } from '../../types';
import { showEventPerformanceAtom } from '../../data/atoms';
import { useInventoryDragEvents } from './Inventory.dragdrop';
import { formatCurrency } from '../../utils/formatters';
import yasml from '@thirtytech/yasml';
import { useGlobalState } from '../../data/Global.state';
import { useSearchParams } from 'react-router-dom';
import { matchesRowFilter } from '../MarketListings/marketListingsFilter';
import { seasons } from '../../data/atoms.seasons';
import { useSeasonPricer } from '../../data/SeasonsPricer.state';
import { DragCell } from './Inventory.DragCell';
import { useFlag } from '@unleash/proxy-client-react';

dayjs.extend(relativeTime);
ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, MenuModule, ColumnsToolPanelModule, RangeSelectionModule]);

export const onNextInventoryItem = new Event('onNextInventoryItem');
export const onPrevInventoryItem = new Event('onPrevInventoryItem');
export const onColumnMoved = new Event('onColumnMoved');
export const onColumnSorted = new Event('onColumnSorted');

const InventoryState = () => {
  const gridRef = useRef<AgGridReact>(null);
  const detailGridRef = useRef<Partial<AgGridReact> | null>(null);
  const [gridReady, setGridReady] = useState(false);
  const [detailGridToAlign, setDetailGridToAlign] = useState<DetailGridInfo[]>([]);
  const { currentUser } = useGlobalState('currentUser');
  const [showSort, toggleShowSort] = useToggle();
  const [selectedLocation, setSelectedLocation] = useAtom(seasons.selectedLocationAtom);
  const [inventoryQuickFilter, setInventoryQuickFilter] = useState<string>();
  const [filter, setFilter] = useState(''); // Used for input state of semi controlled input
  const [searchParams] = useSearchParams();
  const selectedSeasonLocations = useAtomValue(seasons.selectedSeasonLocationsAtom);
  const isShowingEventPerformance = useAtomValue(showEventPerformanceAtom);
  const { validateDirtyRuleState } = useSeasonPricer('validateDirtyRuleState');
  const previousSelectedLocation = useAtomValue(seasons.previousSelectedLocationAtom);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      width: 170,
      flex: 1,
      resizable: showSort,
      suppressMenu: true,
      suppressMovable: !showSort,
      sortable: showSort,
      filter: true,
    }),
    [showSort],
  );

  const [inventoryGridState, setInventoryGridState, clearInventoryGridState] = useLocalStorage<ColumnState[]>({
    key: 'season-grid-state',
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    if (searchParams.has('resetGrid')) {
      clearInventoryGridState();
    }
  }, [clearInventoryGridState, searchParams]);

  const { onMasterRowDragEnd, onMasterRowDragMove, onDetailRowDragEnd, onDetailMasterRowDragEnd } = useInventoryDragEvents({ gridRef, detailGridRef });

  const mergedEventListings = useMemo(() => selectedSeasonLocations?.filter((x) => !x.ruleTier) || [], [selectedSeasonLocations]);
  const [filteredListings, setFilteredListings] = useState<InventorySeasonLocationExtended[]>(selectedSeasonLocations || []);

  useDidUpdate(() => {
    if (inventoryQuickFilter) {
      let matchingListings = [...mergedEventListings];

      const andParts = inventoryQuickFilter.split(' ');

      andParts.forEach((andPart) => {
        const andListings: InventorySeasonLocationExtended[] = [];

        const orParts = andPart.split(',');

        orParts.forEach((orPart) => {
          const rangeParts = orPart.split('-');

          if (
            rangeParts.length === 2 &&
            ((!isNaN(Number(rangeParts[0])) && !isNaN(Number(rangeParts[1]))) ||
              ([...rangeParts[0]].every((char) => char === rangeParts[0][0]) && [...rangeParts[1]].every((char) => char === rangeParts[1][0])))
          ) {
            // Either the part is a number of it is a string of the same character, so check sections or rows
            andListings.push(...matchingListings.filter((listing) => matchesRowFilter(listing.section, [orPart]) || matchesRowFilter(listing.row, [orPart])));
          } else if (orPart.length <= 3 && (!isNaN(Number(orPart)) || [...orPart].every((char) => char === orPart[0]))) {
            // Either the part is a number or it is a string of the same character, so check sections or rows
            andListings.push(...matchingListings.filter((listing) => matchesRowFilter(listing.section, [orPart]) || matchesRowFilter(listing.row, [orPart])));
          } else {
            // Otherwise, check event name, venue name, city, state, or section
            andListings.push(
              ...matchingListings.filter(
                (listing) =>
                  listing.venueName.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.city?.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.state?.toLowerCase().includes(orPart.toLowerCase()) ||
                  listing.section.toLowerCase().includes(orPart.toLowerCase()),
              ),
            );
          }
        });

        matchingListings = andListings;
      });

      setFilteredListings([...matchingListings]);
    } else {
      setFilteredListings(mergedEventListings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryQuickFilter, mergedEventListings]);

  const closeExpandedNode = useCallback((nodeId: string) => {
    gridRef.current?.api.getRowNode(nodeId)?.setExpanded(false);
  }, []);

  type BNInventoryColumn = ColDef<InventorySeasonLocationExtended> & {
    exclude?: boolean;
    allowSorting?: boolean;
    sort?: 'asc' | 'desc' | null;
    sortIndex?: number | null;
  };
  const [selectedHistoryListing, setSelectedHistoryListing] = useState<{
    listing: InventorySeasonLocationExtended | null;
    isOpen: boolean;
  }>({ listing: null, isOpen: false });
  const defaultColumnDefs: BNInventoryColumn[] = useMemo(
    () =>
      [
        { field: 'seasonName', allowSorting: false, rowGroup: true, hide: true, exclude: true, sortable: false },
        {
          colId: 'dragCell',
          minWidth: 38,
          sortable: false,
          allowSorting: false,
          width: 38,
          maxWidth: 38,
          rowDrag: false,
          suppressCellFlash: true,
          exclude: true,
          valueGetter: (params) => JSON.stringify({ ruleCount: params.data?.ruleCount }),
          cellRenderer: (params: ICellRendererParams<InventorySeasonLocationExtended>) => (
            <DragCell
              hashId={params.data?.hashId ?? ''}
              node={params.node}
              level={params.node.level}
              ruleCount={params.data?.ruleCount || 0}
              registerRowDragger={params.registerRowDragger}
            />
          ),
          cellStyle: { textAlign: 'left' },
        },
        {
          field: 'section',
          headerName: 'Section',
          rowDrag: false,
          minWidth: 80,
          width: 160,
          sort: 'asc',
          sortIndex: 0,
          cellStyle: { textAlign: 'left' },
          comparator: (a, b) => a?.localeCompare(b, undefined, { numeric: true }),
        },
        {
          field: 'row',
          headerName: 'Row',
          minWidth: 70,
          width: 70,
          sort: 'asc',
          sortIndex: 1,
          cellStyle: { textAlign: 'center' },
          comparator: (a, b) => a?.localeCompare(b, undefined, { numeric: true }),
        },
        {
          colId: 'seats',
          minWidth: 90,
          width: 90,
          headerName: 'Seats',
          cellStyle: { textAlign: 'center' },
          valueGetter: (params) => {
            if (params.data) {
              const { listings } = params.data;
              const seatFrom = listings.reduce(
                (acc, listing) => {
                  if (listing.seatFrom) {
                    if (acc === null) {
                      return listing.seatFrom;
                    }
                    return listing.seatFrom < acc ? listing.seatFrom : acc;
                  }
                  return acc;
                },
                null as string | null,
              );
              const seatThru = listings.reduce(
                (acc, listing) => {
                  if (listing.seatFrom) {
                    if (acc === null) {
                      return listing.seatThru;
                    }
                    return listing.seatFrom < acc ? listing.seatThru : acc;
                  }
                  return acc;
                },
                null as string | null,
              );

              return `${seatFrom}-${seatThru}`;
            }

            return '';
          },
        },
        {
          colId: 'quantity',
          minWidth: 70,
          width: 70,
          headerName: 'Qty',
          sort: 'desc',
          sortIndex: 2,
          valueGetter: (params) => params.data?.listings.reduce((max, listing) => Math.max(max, listing.quantity), 0),
          cellStyle: { textAlign: 'center' },
        },
        {
          field: 'totalCost',
          minWidth: 110,
          width: 110,
          headerName: 'Total Cost',
          valueFormatter: (params) => formatCurrency(params.value),
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        {
          colId: 'autoPriced',
          minWidth: 110,
          width: 110,
          headerName: 'A / P',
          valueGetter: (params) => {
            const autoPriced = params.data?.listings.reduce((acc, listing) => acc + (listing.pricerStatusId !== 'None' ? 1 : 0), 0);
            const total = params.data?.listings.length;
            return `${autoPriced} / ${total}`;
          },
        },
      ] as BNInventoryColumn[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const columnDefs: ColDef<InventorySeasonLocationExtended>[] = useMemo(
    () =>
      defaultColumnDefs
        .map((col) => {
          // Clears properties before giving it to AG-Grid to avoid warnings.
          const { allowSorting, exclude, ..._col } = col;
          const savedState = inventoryGridState?.filter((x) => !['dragCell'].includes(x.colId)).find((y) => y.colId === col.colId || y.colId === col.field) || {};
          if (_col.colId === 'dragCell') {
            _col.rowDrag = false;
          }
          return { ..._col, ...savedState };
        })
        .sort((a, b) => {
          const aIndex = inventoryGridState?.findIndex((x) => x.colId === a.colId);
          const bIndex = inventoryGridState?.findIndex((x) => x.colId === b.colId);
          return aIndex - bIndex;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSort, currentUser],
  );

  /**
   * Handles selecting the initial inventory listing for marketplace view when grid is loaded and ready
   * @param event
   */
  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      (window as any).gridRef = event;
      if (inventoryGridState && inventoryGridState.length > 0) {
        // event.columnApi.applyColumnState({ state: inventoryGridState, applyOrder: true });
      } else {
        event.columnApi.applyColumnState({
          state: [
            { colId: 'section', sort: 'asc' },
            { colId: 'row', sort: 'asc' },
            { colId: 'quantityRemaining', sort: 'desc' },
          ],
        });
      }
      // Handles hot reloading
      if (selectedLocation) {
        event.api.getRowNode(selectedLocation.hashId)?.setSelected(true);
      }
      setGridReady(true);
    },
    [inventoryGridState, selectedLocation],
  );

  const onCellClickedWhenDirty = useCallback(
    async (gridEvent: CellClickedEvent<InventorySeasonLocationExtended>) => {
      if (gridEvent.node.data && gridEvent.node.data.hashId !== selectedLocation?.hashId) {
        if (await validateDirtyRuleState()) {
          closeExpandedNode(selectedLocation!.hashId);
          setSelectedLocation(gridEvent.node.data);
          gridEvent.node.setSelected(true);

          requestAnimationFrame(() => {
            gridRef.current?.api.ensureNodeVisible(gridEvent.node);
          });
        }
      }
    },
    [closeExpandedNode, selectedLocation, setSelectedLocation, validateDirtyRuleState],
  );

  useDidUpdate(() => {
    if (selectedLocation) {
      const node = gridRef.current?.api?.getRowNode(selectedLocation.hashId);
      if (node && selectedLocation) {
        if (previousSelectedLocation?.hashId) {
          closeExpandedNode(previousSelectedLocation.hashId);
        }
        node.setSelected(true);
        if (node?.isExpandable()) {
          node.setExpanded(true);
        }
      }
    }
    setDetailGridToAlign([]);
  }, [selectedLocation]);

  const gridNodeSelection = useCallback(
    (
      _selectedListingId: string,
      options: {
        pendingChange: boolean;
        ensureNodeVisible: boolean;
      } = { pendingChange: false, ensureNodeVisible: true },
    ) => {
      if (gridRef.current) {
        const node = gridRef.current.api.getRowNode(_selectedListingId) as RowNode<InventorySeasonLocationExtended>;
        if (node && node.data) {
          if (!node.isSelected()) {
            node.setSelected(true);
            setSelectedLocation(node.data);
          }
          if (!node.isExpandable()) {
            if (detailGridToAlign.length > 0) {
              setDetailGridToAlign([]);
            }
          }
          if (node.isExpandable() && !node.expanded) {
            node.setExpanded(true);
          }
          if (options.ensureNodeVisible) {
            requestAnimationFrame(() => {
              gridRef.current?.api.ensureNodeVisible(node);
            });
          }
        } else if (!options.pendingChange) {
          const firstNode = gridRef.current?.api.getRenderedNodes()[1] as RowNode<InventorySeasonLocationExtended>;
          if (firstNode && firstNode.data) {
            firstNode.setSelected(true);
            setSelectedLocation(firstNode.data);
          }
        }
      }
    },
    [detailGridToAlign.length, setSelectedLocation],
  );

  const onComponentStateChanged = (event: ComponentStateChangedEvent<InventorySeasonLocationExtended>) => {
    const firstNode = event.api.getRenderedNodes()[1];
    const selected = event.api.getSelectedNodes()[0];
    if (!selectedLocation) {
      if (firstNode && firstNode.data) {
        setSelectedLocation(firstNode.data);
      }
    } else if (selectedLocation && !selected) {
      const node = event.api.getRowNode(selectedLocation.hashId);
      if (node) {
        node.setSelected(true);
      } else if (firstNode && firstNode.data) {
        setSelectedLocation(firstNode.data);
      }
      // Selected location doesn't exist lets try and select a new one
    } else if (firstNode && firstNode.data && selectedLocation.seasonName !== firstNode.data.seasonName) {
      setSelectedLocation(firstNode.data);
    }
  };

  const trySaveGridState = useCallback(
    (event: ColumnEvent) => {
      if (gridReady) {
        const state = event.columnApi?.getColumnState()!;
        setInventoryGridState(state);
        if (event.type === 'columnMoved') {
          document.dispatchEvent(onColumnMoved);
        }
        if (event.type === 'sortChanged') {
          document.dispatchEvent(onColumnSorted);
        }
      }
    },
    [gridReady, setInventoryGridState],
  );

  const moveNextSelectedRow = useCallback(async () => {
    const index = gridRef.current?.api.getSelectedNodes()[0]?.rowIndex ?? 0;
    const topLevelRows = gridRef.current?.api.getRenderedNodes().filter((x) => x.uiLevel === 1 && !x.detail && x.rowIndex! > index);
    const node = topLevelRows?.[0] as RowNode<InventorySeasonLocationExtended>;
    if (node && node.data && node.data.hashId !== selectedLocation?.hashId) {
      if (await validateDirtyRuleState()) {
        const { hashId } = node.data;
        setSelectedLocation(node.data);
        gridNodeSelection(hashId);
        requestAnimationFrame(() => {
          gridRef.current?.api.ensureNodeVisible(node);
        });
      }
    }
  }, [gridNodeSelection, selectedLocation?.hashId, setSelectedLocation, validateDirtyRuleState]);

  const movePreviousSelectedRow = useCallback(async () => {
    const index = gridRef.current?.api.getSelectedNodes()[0]?.rowIndex ?? 0;
    const topLevelRows = gridRef.current?.api
      .getRenderedNodes()
      .filter((x) => x.uiLevel === 1 && !x.detail && x.rowIndex! < index)
      .sort((a, b) => b.rowIndex! - a.rowIndex!);
    const node = topLevelRows?.[0] as RowNode<InventorySeasonLocationExtended>;
    if (!node?.data || node.data.hashId === selectedLocation?.hashId) {
      return;
    }
    if (await validateDirtyRuleState()) {
      const { hashId } = node.data;
      setSelectedLocation(node.data);
      gridNodeSelection(hashId);
      requestAnimationFrame(() => {
        gridRef.current?.api.ensureNodeVisible(node);
      });
    }
  }, [gridNodeSelection, selectedLocation?.hashId, setSelectedLocation, validateDirtyRuleState]);

  useEffect(() => {
    document.addEventListener(onNextInventoryItem.type, moveNextSelectedRow);
    document.addEventListener(onPrevInventoryItem.type, movePreviousSelectedRow);

    return () => {
      document.removeEventListener(onNextInventoryItem.type, moveNextSelectedRow);
      document.removeEventListener(onPrevInventoryItem.type, movePreviousSelectedRow);
    };
  }, [moveNextSelectedRow, movePreviousSelectedRow]);

  const detailGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dropZoneParams = gridRef.current!.api.getRowDropZoneParams({ onDragStop: onDetailMasterRowDragEnd });
      detailGridRef.current = { api: params.api, columnApi: params.columnApi } satisfies Partial<AgGridReact>;
      (window as any).detailsGridApi = params;
      params.api.addRowDropZone(dropZoneParams);
      params.columnApi.applyColumnState({ state: [{ colId: 'ruleTier', sort: 'asc', sortIndex: 0 }] });
      const grids: DetailGridInfo[] = [];
      gridRef.current?.api.forEachDetailGridInfo((grid) => {
        grids.push(grid);
      });
      setDetailGridToAlign(grids);
    },
    [onDetailMasterRowDragEnd],
  );

  const getDetailRowDataResults = useCallback(
    (hashId: string, ruleId: string | null | undefined) => {
      const groupedListings = selectedSeasonLocations
        ?.filter((x) => x.ruleId === ruleId)
        .sort((a, b) => (a.ruleTier || 0) - (b.ruleTier || 0))
        .filter((x) => x.hashId !== hashId);
      return groupedListings;
    },
    [selectedSeasonLocations],
  );

  const getDetailRowData = (params: GetDetailRowDataParams) => {
    if (params.node.data.ruleId) {
      const groupedListings = getDetailRowDataResults(params.node.data.hashId, params.node.data.ruleId);
      params.successCallback(groupedListings || []);
    } else {
      params.successCallback([]);
    }
  };

  const enableCellTextSelection = useFlag('grid-text-selection');

  const detailCellRendererParams = () =>
    ({
      refreshStrategy: 'everything',
      detailGridOptions: {
        getRowId: (x) => x.data.hashId,
        alignedGrids: gridRef.current ? [gridRef.current] : undefined,
        rowSelection: 'single',
        enableRangeSelection: false,
        columnDefs: columnDefs
          .filter((x) => x.field !== 'seasonName')
          .map((x) => ({
            ...x,
            sort: null,
            sortIndex: null,
          })),
        headerHeight: -1,
        rowBuffer: 0,
        animateRows: false,
        suppressContextMenu: true,
        suppressColumnMoveAnimation: true,
        suppressRowClickSelection: true,
        suppressHorizontalScroll: true,
        enableCellChangeFlash: true,
        enableCellTextSelection,
        rowHeight: 36,
        rowStyle: { lineHeight: '28px', fontSize: 12 },
        onGridReady: detailGridReady,
        onRowDragEnd: onDetailRowDragEnd,
        onRowDragMove: onMasterRowDragMove,
        defaultColDef: {
          sortable: false,
          suppressMenu: true,
          resizable: false,
          flex: 1,
        },
        suppressCellFocus: true,
        rowDragText: (dragParams) => {
          const sectionName = dragParams.rowNode?.data.section;
          const rowName = dragParams.rowNode?.data.row;
          return `Section ${sectionName} · Row ${rowName}`;
        },
      },
      getDetailRowData,
    }) satisfies Partial<IDetailCellRendererParams<InventorySeasonLocationExtended, InventorySeasonLocationExtended>>;

  return {
    defaultColDef,
    gridRef,
    detailGridToAlign,
    mergedEventListings: filteredListings,
    columnDefs,
    onGridReady,
    onMasterRowDragEnd,
    onMasterRowDragMove,
    selectedHistoryListing,
    setSelectedHistoryListing,
    toggleShowSort,
    onCellClickedWhenDirty,
    setInventoryQuickFilter,
    setFilter,
    filter,
    inventoryQuickFilter,
    onComponentStateChanged,
    gridReady,
    clearInventoryGridState,
    trySaveGridState,
    inventoryGridState,
    defaultColumnDefs,
    isShowingEventPerformance,
    detailCellRendererParams,
  };
};

export const { Provider: InventoryStateProvider, useSelector: useInventory } = yasml(InventoryState);
