import { Box, Flex, Tooltip, Text } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import classes from './SeatingChart.module.css';
import cx from 'clsx';
import { useSeatingChart } from './SeatingChart.hooks';

interface PillsData {
  key: string;
  count: number;
}

interface RowSectionPillsProps {
  pills: PillsData[];
}

export function RowSectionPills({ pills }: RowSectionPillsProps) {
  // Use shared state to set which row group is active
  const { advancedRowsActiveIndex, setAdvancedRowsActiveIndex } = useSeatingChart('advancedRowsActiveIndex', 'setAdvancedRowsActiveIndex');
  const handlePillClick = (index: number) => {
    setAdvancedRowsActiveIndex(index);
  };
  return (
    <Flex px="xs" gap={4} align="center">
      {pills.map((pill, index) => (
        <Flex key={pill.key} className={`rows-${index}`}>
          <Tooltip label={pill.key}>
            <BNButton onClick={() => handlePillClick(index)} className={cx(classes.individualSectionButton, advancedRowsActiveIndex === index && classes.active)} variant="default">
              <Box className={classes.sectionsSelected} />
              <Text ml={4} size="xs">
                {pill.count}
              </Text>
            </BNButton>
          </Tooltip>
        </Flex>
      ))}
    </Flex>
  );
}
