import { Divider, Flex, Group, Menu, Modal, Text, UnstyledButton } from '@mantine/core';
import classes from './MoreMenuModal.module.css';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import { BNSwitch } from '../../components/Switch/Switch';
import WifiIcon from '../../components/icons/Wifi';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import SplitscreenIcon from '../../components/icons/Splitscreen';
import { BarkerEventListing } from '../../types';
import { useGlobalState } from '../../data/Global.state';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export type ListingActionsModalProps = {
  modalOpened: boolean;
  closeModal: () => void;
  listing: BarkerEventListing;
  updateBroadcasting: (tenantId: string, listingId: string, isBroadcasting: boolean) => Promise<void>;
  openSplitsModal: () => void;
  openBroadcastingModal: () => void;
};

export function MoreMenuModal({ modalOpened, closeModal, listing, updateBroadcasting, openSplitsModal, openBroadcastingModal }: ListingActionsModalProps) {
  const { currentUser } = useGlobalState('currentUser');

  const testPartialSelected = false;

  return (
    <Modal
      opened={modalOpened}
      onClose={() => closeModal()}
      closeButtonProps={{ size: 'md' }}
      withCloseButton
      className={classes.bottomSheetMenuModal}
      title="Listing"
      transitionProps={{ transition: 'slide-up' }}
    >
      <Flex p={0} h="100%" justify="space-between" pb="xl">
        <Flex className={classes.mobileMenuItemsWrapper} direction="column" w="100%">
          <Menu>
            {isDtiHosted ? (
              <Menu.Item
                className={classes.stopHover}
                leftSection={<WifiIcon size={20} />}
                component="div"
                fw={400}
                onClick={
                  testPartialSelected
                    ? () => {
                        openBroadcastingModal();
                        closeModal();
                      }
                    : undefined
                }
                rightSection={
                  <Group>
                    <BNSwitch
                      checked={listing.isBroadcasting || testPartialSelected}
                      disabled={currentUser?.roleId === 'ReadOnlyUser' || testPartialSelected}
                      onChange={() => updateBroadcasting(listing.tenantId, listing.listingId, !listing.isBroadcasting)}
                      size="md"
                      defaultChecked
                    />
                    <Text w={30} tt="uppercase" ta="center" c={listing.isBroadcasting ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)'}>
                      {testPartialSelected ? '3/11' : 'All'}
                    </Text>
                    <Divider orientation="vertical" h={24} mt="auto" mb="auto" color="var(--colors-divider)" variant="dotted" />
                    <UnstyledButton
                      onClick={() => {
                        openBroadcastingModal();
                        closeModal();
                      }}
                      h={40}
                      w={40}
                      mr={-14}
                      p={0}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <ChevronForwardIcon size={14} color="var(--colors-gray-5)" />
                    </UnstyledButton>
                  </Group>
                }
              >
                Broadcasting
              </Menu.Item>
            ) : (
              <Menu.Item
                className={classes.stopHover}
                leftSection={<WifiIcon size={20} />}
                component="div"
                fw={400}
                rightSection={
                  <BNSwitch
                    checked={listing.isBroadcasting}
                    disabled={currentUser?.roleId === 'ReadOnlyUser'}
                    onChange={() => updateBroadcasting(listing.tenantId, listing.listingId, !listing.isBroadcasting)}
                    size="md"
                    defaultChecked
                  />
                }
              >
                Broadcasting
              </Menu.Item>
            )}
            <Menu.Item
              onClick={() => {
                openSplitsModal();
                closeModal();
              }}
              leftSection={<SplitscreenIcon size={21} />}
              rightSection={<ChevronForwardIcon size={14} color="var(--colors-gray-5)" />}
              fw={400}
            >
              Splits
            </Menu.Item>
            <Menu.Item onClick={() => {}} leftSection={<LiveHelpIcon size={21} />} rightSection={<ChevronForwardIcon size={14} color="var(--colors-gray-5)" />} fw={400}>
              Request Support
            </Menu.Item>
          </Menu>
        </Flex>
      </Flex>
    </Modal>
  );
}
