import { Box, Divider, Group, SimpleGrid, Slider, Stack, Text, Title, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNSelect } from '../../components/Select/Select';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { BarkerCoreEnumsDeliveryMethod, BarkerCoreEnumsListingDisclosure, BarkerCoreEnumsMarketplace, BarkerCoreEnumsPricingMode } from '../../api';
import { useFlag } from '@unleash/proxy-client-react';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { BNSwitch } from '../../components/Switch/Switch';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import { Link } from 'react-router-dom';
import BNInputGroup from '../../components/Group/InputGroup';
import classes from './PricingSettings.module.css';
import { BNExclusionsMultiSelect } from '../../components/Select/ExclusionsMultiSelect';
import { isNumber } from '../../utils/price-utils';
import { SplitsButtonGroup } from '../../hoc/SeatingChart/Filters/Filters.SplitsButtonGroup';
import { usePricingSettings } from '../../hoc/Settings/PricingSettings.hooks';
import { useGlobalState } from '../../data/Global.state';
import { SoftFloorInput } from '../../components/SoftFloor/SoftFloor';

export default PricingSettings;

export function PricingSettings() {
  const { form, submit, cancel } = usePricingSettings('form', 'submit', 'cancel');
  const { tenants } = useGlobalState('tenants');
  const isMultiTenantUser = (tenants?.length ?? 0) > 1;

  const isTicketNetworkMarketplaceEnabled = useFlag('ticketnetwork-marketplace');
  const hasSoftFloorSettingFlag = useFlag('soft-floor-setting');

  return (
    <>
      <AdminContentHeader
        title="Pricing Settings"
        sticky
        rightSection={
          <>
            <BNButton size="xs" variant="default" disabled={!form.isDirty()} onClick={() => cancel()}>
              Cancel
            </BNButton>
            <BNButton size="xs" variant="filled" color="green" disabled={!form.isDirty()} onClick={() => submit()}>
              Save
            </BNButton>
          </>
        }
      />
      <Box pt="xl" pb={0}>
        <Title order={2} size="h4" pt="sm" mb="lg">
          Search Defaults
        </Title>
        <Box>
          <div className={classes.searchRangeWrapper}>
            <BNInputGroup label={form.values.searchRange.startsWith('c') ? 'Search Range (# of days)' : 'Search Range'}>
              <BNInputGroup.Item w={140}>
                <BNSelect
                  size="xs"
                  style={{ flex: 1 }}
                  miw={120}
                  label={form.values.searchRange.startsWith('c') ? 'Search Range (# of days)' : 'Search Range'}
                  data={[
                    { value: 'd1', label: 'Today' },
                    { value: 'w1', label: '1 Week' },
                    { value: 'M1', label: '1 Month' },
                    { value: 'y1', label: '1 Year' },
                    { value: 'all', label: 'All' },
                    { value: 'c', label: 'Custom' },
                  ]}
                  {...form.getInputProps('searchRange')}
                />
              </BNInputGroup.Item>
              {form.values.searchRange.startsWith('c') && (
                <BNInputGroup.Item w={140}>
                  <BNNumberInput size="xs" min={0} placeholder="# of Days" {...form.getInputProps('searchRangeCustom')} />
                </BNInputGroup.Item>
              )}
            </BNInputGroup>
          </div>
        </Box>
      </Box>
      <Box py="xl" pb="lg">
        <Box pb="lg">
          <Title order={2} size="h4" pt="sm">
            Pricing Mode
          </Title>
          <Text size="xs" mb="lg" c="var(--colors-gray-5)">
            Pricing mode allow for immediate price changes or setting multiple prices in a pending state before applying them to the listings.
          </Text>
          <Box>
            <div style={{ maxWidth: 280 }}>
              <BNSelect
                size="xs"
                label="Pricing Mode"
                data={[
                  { value: BarkerCoreEnumsPricingMode.Individual, label: BarkerCoreEnumsPricingMode.Individual },
                  { value: BarkerCoreEnumsPricingMode.Staged, label: BarkerCoreEnumsPricingMode.Staged },
                ]}
                value={form.values.pricingMode}
                onChange={(e) => form.setFieldValue('pricingMode', e! as BarkerCoreEnumsPricingMode)}
              />
            </div>
            <Text fz={11} mt={4} c="gray.5">
              {form.values.pricingMode === BarkerCoreEnumsPricingMode.Individual && 'You must save your changes to each listing as you price them.'}
              {form.values.pricingMode === BarkerCoreEnumsPricingMode.Staged && 'You can set multiple prices in a pending state before applying them to the listings.'}
            </Text>
          </Box>
        </Box>
        <Box pb="lg">
          <Title order={2} size="h4" pt="sm">
            Marketplace Defaults
          </Title>
          <Text size="xs" mb="lg" c="var(--colors-gray-5)">
            These settings will be used as defaults for the pricer and can be overridden for each listing.
          </Text>
          <Box>
            <div style={{ maxWidth: 280 }}>
              <BNSelect
                size="xs"
                label="Default Marketplace"
                data={
                  isTicketNetworkMarketplaceEnabled
                    ? [
                        { value: BarkerCoreEnumsMarketplace.TicketEvolution, label: 'Ticket Evolution' },
                        { value: BarkerCoreEnumsMarketplace.SeatGeek, label: 'SeatGeek' },
                        { value: BarkerCoreEnumsMarketplace.TicketNetwork, label: 'TicketNetwork' },
                        { value: BarkerCoreEnumsMarketplace.Ticketmaster, label: 'Ticketmaster' },
                      ]
                    : [
                        { value: BarkerCoreEnumsMarketplace.TicketEvolution, label: 'Ticket Evolution' },
                        { value: BarkerCoreEnumsMarketplace.SeatGeek, label: 'SeatGeek' },
                        { value: BarkerCoreEnumsMarketplace.Ticketmaster, label: 'Ticketmaster' },
                      ]
                }
                value={form.values.defaultMarketplace}
                onChange={(e) => form.setFieldValue('defaultMarketplace', e! as BarkerCoreEnumsMarketplace)}
              />
            </div>
          </Box>
        </Box>
        <Box pb="xl">
          <Title order={3} size="h5" mt="xs">
            Rows
          </Title>
          <Text size="xs" mb="xs" c="var(--colors-gray-5)">
            Based on your listing&apos;s row, the pricer can decide which rows to compare against.
          </Text>
          {form.values.rowComparisons.map((rowComparison, index) => (
            <Group mb="md" gap={12} align="end" key={index}>
              <div>
                <BNTextInput label="Row Range" w={132} size="xs" placeholder="1-10" {...form.getInputProps(`rowComparisons.${index}.rowRange`)} />
              </div>
              <div>
                <BNNumberInput label="+/- Rows" w={132} size="xs" placeholder="5" {...form.getInputProps(`rowComparisons.${index}.threshold`)} />
              </div>
              <BNButton
                variant="light"
                size="xs"
                onClick={() => {
                  form.removeListItem('rowComparisons', index);
                }}
              >
                Remove
              </BNButton>
            </Group>
          ))}
          <BNButton variant="default" size="xs" onClick={() => form.insertListItem('rowComparisons', { rowRange: '', threshold: 0 })}>
            Add a Row Range
          </BNButton>
        </Box>
        <Box pb="xl">
          <Title order={3} size="h5" mt="xs">
            Splits
          </Title>
          <Text size="xs" mb="xs" c="var(--colors-gray-5)">
            Select the splits you would like to include by default for specific ticket quantities.
          </Text>
          <Box maw={280}>
            <Group wrap="nowrap" gap={0}>
              <Text w={44} size="xs" c="var(--colors-gray-5)">
                Qty
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                Splits
              </Text>
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                1
              </Text>
              <Box w="100%">
                <SplitsButtonGroup
                  splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['1']}
                  disabled={form.values.autoAdjustSplits}
                  onChange={(splits) => {
                    form.setFieldValue('quantitySplitMatrix', {
                      ...form.values.quantitySplitMatrix,
                      1: splits,
                    });
                  }}
                  size="xs"
                />
              </Box>
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                2
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['2']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    2: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                3
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['3']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    3: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                4
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['4']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    4: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                5
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['5']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    5: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                6
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['6']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    6: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                7
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['7']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    7: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                8+
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['8']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    8: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Text size="xs" mb="xs" c="var(--colors-gray-5)" style={{ textWrap: 'nowrap' }}>
              Listings identified as general admission will use the following splits. If none are selected, the pricer will fallback to the appropriate split based on the quantity.
            </Text>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                GA
              </Text>
              <SplitsButtonGroup
                splits={form.values.generalAdmissionSplits}
                onChange={(splits) => {
                  form.setFieldValue('generalAdmissionSplits', splits);
                }}
                size="xs"
              />
            </Group>
            <Text size="xs" mb="xs" c="var(--colors-gray-5)" style={{ textWrap: 'nowrap' }}>
              If your row isn&#39;t numeric, single-letter, double-letter, or triple-letter, the pricer will assume it&#39;s general admission.
            </Text>
          </Box>
          <Box py="xs">
            <BNSwitch className={classes.switchOutline} {...form.getInputProps('autoAdjustSplits', { type: 'checkbox' })} outlined label="Auto-Splits" size="sm" />
            {isMultiTenantUser ? (
              <>
                <Text size="xs" c="var(--colors-gray-5)" py="xs">
                  Let the auto-pricer maintain your splits based on the quantity of tickets in the listing.
                </Text>
                <Text maw={480} component="p" size="xs" c="var(--colors-gray-5)" my={0} bg="var(--colors-paperHover)" p="xs" px="sm" style={{ borderRadius: 3 }}>
                  Auto-splits are based on company-wide settings. To adjust these settings you must select a company from the{' '}
                  <Link to="/companies">
                    <Text component="span" td="underline" c="var(--colors-brandcolor-5)">
                      Companies
                    </Text>
                  </Link>{' '}
                  page.
                </Text>
              </>
            ) : (
              <>
                <Text size="xs" c="var(--colors-gray-5)" py="xs">
                  Let the auto-pricer maintain your splits based on the quantity of tickets in the listings.
                </Text>
                <Text maw={480} component="p" size="xs" c="var(--colors-gray-5)" my={0} bg="var(--colors-paperHover)" p="xs" px="sm" style={{ borderRadius: 3 }}>
                  Auto-splits are based on company-wide settings available in the{' '}
                  <Link to={`/companies/${tenants?.[0].tenantId}/settings/pricing`}>
                    <Text component="span" td="underline" c="var(--colors-brandcolor-5)">
                      Company Settings
                    </Text>
                  </Link>{' '}
                  page.
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box pb="xl" style={{ maxWidth: 280 }}>
          <Title order={3} size="h5">
            Exclusions
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            Exclude listings by default with these traits.
          </Text>
          <BNExclusionsMultiSelect
            removeSelected
            value={((form.values.exclusions ?? []) as (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]).concat(form.values.deliveryExclusions ?? [])}
            placeholder="None"
            onChange={(value: (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod | string)[]) => {
              const deliveryExclusions = value.filter((v) =>
                Object.values(BarkerCoreEnumsDeliveryMethod).includes(v as unknown as BarkerCoreEnumsDeliveryMethod),
              ) as BarkerCoreEnumsDeliveryMethod[];
              const disclosureExclusions = value.filter((v) =>
                Object.values(BarkerCoreEnumsListingDisclosure).includes(v as unknown as BarkerCoreEnumsListingDisclosure),
              ) as BarkerCoreEnumsListingDisclosure[];

              form.setFieldValue('exclusions', disclosureExclusions);
              form.setFieldValue('deliveryExclusions', deliveryExclusions);
            }}
          />
        </Box>
        <Box style={{ maxWidth: 280 }}>
          <Title order={3} size="h5">
            Outliers
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            Exclude outlier listings based on their percentage below the market.
          </Text>
          <SimpleGrid cols={2}>
            <BNSelect
              clearable
              data={[
                { value: '2', label: '2nd Position' },
                { value: '3', label: '3rd Position' },
                { value: '4', label: '4th Position' },
                { value: '5', label: '5th Position' },
                { value: '6', label: '6th Position' },
              ]}
              label="Compare to"
              size="xs"
              placeholder="Select a position"
              onChange={(value) => {
                if (!value) {
                  form.setFieldValue('outlierCriteria.percentUnder', 0);
                  form.setFieldValue('outlierCriteria.comparableIndex', 0);
                } else if (isNumber(value)) {
                  form.setFieldValue('outlierCriteria.comparableIndex', parseInt(value));
                }
              }}
              error={form.errors['outlierCriteria.comparableIndex']}
              value={(form.values.outlierCriteria?.comparableIndex ?? 0) > 0 ? form.values.outlierCriteria?.comparableIndex.toString() : null}
              className={classes.outlierInput}
            />
            <Stack gap={1} justify="end">
              <Text component="label" c={form.errors['outlierCriteria.percentUnder'] ? 'var(--colors-red-5)' : 'var(--colors-gray-5)'} size="xs" lh="1.25rem">
                Percentage Under
              </Text>
              <Group gap="xs" wrap="nowrap" pos="relative">
                <Slider size="sm" min={0} max={20} step={1} label={null} w={220} className={classes.pctSlider} {...form.getInputProps('outlierCriteria.percentUnder')} />
                <BNNumberInput
                  label=""
                  size="xs"
                  min={0}
                  max={20}
                  hideControls
                  w={240}
                  fixedDecimalScale
                  decimalScale={0}
                  className={classes.pctInput}
                  selectOnFocus
                  rightSection={
                    <Text size="xs" c="var(--colors-gray-4)">
                      %
                    </Text>
                  }
                  rightSectionWidth={24}
                  error={form.errors['outlierCriteria.percentUnder']}
                  {...form.getInputProps('outlierCriteria.percentUnder')}
                />
              </Group>
            </Stack>
          </SimpleGrid>
        </Box>
        {hasSoftFloorSettingFlag && (
          <>
            <Divider color="var(--colors-gray-1)" variant="dotted" my="xl" />
            <Box style={{ maxWidth: 600 }}>
              <Title order={2} size="h4">
                Advanced Feature Toggles
              </Title>
              <Text size="xs" c="var(--colors-gray-5)">
                These features will unlock more advanced functionality within the pricer. Please consult the FAQ and use with caution.
              </Text>
              <Box py="xs">
                <BNSwitch
                  size="sm"
                  label="Soft Floors"
                  {...form.getInputProps('featureFlags.softFloor', { type: 'checkbox' })}
                  onChange={(e) => {
                    form.setFieldValue('featureFlags.softFloor', e.currentTarget.checked);
                    if (!e.currentTarget.checked) {
                      form.setFieldValue('softFloor', undefined);
                    } else {
                      form.setFieldValue('softFloor', {
                        value: 0,
                        typeId: 'Amount',
                        period: '1.00:00:00',
                      });
                    }
                  }}
                />
                <Text size="xs" c="var(--colors-gray-5)" py="xs">
                  Define criteria for how far a listing can drop in price over a period of time.
                </Text>
              </Box>
            </Box>
          </>
        )}
        <Divider color="var(--colors-gray-1)" variant="dotted" my="xl" />
        <Box>
          <Title order={2} size="h4">
            Automation
          </Title>
          <Text size="xs" c="var(--colors-gray-5)">
            These settings will be used as defaults for the auto-pricer and scheduled pricer and can be overridden for each listing.
          </Text>
          <Box py="xs">
            <BNSwitch outlined className={classes.switchOutline} {...form.getInputProps('broadcastSingleListing', { type: 'checkbox' })} label="Auto-Broadcast" size="sm" />
            <Text size="xs" c="var(--colors-gray-5)" py="xs">
              Automatically broadcast listings any time you enable auto-pricing.
            </Text>
          </Box>
        </Box>
        <Divider color="var(--colors-gray-1)" variant="dotted" my="xl" />
        <Box>
          <Title order={2} size="h5">
            Auto-Pricing Defaults
          </Title>
          <Text size="xs" c="var(--colors-gray-5)">
            These settings will be used as defaults for the auto-pricer and can be overridden for each listing.
          </Text>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <AdjustmentValueInputDiagonal
                label="Adjustment"
                onChange={(value) => {
                  form.getInputProps('adjustmentValue').onChange(value.value);
                  form.getInputProps('adjustmentTypeId').onChange(value.type);
                }}
                value={form.values.adjustmentValue || 0}
                type={form.values.adjustmentTypeId || 'Amount'}
                disabled={false}
                numInputWidth={83}
              />
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput
                label="# Comparables"
                size="xs"
                min={1}
                selectOnFocus
                value={form.values.numComparables?.[0].numComparables}
                onChange={(value) => form.setFieldValue('numComparables.0.numComparables', value)}
              />
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <BNNumberInput label="Floor" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('floorMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of lowest comparable
              </Text>
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput label="Ceiling" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('ceilingMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of lowest comparable
              </Text>
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <BNNumberInput label="Floor" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('floorCostMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of listing cost
              </Text>
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput label="Ceiling" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('ceilingCostMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of listing cost
              </Text>
            </Box>
          </Group>
          {form.getInputProps('featureFlags.softFloor').value && (
            <Group w={300}>
              <Box py="xs" w={280}>
                <SoftFloorInput
                  min={0}
                  softFloor={form.values.softFloor}
                  onFloorChange={(value) => {
                    form.setValues({
                      ...form.values,
                      softFloor: {
                        ...form.values.softFloor,
                        value: value.value ?? 0,
                        typeId: value.type,
                        period: form.values.softFloor?.period ?? '1.00:00:00',
                      },
                    });
                  }}
                  onPeriodChange={(value) => {
                    form.setFieldValue('softFloor.period', value);
                  }}
                />
              </Box>
            </Group>
          )}
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <Box pos="relative">
                <Text size="xs" component="label" c="var(--colors-gray-5)" lh="1.25rem">
                  # Active
                </Text>
                <Tooltip withArrow label="Broadcast all listings in the group">
                  <Box pos="absolute" top={6} right={0}>
                    <BNSwitch
                      checked={form.values.numActive >= 2147483647}
                      onChange={() => form.setFieldValue('numActive', form.values.numActive >= 2147483647 ? 1 : 2147483647)}
                      size="xxs"
                      label="ALL"
                    />
                  </Box>
                </Tooltip>
                {form.values.numActive >= 2147483647 && <BNTextInput disabled size="xs" value="All Listings" />}
                {form.values.numActive < 2147483647 && (
                  <BNNumberInput {...form.getInputProps('numActive')} decimalScale={0} fixedDecimalScale allowDecimal={false} min={0} step={1} size="xs" />
                )}
              </Box>
            </Box>
            <Box py="xs" w={134}>
              <StaggerByValueInput
                numInputWidth={83}
                disabled={false}
                label="Stagger By"
                onChange={(value) => {
                  form.getInputProps('staggerByValue').onChange(value.value);
                  form.getInputProps('staggerByTypeId').onChange(value.type);
                }}
                value={form.values.staggerByValue || 0}
                type={form.values.staggerByTypeId || 'Amount'}
              />
            </Box>
          </Group>
        </Box>
        <Divider color="var(--colors-gray-1)" my="xl" />
        <Box>
          <Title order={2} size="h5">
            Scheduled Pricing Defaults
          </Title>
          <Text size="xs" c="var(--colors-gray-5)">
            These settings will be used as defaults for the scheduled pricer and can be overridden for each listing.
          </Text>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <AdjustmentValueInputDiagonal
                label="Adjustment"
                onChange={(value) => {
                  form.getInputProps('schedulePricerSettings.adjustmentValue').onChange(value.value);
                  form.getInputProps('schedulePricerSettings.adjustmentTypeId').onChange(value.type);
                }}
                value={form.values.schedulePricerSettings?.adjustmentValue || 0}
                type={form.values.schedulePricerSettings?.adjustmentTypeId || 'Amount'}
                disabled={false}
                numInputWidth={83}
              />
            </Box>
            <Box py="xs" w={134}>
              <BNSelect
                size="xs"
                style={{ flex: 1 }}
                miw={120}
                label="Interval"
                data={[
                  { value: '5', label: '5 Minutes' },
                  { value: '15', label: '15 Minutes' },
                  { value: '60', label: '1 Hour' },
                  { value: '1440', label: '1 Day' },
                ]}
                {...form.getInputProps('schedulePricerSettings.intervalMinutes')}
                value={form.values.schedulePricerSettings?.intervalMinutes?.toString()}
              />
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <BNNumberInput
                label="Floor"
                size="xs"
                step={1}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                selectOnFocus
                {...form.getInputProps('schedulePricerSettings.floorMultiplier')}
              />
              <Text fz={11} mt={4} color="var(--colors-gray-5)">
                % of cost
              </Text>
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput
                label="Ceiling"
                size="xs"
                step={1}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                selectOnFocus
                {...form.getInputProps('schedulePricerSettings.ceilingMultiplier')}
              />
              <Text fz={11} mt={4} color="var(--colors-gray-5)">
                % of cost
              </Text>
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <Box pos="relative">
                <Text size="xs" c="var(--colors-gray-5)" component="label" lh="1.25rem">
                  # Active
                </Text>
                <Tooltip withArrow label="Broadcast all listings in the group">
                  <Box pos="absolute" top={6} right={0}>
                    <BNSwitch
                      checked={form.values.schedulePricerSettings.numActive >= 2147483647}
                      onChange={() => form.setFieldValue('schedulePricerSettings.numActive', form.values.schedulePricerSettings.numActive >= 2147483647 ? 1 : 2147483647)}
                      size="xxs"
                      label="ALL"
                    />
                  </Box>
                </Tooltip>
                {form.values.schedulePricerSettings.numActive >= 2147483647 && <BNTextInput disabled size="xs" value="All Listings" />}
                {form.values.schedulePricerSettings.numActive < 2147483647 && (
                  <BNNumberInput {...form.getInputProps('schedulePricerSettings.numActive')} decimalScale={0} fixedDecimalScale allowDecimal={false} min={0} step={1} size="xs" />
                )}
              </Box>
            </Box>
            <Box py="xs" w={134}>
              <StaggerByValueInput
                numInputWidth={83}
                disabled={false}
                label="Stagger By"
                onChange={(value) => {
                  form.getInputProps('schedulePricerSettings.staggerByValue').onChange(value.value);
                  form.getInputProps('schedulePricerSettings.staggerByTypeId').onChange(value.type);
                }}
                value={form.values.schedulePricerSettings?.staggerByValue || 0}
                type={form.values.schedulePricerSettings?.staggerByTypeId || 'Amount'}
              />
            </Box>
          </Group>
        </Box>
      </Box>
    </>
  );
}
