import { Box, Divider, Group, Skeleton, Space, Text } from '@mantine/core';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { BNButton } from '../../components/Button/Button';
import { BNTextInput } from '../../components/TextInput/TextInput';
import InfoIcon from '../../components/icons/Info';
import GoogleIcon from '../../components/icons/Google';
import { useGlobalState } from '../../data/Global.state';
import { useForm } from '@mantine/form';
import { BNSwitch } from '../../components/Switch/Switch';
import {
  getGetApiPrincipalsSelfQueryKey,
  useGetApiPrincipalsMarketing,
  usePatchApiPrincipalsSelf,
  usePutApiPrincipalsMarketingSubscribe,
  usePutApiPrincipalsMarketingUnsubscribe,
} from '../../api';
import { useQueryClient } from '@tanstack/react-query';
import { supportEmail } from '../../utils/whitelabel-consts';

export default function ProfileSettings() {
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const queryClient = useQueryClient();

  const {
    data: isMarketingAllowed,
    isLoading,
    refetch,
  } = useGetApiPrincipalsMarketing({
    query: {
      select: (data) => data.data.isMarketingAllowed,
    },
  });
  const { mutateAsync: subscribe } = usePutApiPrincipalsMarketingSubscribe();
  const { mutateAsync: unsubscribe } = usePutApiPrincipalsMarketingUnsubscribe();
  const { mutateAsync: updateSelf } = usePatchApiPrincipalsSelf();
  const form = useForm({
    initialValues: {
      givenName: principal?.givenName ?? '',
      familyName: principal?.familyName ?? '',
    },
  });

  if (!principal || !tenants) {
    return null;
  }

  return (
    <>
      <AdminContentHeader
        title="Profile"
        sticky
        rightSection={
          <>
            <BNButton
              size="xs"
              variant="default"
              disabled={!form.isDirty()}
              onClick={() => {
                form.setValues({ givenName: principal.givenName, familyName: principal.familyName });
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              size="xs"
              variant="filled"
              color="green"
              disabled={!form.isDirty()}
              onClick={() =>
                form.onSubmit((values) => {
                  updateSelf(
                    {
                      data: [
                        { op: 'Replace', path: '/givenName', value: values.givenName },
                        { op: 'Replace', path: '/familyName', value: values.familyName },
                      ],
                    },
                    {
                      onSuccess: (data) => {
                        queryClient.setQueryData(getGetApiPrincipalsSelfQueryKey(), () => data);
                      },
                    },
                  ).then((data) => {
                    const response = data.data;
                    form.resetDirty({
                      givenName: response.givenName,
                      familyName: response.familyName,
                    });
                  });
                })()
              }
            >
              Save
            </BNButton>
          </>
        }
      />
      <Box pt="xl" pb="lg">
        <Box>
          <Group wrap="nowrap" gap="xl">
            <BNTextInput maw={280} size="xs" label="First Name" {...form.getInputProps('givenName')} />
            <BNTextInput maw={280} size="xs" label="Last Name" {...form.getInputProps('familyName')} />
          </Group>
          <Space h="md" />
          <Space h="md" />
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <Space h="xl" />
          <Group wrap="nowrap" align="start" gap="xl">
            <Box maw={280} w="100%">
              <Group align="center">
                <BNTextInput maw={280} size="xs" label="Email Address" defaultValue={principal.email} disabled />
              </Group>
              <Text size="xs" c="var(--colors-gray-5)" maw={280} mt="xs" pr="xs">
                E-mail Address can only be modified by contacting{' '}
                <Text component="a" href={`mailto:${supportEmail}`} td="underline" mr={4} c="var(--colors-brandcolor-5)">
                  {supportEmail}
                </Text>
              </Text>
            </Box>
            <Box maw={280} w="100%">
              {principal.openId.startsWith('google') ? (
                <>
                  <Text size="xs" c="var(--colors-gray-5)" pt={5}>
                    Password
                  </Text>
                  <Group gap="xs">
                    <Box h={18} w={18}>
                      <GoogleIcon size={18} />
                    </Box>
                    <Text size="xs" c="var(--colors-gray-7)" h={32} display="flex" style={{ alignItems: 'center' }}>
                      Managed by your Google account
                    </Text>
                  </Group>
                </>
              ) : (
                <>
                  <BNTextInput size="xs" maw={320} label="Password" disabled type="password" defaultValue="you peeked" />
                </>
              )}
            </Box>
          </Group>
          <Space h="md" />
          <Space h="md" />
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <Space h="xl" />
          <Box>
            <Skeleton animate visible={isLoading} maw={280}>
              <BNSwitch
                size="sm"
                outlined
                label="Receive Marketing Emails"
                checked={isMarketingAllowed ?? false}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    subscribe().then(() => refetch());
                  } else {
                    unsubscribe().then(() => refetch());
                  }
                }}
              />
            </Skeleton>
          </Box>
          <Space h="xl" />
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <Space h="xl" />
          <Group align="center" gap="xs">
            <InfoIcon color="var(--colors-gray-5)" />
            <Text size="xs" c="var(--colors-gray-5)" pr="xs">
              To close your account, please contact{' '}
              <Text component="a" href={`mailto:${supportEmail}`} td="underline" mr={4} c="var(--colors-brandcolor-5)">
                {supportEmail}
              </Text>
            </Text>
          </Group>
        </Box>
      </Box>
    </>
  );
}
