import { ActionIcon, Badge, Box, Button, Center, Divider, Flex, Group, Popover, ScrollArea, SimpleGrid, Skeleton, Space, Text, UnstyledButton } from '@mantine/core';
import mobileClasses from './Mobile.module.css';
import classes from './ListingDetails.Mobile.module.css';
import cx from 'clsx';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronBackIcon from '../components/icons/ChevronBack';
import { BNButton } from '../components/Button/Button';
import MoreHorizIcon from '../components/icons/MoreHoriz';
import { BNSwitch } from '../components/Switch/Switch';
import WifiIcon from '../components/icons/Wifi';
import MotionPhotosPausedFill from '../components/icons/MotionPhotosPausedFill';
import AutoPriceIcon from '../components/icons/AutoPrice';
import { useCallback, useEffect, useMemo } from 'react';
import { InputModal } from '../hoc/Mobile/InputModal';
import WifiOffIcon from '../components/icons/WifiOff';
import { useInventory } from '../hoc/Inventory/Inventory.hooks';
import { useSearchResults } from '../hoc/EventSearch/SearchResults.hooks';
import pluralize from 'pluralize';
import { formatCurrency, formatPricedAt, formatSeatRange } from '../utils/formatters';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { useAtomValue, useSetAtom } from 'jotai/index';
import {
  filtersDirtyAtom,
  mergedEventListingsAtom,
  pendingListingUpdatesAtom,
  ruleDirtyAtom,
  ruleStateAtom,
  ruleStateResetAtom,
  selectedTenantListingIdAtom,
  updateListingsAtom,
} from '../data/atoms';
import { ensureContainsPipe } from '../models/tenantIdListingId';
import { useGlobalState } from '../data/Global.state';
import { BarkerCoreEnumsAdjustmentType } from '../api';
import { NowPricingProvider, useNowPricing } from '../hoc/NowPricing/NowPricing.hooks';
import { useRuleState } from '../data/Rule.state';
import { useForm } from '@mantine/form';
import ErrorIcon from '../components/icons/Error';
import dayjs from 'dayjs';
import { MobileNumberInputModal } from '../hoc/MobileNumberInputModal/MobileNumberInputModal';
import ArrowForwardIcon from '../components/icons/ArrowForward';
import WarningIcon from '../components/icons/Warning';
import { ListingMarketplaceDetails } from '../hoc/Mobile/ListingMarketplaceDetails';
import { MoreMenuModal } from '../hoc/Mobile/MoreMenuModal';
import { FloorUpdateForm } from '../hoc/Mobile/Forms/FloorUpdateForm';
import { CeilingUpdateForm } from '../hoc/Mobile/Forms/CeilingUpdateForm';
import { AdjustmentUpdateForm } from '../hoc/Mobile/Forms/AdjustmentUpdateForm';
import { ComparablesUpdateForm } from '../hoc/Mobile/Forms/ComparablesUpdateForm';
import { NumActiveUpdateForm } from '../hoc/Mobile/Forms/NumActiveUpdateForm';
import { StaggerByUpdateForm } from '../hoc/Mobile/Forms/StaggerByUpdateForm';
import { SplitsUpdateForm } from '../hoc/Mobile/Forms/SplitsUpdateForm';
import { MobileFieldLabel } from '../hoc/Mobile/MobileFieldLabel';
import { useListingState } from '../data/Listing.state';
import { isDrasticChange as isDrasticChangeFunc } from '../utils/price-utils';
import { BroadcastingOptionsForm } from '../hoc/Mobile/Forms/BroadcastingOptions';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { NavBarMobile } from '../hoc/NavBar/NavBar.Mobile';
import { useQueryParam } from '../utils/use-query-param';
import { useFlag } from '@unleash/proxy-client-react';
import WarningPauseIcon from '../components/icons/WarningPause';

export function ListingDetailsMobile() {
  const selectedListingId = useAtomValue(selectedTenantListingIdAtom);
  const rule = useAtomValue(ruleStateAtom);
  const { formKey } = useRuleState('formKey');
  const eventListings = useAtomValue(mergedEventListingsAtom);
  const { listingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const found = eventListings.find((x) => x.listingId === listingId);
    if (!found) {
      navigate('/mobile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NowPricingProvider key={formKey.toString() + selectedListingId.toString() + (rule.ruleId || '')}>
      <ListingDetailsMobileInner />
    </NowPricingProvider>
  );
}

export function ListingDetailsMobileInner() {
  document.body.setAttribute('data-mobile', 'true');
  const [menuModalOpened, { open: openMenuModal, close: closeMenuModal }] = useDisclosure(false);
  const [priceUpdateModalOpened, { open: openPriceUpdateModal, close: closePriceUpdateModal }] = useDisclosure(false);
  const [floorModalOpened, { open: openFloorModal, close: closeFloorModal }] = useDisclosure(false);
  const [ceilingModalOpened, { open: openCeilingModal, close: closeCeilingModal }] = useDisclosure(false);
  const [adjustmentModalOpened, { open: openAdjustmentModal, close: closeAdjustmentModal }] = useDisclosure(false);
  const [comparableModalOpened, { open: openComparableModal, close: closeComparableModal }] = useDisclosure(false);
  const [numActiveModalOpened, { open: openNumActiveModal, close: closeNumActiveModal }] = useDisclosure(false);
  const [staggerByModalOpened, { open: openStaggerByModal, close: closeStaggerByModal }] = useDisclosure(false);
  const [splitsModalOpened, { open: openSplitsModal, close: closeSplitsModal }] = useDisclosure(false);
  const [broadcastingModalOpened, { open: openBroadcastingModal, close: closeBroadcastingModal }] = useDisclosure(false);
  const navigate = useNavigate();

  const rule = useAtomValue(ruleStateAtom);
  const ruleDirty = useAtomValue(ruleDirtyAtom);
  const filtersDirty = useAtomValue(filtersDirtyAtom);
  const resetRule = useSetAtom(ruleStateResetAtom);
  const resetPending = useSetAtom(pendingListingUpdatesAtom);

  const { resetForm, triggerSaveRule } = useRuleState('resetForm', 'triggerSaveRule');
  const reset = useCallback(() => {
    resetRule();
    resetForm();
    resetPending([]);
  }, [resetForm, resetPending, resetRule]);

  const { eventId, listingId } = useParams();
  const query_tenantId = useQueryParam('tenantId', '');
  const { selectedEvents } = useSearchResults('selectedEvents');
  const selectedEvent = selectedEvents.find((x) => x.eventId === eventId);
  const { mergedEventListings } = useInventory('mergedEventListings');
  const selectedListing = mergedEventListings.find((x) => x.tenantId === query_tenantId && x.eventId === eventId && x.listingId === listingId);
  const setSelectedListingId = useSetAtom(selectedTenantListingIdAtom);
  const updateInventoryListing = useSetAtom(updateListingsAtom);
  const { getUserName, currentUser } = useGlobalState('getUserName', 'currentUser');
  const { patchListing } = useListingState('patchListing');
  const {
    form,
    onAutoPricerToggle,
    saveRuleAndPrice,
    initiallyAutoPriced,
    filtersEmpty,
    isFormReady,
    isDirty,
    hasPendingUpdates,
    isGroupedListing,
    isChangeConflict,
    closeDrasticChangeDialog,
    refreshRule,
    saving,
    isLoading,
    isDrasticChange,
  } = useNowPricing(
    'form',
    'onAutoPricerToggle',
    'saveRuleAndPrice',
    'initiallyAutoPriced',
    'filtersEmpty',
    'isFormReady',
    'isDirty',
    'hasPendingUpdates',
    'isGroupedListing',
    'isChangeConflict',
    'closeDrasticChangeDialog',
    'refreshRule',
    'saving',
    'isLoading',
    'isDrasticChange',
  );
  // const isLoading = true;
  const isLoadingData = false;
  const unsavedChanges = (rule.isAutoPriced || initiallyAutoPriced) && (ruleDirty || filtersDirty);
  const { isAutoPriced } = form.values;
  const updatedBy = isChangeConflict?.updatedBy;
  const updatedAt = isChangeConflict?.updatedAt;
  const user = getUserName(updatedBy);
  const formattedDate = dayjs(updatedAt).fromNow(true);
  const disableSave =
    initiallyAutoPriced || form.values.isAutoPriced || form.values.isScheduled
      ? currentUser?.roleId === 'ReadOnlyUser' ||
        !isFormReady ||
        filtersEmpty ||
        (form.values.isScheduled ? !form.isDirty() && !isDirty && !hasPendingUpdates : !isDirty && !hasPendingUpdates)
      : currentUser?.roleId === 'ReadOnlyUser' || !isFormReady || !form.isDirty() || !form.isValid();

  const disableCancel =
    initiallyAutoPriced || form.values.isAutoPriced || form.values.isScheduled
      ? isLoading || !isFormReady || (form.values.isScheduled ? !form.isDirty() && !isDirty && !hasPendingUpdates : !isDirty && !hasPendingUpdates)
      : isLoading || !isFormReady || !form.isDirty();

  const updateBroadcasting = useCallback(
    async (tenantId: string, targetListingId: string, isBroadcasting: boolean) => {
      const { data: listing } = await patchListing({
        tenantId,
        listingId: targetListingId,
        data: [{ op: 'Replace', path: '/isBroadcasting', value: isBroadcasting }],
      });
      updateInventoryListing(listing);
    },
    [patchListing, updateInventoryListing],
  );

  useEffect(() => {
    if (selectedListing) {
      setSelectedListingId(ensureContainsPipe(`${selectedListing.tenantId}|${selectedListing.listingId}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId, setSelectedListingId]);

  const formatAdjustmentValue = (type: typeof form.values.adjustmentTypeId, value: typeof form.values.adjustmentValue) => {
    if (!value) return '';
    if (type === BarkerCoreEnumsAdjustmentType.Percentage) {
      return `${parseFloat(((value - 1) * 100).toFixed(4))}%`;
    }
    return formatCurrency(value);
  };

  const priceUpdateForm = useForm<{ unitPrice: number | '' }>({
    initialValues: {
      unitPrice: form.values.listPrice,
    },
  });

  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const isMultiTenant = tenants && tenants.length > 1;
  // Set tenant color name by searching through color arrays
  const tenantColorName = useMemo(() => {
    if (!selectedEvent?.eventId || !isMultiTenant) return 'brandcolor';

    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    const tenantColor = principal?.settings?.tenantColors[selectedEvent?.tenantId];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'gray1';
      }
    }
    return 'brandcolor';
  }, [selectedEvent?.eventId, selectedEvent?.tenantId, isMultiTenant, principal?.settings?.tenantColors]);

  const tenantClassName = isMultiTenant ? `colors-tenant-mobile-${tenantColorName}` : 'tenant-single';
  document.body.style.background = `var(--${tenantClassName}, var(--colors-mobileBg))`;

  const testPartialBroadcasting = false;

  const pauseAutoPricingFlag = useFlag('pause-autopricing');
  const isPaused = false;
  const isPausedWarning = false;

  if (!selectedEvent || !selectedListing) {
    return null;
  }

  const companySettings = tenants?.find((x) => x.tenantId === selectedListing.tenantId)?.settings;
  const percentage = companySettings?.pricerSettings?.drasticPriceThresholdPercentage ?? 0;
  const threshold = companySettings?.pricerSettings?.drasticPriceThresholdAmount ?? 0;

  const _isDrasticChange = isDrasticChangeFunc(percentage, threshold, priceUpdateForm.values.unitPrice || 0, selectedListing.unitPrice, selectedListing.unitCost);

  return (
    <>
      <div className={mobileClasses.mobileAppWrap}>
        <NavBarMobile
          leftSide={
            !unsavedChanges ? (
              <ActionIcon
                opacity={0.7}
                size={36}
                onClick={() => {
                  triggerSaveRule(); // Not going to await this for UX navigation reasons. Will see if that's a problem
                  navigate(-1);
                }}
                className={mobileClasses.chevronIconButton}
              >
                <ChevronBackIcon size={20} color="white" />
              </ActionIcon>
            ) : (
              <BNButton
                disabled={disableCancel}
                className={mobileClasses.headerButtonDefault}
                variant="default"
                h={28}
                w={64}
                px={0}
                c="white"
                onClick={() => reset()}
                style={{ borderRadius: 6, borderColor: 'rgba(255,255,255,.3)' }}
              >
                Cancel
              </BNButton>
            )
          }
          rightSide={
            !unsavedChanges ? (
              <ActionIcon opacity={0.7} size={36} onClick={() => openMenuModal()}>
                <MoreHorizIcon size={24} color="white" />
              </ActionIcon>
            ) : (
              <Popover width={220} position="bottom" withArrow closeOnClickOutside onClose={() => closeDrasticChangeDialog()} opened={!!isChangeConflict}>
                <Popover.Target>
                  <BNButton
                    loading={saving}
                    className={mobileClasses.headerButtonFill}
                    disabled={disableSave}
                    variant="filled"
                    bg="white"
                    h={28}
                    w={64}
                    px={0}
                    c={`var(--${tenantClassName}, var(--colors-mobileBg))`}
                    onClick={() => saveRuleAndPrice()}
                    style={{ borderRadius: 6 }}
                  >
                    Save
                  </BNButton>
                </Popover.Target>
                <Popover.Dropdown p={0}>
                  {isChangeConflict && (
                    <Box>
                      <Group wrap="nowrap" p={12} align="start" gap={8}>
                        <Box>
                          <ErrorIcon fill color="var(--colors-red-error)" />
                        </Box>
                        <Box style={{ flex: 1 }}>
                          {/* Title */}
                          <Text size="xs" fw="bold">
                            Rule Conflict
                          </Text>
                          {/* Description */}
                          <Group gap={0}>
                            <Text size="xs">
                              {user} updated this rule{user === 'You' ? 'in another window.' : '.'}
                            </Text>
                          </Group>
                          {/* Secondary Description */}
                          <Text fz={11} c="var(--colors-gray-5)">
                            {formattedDate}
                          </Text>
                        </Box>
                      </Group>
                      <Divider color="var(--colors-divider)" />
                      {/* Dropdown buttons */}
                      <Button.Group>
                        <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                          <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => refreshRule()}>
                            Refresh
                          </BNButton>
                        </Group>
                      </Button.Group>
                    </Box>
                  )}
                </Popover.Dropdown>
              </Popover>
            )
          }
          titleArea={
            <>
              {isLoadingData && <Skeleton h={8} w={140} opacity={0.5} />}
              {!isLoadingData && !unsavedChanges && (
                <Text fw={600} ta="center" c="white" lh={2} fz={14} truncate>
                  Listing &middot; {selectedListing.quantityRemaining} {pluralize('Ticket', selectedListing.quantityRemaining)}
                  {/* {selectedListing.ruleCount || 0 > 1 ? 'Group' : 'Single'} Listing &middot; {selectedListing.quantityRemaining} {pluralize('Ticket', selectedListing.quantityRemaining)} */}
                </Text>
              )}
              {!isLoadingData && unsavedChanges && (
                <Text fw={600} ta="center" c="white" lh={2} fz={14} truncate>
                  Unsaved Changes
                </Text>
              )}
            </>
          }
          containerStyle={{ background: `var(--${tenantClassName}, var(--colors-mobileBg))` }}
          headerBg="transparent"
          bottomContent={<Space h={114} />}
        />
        <Flex direction="column" h="100%">
          <Flex direction="column" className={classes.listingContentWrapper} pb="xs">
            <Flex
              bg="var(--colors-paper)"
              pos="relative"
              direction="column"
              mx="xs"
              mt={-114}
              py="sm"
              px="md"
              mih={176}
              style={{ borderRadius: 6, border: '0px solid var(--colors-divider)', boxShadow: '0 0 12px rgba(0,0,0,.06)' }}
              fz={13}
            >
              {!isLoadingData && (
                <>
                  <Flex
                    mt={-1}
                    mb={-1}
                    pos="absolute"
                    right={12}
                    top={12}
                    style={{
                      gap: 8,
                    }}
                  >
                    {selectedListing?.quantityReserved > 0 && (
                      <>
                        <Center
                          h={24}
                          w={24}
                          className={cx(classes.onHoldIcon)}
                          style={{
                            borderRadius: 100,
                          }}
                        >
                          <MotionPhotosPausedFill size={20} color="var(--colors-yellow-5)" />
                        </Center>
                        <Divider color="var(--colors-gray-0)" orientation="vertical" />
                      </>
                    )}
                    {isDtiHosted ? (
                      <>
                        {testPartialBroadcasting ? (
                          <UnstyledButton
                            onClick={() => openBroadcastingModal()}
                            className={cx(classes.broadcastIconButton, classes.broadcastIconButtonPartial)}
                            h={24}
                            w={24}
                            c="var(--colors-yellow-warning)"
                            style={{ borderRadius: 100 }}
                          >
                            <WifiIcon color="var(--colors-yellow-warning)" size={16} />
                          </UnstyledButton>
                        ) : (
                          <UnstyledButton
                            onClick={() => openBroadcastingModal()}
                            className={classes.broadcastIconButton}
                            h={24}
                            w={24}
                            bg={selectedListing.isBroadcasting ? 'var(--colors-green-0)' : 'var(--colors-divider)'}
                            style={{ borderRadius: 100 }}
                          >
                            {selectedListing.isBroadcasting ? <WifiIcon color="var(--colors-green-broadcast)" size={16} /> : <WifiOffIcon color="var(--colors-gray-5)" size={16} />}
                          </UnstyledButton>
                        )}
                      </>
                    ) : (
                      <>
                        {selectedListing.isBroadcasting ? (
                          <UnstyledButton
                            onClick={() => openMenuModal()}
                            className={classes.broadcastIconButton}
                            h={24}
                            w={24}
                            bg="var(--colors-green-0)"
                            style={{ borderRadius: 100 }}
                          >
                            <WifiIcon color="var(--colors-green-broadcast)" size={16} />
                          </UnstyledButton>
                        ) : (
                          <UnstyledButton
                            onClick={() => openMenuModal()}
                            className={classes.broadcastIconButton}
                            h={24}
                            w={24}
                            bg="var(--colors-divider)"
                            style={{ borderRadius: 100 }}
                          >
                            <WifiOffIcon color="var(--colors-gray-5)" size={16} />
                          </UnstyledButton>
                        )}
                      </>
                    )}
                  </Flex>
                </>
              )}
              {isLoadingData && (
                <>
                  <Skeleton h={24} w={24} pos="absolute" top={12} right={12} circle style={{ borderRadius: 100 }} />
                  <Skeleton h={8} w={120} mt={10} mb={9} />
                  <Skeleton h={8} w={160} mt={10} mb={9} />
                  <Skeleton h={8} w={200} mt={10} mb={4} />
                </>
              )}
              {!isLoadingData && (
                <>
                  <Text fw={600} fz="lg" mb={2}>
                    {selectedListing.section}
                  </Text>
                  <Flex gap="xs" mb={4}>
                    <Flex gap={4}>
                      <Text fz={13} c="var(--colors-gray-5)">
                        Row
                      </Text>{' '}
                      <Text fz={13} fw={600} c="var(--colors-gray-9)">
                        {selectedListing.row}
                      </Text>
                    </Flex>
                    <Flex gap={4}>
                      <Text fz={13} c="var(--colors-gray-5)">
                        Qty
                      </Text>{' '}
                      <Text fz={13} fw={600} c="var(--colors-gray-9)">
                        {selectedListing.quantityRemaining}
                      </Text>
                    </Flex>
                    <Flex gap={4}>
                      <Text fz={13} c="var(--colors-gray-5)">
                        Seats
                      </Text>{' '}
                      <Text fz={13} fw={600} c="var(--colors-gray-9)">
                        {formatSeatRange(selectedListing.seatFrom, selectedListing.seatThru, selectedListing.quantityRemaining)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex gap="xs" mb={2}>
                    <Flex gap={4}>
                      <Text fz={13} c="var(--colors-gray-5)">
                        Unit Cost
                      </Text>{' '}
                      <Text fz={13} fw={600} c="var(--colors-gray-9)">
                        {formatCurrency(selectedListing.unitCost)}
                      </Text>
                    </Flex>
                    <Flex gap={4}>
                      <Text fz={13} c="var(--colors-gray-5)">
                        Repriced
                      </Text>{' '}
                      <Text fz={13} fw={600} c="var(--colors-gray-9)">
                        {formatPricedAt(selectedListing.pricedAt, selectedListing.posPricedAt, selectedListing.unitPrice, selectedListing.latestPrice)}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
              <Divider w="100%" color="var(--colors-divider)" my="md" />
              <Flex justify="space-between" gap="md">
                <Box h={36} w={156} pos="relative">
                  {form.values.isAutoPriced && form.values.listPrice !== selectedListing.unitPrice && (
                    <Flex
                      className={!isDrasticChange ? classes.pendingDisplay : `${classes.pendingDisplay} ${classes.pendingDisplayDrastic}`}
                      pos="absolute"
                      align="center"
                      justify="space-between"
                      h={36}
                      top="calc(-100% + 4px)"
                      left="auto"
                      right={0}
                      w={130}
                      py={6}
                      pr={10}
                      bg="var(--colors-paper)"
                    >
                      {!isDrasticChange ? (
                        <Center h={36} w={36} c="var(--colors-paper)" bg="var(--colors-selectedBorder)" style={{ borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                          <ArrowForwardIcon />
                        </Center>
                      ) : (
                        <Center h={36} w={36} c="var(--colors-paper)" bg="var(--colors-yellow-warning)" style={{ borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                          <WarningIcon />
                        </Center>
                      )}
                      <Text size="sm" fw={600}>
                        {formatCurrency(form.values.listPrice)}
                      </Text>
                    </Flex>
                  )}
                  <MobileFieldLabel
                    h={36}
                    w={156}
                    label="List Price"
                    value={
                      priceUpdateModalOpened
                        ? priceUpdateForm.values.unitPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : form.values.isAutoPriced
                          ? selectedListing.unitPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : form.values.listPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    }
                    disabled={!isFormReady || isAutoPriced}
                    isLoading={!isFormReady}
                    onClick={() => {
                      if (!rule.isAutoPriced) {
                        openPriceUpdateModal();
                      }
                    }}
                    borderColor={priceUpdateModalOpened ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-3)'}
                    highlightText={priceUpdateModalOpened && priceUpdateForm.values.unitPrice === form.values.listPrice}
                    priceUpdateModalOpened={priceUpdateModalOpened}
                    error={!isAutoPriced && _isDrasticChange}
                    errorVariant="warning"
                  />
                </Box>
                <Flex align="center" gap="xs" style={{ overflow: 'hidden' }}>
                  <Text c={pauseAutoPricingFlag && isPaused && isPausedWarning ? 'var(--colors-yellow-warning)' : 'var(--colors-gray-5)'} size="xs" truncate>
                    Auto-Price
                  </Text>
                  {pauseAutoPricingFlag && isPaused ? (
                    <Flex flex={1} w={91} miw={91} gap={2} pos="relative" className={cx(classes.pausedOverlay, isPausedWarning && classes.warning)}>
                      <Badge
                        w={91}
                        pos="absolute"
                        top={-2}
                        right={-11.5}
                        pr="sm"
                        left="auto"
                        size="xs"
                        variant="transparent"
                        color="var(--colors-gray-5)"
                        className={classes.desktopOnlyBadge}
                      >
                        DESKTOP ONLY
                      </Badge>
                      <Text c="var(--colors-gray-4)" fw={600}>
                        Paused
                      </Text>
                    </Flex>
                  ) : (
                    <BNSwitch
                      size="lg"
                      disabled={!isFormReady}
                      {...form.getInputProps('isAutoPriced', { type: 'checkbox' })}
                      onChange={onAutoPricerToggle}
                      label={
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: -6,
                          }}
                        >
                          <AutoPriceIcon size={24} color={!isAutoPriced ? 'var(--colors-gray-5)' : 'var(--colors-selectedBorder)'} />
                        </Box>
                      }
                      colorLabel
                    />
                  )}
                </Flex>
              </Flex>
              {isAutoPriced && (
                <SimpleGrid
                  spacing={16}
                  verticalSpacing={12}
                  cols={2}
                  mt={16}
                  mx={-16}
                  mb={-12}
                  p={16}
                  pos="relative"
                  style={{ border: '4px solid var(--colors-divider)', borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }}
                >
                  <MobileFieldLabel
                    error={form.getInputProps('floorPrice').error}
                    h={36}
                    w="100%"
                    label="Floor"
                    disabled={!isFormReady}
                    isLoading={!isFormReady}
                    value={formatCurrency(form.values.floorPrice || 0)}
                    onClick={() => openFloorModal()}
                  />
                  <MobileFieldLabel
                    h={36}
                    w="100%"
                    label="Ceiling"
                    disabled={!isFormReady}
                    isLoading={!isFormReady}
                    value={form.values.ceilingPrice ? formatCurrency(form.values.ceilingPrice) : ''}
                    onClick={() => openCeilingModal()}
                  />
                  <MobileFieldLabel
                    h={36}
                    w="100%"
                    label="Adjustment"
                    disabled={!isFormReady}
                    isLoading={!isFormReady}
                    value={formatAdjustmentValue(form.values.adjustmentTypeId, form.values.adjustmentValue)}
                    onClick={() => openAdjustmentModal()}
                  />
                  <MobileFieldLabel
                    isLoading={!isFormReady}
                    disabled={!isFormReady}
                    h={36}
                    w="100%"
                    label="# Comparables"
                    value={form.values.numComparables?.toString() ?? ''}
                    onClick={() => openComparableModal()}
                  />
                  {isGroupedListing && (
                    <>
                      <MobileFieldLabel
                        disabled={!isFormReady}
                        isLoading={!isFormReady}
                        h={36}
                        w="100%"
                        label="# Active"
                        value={!form.values.numActive ? '' : form.values.numActive >= 2147483647 ? 'ALL' : form.values.numActive?.toString() ?? ''}
                        onClick={() => openNumActiveModal()}
                      />
                      <MobileFieldLabel
                        h={36}
                        isLoading={!isFormReady}
                        disabled={!isFormReady}
                        w="100%"
                        label="Stagger by"
                        value={formatAdjustmentValue(form.values.staggerByTypeId, form.values.staggerByValue)}
                        onClick={() => openStaggerByModal()}
                      />
                    </>
                  )}
                  {pauseAutoPricingFlag && isPaused && isPausedWarning && (
                    <Flex direction="column" pos="absolute" inset={0} bg="var(--colors-paper)" className={classes.warningNote}>
                      <Flex align="center" gap={4} h={30} px={6} bg="var(--colors-yellow-0)" c="var(--colors-yellow-warning)" fz={12} style={{ borderRadius: '3px 3px 0 0' }}>
                        <WarningPauseIcon />
                        Auto-Price Warning Note
                      </Flex>
                      <ScrollArea type="always" scrollbars="y" flex={1}>
                        <Box p="xs" pr="md">
                          <Text>
                            This area will be filled with text from the warning notes that are supplied as a result of auto-price listing imports from external accounts that do not
                            nicely match with how the pricer handles data. It could have a scrollbar when necessary.
                          </Text>
                        </Box>
                      </ScrollArea>
                    </Flex>
                  )}
                </SimpleGrid>
              )}
            </Flex>
          </Flex>
          <SeatingChartProvider>
            <ListingMarketplaceDetails />
          </SeatingChartProvider>
        </Flex>
      </div>
      {menuModalOpened && (
        <MoreMenuModal
          modalOpened
          closeModal={closeMenuModal}
          listing={selectedListing}
          updateBroadcasting={updateBroadcasting}
          openSplitsModal={openSplitsModal}
          openBroadcastingModal={openBroadcastingModal}
        />
      )}
      {/*{priceModalOpened && (*/}
      {/*  <InputModal closeModal={closePriceModal}>*/}
      {/*    <PriceUpdateForm*/}
      {/*      closeModal={closePriceModal}*/}
      {/*      onSubmit={({ price }) => {*/}
      {/*        form.setFieldValue('listPrice', price);*/}
      {/*        saveRuleAndPrice({ ...form.values, listPrice: price });*/}
      {/*      }}*/}
      {/*      listing={selectedListing}*/}
      {/*    />*/}
      {/*  </InputModal>*/}
      {/*)}*/}
      {priceUpdateModalOpened && (
        <MobileNumberInputModal
          cancel={() => {
            priceUpdateForm.setFieldValue('unitPrice', selectedListing.unitPrice);
            closePriceUpdateModal();
          }}
          save={() => {
            form.setFieldValue('listPrice', priceUpdateForm.values.unitPrice === '' ? 0 : priceUpdateForm.values.unitPrice);
            saveRuleAndPrice({ ...form.values, listPrice: priceUpdateForm.values.unitPrice === '' ? 0 : priceUpdateForm.values.unitPrice });
            closePriceUpdateModal();
          }}
          initialInput={form.values.listPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          handleKeyboardInput={(input) => priceUpdateForm.setFieldValue('unitPrice', input === '' ? '' : parseFloat(input))}
          drasticAmount={_isDrasticChange ? priceUpdateForm.values.unitPrice || 0 : undefined}
          cost={selectedListing.unitCost}
        />
      )}
      {floorModalOpened && (
        <InputModal closeModal={closeFloorModal}>
          <FloorUpdateForm closeModal={closeFloorModal} />
        </InputModal>
      )}
      {ceilingModalOpened && (
        <InputModal closeModal={closeCeilingModal}>
          <CeilingUpdateForm closeModal={closeCeilingModal} />
        </InputModal>
      )}
      {adjustmentModalOpened && (
        <InputModal closeModal={closeAdjustmentModal}>
          <AdjustmentUpdateForm closeModal={closeAdjustmentModal} />
        </InputModal>
      )}
      {comparableModalOpened && (
        <InputModal closeModal={closeComparableModal}>
          <ComparablesUpdateForm closeModal={closeComparableModal} />
        </InputModal>
      )}
      {numActiveModalOpened && (
        <InputModal closeModal={closeNumActiveModal}>
          <NumActiveUpdateForm closeModal={closeNumActiveModal} />
        </InputModal>
      )}
      {staggerByModalOpened && (
        <InputModal closeModal={closeStaggerByModal}>
          <StaggerByUpdateForm closeModal={closeStaggerByModal} />
        </InputModal>
      )}
      {splitsModalOpened && (
        <InputModal closeModal={closeSplitsModal}>
          <SplitsUpdateForm closeModal={closeSplitsModal} listing={selectedListing} />
        </InputModal>
      )}
      {isDtiHosted && broadcastingModalOpened && (
        <InputModal closeModal={closeSplitsModal}>
          <BroadcastingOptionsForm closeModal={closeBroadcastingModal} />
        </InputModal>
      )}
    </>
  );
}
