import { IconPropsBase } from './IconPropsBase';

export default function ScheduledPriceOffIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path fill={color} d="M18.9,18.11c.4-.53.76-1.1,1-1.71.5-1,.7-2.1.7-3.3,0-1-.2-2-.5-2.9-.4-.9-.9-1.8-1.5-2.6l1.2-1.2-1.1-1.1-1.2,1.2c-.8-.7-1.7-1.2-2.6-1.5-.9-.3-1.9-.5-2.9-.5-1.2,0-2.3.3-3.3.7-.62.25-1.19.58-1.73.98l-3.47-3.47-1.06,1.06,18.66,18.66,1.06-1.06-3.26-3.26ZM14.7,13.91v-.81s-.01-.05-.01-.07c0-.02.01-.05.01-.07,0-.42-.34-.76-.76-.76h-.95l-1.5-1.5h3.21v-1.4h-2v-1h-1.5v1h-1.1s0,0,0,0l-2.04-2.04c1.14-.84,2.48-1.26,3.95-1.26,1.9,0,3.7.8,5,2.1,1.3,1.3,2,2.9,2,4.9,0,1.54-.36,2.9-1.17,4.04l-3.13-3.13Z" />
        <path fill={color} d="M12.7,17.7v-.85l-1.55-1.55h-1.95v1.5h2c0-.1,0,.9,0,.9h1.5Z" />
        <path fill={color} d="M12,20c-1.9,0-3.5-.6-4.9-2-1.4-1.4-2.1-3.1-2.1-5,0-1.12.28-2.14.78-3.07l-1.14-1.14c-.16.29-.32.59-.44.91-.5,1-.7,2.1-.7,3.3s.3,2.3.7,3.3c.4,1,1,1.9,1.8,2.7.8.7,1.7,1.4,2.7,1.8,1,.5,2.1.7,3.3.7s2.3-.2,3.4-.6c.31-.13.61-.28.91-.44l-1.17-1.17c-.96.47-2.01.72-3.13.72Z" />
        <rect fill={color} x="9.2" y="1.3" width="5.6" height="1.4" />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M15.46,14.88c.26-.37.48-.76.64-1.18.3-.8.5-1.7.5-2.6,0-.8-.1-1.6-.4-2.3-.2-.7-.6-1.4-1.1-2l.9-1-.7-.8-1,.9c-.6-.4-1.3-.8-2-1.1-.8-.3-1.5-.4-2.3-.4-.9,0-1.8.2-2.6.5-.45.17-.86.4-1.24.68l-3.46-3.46-.77.77,3.4,3.4h0s.8.8.8.8h0s1.66,1.66,1.66,1.66c0,0,0,0,0,0l1.02,1.02h0s.74.74.74.74h0l1,1h0l.66.66h0s.95.95.95.95c0,0,0,0,0,0l1.77,1.77h0s.85.85.85.85h0s2.96,2.96,2.96,2.96l.77-.77-3.03-3.03ZM14.61,14.03l-2.41-2.41v-.25c0-.12-.03-.24-.08-.35,0,0,0,0,0,0-.14-.31-.44-.52-.8-.52h-.25l-1.2-1.2h2.42v-1.1h-1.8v-1h-1.1v1h-.62l-1.81-1.81c.9-.6,1.91-.89,3.03-.89,1.5,0,2.8.5,3.9,1.6,1.1,1.1,1.6,2.4,1.6,3.9,0,1.12-.28,2.13-.89,3.03Z"
        />
        <polygon fill={color} points="7.7 13.8 9.4 13.8 9.4 14.8 10.12 14.8 10.5 14.8 10.5 13.8 10.73 13.8 9.63 12.7 7.7 12.7 7.7 13.8" />
        <path fill={color} d="M12.4,1.7h-4.7v1.1h4.7v-1.1Z" />
        <path
          fill={color}
          d="M10,16.5c-1.5,0-2.8-.5-3.9-1.6-1.1-1.1-1.6-2.4-1.6-3.9,0-1,.24-1.89.71-2.71l-.83-.83c-.19.3-.35.61-.48.95-.3.8-.5,1.7-.5,2.6s.2,1.8.5,2.6c.3.8.8,1.5,1.4,2.1.6.6,1.3,1.1,2.1,1.4.8.3,1.7.5,2.6.5s1.8-.2,2.6-.4c.36-.13.69-.31,1.01-.52l-.9-.9c-.82.47-1.72.71-2.71.71Z"
        />
      </svg>
    );
  }
}
