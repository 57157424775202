import { ActionIcon, Box, Group, Progress, Stack, Text, Tooltip } from '@mantine/core';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import DemographyIcon from '../../components/icons/Demography';
import FiltersIcon from '../../components/icons/Filters';
import ListIcon from '../../components/icons/List';
import MoreVertIcon from '../../components/icons/MoreVert';
import { BNLabel } from '../../components/Label/Label';
import { useMarketListings } from '../MarketListings/MarketListings.hooks';
import BlockIcon from '../../components/icons/Block';
import millify from 'millify';
import pluralize from 'pluralize';
import ListingAgeIcon from '../../components/icons/ListingAge';
import ScatterPlotIcon from '../../components/icons/ScatterPlot';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import classes from '../MarketListings/MarketListings.styles.tsx.module.css';
import { BNSelect } from '../../components/Select/Select';
import { useDTIMarketplace } from '../DTI/DTI.Marketplace.hooks';
import { MarketplaceResultsSelection } from './MarketplaceResults.types';
import RefreshIcon from '../../components/icons/Refresh';
import { useAtomValue } from 'jotai';
import { selectedMarketplaceEventAtom } from '../../data/atoms';

export function MarketplaceResultsHeader({
  setResultsSelection,
  resultsSelection,
}: {
  setResultsSelection: (value: MarketplaceResultsSelection) => void;
  resultsSelection: MarketplaceResultsSelection;
}) {
  const {
    summaryTotals,
    setShowOwnListings,
    showOwnListings,
    showIgnoredListings,
    setShowIgnoredListings,
    isFetchingUpdatedListings,
    fetchUpdatedListings,
    showListingAge,
    setShowListingAge,
    showOutliers,
    setShowOutliers,
    marketplaceId,
  } = useMarketListings(
    'summaryTotals',
    'setShowOwnListings',
    'showOwnListings',
    'showIgnoredListings',
    'setShowIgnoredListings',
    'isFetchingUpdatedListings',
    'fetchUpdatedListings',
    'showListingAge',
    'setShowListingAge',
    'showOutliers',
    'setShowOutliers',
    'marketplaceId',
  );

  const { salesTotals, refetchSeatGeek, isFetchingSeatGeek } = useDTIMarketplace('salesTotals', 'refetchSeatGeek', 'isFetchingSeatGeek');
  const globalSelectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);

  const totals = resultsSelection === 'Listings' || globalSelectedMarketplaceEvent.marketplaceId !== 'SeatGeek' ? summaryTotals : salesTotals;

  return (
    <Group px={0} className="market-results-header">
      <Stack gap={0} w="100%" p={0} m={0} pos="relative">
        <Group className={classes.wrapper} gap={0}>
          <Group px={16} pr={8} gap={0} wrap="nowrap" flex={1}>
            <ActionIcon className="marketFiltersTrigger" onClick={() => document.querySelector('.appRight')?.classList.toggle('show-market-filters')}>
              <FiltersIcon size={20} />
            </ActionIcon>
            {marketplaceId === 'SeatGeek' ? (
              <BNSelect
                className={classes.subtleSelect}
                data={['Listings', 'Sales'] as MarketplaceResultsSelection[]}
                value={resultsSelection}
                size="xs"
                fw={600}
                maw={86}
                onChange={(value) => setResultsSelection((value ?? 'Listings') as MarketplaceResultsSelection)}
                scrollAreaProps={{ pr: 4 }}
              />
            ) : (
              <Text fw={600} fz="sm">
                Results
              </Text>
            )}
          </Group>
          <Group className={classes.statsWrapper} pr="xs" gap="0.5rem" justify="end">
            <Tooltip
              label={
                totals?.totalListings !== totals.totalFilteredListings
                  ? `${totals.totalFilteredListings.toLocaleString()} filtered ${pluralize(
                      'listing',
                      totals.totalFilteredListings,
                    )} / ${totals.totalListings.toLocaleString()} total`
                  : `${totals.totalListings.toLocaleString()} total ${pluralize('listing', totals.totalFilteredListings)}`
              }
              withArrow
            >
              <Box>
                <BNLabel
                  className={classes.label}
                  leftIcon={<ListIcon size={20} />}
                  text={
                    <span>
                      {totals?.totalListings !== totals.totalFilteredListings ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: 'var(--colors-paperReverse)',
                            }}
                          >
                            {millify(totals.totalFilteredListings)}
                          </span>{' '}
                          / {millify(totals.totalListings)}
                        </span>
                      ) : (
                        <span>{millify(totals.totalListings)}</span>
                      )}
                    </span>
                  }
                />
              </Box>
            </Tooltip>
            <Tooltip
              label={
                totals?.totalTickets !== totals.totalFilteredTickets
                  ? `${totals.totalFilteredTickets.toLocaleString()} filtered ${pluralize('ticket', totals.totalFilteredTickets)} / ${totals.totalTickets.toLocaleString()} total`
                  : `${totals.totalTickets.toLocaleString()} total ${pluralize('ticket', totals.totalTickets)}`
              }
              withArrow
            >
              <Box>
                <BNLabel
                  className={classes.label}
                  leftIcon={<ConfirmationNumberIcon size={20} />}
                  text={
                    <span>
                      {totals?.totalTickets !== totals.totalFilteredTickets ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: 'var(--colors-paperReverse)',
                            }}
                          >
                            {millify(totals.totalFilteredTickets)}
                          </span>{' '}
                          / {millify(totals.totalTickets)}
                        </span>
                      ) : (
                        <span>{millify(totals.totalTickets)}</span>
                      )}
                    </span>
                  }
                />
              </Box>
            </Tooltip>
            <BNAccordionMenu
              width={260}
              target={
                <ActionIcon>
                  <MoreVertIcon size={20} color="var(--colors-gray-5)" data-pendo-name="Marketplace Results Action Menu Button" />
                </ActionIcon>
              }
            >
              <BNAccordionMenu.Item
                onClick={() => {
                  if (resultsSelection === 'Listings') {
                    fetchUpdatedListings();
                  } else {
                    refetchSeatGeek();
                  }
                }}
                leftSection={<RefreshIcon />}
              >
                Refresh
              </BNAccordionMenu.Item>
              {resultsSelection === 'Listings' && (
                <>
                  <BNAccordionMenu.Switch
                    checked={showOwnListings}
                    onChange={() => setShowOwnListings(!showOwnListings)}
                    leftSection={<DemographyIcon />}
                    title="Show My Listings"
                    data-pendo-name="Show My Listings Switch"
                  />
                  <BNAccordionMenu.Switch
                    checked={showIgnoredListings}
                    onChange={() => setShowIgnoredListings(!showIgnoredListings)}
                    leftSection={<BlockIcon />}
                    title="Show Ignored Listings"
                    data-pendo-name="Show Ignored Listings Switch"
                  />
                  <BNAccordionMenu.Switch
                    checked={showOutliers}
                    onChange={() => setShowOutliers(!showOutliers)}
                    leftSection={<ScatterPlotIcon />}
                    title="Show Outliers"
                    data-pendo-name="Show Outliers Switch"
                  />
                  <BNAccordionMenu.Switch
                    checked={showListingAge}
                    onChange={() => setShowListingAge(!showListingAge)}
                    leftSection={<ListingAgeIcon />}
                    title="Show Listing Age"
                    data-pendo-name="Show Listing Age Switch"
                  />
                </>
              )}
            </BNAccordionMenu>
          </Group>
        </Group>
        {/* {isFetchingUpdatedListings && <Progress radius="xs" value={100} animate color="var(--colors-brandcolor-5)" style={{ marginTop: -4, height: '4px' }} />} */}
        {/* Tweaked style here to remove rounded sides & changed color to gray to match other section loading indicators -- I think green would be good if we could make it an actual progress bar and show it loading */}
        {(isFetchingUpdatedListings || isFetchingSeatGeek) && (
          <Progress radius={0} value={100} animated color="var(--colors-gray-2)" size={4} pos="absolute" bottom={0} left={0} right={0} className={classes.progressBar} />
        )}
      </Stack>
    </Group>
  );
}
