import { Box, Divider, Stack, Text, Title } from '@mantine/core';
import { BNSwitch } from '../../components/Switch/Switch';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { BNButton } from '../../components/Button/Button';
import {
  BarkerCoreEnumsShowEventCountdown,
  BarkerCoreEnumsShowWeather,
  BarkerCoreModelsAdministrativeAppearanceSettings,
  BarkerCoreModelsAdministrativePricerSettings,
  BarkerCoreModelsAdministrativePrincipalSettings,
  getGetApiPrincipalsSelfQueryKey,
  usePatchApiPrincipalsSelf,
} from '../../api';
import { useGlobalState } from '../../data/Global.state';
import { DEFAULT_RULE, DEFAULT_RULE_SETTINGS } from '../../data/constants';
import { useForm } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { BNSelect } from '../../components/Select/Select';
import classes from './AppearanceSettings.module.css';
import { BNViewSwitcher } from '../../components/ViewSwitcher/ViewSwitcher';
import { useFlag } from '@unleash/proxy-client-react';

export default function AppearanceSettings() {
  const { principal } = useGlobalState('principal');
  const settings = principal?.settings ?? ({} as BarkerCoreModelsAdministrativePrincipalSettings);
  const pricerSettings = (settings.pricerSettings as BarkerCoreModelsAdministrativePricerSettings) ?? {
    defaultMarketplace: DEFAULT_RULE.marketplaceId,
    numActive: DEFAULT_RULE.numActive,
    staggerByTypeId: DEFAULT_RULE.staggerByTypeId,
    adjustmentTypeId: DEFAULT_RULE.adjustmentTypeId,
    adjustmentValue: DEFAULT_RULE.adjustmentValue,
    staggerByValue: DEFAULT_RULE.staggerByValue,
    broadcastSingleListing: DEFAULT_RULE_SETTINGS.broadcastSingleListing,
    autoAdjustSplits: DEFAULT_RULE.autoAdjustSplits,
  };
  const { mutateAsync: updateSelf } = usePatchApiPrincipalsSelf();
  const queryClient = useQueryClient();

  const appearanceSettings = (pricerSettings.appearanceSettings as BarkerCoreModelsAdministrativeAppearanceSettings) ?? {
    colorPricesBelowCost: DEFAULT_RULE_SETTINGS.colorPricesBelowCost,
  };

  const { pricerView, setPricerView } = useGlobalState();

  const form = useForm({
    initialValues: {
      pricerView,
      ...appearanceSettings,
    },
  });

  const hasEventCountdownFlag = useFlag('event-countdown');
  const hasUnbroadcastHighlightFlag = useFlag('highlight-unbroadcasted-listings');

  return (
    <>
      <AdminContentHeader
        title="Appearance Settings"
        sticky
        rightSection={
          <>
            <BNButton
              size="xs"
              variant="default"
              disabled={!form.isDirty()}
              onClick={() => {
                form.setValues(appearanceSettings);
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              size="xs"
              variant="filled"
              color="green"
              disabled={!form.isDirty()}
              onClick={() =>
                form.onSubmit((values) => {
                  const { pricerView: pricerViewSetting, ...appearanceSettingsWithoutPricerView } = values;
                  const newSettings: BarkerCoreModelsAdministrativePrincipalSettings = {
                    ...settings,
                    pricerSettings: {
                      ...pricerSettings,
                      appearanceSettings: appearanceSettingsWithoutPricerView,
                    },
                  };
                  setPricerView(pricerViewSetting);
                  updateSelf(
                    { data: [{ op: 'Replace', path: '/settings', value: newSettings }] },
                    {
                      onSuccess: (data) => {
                        queryClient.setQueryData(getGetApiPrincipalsSelfQueryKey(), () => data);
                      },
                    },
                  ).then(() => {
                    form.resetDirty();
                  });
                })()
              }
            >
              Save
            </BNButton>
          </>
        }
      />
      <Box py="xl">
        <Title order={2} size="h4" pt="sm">
          Inventory Appearance
        </Title>
        <Text size="xs" c="var(--colors-gray-5)">
          Customize how inventory is displayed in the pricer.
        </Text>
        <Box mt="xl">
          <BNViewSwitcher selectedView={form.values.pricerView} onChange={(newValue) => form.setFieldValue('pricerView', newValue)} />
          <Divider color="var(--colors-gray-1)" mt="xl" />
        </Box>
        <Stack gap="xs" mt="sm" pt="sm" pb={0}>
          <Box>
            <BNSwitch className={classes.switchOutline} {...form.getInputProps('highlightListingsOnHold', { type: 'checkbox' })} label="Highlight Listings On Hold" size="sm" />
            <Text size="xs" c="var(--colors-gray-5)" py="xs">
              Listings on hold will have their entire row highlighted in yellow for greater visibility.
            </Text>
          </Box>
          <Box>
            <BNSwitch className={classes.switchOutline} {...form.getInputProps('colorPricesBelowCost', { type: 'checkbox' })} label="Color Code Prices Below Cost" size="sm" />
            <Text size="xs" c="var(--colors-gray-5)" py="xs">
              Prices in the inventory grid will be colored red when they are below cost.
            </Text>
          </Box>
          <Box>
            <BNSwitch className={classes.switchOutline} {...form.getInputProps('highlightListingsAtFloor', { type: 'checkbox' })} label="Highlight Listings At Floor" size="sm" />
            <Text size="xs" c="var(--colors-gray-5)" py="xs">
              Listings at the floor will have their entire row highlighted in red for greater visibility.
            </Text>
          </Box>
          {hasUnbroadcastHighlightFlag && (
            <Box>
              <BNSwitch
                className={classes.switchOutline}
                {...form.getInputProps('highlightListingsNotBroadcasting', { type: 'checkbox' })}
                label="Highlight Listings Not Broadcasting"
                size="sm"
              />
              <Text size="xs" c="var(--colors-gray-5)" py="xs">
                Listings that aren't broadcasting will have their entire row highlighted in grey for greater visibility.
              </Text>
            </Box>
          )}
        </Stack>
      </Box>
      <Divider color="var(--colors-gray-1)" mb="xl" />
      <Box>
        <Title order={2} size="h4">
          Weather
        </Title>
        <Text size="xs" c="var(--colors-gray-5)">
          You can choose to show weather information for near-term events.
        </Text>
        <Box py="xs">
          <BNSelect
            size="xs"
            data={[
              { label: 'Do not show', value: BarkerCoreEnumsShowWeather.Never },
              { label: 'Only important', value: BarkerCoreEnumsShowWeather.OnlyImportant },
              { label: 'Show all', value: BarkerCoreEnumsShowWeather.Always },
            ]}
            style={{ maxWidth: 280 }}
            aria-label="Show Weather for"
            {...form.getInputProps('showWeather')}
          />
          <Text size="xs" c="var(--colors-gray-5)" py="xs">
            {form.values.showWeather === BarkerCoreEnumsShowWeather.Never
              ? 'No weather information will be shown in the pricer.'
              : form.values.showWeather === BarkerCoreEnumsShowWeather.OnlyImportant
                ? 'Only poor weather conditions for open-air events or severe weather alerts will be shown.'
                : 'Weather information will be shown for all near-term events.'}
          </Text>
        </Box>
      </Box>
      {hasEventCountdownFlag && (
        <Box>
          <Title order={2} size="h4">
            Event Countdown
          </Title>
          <Text size="xs" c="var(--colors-gray-5)">
            Use this feature to display the hours or days remaining until an event starts.
          </Text>
          <Box py="xs">
            <BNSelect
              size="xs"
              data={[
                { label: 'Do not show', value: BarkerCoreEnumsShowEventCountdown.Never },
                { label: 'Only within 24 hours', value: BarkerCoreEnumsShowEventCountdown.OnlyWithin24Hours },
                { label: 'Only within 48 hours', value: BarkerCoreEnumsShowEventCountdown.OnlyWithin48Hours },
                { label: 'Always show', value: BarkerCoreEnumsShowEventCountdown.Always },
              ]}
              style={{ maxWidth: 280 }}
              aria-label="Show Event Countdown for"
              {...form.getInputProps('showEventCountdown')}
            />
          </Box>
          <Text size="xs" c="var(--colors-gray-5)">
            This feature is only available for SkyBox points of sale at the moment.
          </Text>
        </Box>
      )}
    </>
  );
}
