import { Box, Button, Divider, Flex, Grid, Group, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import { useMemo, useState } from 'react';
import { BNButton } from '../../components/Button/Button';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import DateRangeIcon from '../../components/icons/DateRange';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import SearchIcon from '../../components/icons/Search';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { useSearchResults } from './SearchResults.hooks';
import { BNSelect } from '../../components/Select/Select';
import dayjs from 'dayjs';
import AutoPriceOffIcon from '../../components/icons/AutoPriceOff';
import { useFlag } from '@unleash/proxy-client-react';
import { useGlobalState } from '../../data/Global.state';
import { BNSwitch } from '../../components/Switch/Switch';
import classes from './Search.Mobile.module.css';
import { TagsFilter } from './Search.TagsFilter';
import { AntiTagsFilter } from './Search.AntiTagsFilter';
import { MobileDateOptions } from './Search.types';
import { MobileDateButtonGroup } from '../../components/ButtonGroup/MobileDateButtonGroup';

type SearchMobileProps = {
  closeSearch: () => void;
};

export function SearchMobile({ closeSearch }: SearchMobileProps) {
  const { form, search, reset, mobileDateLabel } = useSearchResults('form', 'search', 'reset', 'mobileDateLabel');

  const [showCustomPurchasedFrom, setShowCustomPurchasedFrom] = useState(
    form.values.purchaseDateFrom !== undefined &&
      form.values.purchaseDateTo !== undefined &&
      form.values.purchaseDateFrom.getTime() !== dayjs().startOf('day').toDate().getTime() &&
      form.values.purchaseDateFrom.getTime() !== dayjs().subtract(1, 'day').startOf('day').toDate().getTime() &&
      form.values.purchaseDateFrom.getTime() !== dayjs().subtract(7, 'day').startOf('day').toDate().getTime(),
  );

  const [showCustomPricedSince, setShowCustomPricedSince] = useState(
    form.values.daysSinceRepriced !== undefined &&
      form.values.daysSinceRepriced !== 1 &&
      form.values.daysSinceRepriced !== 7 &&
      form.values.daysSinceRepriced !== 30 &&
      form.values.daysSinceRepriced !== 9999,
  );

  const [showCustomSoldSince, setShowCustomSoldSince] = useState(
    form.values.daysSinceLastSale !== undefined &&
      form.values.daysSinceLastSale !== 1 &&
      form.values.daysSinceLastSale !== 7 &&
      form.values.daysSinceLastSale !== 30 &&
      form.values.daysSinceLastSale !== 9999,
  );

  const [showCustomFirstSeen, setShowCustomFirstSeen] = useState(
    form.values.daysSinceFirstSeen !== undefined &&
      form.values.daysSinceFirstSeen !== 1 &&
      form.values.daysSinceFirstSeen !== 7 &&
      form.values.daysSinceFirstSeen !== 30 &&
      form.values.daysSinceFirstSeen !== 9999,
  );

  const { tenants, selectedTenantIds } = useGlobalState('tenants', 'selectedTenantIds');
  const singleSkyBoxTenantSelected = useMemo(
    () => selectedTenantIds?.length === 1 && tenants?.find((t) => t.tenantId === selectedTenantIds[0] && t.pointOfSaleId === 'SkyBox'),
    [selectedTenantIds, tenants],
  );

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      search(form.values);
      closeSearch();
    }
  }

  const handleDateChange = (newState: MobileDateOptions) => {
    if (newState === 'Today') {
      const newFromDate = dayjs().startOf('day').toDate();
      const newToDate = dayjs().endOf('day').toDate();
      form.setFieldValue('fromDate', newFromDate);
      form.setFieldValue('toDate', newToDate);
    }
    if (newState === '7 Days') {
      const newFromDate = dayjs().startOf('day').toDate();
      const newToDate = dayjs().add(7, 'day').endOf('day').toDate();
      form.setFieldValue('fromDate', newFromDate);
      form.setFieldValue('toDate', newToDate);
    }
    if (newState === '30 Days') {
      const newFromDate = dayjs().startOf('day').toDate();
      const newToDate = dayjs().add(30, 'day').endOf('day').toDate();
      form.setFieldValue('fromDate', newFromDate);
      form.setFieldValue('toDate', newToDate);
    }
    if (newState === 'All') {
      form.setFieldValue('fromDate', undefined);
      form.setFieldValue('toDate', undefined);
    }
    if (newState === 'Custom') {
      form.setFieldValue('fromDate', dayjs().startOf('day').toDate());
      form.setFieldValue('toDate', dayjs().add(1, 'day').endOf('day').toDate());
    }
  };

  const hasFirstSeenFilterFlag =
    useFlag('filter-by-first-seen') || selectedTenantIds?.some((id) => id === 'deac90fd-337e-4ef5-9f11-954437fd9298' || id === '0e1461d2-6e23-4f29-8ebc-e100cdacb2ae');

  return (
    <>
      <Flex
        className={classes.bottomSheetModalHeader}
        justify="space-between"
        align="center"
        p={16}
        top={0}
        pos="fixed"
        left={0}
        right={0}
        style={{ borderBottom: '1px dotted var(--colors-divider)', zIndex: 1 }}
      >
        <BNButton
          size="compact-sm"
          px={0}
          py={0}
          h={28}
          w={64}
          fz="md"
          fw={500}
          variant="subtle"
          onClick={() => {
            reset();
            setShowCustomPricedSince(false);
            setShowCustomPurchasedFrom(false);
          }}
        >
          Reset
        </BNButton>
        <Text fw={600} fz="md">
          Events Search
        </Text>
        <BNButton
          variant="subtle"
          c="var(--colors-green-7)"
          fz="md"
          size="compact-sm"
          px={0}
          py={0}
          h={28}
          w={64}
          onClick={() => {
            search(form.values);
            closeSearch();
          }}
        >
          Done
        </BNButton>
      </Flex>
      <Flex align="start" className={classes.searchAreaOuterWrapper}>
        <Flex className={classes.searchAreaWrapper}>
          <Group gap={12} className="searchArea">
            <Grid columns={24} px={16} gutter={0} w="100%" mb={12}>
              <Grid.Col span="content" className={classes.flex1}>
                <form action="." onSubmit={(e) => e.preventDefault()}>
                  <BNTextInput
                    {...form.getInputProps('query')}
                    size="sm"
                    onKeyUp={handleKeyUp}
                    variant="default"
                    leftSection={<SearchIcon size={24} />}
                    placeholder="Search Events"
                    type="search"
                    clearable
                    clearableOnClick={() => form.setValues({ query: '' })}
                    name="search"
                    id="search"
                  />
                </form>
              </Grid.Col>
            </Grid>
            <Box px={16} w="100%">
              <MobileDateButtonGroup dateLabel={mobileDateLabel} handleDateChange={handleDateChange} />
            </Box>
            {mobileDateLabel === 'Custom' && (
              <SimpleGrid cols={2} spacing={12} px={16} style={{ flexGrow: 1 }}>
                <BNDatePicker
                  size="sm"
                  variant="default"
                  {...form.getInputProps('fromDate')}
                  clearable
                  valueFormat="MM/DD/YY"
                  leftSection={<DateRangeIcon size={24} color="var(--colors-gray-4)" />}
                  selectOnFocus
                  onKeyUp={handleKeyUp}
                  placeholder="From Date"
                  onChange={(dateValue) => {
                    if (dateValue === null) {
                      // @ts-ignore
                      form.setFieldValue('fromDate', dateValue);
                      return;
                    }

                    form.setFieldValue('fromDate', dateValue);

                    if (form.values.toDate && form.values.toDate < dateValue) {
                      form.setFieldValue('toDate', dateValue);
                    }
                  }}
                />
                <BNDatePicker
                  size="sm"
                  variant="default"
                  {...form.getInputProps('toDate')}
                  clearable
                  valueFormat="MM/DD/YY"
                  leftSection={<DateRangeIcon size={24} color="var(--colors-gray-4)" />}
                  selectOnFocus
                  onKeyUp={handleKeyUp}
                  placeholder="To Date"
                  onChange={(dateValue) => {
                    if (dateValue === null) {
                      // @ts-ignore
                      form.setFieldValue('toDate', dateValue);
                      return;
                    }

                    form.setFieldValue('toDate', dateValue);

                    if (form.values.fromDate && form.values.fromDate > dateValue) {
                      form.setFieldValue('fromDate', dateValue);
                    }
                  }}
                />
              </SimpleGrid>
            )}
            <Divider color="var(--colors-divider)" mt="md" mb="sm" w="100%" mx="auto" style={{ borderBottom: '4px solid var(--colors-darkPaper)' }} />
            <Stack gap={16} px={16} pb={48} w="100%">
              <Flex direction="column" gap={1} style={{ flexBasis: '100%' }}>
                <Text fz="sm" c="var(--colors-gray-5)" lh="1.25rem">
                  Quick Filters
                </Text>
                <Button.Group className={classes.btnGroup}>
                  <Tooltip label="Auto-Priced" withArrow>
                    <BNButton
                      variant="default"
                      size="sm"
                      data-pendo-name="Auto-Priced Filter Button"
                      className={form.values.pricerStatuses?.some((s) => s === 'AutoPriced') ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
                      fullWidth
                      onClick={() =>
                        form.setFieldValue(
                          'pricerStatuses',
                          form.values.pricerStatuses?.some((s) => s === 'AutoPriced')
                            ? [...form.values.pricerStatuses.filter((s) => s !== 'AutoPriced')]
                            : [...(form.values.pricerStatuses ?? []).concat(['AutoPriced'])],
                        )
                      }
                    >
                      <AutoPriceIcon size={24} color={form.values.pricerStatuses?.some((s) => s === 'AutoPriced') ? 'var(--colors-selectedBorder)' : undefined} />
                    </BNButton>
                  </Tooltip>
                  <Tooltip label="At Floor" withArrow>
                    <BNButton
                      variant="default"
                      size="sm"
                      className={form.values.pricerStatuses?.some((s) => s === 'AtFloor') ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
                      fullWidth
                      data-pendo-name="At Floor Filter Button"
                      onClick={() =>
                        form.setFieldValue(
                          'pricerStatuses',
                          form.values.pricerStatuses?.some((s) => s === 'AtFloor')
                            ? [...form.values.pricerStatuses.filter((s) => s !== 'AtFloor')]
                            : [...(form.values.pricerStatuses ?? []).concat(['AtFloor'])],
                        )
                      }
                    >
                      <VerticalAlignBottomIcon size={24} color={form.values.pricerStatuses?.some((s) => s === 'AtFloor') ? 'var(--colors-selectedBorder)' : undefined} />
                    </BNButton>
                  </Tooltip>
                  <Tooltip label="No Comparables" withArrow>
                    <BNButton
                      variant="default"
                      size="sm"
                      data-pendo-name="No Comparables Filter Button"
                      className={form.values.pricerStatuses?.some((s) => s === 'NoComparables') ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
                      fullWidth
                      onClick={() =>
                        form.setFieldValue(
                          'pricerStatuses',
                          form.values.pricerStatuses?.some((s) => s === 'NoComparables')
                            ? [...form.values.pricerStatuses.filter((s) => s !== 'NoComparables')]
                            : [...(form.values.pricerStatuses ?? []).concat(['NoComparables'])],
                        )
                      }
                    >
                      <MobileDataOffIcon size={24} color={form.values.pricerStatuses?.some((s) => s === 'NoComparables') ? 'var(--colors-selectedBorder)' : undefined} />
                    </BNButton>
                  </Tooltip>
                  <Tooltip label="Not Auto-Priced" withArrow>
                    <BNButton
                      variant="default"
                      size="sm"
                      data-pendo-name="Not Auto-Priced Filter Button"
                      className={form.values.pricerStatuses?.some((s) => s === 'None') ? `${classes.buttons} ${classes.activeButton}` : `${classes.buttons}`}
                      fullWidth
                      onClick={() =>
                        form.setFieldValue(
                          'pricerStatuses',
                          form.values.pricerStatuses?.some((s) => s === 'None')
                            ? [...form.values.pricerStatuses.filter((s) => s !== 'None')]
                            : [...(form.values.pricerStatuses ?? []).concat(['None'])],
                        )
                      }
                    >
                      <AutoPriceOffIcon size={24} color={form.values.pricerStatuses?.some((s) => s === 'None') ? 'var(--colors-selectedBorder)' : undefined} />
                    </BNButton>
                  </Tooltip>
                  {/*<Tooltip label="Errored" withArrow>*/}
                  {/*  <BNButton*/}
                  {/*    variant="default"*/}
                  {/*    size="xs"*/}
                  {/*    className={form.values.pricerStatuses?.some((s) => s === 'Errored') ? `${classes.groupBtn} ${classes.groupBtnSelected}` : `${classes.groupBtn}`}*/}
                  {/*    fullWidth*/}
                  {/*    onClick={() =>*/}
                  {/*      form.setFieldValue(*/}
                  {/*        'pricerStatuses',*/}
                  {/*        form.values.pricerStatuses?.some((s) => s === 'Errored')*/}
                  {/*          ? [...form.values.pricerStatuses.filter((s) => s !== 'Errored')]*/}
                  {/*          : [...(form.values.pricerStatuses ?? []).concat(['Errored'])],*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <ErrorIcon color={form.values.pricerStatuses?.some((s) => s === 'Errored') ? 'var(--colors-selectedBorder)' : undefined} />*/}
                  {/*  </BNButton>*/}
                  {/*</Tooltip>*/}
                </Button.Group>
              </Flex>
              {/* Commented out until we develop a more elegant way of handling many different advanced filters */}
              <BNTextInput
                size="sm"
                variant="default"
                label="Listing ID"
                {...form.getInputProps('listingId')}
                clearable
                clearableOnClick={() => form.setFieldValue('listingId', '')}
                onKeyUp={handleKeyUp}
                data-pendo-name="Listing ID Filter Input"
              />
              <BNTextInput
                size="sm"
                variant="default"
                label="Event ID"
                {...form.getInputProps('eventId')}
                clearable
                clearableOnClick={() => form.setFieldValue('eventId', '')}
                onKeyUp={handleKeyUp}
                data-pendo-name="Event ID Filter Input"
              />
              {showCustomPricedSince ? (
                <div>
                  <BNDatePicker
                    size="sm"
                    variant="default"
                    valueFormat="MM/DD/YY"
                    label="Not Priced Since"
                    clearable
                    maxDate={dayjs().startOf('day').toDate()}
                    value={form.values.daysSinceRepriced === undefined ? null : dayjs().subtract(form.values.daysSinceRepriced, 'day').toDate()}
                    onChange={(e) => {
                      const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                      form.setFieldValue('daysSinceRepriced', days);
                    }}
                    clearButtonProps={{
                      onClick: () => {
                        form.setFieldValue('daysSinceRepriced', undefined);
                        setShowCustomPricedSince(false);
                      },
                    }}
                    leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                    onKeyUp={handleKeyUp}
                    className={classes.customDatePicker}
                  />
                </div>
              ) : (
                <BNSelect
                  label="Not Priced In"
                  size="sm"
                  variant="default"
                  clearable
                  value={form.values.daysSinceRepriced === undefined ? null : form.values.daysSinceRepriced.toString()}
                  onChange={(e) => {
                    if (e === 'custom') {
                      setShowCustomPricedSince(true);
                    } else if (e === null) {
                      form.setFieldValue('daysSinceRepriced', undefined);
                    } else {
                      form.setFieldValue('daysSinceRepriced', parseInt(e));
                    }
                  }}
                  data={[
                    { value: '1', label: '1 Day' },
                    { value: '7', label: '7 Days' },
                    { value: '30', label: '30 Days' },
                    { value: 'custom', label: 'Custom Date' },
                    { value: '9999', label: 'Ever' },
                  ]}
                  data-pendo-name="Not Priced In Filter Select"
                />
              )}
              {showCustomPurchasedFrom ? (
                <div>
                  <BNDatePicker
                    leftSection={<DateRangeIcon size={24} color="var(--colors-gray-4)" />}
                    valueFormat="MM/DD/YY"
                    size="sm"
                    className={classes.mobileDatePicker}
                    variant="default"
                    label="Purchased Since"
                    clearable
                    maxDate={dayjs().startOf('day').toDate()}
                    clearButtonProps={{
                      onClick: () => {
                        form.setFieldValue('purchaseDateFrom', undefined);
                        form.setFieldValue('purchaseDateTo', undefined);
                        setShowCustomPurchasedFrom(false);
                      },
                    }}
                    value={form.values.purchaseDateFrom}
                    onChange={(e) => {
                      if (e === null) {
                        form.setFieldValue('purchaseDateFrom', undefined);
                        form.setFieldValue('purchaseDateTo', undefined);
                      } else {
                        form.setFieldValue('purchaseDateFrom', e);
                        form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                      }
                    }}
                  />
                </div>
              ) : (
                <BNSelect
                  label="Purchased"
                  size="sm"
                  clearable
                  value={
                    !form.values.purchaseDateFrom || !form.values.purchaseDateTo
                      ? ''
                      : form.values.purchaseDateFrom.getTime() === dayjs().startOf('day').toDate().getTime() &&
                          form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                        ? '0'
                        : form.values.purchaseDateFrom.getTime() === dayjs().subtract(1, 'day').startOf('day').toDate().getTime() &&
                            form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                          ? '1'
                          : form.values.purchaseDateFrom.getTime() === dayjs().startOf('week').toDate().getTime() &&
                              form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                            ? 'week'
                            : form.values.purchaseDateFrom && form.values.purchaseDateTo
                              ? 'custom'
                              : undefined
                  }
                  onChange={(e) => {
                    if (e === 'custom') {
                      setShowCustomPurchasedFrom(true);
                    } else if (e === null) {
                      form.setFieldValue('purchaseDateFrom', undefined);
                      form.setFieldValue('purchaseDateTo', undefined);
                    } else if (e === 'week') {
                      form.setFieldValue('purchaseDateFrom', dayjs().startOf('week').startOf('day').toDate());
                      form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                    } else {
                      form.setFieldValue('purchaseDateFrom', dayjs().subtract(parseInt(e), 'day').startOf('day').toDate());
                      form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                    }
                  }}
                  data={[
                    { value: '0', label: 'Today' },
                    { value: '1', label: 'Yesterday' },
                    { value: 'week', label: 'This Week' },
                    { value: 'custom', label: 'Custom Date' },
                  ]}
                  data-pendo-name="Purchased Filter Select"
                />
              )}
              {showCustomSoldSince ? (
                <div>
                  <BNDatePicker
                    size="sm"
                    variant="default"
                    valueFormat="MM/DD/YY"
                    label="No Sales Since"
                    clearable
                    maxDate={dayjs().startOf('day').toDate()}
                    value={form.values.daysSinceLastSale === undefined ? null : dayjs().subtract(form.values.daysSinceLastSale, 'day').toDate()}
                    onChange={(e) => {
                      const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                      form.setFieldValue('daysSinceLastSale', days);
                    }}
                    clearButtonProps={{
                      onClick: () => {
                        form.setFieldValue('daysSinceLastSale', undefined);
                        setShowCustomSoldSince(false);
                      },
                    }}
                    leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                    onKeyUp={handleKeyUp}
                    className={classes.customDatePicker}
                  />
                </div>
              ) : (
                <BNSelect
                  label="No Sales In"
                  size="sm"
                  clearable
                  value={form.values.daysSinceLastSale === undefined ? null : form.values.daysSinceLastSale.toString()}
                  onChange={(e) => {
                    if (e === 'custom') {
                      setShowCustomSoldSince(true);
                    } else if (e === null) {
                      form.setFieldValue('daysSinceLastSale', undefined);
                    } else {
                      form.setFieldValue('daysSinceLastSale', parseInt(e));
                    }
                  }}
                  data={[
                    { value: '1', label: '1 Day' },
                    { value: '7', label: '7 Days' },
                    { value: '30', label: '30 Days' },
                    { value: 'custom', label: 'Custom Date' },
                    { value: '9999', label: 'Ever' },
                  ]}
                  data-pendo-name="No Sales In Filter Select"
                />
              )}
              {hasFirstSeenFilterFlag && (
                <>
                  {showCustomFirstSeen ? (
                    <div>
                      <BNDatePicker
                        size="sm"
                        variant="default"
                        valueFormat="MM/DD/YY"
                        label="First Seen"
                        clearable
                        maxDate={dayjs().startOf('day').toDate()}
                        value={form.values.daysSinceFirstSeen === undefined ? null : dayjs().subtract(form.values.daysSinceFirstSeen, 'day').toDate()}
                        onChange={(e) => {
                          const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                          form.setFieldValue('daysSinceFirstSeen', days);
                        }}
                        clearButtonProps={{
                          onClick: () => {
                            form.setFieldValue('daysSinceFirstSeen', undefined);
                            setShowCustomFirstSeen(false);
                          },
                        }}
                        leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                        onKeyUp={handleKeyUp}
                        className={classes.customDatePicker}
                      />
                    </div>
                  ) : (
                    <BNSelect
                      label="First Seen"
                      size="sm"
                      clearable
                      value={form.values.daysSinceFirstSeen === undefined ? null : form.values.daysSinceFirstSeen.toString()}
                      onChange={(e) => {
                        if (e === 'custom') {
                          setShowCustomFirstSeen(true);
                        } else if (e === null) {
                          form.setFieldValue('daysSinceFirstSeen', undefined);
                        } else {
                          form.setFieldValue('daysSinceFirstSeen', parseInt(e));
                        }
                      }}
                      data={[
                        { value: '1', label: '1 Day' },
                        { value: '7', label: '7 Days' },
                        { value: '30', label: '30 Days' },
                        { value: 'custom', label: 'Custom Date' },
                        { value: '9999', label: 'Ever' },
                      ]}
                      data-pendo-name="First Seen Filter Select"
                    />
                  )}
                </>
              )}
              <BNSwitch
                label="Not Broadcasting"
                size="lg"
                checked={form.values.onlyNotBroadcasting === true}
                onChange={(event) => form.setFieldValue('onlyNotBroadcasting', event.currentTarget.checked)}
                className={classes.switchLabelTop}
                data-pendo-name="Not Broadcasting Filter Switch"
              />
              <TagsFilter size="sm" />
              {singleSkyBoxTenantSelected && <AntiTagsFilter size="sm" />}
            </Stack>
          </Group>
          {/* <SimpleGrid cols={2} className={classes.btnWrapper}>
              <BNButton
                size="md"
                fullWidth
                variant="default"
                onClick={() => {
                  reset();
                  setShowCustomPricedSince(false);
                  setShowCustomPurchasedFrom(false);
                  //setShowCustomViewedSince(false);
                }}
              >
                Reset
              </BNButton>
              <BNButton disabled={!form.isValid()} loading={isLoading} size="md" variant="filled" color="gray" fullWidth onClick={() => search()}>
                Search
              </BNButton>
            </SimpleGrid> */}
        </Flex>
      </Flex>
    </>
  );
}
