import millify from 'millify';
import { useMemo } from 'react';
import ConfirmationNumberIcon from '../icons/ConfirmationNumber';
import { BNLabel } from './Label';
import pluralize from 'pluralize';
import classes from './OpenTicketsLabel.module.css';
import { MantineFontSize } from '@mantine/core';

export const OpenticketsLabel = ({
  tickets,
  className,
  skeleton,
  hideOpen,
  size,
}: {
  hideOpen?: boolean;
  className?: string;
  tickets: number;
  skeleton?: boolean;
  size?: MantineFontSize;
}) => {
  const text = useMemo(() => (tickets >= 1000 ? `${millify(tickets || 0, { lowercase: true })}` : tickets), [tickets]);
  return (
    <BNLabel
      className={className || classes.label}
      leftIcon={<ConfirmationNumberIcon size={size === '10' ? 16 : 20} />}
      text={text}
      skeleton={skeleton}
      tooltip={`${tickets?.toLocaleString()}${!hideOpen ? ' open ' : ' '}${pluralize('ticket', tickets)}`}
      fz={size}
    />
  );
};
