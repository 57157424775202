import {
  BarkerCoreModelsAdministrativePricerSettings,
  BarkerCoreModelsAdministrativePrincipalSettings,
  getGetApiPrincipalsSelfQueryKey,
  usePatchApiPrincipalsSelf,
} from '../../api';
import { FlattenObjectKeys, MakeNonNullable } from '../../ts-utils';
import { useGlobalState } from '../../data/Global.state';
import { useQueryClient } from '@tanstack/react-query';
import { DEFAULT_RULE, DEFAULT_RULE_SETTINGS } from '../../data/constants';
import { useForm } from '@mantine/form';
import yasml from '@thirtytech/yasml';

function getFormValues(pricerSettings: BarkerCoreModelsAdministrativePricerSettings): BarkerCoreModelsAdministrativePricerSettings & {
  searchRangeCustom: number | undefined;
} & MakeNonNullable<BarkerCoreModelsAdministrativePricerSettings, 'schedulePricerSettings' | 'outlierCriteria'> {
  return {
    ...pricerSettings,
    floorMultiplier: !pricerSettings?.floorMultiplier ? 0 : pricerSettings.floorMultiplier * 100,
    ceilingMultiplier: !pricerSettings?.ceilingMultiplier ? 0 : pricerSettings.ceilingMultiplier * 100,
    floorCostMultiplier: !pricerSettings?.floorCostMultiplier ? 0 : pricerSettings.floorCostMultiplier * 100,
    ceilingCostMultiplier: !pricerSettings?.ceilingCostMultiplier ? 0 : pricerSettings.ceilingCostMultiplier * 100,
    searchRange: pricerSettings?.searchRange ? (pricerSettings.searchRange.startsWith('c') ? 'c' : pricerSettings.searchRange) : 'M1',
    searchRangeCustom: pricerSettings?.searchRange.startsWith('c') ? parseInt(pricerSettings?.searchRange.slice(1)) : undefined,
    softFloor: pricerSettings.softFloor ?? { value: 0, typeId: 'Percentage', period: '1.00:00:00' },
    outlierCriteria: pricerSettings.outlierCriteria
      ? {
          ...pricerSettings.outlierCriteria,
          percentUnder: pricerSettings.outlierCriteria.percentUnder * 100,
          comparableIndex: pricerSettings.outlierCriteria.comparableIndex + 1,
        }
      : { comparableIndex: 0, percentUnder: 0 },
    schedulePricerSettings: pricerSettings.schedulePricerSettings
      ? {
          ...pricerSettings.schedulePricerSettings,
          floorMultiplier: !pricerSettings.schedulePricerSettings?.floorMultiplier ? 0 : pricerSettings.schedulePricerSettings.floorMultiplier * 100,
          ceilingMultiplier: !pricerSettings.schedulePricerSettings?.ceilingMultiplier ? 0 : pricerSettings.schedulePricerSettings.ceilingMultiplier * 100,
        }
      : {
          numActive: 2147483647,
          adjustmentTypeId: 'Amount',
          adjustmentValue: 0,
          intervalMinutes: 1440,
          floorMultiplier: undefined,
          ceilingMultiplier: undefined,
          staggerByTypeId: 'Percentage',
          staggerByValue: 1.05,
        },
  };
}

const PricingSettingsState = () => {
  const { principal } = useGlobalState('principal');
  const queryClient = useQueryClient();
  const settings = principal?.settings ?? ({} as BarkerCoreModelsAdministrativePrincipalSettings);
  const pricerSettings =
    settings.pricerSettings ??
    ({
      defaultMarketplace: DEFAULT_RULE.marketplaceId,
      numActive: DEFAULT_RULE.numActive,
      staggerByTypeId: DEFAULT_RULE.staggerByTypeId,
      adjustmentTypeId: DEFAULT_RULE.adjustmentTypeId,
      adjustmentValue: DEFAULT_RULE.adjustmentValue,
      staggerByValue: DEFAULT_RULE.staggerByValue,
      broadcastSingleListing: DEFAULT_RULE_SETTINGS.broadcastSingleListing,
      autoAdjustSplits: DEFAULT_RULE.autoAdjustSplits,
      numComparables: [],
      searchRange: 'M1',
      rowComparisons: [],
      quantitySplitMatrix: {},
      exclusions: [],
      appearanceSettings: {
        colorPricesBelowCost: false,
        highlightListingsAtFloor: false,
        showWeather: 'Never',
        highlightListingsOnHold: true,
        highlightListingsNotBroadcasting: false,
        showEventCountdown: 'Never',
      },
      pricingMode: 'Individual',
      featureFlags: {
        softFloor: false,
        rowsPerSection: false,
      },
      softFloor: {
        value: 0,
        typeId: 'Percentage',
        period: '1.00:00:00',
      },
    } satisfies BarkerCoreModelsAdministrativePricerSettings);

  if ((pricerSettings.numComparables?.length ?? 0) === 0) {
    pricerSettings.numComparables = [{ from: 0, to: 9999, numComparables: DEFAULT_RULE.numComparables }];
  }

  const { mutateAsync: updateSelf } = usePatchApiPrincipalsSelf();
  const form = useForm({
    initialValues: getFormValues(pricerSettings),
    validate: (values) => {
      const errors: Partial<Record<FlattenObjectKeys<typeof values>, string>> = {};
      if (values.ceilingMultiplier && values.ceilingMultiplier > 0 && values.floorMultiplier && values.floorMultiplier > values.ceilingMultiplier) {
        errors.ceilingMultiplier = 'Ceiling must be greater than floor';
      }
      if (values.ceilingCostMultiplier && values.ceilingCostMultiplier > 0 && values.floorCostMultiplier && values.floorCostMultiplier > values.ceilingCostMultiplier) {
        errors.ceilingCostMultiplier = 'Ceiling must be greater than floor';
      }
      if (
        values.schedulePricerSettings &&
        values.schedulePricerSettings.ceilingMultiplier &&
        values.schedulePricerSettings.ceilingMultiplier > 0 &&
        values.schedulePricerSettings.floorMultiplier &&
        values.schedulePricerSettings.floorMultiplier > values.schedulePricerSettings.ceilingMultiplier
      ) {
        errors['schedulePricerSettings.ceilingMultiplier'] = 'Ceiling must be greater than floor';
      }
      if (values.searchRange === 'c' && !values.searchRangeCustom) {
        errors.searchRangeCustom = 'Search number of days value is required when custom is set';
      }
      if (typeof values.outlierCriteria?.percentUnder === 'number' && values.outlierCriteria?.percentUnder > 0 && !values.outlierCriteria.comparableIndex) {
        errors['outlierCriteria.comparableIndex'] = 'Compare to is required with percent under set';
      }
      if (typeof values.outlierCriteria?.percentUnder === 'string' && values.outlierCriteria.comparableIndex !== 0) {
        errors['outlierCriteria.percentUnder'] = 'Percent under is required with compare to set. Value can be 0% up to 20%';
      }
      values.rowComparisons.forEach((rowComparison, index) => {
        if (rowComparison.rowRange === '') {
          errors[`rowComparisons.${index}.rowRange`] = 'Row Range is required';
        } else if (!/^\w+-\w+$/.test(rowComparison.rowRange)) {
          errors[`rowComparisons.${index}.rowRange`] = 'Row Range must be in the format of 1-10';
        } else {
          const [from, to] = rowComparison.rowRange.split('-');
          const isFromNumber = isNaN(Number(from));
          const isToNumber = isNaN(Number(to));
          // Check if both from and to are numbers
          if (isFromNumber !== isToNumber) {
            errors[`rowComparisons.${index}.rowRange`] = 'Row Range must be entirely numeric or alphabetical';
          }
        }
        // @ts-ignore
        if (rowComparison.threshold === '') {
          errors[`rowComparisons.${index}.threshold`] = 'Value is required';
        }
      });

      return errors;
    },
    transformValues: (values) => ({
      ...values,
      floorMultiplier: typeof values.floorMultiplier === 'string' ? null : values.floorMultiplier === 0 ? null : values.floorMultiplier! / 100,
      ceilingMultiplier: typeof values.ceilingMultiplier === 'string' ? null : values.ceilingMultiplier === 0 ? null : values.ceilingMultiplier! / 100,
      floorCostMultiplier: typeof values.floorCostMultiplier === 'string' ? null : values.floorCostMultiplier === 0 ? null : values.floorCostMultiplier! / 100,
      ceilingCostMultiplier: typeof values.ceilingCostMultiplier === 'string' ? null : values.ceilingCostMultiplier === 0 ? null : values.ceilingCostMultiplier! / 100,
      searchRange: values.searchRange === 'c' ? `c${values.searchRangeCustom}` : values.searchRange,
      outlierCriteria: values.outlierCriteria?.comparableIndex
        ? {
            comparableIndex: values.outlierCriteria.comparableIndex - 1,
            percentUnder: values.outlierCriteria.percentUnder / 100,
          }
        : undefined,
      schedulePricerSettings: {
        ...values.schedulePricerSettings,
        floorMultiplier: !values.schedulePricerSettings?.floorMultiplier ? null : values.schedulePricerSettings.floorMultiplier / 100,
        ceilingMultiplier: !values.schedulePricerSettings?.ceilingMultiplier ? null : values.schedulePricerSettings.ceilingMultiplier / 100,
      },
    }),
  });

  const submit = form.onSubmit((values) => {
    const newSettings: BarkerCoreModelsAdministrativePrincipalSettings = {
      ...settings,
      pricerSettings: values,
    };

    updateSelf(
      { data: [{ op: 'Replace', path: '/settings', value: newSettings }] },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(getGetApiPrincipalsSelfQueryKey(), () => data);
        },
      },
    ).then(() => {
      form.resetDirty();
    });
  });

  const cancel = () => form.setValues(getFormValues(pricerSettings));

  return { form, submit, cancel, updateSelf, pricerSettings };
};

export const { Provider: PricingSettingsStateProvider, useSelector: usePricingSettings } = yasml(PricingSettingsState);
