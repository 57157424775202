import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import LogoLoader from '../components/Loading/LogoLoader';
import { useAuth0 } from '@auth0/auth0-react';

export default function AuthCallback() {
  const { error } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.message === 'Invalid state') {
      // Take them back to the start, which should prompt them to log back in again
      navigate('/');
    } else if (error && error.message !== 'Invalid state') {
      throw new Error(error.message);
    }
  }, [error, navigate]);

  return <LogoLoader />;
}
