import yasml from '@thirtytech/yasml';
import { Text } from '@mantine/core';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { seasons } from './atoms.seasons';
import { useQueries } from '@tanstack/react-query';
import {
  BarkerCoreModelsInventoryListing,
  getApiInventoryEventsEventId,
  getApiInventoryEventsEventIdMappings,
  getApiInventoryListingsListingId,
  getGetApiInventoryEventsEventIdMappingsQueryKey,
  getGetApiInventoryEventsEventIdQueryKey,
  getGetApiInventoryListingsListingIdQueryKey,
  patchApiInventoryListingsListingId,
  postApiPricingRules,
} from '../api';
import { useDidUpdate, useScrollIntoView } from '@mantine/hooks';
import { ruleStateAtom, seatingChartFiltersAtom, selectedEventAtom, selectedMarketplaceIdAtom, showEventMappingAtom } from './atoms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Rule } from '../types';
import { auth } from './atoms.auth';
import { calculateStaggeredPrice, getSplitsValue } from '../utils/rule-utils';
import { queryClient } from './api-config';
import { useRuleState } from './Rule.state';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { v4 as uuid } from 'uuid';
import { openConfirmModal } from '@mantine/modals';
import pLimit from 'p-limit';

export const seasonEventLimit = pLimit(5);
export const seasonListingLimit = pLimit(5);
export const seasonMappingLimit = pLimit(5);
export const seasonSavingLimit = pLimit(5);

function SeasonPricerState() {
  const selectedLocation = useAtomValue(seasons.selectedLocationAtom);
  const selectedSeasonLocations = useAtomValue(seasons.selectedSeasonAtom)?.locations;
  const setSelectedEvent = useSetAtom(selectedEventAtom);
  const [isDirty, setIsDirty] = useState(false);
  const showEventMapping = useAtomValue(showEventMappingAtom);
  const tenantId = selectedLocation?.tenantId;
  const [eventMappingEvent, setEventMappingEvent] = useState<ReturnType<typeof selectedEventAtom.read>>();
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement, HTMLDivElement>();
  const ruleGroupCount = useMemo(
    () => selectedSeasonLocations?.filter((x) => x.ruleId === selectedLocation?.ruleId).length || 1,
    [selectedLocation?.ruleId, selectedSeasonLocations],
  );
  const listingsMax =
    (selectedLocation?.ruleId
      ? selectedSeasonLocations?.filter((x) => x.ruleId === selectedLocation.ruleId).flatMap((x) => x.listings).length
      : selectedLocation?.listings.length) || 0;
  const isSelectedLocationInAGroup = useMemo(() => (selectedLocation?.ruleId ? ruleGroupCount > 1 : false), [ruleGroupCount, selectedLocation?.ruleId]);
  const clearRuleStateFromLocations = useSetAtom(seasons.clearRuleStateFromLocationsAtom);
  const updateLocationListing = useSetAtom(seasons.updateLocationListingAtom);

  const inventoryEventsQuery = useQueries({
    queries:
      selectedLocation?.listings.map(({ eventId }) => ({
        queryKey: getGetApiInventoryEventsEventIdQueryKey(eventId),
        queryFn: () =>
          seasonEventLimit(() =>
            getApiInventoryEventsEventId(eventId, {
              headers: {
                'x-tenant-id': tenantId,
              },
            }),
          ),
      })) ?? [],
  });

  const inventoryEventsQueryProcessingCount = inventoryEventsQuery.filter((x) => x.isLoading || x.isFetching).length;

  const inventoryEvents = useMemo(
    () => (inventoryEventsQuery.some((x) => !x.data?.data) ? [] : inventoryEventsQuery.map((x) => x.data?.data)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inventoryEventsQueryProcessingCount],
  );

  const listingsQuery = useQueries({
    queries:
      (selectedLocation?.ruleId ? selectedSeasonLocations?.filter((x) => x.ruleId === selectedLocation.ruleId).flatMap((x) => x.listings) : selectedLocation?.listings)?.map(
        ({ listingId }) => ({
          // selectedLocation?.listings.map(({ listingId }) => ({
          enabled: !inventoryEventsQuery.some((x) => x.isLoading || x.isFetching),
          queryKey: getGetApiInventoryListingsListingIdQueryKey(listingId),
          staleTime: 5 * 60 * 1000, // 5 Mins
          gcTime: 5 * 60 * 1000, // 5 Mins
          queryFn: () =>
            seasonListingLimit(() =>
              getApiInventoryListingsListingId(listingId, {
                headers: {
                  'x-tenant-id': tenantId,
                },
              }),
            ),
        }),
      ) ?? [],
  });

  const listingsProcessingCount = inventoryEventsQueryProcessingCount > 0 ? listingsMax : listingsQuery.filter((x) => x.isLoading || x.isFetching).length;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listings = useMemo(() => listingsQuery.map((x) => x.data?.data).filter((x) => x), [selectedLocation?.listings, listingsProcessingCount]);
  const listingsAtom = useMemo(() => {
    // Convert listings array into Record<string, BarkerCoreModelsInventorySeasonLocationListing> by listingId
    const _listings: Record<string, BarkerCoreModelsInventoryListing> = {};
    listings.forEach((listing) => {
      if (listing) {
        _listings[listing.listingId] = listing;
      }
    });
    return atomWithReset(_listings);
  }, [listings]);
  const listingsReset = useResetAtom(listingsAtom);
  const listingsValues = useAtomValue(listingsAtom);

  const eventMappingQuery = useQueries({
    queries:
      selectedLocation?.listings.map(({ eventId }) => ({
        enabled: !inventoryEventsQuery.some((x) => x.isLoading || x.isFetching),
        queryKey: [...getGetApiInventoryEventsEventIdMappingsQueryKey(eventId), !inventoryEventsQuery.some((x) => x.isLoading || x.isFetching)],
        queryFn: () =>
          seasonMappingLimit(() =>
            getApiInventoryEventsEventIdMappings(eventId, {
              headers: {
                'x-tenant-id': tenantId,
              },
            }),
          ),
      })) ?? [],
  });

  const eventMappingProcessingCount = eventMappingQuery.filter((x) => x.isLoading || x.isFetching).length;

  const isLoading =
    inventoryEventsQuery.some((x) => x.isLoading || x.isFetching) ||
    eventMappingQuery.some((x) => x.isLoading || x.isFetching) ||
    listingsQuery.some((x) => x.isLoading || x.isFetching);

  const eventMappings = useMemo(
    () =>
      isLoading
        ? []
        : eventMappingQuery
            .map((x) => x.data?.data)
            .filter((x) => x)
            .flatMap((x) => x!) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const marketplaceId = useAtomValue(selectedMarketplaceIdAtom);

  useDidUpdate(() => {
    if (inventoryEvents) {
      const firstEvent = inventoryEventsQuery?.sort((a, b) => (a.data?.data?.localDateTime?.getTime() ?? 0) - (b.data?.data?.localDateTime?.getTime() ?? 0))?.[0]?.data?.data;
      setSelectedEvent(firstEvent);
    }
  }, [isLoading]);

  const [defaultRule, setDefaultRule] = useAtom(ruleStateAtom);
  const rulesAtom = useMemo(() => atom<Record<string, Rule>>({}), []);
  const setRules = useSetAtom(rulesAtom);
  useEffect(() => {
    resetAllRules();
    // Note: We modify the listings to toggle autoPricing on/off. Using that dep would cause rules to refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation?.hashId]);

  const resetAllRules = useCallback(() => {
    if (selectedLocation?.listings) {
      listingsReset();
      setErrorEventId('');
      const _rules: { [key: string]: any } = {};
      selectedLocation.listings.forEach(({ eventId }) => {
        _rules[eventId] = {
          ...defaultRule,
          ruleId: uuid(),
          isAutoPriced: false,
        } as Rule;
      });
      setRules(_rules);
    }
  }, [defaultRule, listingsReset, selectedLocation, setRules]);

  const [seatingChartFilters, setSeatingChartFilters] = useAtom(seatingChartFiltersAtom);
  const { adjustmentTypeId, adjustmentValue, numComparables, autoAdjustSplits, staggerByTypeId, staggerByValue, numActive } = defaultRule;
  const principalPricerSettings = useAtomValue(auth.principalAtom)?.settings?.pricerSettings;
  const tenantPricerSettings = useAtomValue(auth.tenantsAtom).filter((t) => t.tenantId === selectedLocation?.tenantId)[0]?.settings?.pricerSettings;

  useEffect(() => {
    if (!defaultRule.isAutoPriced) {
      setDefaultRule((x) => ({ ...x, isAutoPriced: true }));
    }
  }, [defaultRule.isAutoPriced, setDefaultRule]);

  useDidUpdate(() => {
    if (defaultRule.autoAdjustSplits) {
      setSeatingChartFilters((prev) => ({ ...prev, splits: [] }));
    }
  }, [defaultRule.autoAdjustSplits]);

  useDidUpdate(() => {
    setRules((prev) => {
      const _rules = { ...prev };
      Object.keys(_rules).forEach((eventId) => {
        const quantity = selectedLocation?.listings.find((x) => x.eventId === eventId)?.quantity;
        const row = selectedLocation?.row;
        const _rule = _rules[eventId];
        if (_rule && row) {
          const splits = _rule.filters?.splits;
          _rules[eventId] = {
            ..._rule,
            autoAdjustSplits,
            filters: {
              ...seatingChartFilters,
              splits: !autoAdjustSplits
                ? seatingChartFilters.splits
                : getSplitsValue(
                    autoAdjustSplits,
                    splits,
                    quantity || 1,
                    row,
                    principalPricerSettings?.quantitySplitMatrix,
                    principalPricerSettings?.generalAdmissionSplits,
                    tenantPricerSettings?.quantitySplitMatrix,
                    tenantPricerSettings?.generalAdmissionSplits,
                  ),
            },
          };
        }
      });
      return _rules;
    });
  }, [seatingChartFilters, autoAdjustSplits, principalPricerSettings, tenantPricerSettings]);

  useDidUpdate(() => {
    if (marketplaceId) {
      setRules((prev) => {
        const _rules = { ...prev };
        Object.keys(_rules).forEach((eventId) => {
          const _rule = _rules[eventId];
          if (_rule) {
            _rules[eventId] = {
              ..._rule,
              ...(!_rule.meta?.adjustmentOverride
                ? {
                    adjustmentTypeId,
                    adjustmentValue,
                    staggerByTypeId,
                    staggerByValue,
                    numActive,
                  }
                : {
                    staggerByTypeId,
                    staggerByValue,
                    numActive,
                  }),
              ...(!_rule.meta?.numComparablesOverride
                ? {
                    numComparables,
                  }
                : {}),
              marketplaceId,
            };
          }
        });
        return _rules;
      });
    }
  }, [adjustmentTypeId, adjustmentValue, staggerByTypeId, staggerByValue, numActive, numComparables, marketplaceId]);

  const toggleRemaining = useCallback(
    (value: boolean) => {
      setRules((prev) => {
        const _rules = { ...prev };
        Object.keys(_rules).forEach((eventId) => {
          // TODO May have to care about tenantId but will think about that later.
          const hasMapping = eventMappings.some((y) => y.eventId === eventId);
          const listing = Object.values(listingsValues).find((x) => x.eventId === eventId);
          if (hasMapping) {
            const _rule = _rules[eventId];
            if (_rule && listing?.pricerStatusId === 'None') {
              _rules[eventId] = {
                ..._rule,
                isAutoPriced: value,
              };
            }
          }
        });
        return _rules;
      });
    },
    [eventMappings, listingsValues, setRules],
  );

  const targetComparablePriceByEventIdAtom = useMemo(() => atom<Record<string, number | null>>({}), []);

  const [errorEventId, setErrorEventId] = useState('');
  const rules = useAtomValue(rulesAtom);
  const isAnyRuleEnabled = Object.values(rules).some((r) => r.isAutoPriced);
  const setSeasonRuleDefault = useSetAtom(seasons.setSeasonRuleFromLocationAtom);
  const { calculatePricerStatusId } = useRuleState('calculatePricerStatusId');
  const targetComparablePriceByEventId = useAtomValue(targetComparablePriceByEventIdAtom);
  const [isSaving, setIsSaving] = useState(0);

  const saveRules = useCallback(async () => {
    // TODO: Consider loading states and how they cascade
    // TODO: Error handling?
    // TODO: How to resolve UI after saving rules and applying to listings.

    const isValid = Object.values(rules).every((r) => (r.isAutoPriced && typeof r.floorPrice === 'number' && r.floorPrice > 0) || !r.isAutoPriced);
    if (!isValid) {
      const invalidRecord = Object.entries(rules).find(([, rule]) => rule.isAutoPriced && (typeof rule.floorPrice !== 'number' || rule.floorPrice <= 0));
      if (invalidRecord) {
        const eventId = invalidRecord[0];
        setErrorEventId(eventId);
        requestAnimationFrame(() => {
          scrollIntoView();
        });
      }

      // Handle focus the list on the first broken rule.
      return false;
    }

    const rulesToSaveCount = Object.values(rules).filter((x) => x.isAutoPriced).length;
    const groupedLocationCount = selectedLocation?.ruleId ? selectedSeasonLocations?.filter((x) => x.ruleId === selectedLocation?.ruleId).length || 1 : 1;
    const allRuleLocationsListings = selectedLocation?.ruleId
      ? selectedSeasonLocations?.filter((x) => x.ruleId === selectedLocation?.ruleId).flatMap((x) => x.listings.map((listing) => ({ ruleTier: x.ruleTier, ...listing })))
      : selectedLocation?.listings.map((listing) => ({ ruleTier: groupedLocationCount === 1 ? undefined : 0, ...listing }));
    setIsSaving(rulesToSaveCount);

    const saving = Object.keys(rules).map(async (eventId) => {
      const rule = rules[eventId];
      if (rule && rule.isAutoPriced) {
        const { data: savedRule } = await postApiPricingRules(
          { ...rule, pointOfSaleEventId: eventId },
          {
            headers: {
              'x-tenant-id': selectedLocation?.tenantId,
            },
          },
        );

        setIsSaving((prev) => prev - 0.5);

        const savingListings = allRuleLocationsListings
          ?.filter((x) => x.eventId === eventId)
          .toSorted((a, b) => (a.ruleTier ?? 0) - (b.ruleTier ?? 0))
          .map(async ({ listingId, ruleTier }, index) => {
            const listing = listings.find((x) => x?.listingId === listingId)!;
            if (listing) {
              const status = calculatePricerStatusId(rule, listing);
              const previousPrice = listing.unitPrice;
              const staggerPrice = parseFloat(
                calculateStaggeredPrice({
                  floorPrice: rule.floorPrice,
                  staggerByTypeId: rule.staggerByTypeId,
                  staggerByValue: rule.staggerByValue,
                  offset: index, // TODO figure out how to get offset from ruleTier
                  price: targetComparablePriceByEventId[eventId] ?? 0,
                  tenantRoundingSettings: tenantPricerSettings?.roundPrices ?? 'None',
                }).toFixed(2),
              );
              const { data: patchListing } = await patchApiInventoryListingsListingId(
                listingId!,
                [
                  { path: '/ruleId', op: 'replace', value: savedRule.ruleId },
                  { path: '/pricerStatusId', op: 'replace', value: status },
                  { path: '/ruleTier', op: 'replace', value: ruleTier },
                  { path: '/unitPrice', op: 'replace', value: staggerPrice },
                  { path: '/previousPrice', op: 'replace', value: previousPrice },
                ],
                {
                  headers: {
                    'x-tenant-id': selectedLocation?.tenantId,
                  },
                },
              );
              queryClient.setQueryData(getGetApiInventoryListingsListingIdQueryKey(listingId!), { data: patchListing });
              updateLocationListing(listingId, { pricerStatusId: status });
            }
            setIsSaving((prev) => parseFloat((prev - 0.5 / groupedLocationCount).toPrecision(3)));
            return true;
          });
        await Promise.all(savingListings ?? []);
      }
    });
    await Promise.all(saving);

    // TODO: If the season is fully auto priced. Prompt to break up the group.

    // NOTE: May not need to fetch the whole season now that we update the season listing status.
    // queryClient.invalidateQueries(getGetApiInventorySeasonsQueryKey());

    if (selectedLocation) {
      setSeasonRuleDefault(selectedLocation);
    }

    resetAllRules();
    setIsDirty(false);
    return true;
  }, [
    calculatePricerStatusId,
    listings,
    resetAllRules,
    rules,
    scrollIntoView,
    selectedLocation,
    selectedSeasonLocations,
    setSeasonRuleDefault,
    targetComparablePriceByEventId,
    tenantPricerSettings?.roundPrices,
    updateLocationListing,
  ]);

  useDidUpdate(() => {
    if (!isDirty) {
      Object.values(rules).forEach((rule) => {
        if (rule.isAutoPriced) {
          setIsDirty(true);
        }
      });
    }
  }, [rules]);

  const validateDirtyRuleState = useCallback(
    () =>
      new Promise((resolve) => {
        if (isDirty || isSelectedLocationInAGroup) {
          openConfirmModal({
            title: 'Unsaved Changes',
            children: <Text size="sm">Changes to this listing will be reset if you continue.</Text>,
            labels: { confirm: 'Reset & Continue', cancel: 'Cancel' },
            confirmProps: { className: 'confirmButton', variant: 'default', size: 'sm' },
            cancelProps: { className: 'cancelButton', variant: 'filled', color: 'gray', size: 'sm' },
            closeButtonProps: { size: 'md' },
            onConfirm() {
              setIsDirty(false);
              resolve(true);
              clearRuleStateFromLocations();
            },
            onCancel() {
              resolve(false);
            },
          });
        } else {
          resolve(true);
        }
      }),
    [clearRuleStateFromLocations, isDirty, isSelectedLocationInAGroup],
  );

  return {
    listingsMax,
    inventoryEvents,
    listings,
    eventMappings,
    selectedLocation,
    rulesAtom,
    toggleRemaining,
    saveRules,
    targetComparablePriceByEventIdAtom,
    resetAllRules,
    listingsAtom,
    validateDirtyRuleState,
    isDirty,
    setIsDirty,
    showEventMapping,
    eventMappingEvent,
    setEventMappingEvent,
    marketplaceId,
    errorEventId,
    targetRef,
    scrollableRef,
    isLoading,
    isAnyRuleEnabled,
    isSaving,
    inventoryEventsQueryProcessingCount,
    listingsProcessingCount,
    eventMappingProcessingCount,
    ruleGroupCount,
  };
}

export const { Provider: SeasonPricerProvider, useSelector: useSeasonPricer } = yasml(SeasonPricerState);
