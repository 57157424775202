import yasml from '@thirtytech/yasml';
import { useAtomValue, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useSeasonPricer } from '../../data/SeasonsPricer.state';
import { useMarketListings } from '../MarketListings/MarketListings.hooks';
import { GetTargetComparablePrice, GetTargetComparables } from '../MarketListings/marketListingsFilter';
import { marketplaceListingsFilteredAtom, marketplaceTargetComparablesAtom } from '../../data/atoms';
import { calculateTargetPrice } from '../../utils/rule-utils';
import { BarkerCoreModelsInventoryListing } from '../../api';
import { useGlobalState } from '../../data/Global.state';

function SeasonEventCardState({
  eventId,
  listing,
  setListing,
}: {
  eventId: string;
  listing: BarkerCoreModelsInventoryListing | null;
  setListing: Dispatch<SetStateAction<BarkerCoreModelsInventoryListing>>;
}) {
  const { rowData, unfilteredListings } = useMarketListings('rowData', 'unfilteredListings');
  const { rulesAtom, targetComparablePriceByEventIdAtom } = useSeasonPricer('rulesAtom', 'targetComparablePriceByEventIdAtom');
  const rule = useAtomValue(useMemo(() => focusAtom(rulesAtom, (x) => x.prop(eventId)), [eventId, rulesAtom]));
  const updateRule = useSetAtom(useMemo(() => focusAtom(rulesAtom, (x) => x.prop(eventId)), [eventId, rulesAtom]));
  const filteredRowData = useAtomValue(marketplaceListingsFilteredAtom);
  const targetComparables = useAtomValue(marketplaceTargetComparablesAtom);
  const { tenants } = useGlobalState('tenants');
  const roundingSettings = tenants?.find((tenant) => tenant.tenantId === listing?.tenantId)?.settings?.pricerSettings?.roundPrices ?? 'None';

  const summaryTotals = useMemo(
    () => ({
      totalListings: unfilteredListings.length,
      totalFilteredListings: filteredRowData.length,
      totalTickets: unfilteredListings.reduce((acc, _listing) => acc + (_listing.quantity || 0), 0),
      totalFilteredTickets: filteredRowData.reduce((acc, _listing) => acc + (_listing.quantity || 0), 0),
    }),
    [filteredRowData, unfilteredListings],
  );
  const targetPrice = GetTargetComparablePrice(targetComparables);

  const calculatedTargetPrice = useMemo(() => {
    if (listing) {
      return calculateTargetPrice({
        listing,
        offset: 0,
        rule,
        targetComparables: GetTargetComparables(targetComparables || [], rule.numComparables || 1),
        tenantRoundingSettings: roundingSettings,
      });
    }
    return 0;
  }, [listing, rule, targetComparables, roundingSettings]);

  const setTargetComparablePrice = useSetAtom(useMemo(() => focusAtom(targetComparablePriceByEventIdAtom, (x) => x.prop(eventId)), [eventId, targetComparablePriceByEventIdAtom]));

  useEffect(() => {
    if (listing) {
      const price = calculateTargetPrice({
        listing,
        offset: 0,
        rule,
        targetComparables: GetTargetComparables(filteredRowData, rule.numComparables || 1),
        tenantRoundingSettings: roundingSettings,
      });
      setTargetComparablePrice(price);
    }
  }, [listing, filteredRowData, rule, setTargetComparablePrice, roundingSettings]);

  return { targetPrice, calculatedTargetPrice, summaryTotals, rowData, updateRule, listing, setListing };
}

export const { Provider: SeasonEventCardProvider, useSelector: useSeasonEventCard } = yasml(SeasonEventCardState);
