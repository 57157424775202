import { useSearchResults } from './SearchResults.hooks';
import { MantineSize, MultiSelect } from '@mantine/core';
import { useGlobalState } from '../../data/Global.state';

type AntiTagsFilterProps = {
  size?: MantineSize;
};

export function AntiTagsFilter({ size }: AntiTagsFilterProps) {
  const { tags, form } = useSearchResults('tags', 'form');
  const { selectedTenantIds } = useGlobalState('selectedTenantIds');

  if (!!selectedTenantIds && selectedTenantIds.length > 1) {
    return null;
  }

  return (
    <MultiSelect
      disabled={!tags}
      size={size || 'xs'}
      data={tags ?? []}
      label="Anti Tags"
      hidePickedOptions
      searchable
      nothingFoundMessage="No tags found"
      {...form.getInputProps('antiTags')}
    />
  );
}
