import millify from 'millify';
import { useMemo } from 'react';
import StadiumIcon from '../icons/Stadium';
import { BNLabel } from './Label';
import pluralize from 'pluralize';
import classes from './TotalEventsLabel.module.css';
import { MantineFontSize } from '@mantine/core';

export const TotalEventsLabel = ({ events, className, skeleton, size }: { className?: string; events: number; skeleton?: boolean; size?: MantineFontSize }) => {
  const text = useMemo(() => (events >= 1000 ? `${millify(events || 0, { lowercase: true })}` : events), [events]);
  return (
    <BNLabel
      className={className || classes.label}
      leftIcon={<StadiumIcon size={size === '10' ? 16 : 20} />}
      text={text}
      skeleton={skeleton}
      tooltip={`${events.toLocaleString()} ${pluralize('event', events)}`}
      fz={size}
    />
  );
};
