import { Box, Divider, Space, Table, Text } from '@mantine/core';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { useGlobalState } from '../../data/Global.state';
import { BNSwitch } from '../../components/Switch/Switch';
import {
  BarkerCoreModelsAdministrativeNotificationSettings,
  BarkerCoreModelsAdministrativePricerSettings,
  BarkerCoreModelsAdministrativePrincipalSettings,
  BarkerCoreModelsAdministrativeUserDetails,
  getApiTenantsTenantIdUsers,
  getGetApiPrincipalsSelfQueryKey,
  getGetApiTenantsTenantIdUsersQueryKey,
  getGetApiTenantsTenantIdUsersUserIdQueryKey,
  usePatchApiPrincipalsSelf,
  usePutApiTenantsTenantIdUsersUserId,
} from '../../api';
import { useMemo } from 'react';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { BNButton } from '../../components/Button/Button';
import { DEFAULT_RULE, DEFAULT_RULE_SETTINGS } from '../../data/constants';
import { useForm } from '@mantine/form';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import validator from 'email-validator';
import classes from './NotificationSettings.module.css';
import { useAtomValue } from 'jotai/index';
import { auth } from '../../data/atoms.auth';

export default function NotificationSettings() {
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const allUsers = useQueries({
    queries:
      tenants?.map(({ tenantId }) => ({
        queryKey: getGetApiTenantsTenantIdUsersQueryKey(tenantId),
        enabled: !!apiToken,
        queryFn: () => getApiTenantsTenantIdUsers(tenantId),
      })) ?? [],
  });
  const users = allUsers?.reduce((acc, curr) => {
    if (curr.data?.data) {
      acc.push(...curr.data.data);
    }
    return acc;
  }, [] as BarkerCoreModelsAdministrativeUserDetails[]);
  const onlyUser = tenants?.length !== 1 ? null : users?.find((u) => u.tenantId === tenants[0].tenantId && u.principalId === principal?.principalId);
  const { mutateAsync: updateUser } = usePutApiTenantsTenantIdUsersUserId();
  const { mutateAsync: updateSelf } = usePatchApiPrincipalsSelf();
  const queryClient = useQueryClient();
  const settings = principal?.settings ?? ({} as BarkerCoreModelsAdministrativePrincipalSettings);
  const pricerSettings = (settings.pricerSettings as BarkerCoreModelsAdministrativePricerSettings) ?? {
    defaultMarketplace: DEFAULT_RULE.marketplaceId,
    numActive: DEFAULT_RULE.numActive,
    staggerByTypeId: DEFAULT_RULE.staggerByTypeId,
    adjustmentTypeId: DEFAULT_RULE.adjustmentTypeId,
    adjustmentValue: DEFAULT_RULE.adjustmentValue,
    staggerByValue: DEFAULT_RULE.staggerByValue,
    broadcastSingleListing: DEFAULT_RULE_SETTINGS.broadcastSingleListing,
    autoAdjustSplits: DEFAULT_RULE.autoAdjustSplits,
  };
  const notificationSettings = (pricerSettings.notificationSettings as BarkerCoreModelsAdministrativeNotificationSettings) ?? {
    emailOverride: null,
    eventDaysAway: null,
  };

  const form = useForm({
    initialValues: notificationSettings,
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (values.emailOverride && !validator.validate(values.emailOverride)) {
        errors.emailOverride = 'Invalid email address';
      }

      return errors;
    },
    transformValues: (values) => ({
      ...values,
      eventDaysAway: typeof values.eventDaysAway === 'string' ? null : values.eventDaysAway,
    }),
  });

  const rows = useMemo(
    () =>
      tenants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((tenant) => {
          const receiveNotifications = users?.find((user) => user.tenantId === tenant.tenantId && user.principalId === principal?.principalId)?.receiveNotifications ?? false;

          return (
            <tr key={tenant.name} style={{ position: 'relative', borderTop: '1px solid var(--colors-gray-2)' }}>
              <td>{tenant.name}</td>
              <td width={200}>
                <BNSwitch
                  checked={receiveNotifications}
                  width={50}
                  className={classes.notificationSwitch}
                  outlined
                  size="sm"
                  onChange={(e) => {
                    const user = users?.find((u) => u.tenantId === tenant.tenantId && u.principalId === principal?.principalId);
                    if (!user) {
                      return;
                    }
                    const newUser = {
                      ...user,
                      receiveNotifications: e.currentTarget.checked,
                    };
                    updateUser(
                      { tenantId: tenant.tenantId, userId: principal!.principalId, data: newUser },
                      {
                        onSuccess: (data) => {
                          queryClient.setQueryData(getGetApiTenantsTenantIdUsersUserIdQueryKey(tenant.tenantId, principal!.principalId), () => data);
                        },
                      },
                    ).then(() => {
                      queryClient.invalidateQueries({ queryKey: getGetApiTenantsTenantIdUsersQueryKey(tenant.tenantId) });
                    });
                  }}
                />
              </td>
            </tr>
          );
        }),
    [principal, queryClient, tenants, updateUser, users],
  );

  if (!principal || !tenants) {
    return null;
  }

  return (
    <>
      <AdminContentHeader
        title="Notification Settings"
        sticky
        rightSection={
          <>
            <BNButton
              size="xs"
              variant="default"
              disabled={!form.isDirty()}
              onClick={() => {
                form.setValues({
                  eventDaysAway: notificationSettings.eventDaysAway,
                  emailOverride: notificationSettings.emailOverride,
                });
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              size="xs"
              variant="filled"
              color="green"
              disabled={!form.isDirty()}
              onClick={() =>
                form.onSubmit((values) => {
                  const newSettings: BarkerCoreModelsAdministrativePrincipalSettings = {
                    ...settings,
                    pricerSettings: {
                      ...pricerSettings,
                      notificationSettings: {
                        emailOverride: values.emailOverride,
                        eventDaysAway: values.eventDaysAway,
                      },
                    },
                  };

                  updateSelf(
                    { data: [{ op: 'Replace', path: '/settings', value: newSettings }] },
                    {
                      onSuccess: (data) => {
                        queryClient.setQueryData(getGetApiPrincipalsSelfQueryKey(), () => data);
                      },
                    },
                  ).then(() => {
                    form.resetDirty();
                  });
                })()
              }
            >
              Save
            </BNButton>
          </>
        }
      />
      <Box pt="xl" pb="sm">
        <Box>
          <BNTextInput maw={400} size="xs" label="Notification Email" {...form.getInputProps('emailOverride')} />
          <Text size="xs" c="var(--colors-gray-5)" maw={400} mt="xs" pr="xs">
            Your notifications will be sent to <strong>{principal.email}</strong>. If you would like to receive notifications at a different email address, enter it here.
          </Text>
          <Space h="md" />
          <BNNumberInput w={132} size="xs" min={0} label="Days Until Event" {...form.getInputProps('eventDaysAway')} />
          <Text size="xs" c="var(--colors-gray-5)" maw={400} mt="xs" pr="xs">
            If you only wish to be notified for events within a certain number of days, enter that number here.
          </Text>
          <Space h="md" />
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <Space h="xl" />
        </Box>
      </Box>
      {onlyUser ? (
        <Box>
          <BNSwitch
            size="sm"
            outlined
            label="Receive Pricer Notifications"
            checked={onlyUser?.receiveNotifications ?? false}
            onChange={(e) => {
              if (!onlyUser || !tenants) {
                return;
              }

              const tenant = tenants[0];

              const newUser = {
                ...onlyUser,
                receiveNotifications: e.currentTarget.checked,
              };
              updateUser(
                { tenantId: tenant.tenantId, userId: principal!.principalId, data: newUser },
                {
                  onSuccess: (data) => {
                    queryClient.setQueryData(getGetApiTenantsTenantIdUsersUserIdQueryKey(tenant.tenantId, principal!.principalId), () => data);
                  },
                },
              ).then(() => {
                queryClient.invalidateQueries({ queryKey: getGetApiTenantsTenantIdUsersQueryKey(tenant.tenantId) });
              });
            }}
          />
        </Box>
      ) : (
        <Box>
          <Table verticalSpacing="md" cellPadding="16px 12px 16px 12px" maw={400} withRowBorders>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Company Name</th>
                <th style={{ textAlign: 'right' }}>Receive Notifications</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Box>
      )}
    </>
  );
}
