import { useCallback, useMemo, useState } from 'react';
import { Divider, Flex, Group, Popover, SimpleGrid, Text, Tooltip } from '@mantine/core';
import WifiIcon from '../../components/icons/Wifi';
import WifiOffIcon from '../../components/icons/WifiOff';
import { useRuleState } from '../../data/Rule.state';
import classes from './Inventory.Broadcasting.module.css';
import cx from 'clsx';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import MarketplaceStubHubIcon from '../../components/icons/MarketplaceStubHub';
import MarketplaceVividIcon from '../../components/icons/MarketplaceVivid';
import MarketplaceTicketmasterIcon from '../../components/icons/MarketplaceTicketmaster';
import MarketplaceSeatGeekIcon from '../../components/icons/MarketplaceSeatGeek';
import MarketplaceTicketEvolutionIcon from '../../components/icons/MarketplaceTicketEvolution';
import MarketplaceTickPickIcon from '../../components/icons/MarketplaceTickPick';
import MarketplaceAXSIcon from '../../components/icons/MarketplaceAXS';
import MarketplaceGametimeIcon from '../../components/icons/MarketplaceGametime';
import MarketplaceTicketNetworkIcon from '../../components/icons/MarketplaceTicketNetwork';
import MarketplaceGoTicketsIcon from '../../components/icons/MarketplaceGoTickets';
import { getSelectedMarketplaces } from '../../utils/dti-utils';

export type BroadcastingProps = {
  isBroadcasting: boolean;
  isGrouped: boolean;
  ruleCount?: number | undefined;
  listingId: string;
  tenantId: string;
  updateBroadcasting: (tenantId: string, listingId: string, isBroadcasting: boolean) => void;
  omittedMarketplaces?: string | null; // This should probably be on a DTI-specific type, but we can refactor it later
};

export const Broadcasting = (props: Omit<BroadcastingProps, 'isGrouped'>) => {
  const { isBroadcasting, tenantId, listingId, ruleCount } = props;
  const { pendingListingUpdatesByProperty } = useRuleState('pendingListingUpdatesByProperty');
  const pending = pendingListingUpdatesByProperty({ tenantId, listingId, property: 'isBroadcasting' });
  const broadcasting = (pending?.value as boolean) ?? isBroadcasting;
  const isGrouped = useMemo(() => (ruleCount || 0) > 1, [ruleCount]);

  return (
    <>
      {isDtiHosted ? (
        <DTIBroadcastingImpl
          {...props}
          isBroadcasting={broadcasting}
          isGrouped={isGrouped}
          listingId={listingId}
          tenantId={tenantId}
          omittedMarketplaces={props.omittedMarketplaces}
        />
      ) : (
        <BroadcastingImpl {...props} isBroadcasting={broadcasting} isGrouped={isGrouped} listingId={listingId} tenantId={tenantId} />
      )}
    </>
  );
};

const BroadcastingImpl = ({ isBroadcasting, isGrouped, updateBroadcasting, listingId, tenantId }: Omit<BroadcastingProps, 'ruleCount'>) => {
  const onDoubleClick = useCallback(() => {
    if (isGrouped) {
      return;
    }
    updateBroadcasting(tenantId, listingId, !isBroadcasting);
  }, [isBroadcasting, isGrouped, listingId, tenantId, updateBroadcasting]);

  return (
    <Tooltip label={isBroadcasting ? 'Broadcasting' : 'Not Broadcasting'} withArrow>
      <Flex
        w={22}
        h={22}
        align="center"
        justify="center"
        c={isBroadcasting ? 'var(--colors-green-broadcast)' : 'var(--colors-gray-4)'}
        bg="var(--colors-paper)"
        data-pendo-name="Inline broadcasting toggle"
        className={`${isBroadcasting ? classes.wifiIconBroadcasting : classes.wifiIconNotBroadcasting} ${!isGrouped ? classes.wifiIconWrapper : classes.wifiIconGroupedWrapper}`}
        onDoubleClick={onDoubleClick}
      >
        {isBroadcasting ? <WifiIcon size={16} /> : <WifiOffIcon size={16} />}
      </Flex>
    </Tooltip>
  );
};

const DTIBroadcastingImpl = ({ isBroadcasting, isGrouped, updateBroadcasting, listingId, tenantId, omittedMarketplaces }: Omit<BroadcastingProps, 'ruleCount'>) => {
  const onDoubleClick = useCallback(() => {
    if (isGrouped) {
      return;
    }
    updateBroadcasting(tenantId, listingId, !isBroadcasting);
  }, [isBroadcasting, isGrouped, listingId, tenantId, updateBroadcasting]);

  const selectedMarketplaces = getSelectedMarketplaces(omittedMarketplaces ?? '');
  const allMarketplaces = getSelectedMarketplaces('');

  const broadcasting = !isBroadcasting ? 'none' : selectedMarketplaces.length === allMarketplaces.length ? 'all' : 'partial';
  const [isOpened, setIsOpen] = useState(false);

  return (
    <>
      {broadcasting !== 'partial' ? (
        <Tooltip label={broadcasting === 'all' ? 'Broadcasting' : 'Not Broadcasting'} withArrow>
          <Flex
            w={22}
            h={22}
            align="center"
            justify="center"
            c={isBroadcasting ? 'var(--colors-green-broadcast)' : 'var(--colors-gray-4)'}
            bg="var(--colors-paper)"
            data-pendo-name="Inline broadcasting toggle"
            className={`${isBroadcasting ? classes.wifiIconBroadcasting : classes.wifiIconNotBroadcasting} ${!isGrouped ? classes.wifiIconWrapper : classes.wifiIconGroupedWrapper}`}
            onDoubleClick={onDoubleClick}
          >
            {isBroadcasting ? <WifiIcon size={16} /> : <WifiOffIcon size={16} />}
          </Flex>
        </Tooltip>
      ) : (
        <Popover withArrow position="right" opened={isOpened}>
          <Popover.Target>
            <Flex
              w={22}
              h={22}
              align="center"
              onMouseOver={() => setIsOpen(true)}
              onMouseOut={() => setIsOpen(false)}
              justify="center"
              c="var(--colors-yellow-warning)"
              bg="var(--colors-paper)"
              className={cx(classes.wifiIconPartialBroadcasting, !isGrouped ? classes.wifiIconWrapper : classes.wifiIconGroupedWrapper)}
            >
              <WifiIcon size={16} />
            </Flex>
          </Popover.Target>
          <Popover.Dropdown p="sm">
            <SimpleGrid cols={2} verticalSpacing={8}>
              {selectedMarketplaces.some((m) => m.name === 'StubHub') && (
                <Group gap={4}>
                  <MarketplaceStubHubIcon color="#6635BE" size={18} /> <Text>StubHub</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'Vivid Seats') && (
                <Group gap={4}>
                  <MarketplaceVividIcon color="#CE3197" size={18} /> <Text>Vivid Seats</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'Ticketmaster') && (
                <Group gap={4}>
                  <MarketplaceTicketmasterIcon color="#036CDF" size={18} /> <Text>Ticketmaster</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'SeatGeek') && (
                <Group gap={4}>
                  <MarketplaceSeatGeekIcon color="#F75846" size={18} /> <Text>SeatGeek</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'Ticket Evolution') && (
                <Group gap={4}>
                  <MarketplaceTicketEvolutionIcon color="#EEBA29" size={18} /> <Text>Ticket Evolution</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'TickPick') && (
                <Group gap={4}>
                  <MarketplaceTickPickIcon color="#2E8BF7" size={18} /> <Text>TickPick</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'AXS') && (
                <Group gap={4}>
                  <MarketplaceAXSIcon color="#00529B" size={18} /> <Text>AXS</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'Gametime') && (
                <Group gap={4}>
                  <MarketplaceGametimeIcon color="#6AE3A0" size={18} /> <Text>Gametime</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'Ticket Network') && (
                <Group gap={4}>
                  <MarketplaceTicketNetworkIcon color="#066DEC" size={18} /> <Text>TicketNetwork</Text>
                </Group>
              )}
              {selectedMarketplaces.some((m) => m.name === 'GoTickets') && (
                <Group gap={4}>
                  <MarketplaceGoTicketsIcon color="#3E99F1" size={18} /> <Text>GoTickets</Text>
                </Group>
              )}
            </SimpleGrid>
            <Divider my="xs" color="var(--colors-borderDivider)" />
            <SimpleGrid cols={2} verticalSpacing={8} className={classes.notBroadcastingList}>
              {!selectedMarketplaces.some((m) => m.name === 'StubHub') && (
                <Group gap={4}>
                  <MarketplaceStubHubIcon color="#6635BE" size={18} /> <Text>StubHub</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'Vivid Seats') && (
                <Group gap={4}>
                  <MarketplaceVividIcon color="#CE3197" size={18} /> <Text>Vivid Seats</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'Ticketmaster') && (
                <Group gap={4}>
                  <MarketplaceTicketmasterIcon color="#036CDF" size={18} /> <Text>Ticketmaster</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'SeatGeek') && (
                <Group gap={4}>
                  <MarketplaceSeatGeekIcon color="#F75846" size={18} /> <Text>SeatGeek</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'Ticket Evolution') && (
                <Group gap={4}>
                  <MarketplaceTicketEvolutionIcon color="#EEBA29" size={18} /> <Text>Ticket Evolution</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'TickPick') && (
                <Group gap={4}>
                  <MarketplaceTickPickIcon color="#2E8BF7" size={18} /> <Text>TickPick</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'AXS') && (
                <Group gap={4}>
                  <MarketplaceAXSIcon color="#00529B" size={18} /> <Text>AXS</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'Gametime') && (
                <Group gap={4}>
                  <MarketplaceGametimeIcon color="#6AE3A0" size={18} /> <Text>Gametime</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'Ticket Network') && (
                <Group gap={4}>
                  <MarketplaceTicketNetworkIcon color="#066DEC" size={18} /> <Text>TicketNetwork</Text>
                </Group>
              )}
              {!selectedMarketplaces.some((m) => m.name === 'GoTickets') && (
                <Group gap={4}>
                  <MarketplaceGoTicketsIcon color="#3E99F1" size={18} /> <Text>GoTickets</Text>
                </Group>
              )}
            </SimpleGrid>
          </Popover.Dropdown>
        </Popover>
      )}
    </>
  );
};
