/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
import { createCustomEqual } from 'fast-equals';

const _weakEquals = createCustomEqual({
  createInternalComparator: (compare) => (a, b, keyA, keyB, parentA, parentB, state) => typeof a === 'object' && typeof b === 'object' ? compare(a, b, state) : a == b,
});

export const weakEquals = <A extends Record<string, unknown>, B extends Record<keyof A | string, unknown> | null | undefined>(a: A, b: B, keys?: Array<keyof A>) => {
  if (a && b && keys && keys.length > 0) {
    for (const key of keys) {
      if (a[key] instanceof Date && b[key] instanceof Date) {
        if ((a[key] as Date).getTime() !== (b[key] as Date).getTime()) {
          return false;
        }
      } else if (typeof a[key] === 'object' && typeof b[key] === 'object') {
        if (!weakEquals(a[key] as Record<string, unknown>, b[key] as Record<string, unknown>)) {
          return false;
        }
      } else if (a[key] != b[key]) {
        return false;
      }
    }
    return true;
  }
  return _weakEquals(a, b);
};
