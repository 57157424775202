import { ActionIcon, Box, Group, Progress, Stack, Text, Tooltip } from '@mantine/core';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import DemographyIcon from '../../components/icons/Demography';
import FiltersIcon from '../../components/icons/Filters';
import ListIcon from '../../components/icons/List';
import MoreVertIcon from '../../components/icons/MoreVert';
import RefreshIcon from '../../components/icons/Refresh';
import { BNLabel } from '../../components/Label/Label';
import { useMarketListings } from './MarketListings.hooks';
import BlockIcon from '../../components/icons/Block';
import millify from 'millify';
import pluralize from 'pluralize';
import ListingAgeIcon from '../../components/icons/ListingAge';
import ScatterPlotIcon from '../../components/icons/ScatterPlot';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import classes from './MarketListings.styles.tsx.module.css';
import { useFlag } from '@unleash/proxy-client-react';

export function MarketListingsHeader() {
  const {
    summaryTotals,
    setShowOwnListings,
    showOwnListings,
    showIgnoredListings,
    setShowIgnoredListings,
    isFetchingUpdatedListings,
    fetchUpdatedListings,
    showListingAge,
    setShowListingAge,
    showOutliers,
    setShowOutliers,
  } = useMarketListings(
    'summaryTotals',
    'setShowOwnListings',
    'showOwnListings',
    'showIgnoredListings',
    'setShowIgnoredListings',
    'isFetchingUpdatedListings',
    'fetchUpdatedListings',
    'showListingAge',
    'setShowListingAge',
    'showOutliers',
    'setShowOutliers',
  );

  const collapsedFilterColumnFlag = useFlag('collapsed-filter-column');

  return (
    <Group px={0} className="market-results-header">
      <Stack gap={0} w="100%" p={0} m={0} pos="relative">
        <Group className={classes.wrapper} gap={0}>
          <Group px={16} pr={8} gap={0} wrap="nowrap" flex={1}>
            {!collapsedFilterColumnFlag && (
              <ActionIcon className="marketFiltersTrigger" onClick={() => document.querySelector('.appRight')?.classList.toggle('show-market-filters')}>
                <FiltersIcon size={20} />
              </ActionIcon>
            )}
            <Text fw={600} fz="sm">
              Results
            </Text>
          </Group>
          <Group className={classes.statsWrapper} pr="xs" gap="0.5rem" justify="end">
            <Tooltip
              label={
                summaryTotals?.totalListings !== summaryTotals.totalFilteredListings
                  ? `${summaryTotals.totalFilteredListings.toLocaleString()} filtered ${pluralize(
                      'listing',
                      summaryTotals.totalFilteredListings,
                    )} / ${summaryTotals.totalListings.toLocaleString()} total`
                  : `${summaryTotals.totalListings.toLocaleString()} total ${pluralize('listing', summaryTotals.totalFilteredListings)}`
              }
              withArrow
            >
              <Box>
                <BNLabel
                  className={classes.label}
                  leftIcon={<ListIcon size={20} />}
                  text={
                    <span>
                      {summaryTotals?.totalListings !== summaryTotals.totalFilteredListings ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: 'var(--colors-paperReverse)',
                            }}
                          >
                            {millify(summaryTotals.totalFilteredListings)}
                          </span>{' '}
                          / {millify(summaryTotals.totalListings)}
                        </span>
                      ) : (
                        <span>{millify(summaryTotals.totalListings)}</span>
                      )}
                    </span>
                  }
                />
              </Box>
            </Tooltip>
            <Tooltip
              label={
                summaryTotals?.totalTickets !== summaryTotals.totalFilteredTickets
                  ? `${summaryTotals.totalFilteredTickets.toLocaleString()} filtered ${pluralize(
                      'ticket',
                      summaryTotals.totalFilteredTickets,
                    )} / ${summaryTotals.totalTickets.toLocaleString()} total`
                  : `${summaryTotals.totalTickets.toLocaleString()} total ${pluralize('ticket', summaryTotals.totalTickets)}`
              }
              withArrow
            >
              <Box>
                <BNLabel
                  className={classes.label}
                  leftIcon={<ConfirmationNumberIcon size={20} />}
                  text={
                    <span>
                      {summaryTotals?.totalTickets !== summaryTotals.totalFilteredTickets ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: 'var(--colors-paperReverse)',
                            }}
                          >
                            {millify(summaryTotals.totalFilteredTickets)}
                          </span>{' '}
                          / {millify(summaryTotals.totalTickets)}
                        </span>
                      ) : (
                        <span>{millify(summaryTotals.totalTickets)}</span>
                      )}
                    </span>
                  }
                />
              </Box>
            </Tooltip>
            <BNAccordionMenu
              width={260}
              target={
                <ActionIcon>
                  <MoreVertIcon size={20} color="var(--colors-gray-5)" data-pendo-name="Marketplace Results Action Menu Button" />
                </ActionIcon>
              }
            >
              <BNAccordionMenu.Item onClick={() => fetchUpdatedListings()} leftSection={<RefreshIcon />}>
                Refresh
              </BNAccordionMenu.Item>
              <BNAccordionMenu.Switch
                checked={showOwnListings}
                onChange={() => setShowOwnListings(!showOwnListings)}
                leftSection={<DemographyIcon />}
                title="Show My Listings"
                data-pendo-name="Show My Listings Switch"
              />
              <BNAccordionMenu.Switch
                checked={showIgnoredListings}
                onChange={() => setShowIgnoredListings(!showIgnoredListings)}
                leftSection={<BlockIcon />}
                title="Show Ignored Listings"
                data-pendo-name="Show Ignored Listings Switch"
              />
              <BNAccordionMenu.Switch
                checked={showOutliers}
                onChange={() => setShowOutliers(!showOutliers)}
                leftSection={<ScatterPlotIcon />}
                title="Show Outliers"
                data-pendo-name="Show Outliers Switch"
              />
              <BNAccordionMenu.Switch
                checked={showListingAge}
                onChange={() => setShowListingAge(!showListingAge)}
                leftSection={<ListingAgeIcon />}
                title="Show Listing Age"
                data-pendo-name="Show Listing Age Switch"
              />
            </BNAccordionMenu>
          </Group>
        </Group>
        {/* {isFetchingUpdatedListings && <Progress radius="xs" value={100} animate color="var(--colors-brandcolor-5)" style={{ marginTop: -4, height: '4px' }} />} */}
        {/* Tweaked style here to remove rounded sides & changed color to gray to match other section loading indicators -- I think green would be good if we could make it an actual progress bar and show it loading */}
        {isFetchingUpdatedListings && (
          <Progress radius={0} value={100} animated color="var(--colors-gray-2)" size={4} pos="absolute" bottom={0} left={0} right={0} className={classes.progressBar} />
        )}
      </Stack>
    </Group>
  );
}
