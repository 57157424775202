import yasml from '@thirtytech/yasml';
import {
  searchParamsHistoryAtom,
  searchResultsAtom,
  selectedEventAtom,
  selectedMergedListingAtom,
  selectedSearchEventsAtom,
  updateEventAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useRef, useState } from 'react';
import { CellClickedEvent, ColDef, ColumnEvent, ColumnState, GridReadyEvent, ICellRendererParams, RowDataUpdatedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { BarkerCoreModelsInventoryEvent, getApiInventory } from '../../api';
import { DateFormats } from '../../utils/globals';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { AgGridReact } from '@ag-grid-community/react';
import { useDidUpdate, useLocalStorage } from '@mantine/hooks';
import { useInventoryHeader } from './Inventory.Header.hooks';
import { useRuleState } from '../../data/Rule.state';
import { TenantIdentifier } from './TenantIdentifier';
import dayjs from 'dayjs';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export const onColumnMoved = new Event('onColumnMoved');
export const onColumnSorted = new Event('onColumnSorted');

function InventoryEventState() {
  const gridRef = useRef<AgGridReact>(null);
  const { showSettings, showBulkOptions } = useInventoryHeader('showSettings', 'showBulkOptions');
  const { validateDirtyRuleState, triggerSaveRule } = useRuleState('validateDirtyRuleState', 'triggerSaveRule');
  const [gridReady, setGridReady] = useState(false);
  const [selectedEvents, setSelectedEvents] = useAtom(selectedSearchEventsAtom);
  const selectedEvent = useAtomValue(selectedEventAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const searchResults = useAtomValue(searchResultsAtom);
  const events = useMemo(() => searchResults?.events || [], [searchResults]);
  const updateEvent = useSetAtom(updateEventAtom);
  const updateListings = useSetAtom(updateListingsAtom);
  const showSort = showSettings;
  const defaultColumnSettingDefs = useMemo<ColDef>(
    () => ({
      width: 170,
      flex: 0,
      resizable: showSort,
      suppressMenu: true,
      suppressMovable: !showSort,
      sortable: showSort,
      filter: true,
    }),
    [showSort],
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      (window as any).gridRef = event;
      // Handles hot reloading
      if (event.api.getSelectedNodes().length === 0) {
        gridRef.current?.api.forEachNode((node) => {
          if (selectedEvents.find((x) => x.eventId === node.data.eventId)) {
            node.setSelected(true);
          }
        });
      }
      setGridReady(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Need this for show dragCell checkbox
  useDidUpdate(() => {
    gridRef.current?.api.refreshCells();
  }, [showBulkOptions]);

  const [firstRender, setFirstRender] = useState(true);

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<BarkerCoreModelsInventoryEvent>) => {
      if (selectedListing && firstRender) {
        const node = event.api.getRowNode(selectedListing.event.eventId);
        if (node && node.data) {
          event.api.deselectAll();
          node.setSelected(true);
        }
        setSelectedEvents([selectedListing.event]);
      } else if (!selectedListing && selectedEvent && firstRender) {
        const node = event.api.getRowNode(selectedEvent.eventId);
        if (node && node.data) {
          event.api.deselectAll();
          node.setSelected(true);
        }
        setSelectedEvents([selectedEvent]);
      } else if (selectedEvents.length === 0) {
        const firstNode = event.api.getRenderedNodes()[0];
        if (firstNode && firstNode.data) {
          firstNode.setSelected(true);
          setSelectedEvents([firstNode.data!]);
        }
      }
      if (firstRender) {
        setFirstRender(false);
      }
    },
    [firstRender, selectedEvent, selectedEvents.length, selectedListing, setSelectedEvents],
  );

  const onRowSelectionChanged = useCallback(
    (params: RowSelectedEvent<BarkerCoreModelsInventoryEvent>) => {
      const { rowIndex } = params;
      const el = document.querySelector(`[row-index="${rowIndex}"]`);
      if (params.data) {
        el?.setAttribute('aria-label', `Event ${params?.data.eventId} row`);
      }
      const selectedRows = params.api.getSelectedRows();
      setSelectedEvents(selectedRows);
    },
    [setSelectedEvents],
  );

  const [eventGridState, setEventGridState, clearEventGridState] = useLocalStorage<ColumnState[]>({
    key: 'inventory-grid-event-state',
    getInitialValueInEffect: false,
  });

  const trySaveGridState = useCallback(
    (event: ColumnEvent) => {
      if (gridReady) {
        const state = event.columnApi?.getColumnState()!;
        setEventGridState(state);
        if (event.type === 'columnMoved') {
          document.dispatchEvent(onColumnMoved);
        }
        if (event.type === 'sortChanged') {
          document.dispatchEvent(onColumnSorted);
        }
      }
    },
    [gridReady, setEventGridState],
  );

  const searchParams = useAtomValue(searchParamsHistoryAtom);

  const fetchSingleEventAndListings = useCallback(
    (eventId: string, tenantId: string) => {
      if (searchParams) {
        getApiInventory(
          {
            ...searchParams,
            eventIds: [eventId],
          },
          {
            headers: {
              'x-tenant-id': tenantId,
            },
          },
        ).then((result) => {
          if (result.data.events.length === 1) {
            updateEvent(result.data.events[0]);
            updateListings(result.data.listings);
          }
        });
      }
    },
    [searchParams, updateEvent, updateListings],
  );
  const onCellClickedWhenDirty = useCallback(
    async (gridEvent: CellClickedEvent<BarkerCoreModelsInventoryEvent>) => {
      if ((await validateDirtyRuleState()).resolve) {
        await triggerSaveRule();
        // closeExpandedNode(selectedListingId);
        // setSelectedListingId(gridEvent.node.data.tenantIdListingId);
        if (gridEvent.node.data) {
          setSelectedEvents([gridEvent.node.data]);
          gridEvent.api.deselectAll();
          gridEvent.node.setSelected(true);
          fetchSingleEventAndListings(gridEvent.node.data.eventId, gridEvent.node.data.tenantId);
          requestAnimationFrame(() => {
            gridRef.current?.api.ensureNodeVisible(gridEvent.node);
          });
        }
      }
    },
    [fetchSingleEventAndListings, setSelectedEvents, triggerSaveRule, validateDirtyRuleState],
  );

  type BNInventoryColumn = ColDef<BarkerCoreModelsInventoryEvent> & {
    exclude?: boolean;
    allowSorting?: boolean;
    sort?: 'asc' | 'desc' | null;
    sortIndex?: number | null;
  };
  const defaultColumnDefs: BNInventoryColumn[] = useMemo(
    () =>
      [
        { colId: 'dragCell', exclude: true, allowSorting: false, width: 50, minWidth: 50, maxWidth: 50, valueGetter: () => null },
        {
          headerName: 'Company',
          field: 'tenantId',
          hide: true,
          width: 140,
          cellStyle: { display: 'flex' },
          cellRenderer: (params: ICellRendererParams<BNInventoryColumn>) => <TenantIdentifier tenantId={params.value} />,
        },
        { headerName: 'Name', field: 'name', flex: 1, minWidth: 260, sort: undefined },
        {
          headerName: 'Date',
          field: 'localDateTime',
          sortIndex: 0,
          sort: 'asc',
          valueGetter: (params) => params.data?.localDateTime.toString(), // This is to give a constant value to prevent cell flashing / rerendering
          //valueFormatter: (params) => (params.data ? formatDate(params.data.localDateTime, DateFormats.GridFull) : ''),
          valueFormatter: (params) => formatDate(params.value, DateFormats.GridFull),
          comparator: (a, b) => dayjs(a).unix() - dayjs(b).unix(),
          cellStyle: { fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        {
          headerName: 'Day',
          colId: 'day',
          valueGetter: (params) => (params.data?.localDateTime ? dayjs(params.data.localDateTime).format('ddd') : ''),
          width: 70,
          hide: true,
        },
        { headerName: 'Venue', field: 'venue.name', minWidth: 200 },
        { headerName: 'Performer', field: 'performer.name', minWidth: 200, hide: true },
        {
          headerName: 'Tickets Sold',
          field: 'numTicketsSold',
          hide: true,
          width: 100,
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Cost',
          minWidth: 110,
          width: 110,
          field: 'openCost',
          valueFormatter: (params) => (params.data?.openCost ? formatCurrency(params.data.openCost) : ''),
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        {
          headerName: 'Listings',
          field: 'openListings',
          width: 100,
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Tickets',
          field: 'openTickets',
          width: 100,
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Last Sold',
          field: 'lastSoldAt',
          width: 100,
          valueGetter: (params) =>
            params.data?.lastSoldAt
              ? dayjs().date() === dayjs(params.data.lastSoldAt).date()
                ? formatDate(params.data.lastSoldAt, DateFormats.GridTimeOnly)
                : formatDate(params.data.lastSoldAt, DateFormats.GridShort)
              : '',
          cellStyle: { fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        {
          headerName: 'Contingent',
          field: 'vendorProperties.isContingent',
          width: 110,
          hide: true,
        },
        // { headerName: 'Links', field: 'links' },
        // { headerName: 'Monitors', field: 'monitors' },
        // { headerName: 'Number of Comments', field: 'numComments' },
        // { headerName: 'Status ID', field: 'statusId' },
        // { headerName: 'Tags', field: 'tags' },
        // { headerName: 'Tenant ID', field: 'tenantId' },
        // { headerName: 'Viewed At', field: 'viewedAt' },
        // { headerName: 'Viewed By', field: 'viewedBy' },
        // { headerName: 'Weather Forecast', field: 'weatherForecast' },
      ] as BNInventoryColumn[],
    [],
  );
  const columnDefs: ColDef<BarkerCoreModelsInventoryEvent>[] = useMemo(
    () =>
      defaultColumnDefs
        .filter((col) => !(!isDtiHosted && col.headerName === 'Contingent'))
        .map((col) => {
          // Clears properties before giving it to AG-Grid to avoid warnings.
          const { allowSorting, exclude, ..._col } = col;
          if (_col.colId === 'dragCell') {
            _col.hide = !showBulkOptions;
            _col.checkboxSelection = showBulkOptions;
            _col.headerCheckboxSelection = showBulkOptions;
          }
          const savedState = { ...eventGridState?.filter((x) => !['dragCell'].includes(x.colId)).find((y) => y.colId === col.colId || y.colId === col.field) };
          return { ..._col, ...savedState } as ColDef<BarkerCoreModelsInventoryEvent>;
        })
        .sort((a, b) => {
          const aIndex = eventGridState?.findIndex((x) => x.colId === a.colId);
          const bIndex = eventGridState?.findIndex((x) => x.colId === b.colId);
          return aIndex - bIndex;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBulkOptions, showSettings],
  );
  return {
    gridRef,
    onGridReady,
    gridReady,
    trySaveGridState,
    events,
    defaultColumnSettingDefs,
    defaultColumnDefs,
    columnDefs,
    onRowSelectionChanged,
    onRowDataUpdated,
    eventGridState,
    clearEventGridState,
    onCellClickedWhenDirty,
  };
}

export const { Provider: InventoryEventStateProvider, useSelector: useInventoryEvents } = yasml(InventoryEventState);
