import { v4 as uuid } from 'uuid';
import { useForm } from '@mantine/form';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { BarkerCoreModelsDTIItem, BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal, getGetDtiItemsAccountIdQueryKey } from '../../../api';
import { FlattenObjectKeys } from '../../../ts-utils';
import { BarkerEventListing, OrderEdit, OrderEditPlus, TicketGroupEdit } from '../../../types';
import { parsePropertiesChanged, seatRange } from '../../../utils/formatters';
import { calculateSeatRange, location_options, mapStringToDisclosures } from '../../Purchase/Purchase.hooks';
import { OrderItem } from '../../Purchase/Purchase.OrderItem';
import { useDTIInventory } from '../DTI.Inventory.hooks';
import { ScrollArea } from '@mantine/core';
import { queryClient } from '../../../data/api-config';
import { useDidUpdate } from '@mantine/hooks';
import { useGlobalState } from '../../../data/Global.state';

type Changes = Partial<Record<keyof OrderEditPlus, any>>;
export type EditPropertiesDialog_Handles =
  | {
      onSubmit: () => Promise<boolean>;
      onDeleteAllAssets: () => void;
    }
  | undefined;

export const EditProperties_Form = forwardRef<EditPropertiesDialog_Handles, { selectedListing: BarkerEventListing; listingItems: BarkerCoreModelsDTIItem[] | undefined }>(
  ({ selectedListing, listingItems }, ref) => {
    const disclosures = useMemo(() => mapStringToDisclosures(selectedListing.externalNotes ?? ''), [selectedListing]);
    const { editListing, isEditLoading } = useDTIInventory('editListing', 'isEditLoading');
    const firstItem = useMemo(() => listingItems?.[0], [listingItems]);
    const vendorProperties = selectedListing.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;
    const { dtiAccounts } = useGlobalState('dtiAccounts');
    const accountPermissions = dtiAccounts.find((a) => a.id === vendorProperties.ownerId)?.perms;
    const { isBroadcasting } = selectedListing;

    const form = useForm<{ groups: TicketGroupEdit[] }>({
      initialValues: {
        groups: [
          {
            eventIds: [parseInt(selectedListing?.eventId ?? '0')],
            orders: [
              {
                key: uuid(),
                account_info: vendorProperties.accountInfo,
                cost: selectedListing.unitCost,
                disclosures: disclosures.disclosures,
                notes: disclosures.additionalText,
                internal_notes: selectedListing?.internalNotes,
                extra_note: vendorProperties?.extraNotes,
                odd_even: vendorProperties.isOddEven,
                order_number: vendorProperties.orderNumber,
                purchaser: vendorProperties.purchaserEmail,
                quantity: selectedListing.quantityRemaining,
                row: selectedListing.row,
                section: selectedListing.section,
                seat_from: parseInt(selectedListing.seatFrom),
                barcodes: listingItems?.map((item) => ({ item_id: item.item_id!, barcode: item.barcode! })) ?? [],
                transferURLs: listingItems?.map((item) => ({ item_id: item.item_id!, transferURL: item.transferURL! })) ?? [],
                listing_id: selectedListing.listingId!,
                stock: selectedListing.ticketStockId,
                location_id: location_options.find((x) => x.label.toUpperCase() === vendorProperties.location?.toUpperCase())?.value,
                items: listingItems,
                cc_info_card: vendorProperties.ccCardInfo?.toString(),
                cc_info_purchaser: vendorProperties?.ccPurchaserInfo?.toString(),
                pdfs: [],
              },
            ],
            group: uuid(),
          },
        ],
      },
      validate: (values) => {
        const errors: Partial<Record<FlattenObjectKeys<typeof values>, string>> = {};
        values.groups.forEach((group, groupIndex) => {
          group.orders.forEach((order, index) => {
            if (!order.section && !order.row && !order.seat_from && !order.quantity) {
              return;
            }
            if (!order.section) {
              errors[`groups.${groupIndex}.orders.${index}.section`] = 'Section is required';
            }
            if (!order.row) {
              errors[`groups.${groupIndex}.orders.${index}.row`] = 'Row is required';
            }
            if (!order.seat_from) {
              errors[`groups.${groupIndex}.orders.${index}.seat_from`] = 'Seat From is required';
            }
            if (!order.quantity) {
              errors[`groups.${groupIndex}.orders.${index}.quantity`] = 'Quantity is required';
            }
            if (order.cc_info_card && !/^\d+$/.test(order.cc_info_card)) {
              errors[`groups.${groupIndex}.orders.${index}.cc_info_card`] = 'Credit Card number must only contain numbers';
            }
          });
        });
        // TODO: Add validation for seat ranges that they dont overlap.
        return errors;
      },
    });

    useDidUpdate(() => {
      const group = form.values.groups[0];
      group.orders[0].barcodes = listingItems?.map((item) => ({ item_id: item.item_id!, barcode: item.barcode! })) ?? [];
      group.orders[0].transferURLs = listingItems?.map((item) => ({ item_id: item.item_id!, transferURL: item.transferURL! })) ?? [];
      group.orders[0].items = listingItems;
      form.setValues({ groups: [group] });
      form.resetDirty({ groups: [group] }); // NOTE: This could cause an issue if they change something before the items are loaded.
    }, [listingItems]);

    useImperativeHandle(ref, () => ({
      onDeleteAllAssets: async () => {
        const newValues = form.values.groups.map((group) => {
          // eslint-disable-next-line no-param-reassign
          group.orders = group.orders.map((order) => {
            const newOrder = structuredClone(order);
            newOrder.barcodes = order.barcodes.map((barcode) => ({ ...barcode, barcode: '' }));
            newOrder.items = order.items?.map((item) => ({ ...item, barcode: '' })) ?? [];
            newOrder.pdfs = order.items?.map((item) => ({ item_id: item.item_id!, base64: '' })) ?? [];
            return newOrder;
          });
          return group;
        });
        form.setValues({ groups: newValues });
      },
      onSubmit: async () =>
        new Promise<boolean>((resolve, reject) => {
          try {
            form.onSubmit(async (values) => {
              const dirtyProps = parsePropertiesChanged(form.getDirty());
              // console.log('Form Submission', values, dirtyProps);
              const groupValues = values.groups[0].orders[0];

              const changes: Changes = dirtyProps.reduce<Changes>((acc, prop) => {
                acc[prop as keyof OrderEdit] = values.groups[0].orders[0][prop as keyof OrderEdit];
                return acc;
              }, {} as OrderEditPlus);

              if (dirtyProps.includes('location_id')) {
                dirtyProps.splice(dirtyProps.indexOf('location_id'), 1);
                dirtyProps.push('location');
                dirtyProps.push('location_from');
                changes.location_from = firstItem?.location_id;
                changes.location = values.groups[0].orders[0].location_id;
                changes.notes = values.groups[0].orders[0].notes;
              }
              if (dirtyProps.includes('seat_from')) {
                dirtyProps.splice(dirtyProps.indexOf('seat_from'), 1);
                changes.start_seat = values.groups[0].orders[0].seat_from;
              }
              if (!dirtyProps.includes('barcode')) {
                changes.barcodes = [];
              }
              if (!dirtyProps.includes('transferURL')) {
                changes.transferURLs = [];
              }

              if (dirtyProps.includes('disclosures')) {
                changes.notes = `${values.groups[0].orders[0].disclosures.join(' ')} ${values.groups[0].orders[0].notes ?? ''}`;
                dirtyProps.splice(dirtyProps.indexOf('disclosures'), 1);
                if (!dirtyProps.includes('notes')) {
                  dirtyProps.push('notes');
                }
              }

              await editListing({
                checksum: vendorProperties.checksum,
                listing_id: selectedListing.listingId,
                listing_request_id: firstItem?.listing_request_id!,
                barcodes: values.groups[0].orders[0].barcodes,
                pdfs: values.groups[0].orders[0].pdfs.filter((pdf) => pdf.item_id),
                tags: [],
                transferURLs: values.groups[0].orders[0].transferURLs,
                ...changes,
                user_initiated_changes: dirtyProps,
                original: {
                  event_id: parseInt(selectedListing?.eventId),
                  section: selectedListing.section,
                  row: selectedListing.row,
                  // location_id: firstItem?.location_id!,
                  location_id: parseInt(location_options.find((x) => x.label.toUpperCase() === vendorProperties.location?.toUpperCase())?.value ?? '0'),
                  seat_from: parseInt(selectedListing.seatFrom),
                  seat_list: seatRange(parseInt(selectedListing.seatFrom), selectedListing.quantityRemaining!, vendorProperties.isOddEven),
                  odd_even: vendorProperties?.isOddEven ? 1 : 0,
                  quantity: selectedListing.quantityRemaining!,
                },
              });
              await queryClient.invalidateQueries({
                queryKey: getGetDtiItemsAccountIdQueryKey(vendorProperties.ownerId, {
                  listing_id: selectedListing.listingId!,
                }),
              });
              resolve(true);
            })();
          } catch (error) {
            reject(error);
          }
        }),
    }));

    const group = form.values.groups[0];
    const order = group.orders[0];

    return (
      <ScrollArea flex={1} type="auto">
        <OrderItem
          key={order.key}
          group={group}
          order={order}
          groupIndex={0}
          index={0}
          form={form}
          isBroadcasting={isBroadcasting}
          calculateSeatRange={calculateSeatRange}
          ticketAssetsLoading={!listingItems}
          permissions={accountPermissions}
        />
      </ScrollArea>
    );
  },
);
