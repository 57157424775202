import { useCallback } from 'react';
import { BarkerCoreEnumsMarketplace } from '../../api';
import { useSeatingChart } from './SeatingChart.hooks';
import { useGlobalState } from '../../data/Global.state';
import { MarketplaceSelectorMobile } from './SeatingChart.MarketplaceSelector.Mobile';
import { MarketplaceSelectorDesktop } from './SeatingChart.MarketplaceSelector.Desktop';

export function MarketplaceSelector({ onChange }: { onChange: () => void }) {
  const {
    selectedEvent,
    eventMappings,
    selectedMarketplaceId,
    setSelectedMarketplaceId,
    editMarketplaceLinksDialogHandler,
    setEventMarketplaceDefaults,
    availableMarketplaces,
    isSeasonView,
  } = useSeatingChart(
    'selectedEvent',
    'eventMappings',
    'selectedMarketplaceId',
    'setSelectedMarketplaceId',
    'editMarketplaceLinksDialogHandler',
    'setEventMarketplaceDefaults',
    'availableMarketplaces',
    'isSeasonView',
  );

  const { isMobile } = useGlobalState('isMobile');

  const onChangeMarketplace = useCallback(
    (marketplaceId: BarkerCoreEnumsMarketplace) => {
      if (selectedEvent) {
        setSelectedMarketplaceId(marketplaceId);
        setEventMarketplaceDefaults({ marketplaceId, eventId: selectedEvent.eventId });
        onChange();
      }
    },
    [onChange, selectedEvent, setEventMarketplaceDefaults, setSelectedMarketplaceId],
  );

  if (isMobile) {
    return (
      <MarketplaceSelectorMobile
        onChangeMarketplace={onChangeMarketplace}
        eventMappings={eventMappings}
        selectedMarketplaceId={selectedMarketplaceId}
        editMarketplaceLinksDialogHandler={editMarketplaceLinksDialogHandler}
        availableMarketplaces={availableMarketplaces}
        isSeasonView={false}
      />
    );
  }

  return (
    <MarketplaceSelectorDesktop
      onChangeMarketplace={onChangeMarketplace}
      eventMappings={eventMappings}
      selectedMarketplaceId={selectedMarketplaceId}
      editMarketplaceLinksDialogHandler={editMarketplaceLinksDialogHandler}
      availableMarketplaces={availableMarketplaces}
      isSeasonView={isSeasonView ?? false}
    />
  );
}
