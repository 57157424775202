import { Flex, Group, Stack, Text } from '@mantine/core';
import { useMemo, useState } from 'react';
import { BNButton } from '../../../components/Button/Button';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import { useGlobalState } from '../../../data/Global.state';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { markListingsAtom, mergedEventListingsUnfilteredRulesAtom, selectedMergedListingAtom, selectedTenantListingIdAtom, updateListingsAtom } from '../../../data/atoms';
import { BarkerCoreEnumsPointOfSale, BarkerCoreModelsInventoryListing, SystemTextJsonPatchOperationsOperation } from '../../../api';
import { getPointOfSaleSplitType, setSplitsModelForUpdate } from '../../../components/Splits/Splits.utils';
import { useBulkState } from '../../../data/Bulk.state';
import { StringWithPipe } from '../../../ts-utils';
import { useListingState } from '../../../data/Listing.state';
import { BNSwitch } from '../../../components/Switch/Switch';
import WifiIcon from '../../../components/icons/Wifi';
import WifiOffIcon from '../../../components/icons/WifiOff';
import { TenantIdListingId } from '../../../models/tenantIdListingId';
import { BNEmptyState } from '../../../components/EmptyState/EmptyState';
import WarningIcon from '../../../components/icons/Warning';

interface MergeProps {
  toggleMergeListingDialog: (value?: React.SetStateAction<boolean>) => void;
}

export function Merge({ toggleMergeListingDialog }: MergeProps) {
  const { tenants } = useGlobalState('tenants');
  const { mergeListings, mergeListingsLoading, patchListing } = useListingState('mergeListings', 'mergeListingsLoading', 'patchListing');
  const { setMarkedListings } = useBulkState('setMarkedListings');
  const allListings = useAtomValue(mergedEventListingsUnfilteredRulesAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const markedListings = useAtomValue(markListingsAtom);
  const actualMarkedListings = allListings.filter((al) => markedListings.includes(al.tenantIdListingId));
  const updateListing = useSetAtom(updateListingsAtom);

  const listingsToMerge = useMemo(() => {
    const listingIds = new Set();
    return [...actualMarkedListings, selectedListing].filter(Boolean).filter((listing) => {
      if (!listingIds.has(listing.listingId)) {
        listingIds.add(listing.listingId);
        return true;
      }
      return false;
    });
  }, [actualMarkedListings, selectedListing]);

  const listingsToMergeAllBroadcasting = listingsToMerge.every((listing) => listing?.isBroadcasting);
  const listingsToMergeHighPrice = Math.max(...listingsToMerge.map((l) => l.unitPrice));
  const [newPrice, setNewPrice] = useState(listingsToMergeHighPrice);
  const [mergeBroadcastingState, setMergeBroadcastingState] = useState(listingsToMergeAllBroadcasting);
  const listingsToMergeIds = listingsToMerge?.map((l) => l?.listingId) ?? [];
  const { tenantId } = listingsToMerge[0];
  const tenantPointOfSale = tenants?.find((tenant) => tenant.tenantId === tenantId)?.pointOfSaleId ?? BarkerCoreEnumsPointOfSale.Unknown;
  const setSelectedListingId = useSetAtom(selectedTenantListingIdAtom);

  const mergeNotSupported = tenantPointOfSale === 'EIBO' || tenantPointOfSale === 'TicketNetwork' || tenantPointOfSale === 'TradeDeskPos' || tenantPointOfSale === 'AnyTickets';

  const updateBroadcasting = async (listing: BarkerCoreModelsInventoryListing, isBroadcasting: boolean) => {
    const updates: SystemTextJsonPatchOperationsOperation[] = [
      {
        op: 'Replace',
        path: '/isBroadcasting',
        value: isBroadcasting,
      },
    ];

    if (isBroadcasting && !listing?.splits) {
      if (tenantPointOfSale) {
        const splitUpdate = setSplitsModelForUpdate(tenantPointOfSale, getPointOfSaleSplitType(tenantPointOfSale, listing?.splits));
        if (splitUpdate) {
          updates.push(splitUpdate);
        }
      }
    }

    await patchListing({
      tenantId: listing?.tenantId,
      listingId: listing?.listingId,
      data: updates,
    });
  };

  const updatePrice = async (_tenantId: string, listingId: string, price: number, previousPrice: number) => {
    await patchListing({
      tenantId: _tenantId,
      listingId,
      data: [
        { op: 'Replace', path: '/unitPrice', value: price },
        { op: 'Replace', path: '/previousPrice', value: previousPrice },
      ],
    });
  };

  const handleMergeClick = async () => {
    const mergeRequest = { listingIds: listingsToMergeIds ?? [], price: newPrice };
    const mergeResponse = await mergeListings({ tenantId, data: mergeRequest });
    const mergedTenantIdListingId = [mergeResponse.data.tenantId, mergeResponse.data.listingId].join('|') as StringWithPipe;

    if (mergeResponse.data.unitPrice !== newPrice) {
      await updatePrice(tenantId, mergeResponse.data.listingId, newPrice, mergeResponse.data.unitPrice);
      mergeResponse.data.unitPrice = newPrice;
    }

    if (mergeResponse.data.isBroadcasting !== mergeBroadcastingState) {
      await updateBroadcasting(mergeResponse.data, mergeBroadcastingState);
      mergeResponse.data.isBroadcasting = mergeBroadcastingState;
    }

    const listingIdsToRemove = listingsToMergeIds
      .filter((listingId) => listingId !== mergeResponse.data.listingId)
      .map((listingId) => new TenantIdListingId([tenantId, listingId].join('|') as StringWithPipe));

    updateListing({ listingsToUpdate: [mergeResponse.data], listingsToRemove: listingIdsToRemove });
    setMarkedListings([]);
    setSelectedListingId(mergedTenantIdListingId);
    toggleMergeListingDialog(false);
  };

  if (mergeNotSupported) {
    return <BNEmptyState title="Merge not supported" description="Merging is not yet supported for your Point of Sale." icon={<WarningIcon size={24} />} w={350} h={200} />;
  }

  return (
    <Stack gap="xs" mt={-4} pb={4} w="100%" align="flex-end">
      <Group gap="xs" wrap="nowrap" w={236}>
        <BNNumberInput
          value={newPrice}
          label="New Price"
          decimalScale={2}
          fixedDecimalScale
          size="xs"
          onChange={(e) => setNewPrice(e as number)}
          min={0}
          step={1.0}
          leftSection={
            <Text size="sm" ml={-2}>
              $
            </Text>
          }
          style={{ flexBasis: '100%' }}
        />
        <Flex direction="column" gap={1} justify="end" style={{ flexBasis: '100%' }}>
          <Text c="var(--colors-gray-5)" size="xs" lh="1.25rem">
            Broadcast
          </Text>
          <Group gap="xs" h={30} align="center">
            <BNSwitch size="md" checked={mergeBroadcastingState} onChange={() => setMergeBroadcastingState(!mergeBroadcastingState)} />
            {mergeBroadcastingState ? <WifiIcon size={20} color="var(--colors-green-broadcast)" /> : <WifiOffIcon size={20} color="var(--colors-gray-5)" />}
          </Group>
        </Flex>
      </Group>
      <Group gap="xs" wrap="nowrap" w={236}>
        <BNButton
          fullWidth
          variant="default"
          disabled={mergeListingsLoading}
          size="xs"
          onClick={() => {
            toggleMergeListingDialog(false);
          }}
        >
          Cancel
        </BNButton>
        <BNButton fullWidth loading={mergeListingsLoading} variant="filled" color="green" size="xs" onClick={() => handleMergeClick()}>
          Merge
        </BNButton>
      </Group>
    </Stack>
  );

  // return (
  //   <Flex style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'center', margin: 6 }}>
  //     <Group style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', margin: 0 }}>
  //       <Box m={2}>
  //         <WifiIcon size={20} color="var(--colors-iconFill)" />
  //       </Box>
  //       <Box style={{ marginLeft: 6, marginRight: 6 }}>
  //         <Text size="xs" c="var(--colors-gray-6)" fw={800}>
  //           Broadcasting
  //         </Text>
  //       </Box>
  //       <Box m={2}>
  //         <BNSwitch size="sm" checked={mergeBroadcastingState} onChange={() => setMergeBroadcastingState(!mergeBroadcastingState)} />
  //         {/*<Checkbox checked={mergeBroadcastingState} onChange={() => setMergeBroadcastingState(!mergeBroadcastingState)} />*/}
  //       </Box>
  //     </Group>
  //
  //     <BNNumberInput
  //       value={newPrice}
  //       label="New Price"
  //       decimalScale={2}
  //       fixedDecimalScale
  //       onChange={(e) => setNewPrice(e as number)}
  //       placeholder="100.00"
  //       size="xs"
  //       radius="sm"
  //       width={25}
  //       min={0.0}
  //       max={999999}
  //       step={1.0}
  //       m={10}
  //       leftSection={
  //         <Text size="sm" ml={-2}>
  //           $
  //         </Text>
  //       }
  //     />
  //     <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" m={10}>
  //       <BNButton
  //         fullWidth
  //         variant="default"
  //         disabled={mergeListingsLoading}
  //         size="xs"
  //         onClick={() => {
  //           toggleMergeListingDialog(false);
  //         }}
  //       >
  //         Cancel
  //       </BNButton>
  //       <BNButton fullWidth loading={mergeListingsLoading} variant="filled" color="green" size="xs" onClick={() => handleMergeClick()}>
  //         Merge
  //       </BNButton>
  //     </Group>
  //   </Flex>
  // );
}
