import { ActionIcon, Box, Button, Divider, Group, Text, Tooltip } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { BNButton } from '../../components/Button/Button';
import RemoveIcon from '../../components/icons/Remove';
import AddIcon from '../../components/icons/Add';
import { BNSwitch } from '../../components/Switch/Switch';
import ResetIcon from '../../components/icons/Reset';
import { useSeatingChart } from './SeatingChart.hooks';
import { useHoverKeystrokes } from '../../components/hooks/useHoverKeystrokes';
import { MissingSections } from './SeatingChart.MissingSections';
import classes from './SeatingChart.module.css';
import { SvgSeatingChart } from '../../components/seating-charts/svg/SvgSeatingChart';
import { useFlag } from '@unleash/proxy-client-react';
import cx from 'clsx';
import { RowSectionPills } from './SeatingChart.RowSectionPills';

export function Venue() {
  const [zoneMode, setZoneMode] = useState(false);
  const {
    selectedMarketplaceEvent,
    filterSectionsToUniqueGroupCount,
    selectAllSections,
    updateAllSectionsInSeatingChart,
    suggestions,
    rule,
    sectionsWithListings,
    ruleSectionsMissingCount,
    allSectionsInSeatingChart,
    sectionAliases,
    selectedSection,
    isSeasonView,
    form,
    selectedTenantListingId,
    advancedSectionRows,
    advancedRowsActiveIndex,
    onSectionClick,
  } = useSeatingChart();
  const [isInitialView, setIsInitialView] = useState(true);
  const { setFieldValue } = form;
  const advancedRowsInputFlag = useFlag('advanced-rows-input');

  const clearSections = useCallback(() => {
    setFieldValue('sectionIds', []);
    if (form.getValues().sectionRows) {
      // Parse out the rows and put them back in but clear sectionIds
      const uniqueRows =
        form.getValues().sectionRows?.reduce((acc, item) => {
          acc.add(item.rows);
          return acc;
        }, new Set<string>()) || new Set<string>();
      setFieldValue(
        'sectionRows',
        Array.from(uniqueRows).map((rows) => ({ rows, sectionId: '' })),
      );
    }
  }, [form, setFieldValue]);

  const { svgDetails, marketplaceId } = selectedMarketplaceEvent;
  const transformRef = useRef<ReactZoomPanPinchRef>(null);
  const { isKeyPressed } = useHoverKeystrokes('Shift');

  return (
    <Box className={classes.venueWrapper} id="venueWrapper">
      <TransformWrapper
        doubleClick={{ disabled: true }}
        panning={{ disabled: isInitialView || isKeyPressed }}
        onZoom={(ref) => {
          setIsInitialView(ref.state.scale === 1);
        }}
        onWheel={(ref) => {
          setIsInitialView(ref.state.scale === 1);
        }}
        ref={transformRef}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <TransformComponent>
              <SvgSeatingChart
                key={`${svgDetails?.chartSvgUrl}`}
                updateAllSectionsInSeatingChart={updateAllSectionsInSeatingChart}
                marketplaceId={marketplaceId}
                tenantListingId={selectedTenantListingId}
                svgUrl={svgDetails?.chartSvgUrl!}
                selectedSections={form.values.sectionIds ?? []}
                zones={svgDetails?.zones}
                selectedListingSection={selectedSection}
                marketSectionAliases={sectionAliases}
                onSectionClick={onSectionClick}
                zoneMode={zoneMode}
                sectionsWithListings={sectionsWithListings}
                suggestedSections={suggestions.sectionIds}
                markAllSections={isSeasonView}
                advancedRowsEnabled={advancedSectionRows.isEnabled}
                advancedRows={advancedSectionRows.advancedRows}
                advancedRowsActiveIndex={advancedRowsActiveIndex}
              />
            </TransformComponent>
            {/* Seating Chart Controls for Zoom & Zones */}
            {/* Show section name on hover... */}
            <Group className={`${classes.sectionsHoverWrapper} sections-hover-wrapper hidden`}>
              <Text className={`${classes.sectionsHoverText} sections-hover-text`} size="xs">
                {/* ORCHESTRA FRONT CENTER - ZONE NAME */}
              </Text>
            </Group>
            {(form.values.sectionIds?.length || 0) > 0 && (!advancedRowsInputFlag || !advancedSectionRows.isEnabled) && (
              <Group className={`${classes.sectionsSelectedWrapper} show-on-hover`} gap={0}>
                <Box className={classes.sectionsSelected} />
                <Text className={classes.sectionsSelectedText} size="xs" pr={12} pl={4}>
                  {filterSectionsToUniqueGroupCount} sections selected
                </Text>
                <Divider m={0} p={0} orientation="vertical" color="var(--colors-gray-1)" />
                <Tooltip label="Clear selections" withArrow>
                  <ActionIcon onClick={() => clearSections()} h={30} w={30} className={classes.resetButton}>
                    <ResetIcon />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )}

            {advancedRowsInputFlag && advancedSectionRows.isEnabled && advancedSectionRows.hasSections && (
              <Group className={cx(classes.sectionsSelectedWrapper, 'show-on-hover', advancedSectionRows.isEnabled && classes.advancedRowsEnabled)} gap={0}>
                {advancedSectionRows.isEnabled && advancedSectionRows.advancedRows && advancedSectionRows.hasSections ? (
                  <>
                    <Text className={cx(classes.sectionsSelectedText)} size="xs" pr={12} pl={4}>
                      {filterSectionsToUniqueGroupCount} sections selected
                    </Text>
                    <Divider className={classes.sectionsSelectedDivider} m={0} p={0} orientation="vertical" color="var(--colors-gray-1)" />
                    <RowSectionPills
                      pills={[...(advancedSectionRows.advancedRows.keys() || [])].map((rows) => ({
                        key: rows,
                        count: advancedSectionRows.advancedRows?.get(rows)?.filter((x) => x)?.length || 0,
                      }))}
                    />
                  </>
                ) : (
                  <>
                    <Box className={classes.sectionsSelected} />
                    <Text className={classes.sectionsSelectedText} size="xs" pr={12} pl={4}>
                      {filterSectionsToUniqueGroupCount} sections selected
                    </Text>
                  </>
                )}
                <Divider m={0} p={0} orientation="vertical" color="var(--colors-gray-1)" />
                <Tooltip label="Clear selections" withArrow>
                  <ActionIcon onClick={() => clearSections()} h={30} w={30} className={classes.resetButton}>
                    <ResetIcon />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )}

            {(form.values.sectionIds?.length ?? 0) === 0 && !advancedSectionRows.hasSections && (suggestions?.sectionIds?.length ?? 0) > 0 && (
              <Group className={`animated-border ${classes.sectionsSuggestedWrapper}`} bg="var(--colors-brandcolor-2)" align="center" justify="center" gap={0}>
                <Text size="xs" pl="xs" c="var(--colors-gray-7)">
                  Section Suggestions
                </Text>
                <BNButton
                  size="compact-xs"
                  mr={1}
                  variant="filled"
                  color="brandcolor"
                  ml="xs"
                  onClick={() => {
                    onSectionClick(suggestions?.sectionIds || []);
                  }}
                  data-pendo-name="Accept All Suggestions Button"
                >
                  Accept
                </BNButton>
              </Group>
            )}
            <Button.Group className={`${classes.zoomWrapper} show-on-hover`}>
              <BNButton
                size="xs"
                variant="subtle"
                px={6}
                style={{ borderRight: '1px solid var(--colors-gray-1)' }}
                onClick={() => {
                  zoomOut();
                  // This is a bit of a hack because the ref doesn't get immediately updated so we can't look at the current.state.scale
                  if (transformRef.current?.instance.transformState !== undefined && transformRef.current.instance.transformState.previousScale <= 1.8) {
                    setIsInitialView(true);
                  }
                }}
                className={classes.btnZoomOut}
              >
                <RemoveIcon size={20} />
              </BNButton>
              <BNButton
                size="xs"
                variant="subtle"
                px={6}
                onClick={() => {
                  zoomIn();
                  setIsInitialView(false);
                }}
                className={classes.btnZoomIn}
              >
                <AddIcon size={20} />
              </BNButton>
              {!isInitialView && (
                <BNButton
                  size="xs"
                  variant="subtle"
                  px={6}
                  style={{ borderLeft: '1px solid var(--colors-gray-1)' }}
                  onClick={() => {
                    resetTransform();
                    setIsInitialView(true);
                  }}
                  className={classes.btnReset}
                >
                  <ResetIcon size={20} />
                </BNButton>
              )}
            </Button.Group>
            {svgDetails && svgDetails.zones.length > 0 && !advancedSectionRows.isEnabled && (
              <>
                {rule.filters.sectionIds?.length === 0 && (
                  <BNButton
                    pos="absolute"
                    top={16}
                    left={16}
                    right="auto"
                    size="xs"
                    variant="subtle"
                    px="xs"
                    className={`${classes.allSections} show-on-hover`}
                    onClick={selectAllSections}
                  >
                    Select All
                  </BNButton>
                )}
                <Group px={6} py={7} pr={10} className={`${classes.switchWrapper} show-on-hover`}>
                  <BNSwitch size="xs" label="Zone" onChange={() => setZoneMode(!zoneMode)} className={classes.zoneSwitch} />
                </Group>
              </>
            )}
            {allSectionsInSeatingChart.length > 0 && svgDetails && ruleSectionsMissingCount > 0 && <MissingSections count={ruleSectionsMissingCount} />}
          </>
        )}
      </TransformWrapper>
    </Box>
  );
}
