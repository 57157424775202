import { Center, Flex, Loader, Skeleton, Text, UnstyledButton } from '@mantine/core';
import classes from './ListingMarketplaceDetails.module.css';
import { useNavigate } from 'react-router-dom';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import { MarketListingProvider } from '../MarketListings/MarketListings.hooks';
import pluralize from 'pluralize';
import { useSeatingChart } from '../SeatingChart/SeatingChart.hooks';
import { useAtomValue } from 'jotai/index';
import { ruleStateAtom } from '../../data/atoms';
import { MobileMarketplaceAvatar } from '../../pages/MarketFilters.Mobile';
import { MarketListings } from '../MarketListings/MarketListings';

export function ListingMarketplaceDetails() {
  const navigate = useNavigate();
  const rule = useAtomValue(ruleStateAtom);
  const { selectedEvent, selectedMarketplaceId, isLoading, advancedSectionRows } = useSeatingChart();

  /// TODO: Josh needs to add empty state for no selected event
  if (!selectedEvent) {
    return (
      <Flex className={classes.filterMarketplaceContentWrapper} direction="column" w="100%" h="100%">
        <Flex w="100%" p="md" gap="md" align="center">
          <MobileMarketplaceAvatar marketplaceId={selectedMarketplaceId} />
          <Flex direction="column" style={{ flex: 1, maxWidth: '100%', overflow: 'hidden' }}>
            <Text size="sm" fw={600} lh={1.3} truncate>
              Marketplace data not found
            </Text>
            <Text fz={11} c="var(--colors-gray-5)" truncate style={{ display: 'block' }}>
              Check back later or update the mapping (desktop only)
            </Text>
            {/* Loading skeleton sizes */}
            {isLoading && (
              <>
                <Skeleton height={8} maw={240} width="100%" my={4} />
                <Skeleton height={8} maw={160} width="100%" my={4} />
              </>
            )}
          </Flex>
        </Flex>
        <Flex h="100%" w="100%">
          <MarketListingProvider>{!isLoading && <MarketListings />}</MarketListingProvider>
        </Flex>
      </Flex>
    );
  }

  const sectionCount = rule?.filters?.sectionIds?.length ?? 0;
  const rowFilterLabel = rule?.filters?.rows ? `Rows ${rule.filters.rows}` : 'All Rows';
  const splitsFilterLabel = rule?.autoAdjustSplits
    ? 'Auto Splits'
    : rule?.filters?.splits === null || rule?.filters?.splits === undefined || rule?.filters?.splits.length === 8
      ? 'All Splits'
      : `Splits ${rule.filters.splits.toString()}`;
  const exclusionsFilterLabel = rule?.filters?.exclusions === null || rule?.filters?.exclusions === undefined ? 'No Exclusions' : 'Exclusions';
  const outliersFilterLabel = rule?.filters?.outlierCriteria === null || rule?.filters?.outlierCriteria === undefined ? 'No Outliers' : 'Outliers';

  return (
    <Flex className={classes.filterMarketplaceContentWrapper} w="100%" h="100%">
      <UnstyledButton disabled={isLoading} w="100%" p="md" className={classes.filterButton} onClick={() => navigate('filters')}>
        <MobileMarketplaceAvatar marketplaceId={selectedMarketplaceId} width={38} />
        <Flex direction="column" style={{ flex: 1, maxWidth: '100%', overflow: 'hidden' }}>
          {!isLoading && (
            <>
              <Text size="sm" fw={600} lh={1.3} truncate>
                {selectedEvent.name}
              </Text>
              <Text fz={11} c="var(--colors-gray-5)" truncate style={{ display: 'block' }}>
                {advancedSectionRows.isEnabled && (
                  <>
                    Advanced Sections & Rows &middot; {splitsFilterLabel} &middot; {exclusionsFilterLabel} &middot;{' '}
                  </>
                )}
                {!advancedSectionRows.isEnabled && sectionCount === 0 && (
                  <>
                    All Sections &middot; {rowFilterLabel} &middot; {splitsFilterLabel} &middot; {exclusionsFilterLabel} &middot;{' '}
                  </>
                )}
                {!advancedSectionRows.isEnabled && sectionCount > 0 && (
                  <>
                    {sectionCount} {pluralize('Section', sectionCount)} Selected &middot; {rowFilterLabel} &middot; {splitsFilterLabel} &middot; {exclusionsFilterLabel} &middot;{' '}
                  </>
                )}
                {outliersFilterLabel}
              </Text>
            </>
          )}
          {/* Loading skeleton sizes */}
          {isLoading && (
            <>
              <Skeleton height={8} maw={240} width="100%" my={4} />
              <Skeleton height={8} maw={160} width="100%" my={4} />
            </>
          )}
        </Flex>
        <ChevronForwardIcon size={18} color="var(--colors-gray-5)" />
      </UnstyledButton>
      <Flex h="100%" w="100%">
        <MarketListingProvider>
          {!isLoading ? (
            <MarketListings />
          ) : (
            <Center h="100%" w="100%" bg="var(--colors-gray-0)">
              <Loader type="dots" />
            </Center>
          )}
        </MarketListingProvider>
      </Flex>
    </Flex>
  );
}
