import { BarkerCoreModelsMarketplacesListing, BarkerCoreModelsPricingOutlierCriteria, BarkerCoreModelsPricingRuleFilters } from '../../api';

export function GetTargetComparablePrice(comparables: BarkerCoreModelsMarketplacesListing[] | null | undefined) {
  if (!comparables || comparables.length === 0) {
    return null;
  }

  return Number((comparables.reduce((a, b) => a + b.adjustedPrice.value, 0) / comparables.length).toFixed(2));
}

export function GetTargetComparables(listings: BarkerCoreModelsMarketplacesListing[], numComparables: number) {
  return listings.filter((l) => !l.isOwnListing).slice(0, numComparables);
}

export function ApplyFilters(
  listings: BarkerCoreModelsMarketplacesListing[],
  filters: BarkerCoreModelsPricingRuleFilters,
  includeOwnListings = false,
  includeIgnoredListings = false,
  includeOutliers = false,
) {
  if (filters.sectionIds && filters.sectionIds.length > 0 && filters.sectionRows && filters.sectionRows.length > 0) {
    throw new Error('Cannot filter by both sectionIds and sectionRows');
  }

  if (filters.rows && filters.sectionRows && filters.sectionRows.length > 0) {
    throw new Error('Cannot filter by both rows and sectionRows');
  }

  const rowParts = filters.rows?.split(/\s+/);

  const results = listings
    .filter((listing) => {
      if (filters.noFilters) {
        return true;
      }

      if (filters.sectionRows && filters.sectionRows.length > 0) {
        if (!listing.sectionId) {
          return false;
        }

        const matchingSectionRow = filters.sectionRows.find(
          (sectionRow) =>
            sectionRow.sectionId.toLowerCase() === listing.sectionId?.toLowerCase() && (!sectionRow.rows || matchesRowFilter(listing.row, sectionRow.rows.split(/\s+/))),
        );

        if (!matchingSectionRow) {
          return false;
        }
      }

      if (
        filters.sectionIds &&
        filters.sectionIds.length > 0 &&
        (listing.sectionId === undefined || listing.sectionId == null || !filters.sectionIds.map((x) => x.toLowerCase()).includes(listing.sectionId.toLowerCase()))
      ) {
        return false;
      }

      if (filters.rows) {
        if (!rowParts) {
          return false;
        }

        if (!matchesRowFilter(listing.row, rowParts)) {
          return false;
        }
      }

      if (filters.splits && filters.splits.length > 0 && filters.splits.filter((split) => listing.splits.includes(split)).length === 0) {
        return false;
      }

      if (filters.minPrice && listing.adjustedPrice.value < filters.minPrice) {
        return false;
      }

      if (filters.maxPrice && listing.adjustedPrice.value > filters.maxPrice) {
        return false;
      }

      if (filters.exclusions && listing.disclosures && filters.exclusions.some((exclusion) => listing.disclosures.includes(exclusion))) {
        return false;
      }

      if (filters.deliveryExclusions && listing.deliveryMethod && filters.deliveryExclusions.some((exclusion) => listing.deliveryMethod === exclusion)) {
        return false;
      }

      if (filters.ignoredListingIds && filters.ignoredListingIds.length > 0 && filters.ignoredListingIds.includes(listing.listingId) && !includeIgnoredListings) {
        return false;
      }

      if (!includeOwnListings && listing.isOwnListing) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      if (a.adjustedPrice.value < b.adjustedPrice.value) {
        return -1;
      }

      if (a.adjustedPrice.value > b.adjustedPrice.value) {
        return 1;
      }

      if (a.isOwnListing) {
        return -1;
      }

      if (b.isOwnListing) {
        return 1;
      }

      return 0;
    });

  if (includeOutliers) {
    return results;
  }

  if (!filters.outlierCriteria) {
    return results;
  }

  const outlierListings = getOutlierListings(results, filters.outlierCriteria);

  return results.filter((listing) => !outlierListings.includes(listing));
}

export function getOutlierPriceThreshold(listings: BarkerCoreModelsMarketplacesListing[], outlierCriteria: BarkerCoreModelsPricingOutlierCriteria) {
  if (!outlierCriteria) {
    return null;
  }

  if (listings.length <= outlierCriteria.comparableIndex + 1) {
    return null;
  }

  const outlierAnchor = listings[outlierCriteria.comparableIndex];

  return outlierAnchor.adjustedPrice.value * (1 - outlierCriteria.percentUnder);
}

export function getOutlierListings(listings: BarkerCoreModelsMarketplacesListing[], outlierCriteria: BarkerCoreModelsPricingOutlierCriteria) {
  const notOurListings = listings.filter((listing) => !listing.isOwnListing);

  const outlierPriceThreshold = getOutlierPriceThreshold(notOurListings, outlierCriteria);

  if (outlierPriceThreshold === null) {
    return [];
  }

  return notOurListings.filter((listing) => listing.adjustedPrice.value < outlierPriceThreshold);
}

export function matchesRowFilter(row: string, rowFilterParts: string[]) {
  const sanitizedRow = row.toLowerCase();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rowFilterParts.length; i++) {
    const part = rowFilterParts[i].toLowerCase();

    if (part.indexOf('-') === -1) {
      if (sanitizedRow === part || part === '*') {
        return true;
      }
      // eslint-disable-next-line no-continue
      continue;
      //return false;
    }
    const range = part.split('-');

    if (range.length !== 2) {
      // eslint-disable-next-line no-continue
      continue;
      //return false;
    }

    const min = range[0];
    const max = range[1];

    const minNumber = parseInt(min);
    const maxNumber = parseInt(max);

    if (isNaN(minNumber) !== isNaN(maxNumber)) {
      // eslint-disable-next-line no-continue
      continue;
      //return false;
    }

    if (isNaN(minNumber)) {
      if (sanitizedRow >= range[0] && sanitizedRow <= range[1] && sanitizedRow.length === range[1].length) {
        if (
          range[0].split('').some((letter) => letter !== range[0][0]) ||
          range[1].split('').some((letter) => letter !== range[1][0]) ||
          sanitizedRow.split('').some((letter) => letter !== sanitizedRow[0])
        ) {
          // eslint-disable-next-line no-continue
          continue;
          //return false;
        }

        return true;
      }
    } else if (parseInt(sanitizedRow) >= minNumber && parseInt(sanitizedRow) <= maxNumber) {
      return true;
    }
  }

  return false;
}
