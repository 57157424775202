import { Box, Group, MantineFontSize, Skeleton, Text, Tooltip } from '@mantine/core';
import React from 'react';

interface BNLabelProps {
  text: string | React.ReactNode;
  leftIcon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  tooltip?: string | React.ReactNode;
  skeleton?: boolean;
  fz?: MantineFontSize;
}

/**
 * Icon with Text Label
 */
export const BNLabel = ({ text, leftIcon, className, tooltip, skeleton, fz = '11', ...props }: BNLabelProps) => {
  // LWW 9/30/24 - Removed conditionally-rendering tooltips because it was causing
  //const { hovered, ref: hoverRef } = useHover();
  return tooltip ? (
    // <Box ref={hoverRef}>
    //   {hovered ? (
    <Tooltip label={tooltip} withArrow withinPortal>
      <Group className={className} gap={4}>
        <Box display="flex" opacity={0.8}>
          {leftIcon}
        </Box>
        {skeleton ? (
          <Skeleton height={4} width={20} />
        ) : (
          <Text component="div" fz={fz} mx={0}>
            {text}
          </Text>
        )}
      </Group>
    </Tooltip>
  ) : (
    // ) : (
    //   <Group className={className} gap={4}>
    //     <Box display="flex" opacity={0.8}>
    //       {leftIcon}
    //     </Box>
    //     {skeleton ? (
    //       <Skeleton height={4} width={20} />
    //     ) : (
    //       <Text component="div" fz={11} mx={0}>
    //         {text}
    //       </Text>
    //     )}
    //   </Group>
    // )}
    // </Box>
    <Group className={className} gap={4}>
      <Box display="flex" opacity={0.8}>
        {leftIcon}
      </Box>
      {skeleton ? (
        <Skeleton height={4} width={20} />
      ) : (
        <Text component="div" fz={fz} mx={0}>
          {text}
        </Text>
      )}
    </Group>
  );
};
