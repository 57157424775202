import { BNAccordionMenu } from '../../../components/Accordion/BNAccordion';
import { ActionIcon, Box, Center, Checkbox, Flex, Group, Text, Tooltip } from '@mantine/core';
import classes from './SharingMenu.module.css';
import { useDTIInventory } from '../DTI.Inventory.hooks';
import { ReactNode, useState } from 'react';
import cx from 'clsx';
import MarketplaceStubHubIcon from '../../../components/icons/MarketplaceStubHub';
import MarketplaceVividIcon from '../../../components/icons/MarketplaceVivid';
import MarketplaceTicketmasterIcon from '../../../components/icons/MarketplaceTicketmaster';
import MarketplaceSeatGeekIcon from '../../../components/icons/MarketplaceSeatGeek';
import MarketplaceTicketEvolutionIcon from '../../../components/icons/MarketplaceTicketEvolution';
import MarketplaceTickPickIcon from '../../../components/icons/MarketplaceTickPick';
import MarketplaceAXSIcon from '../../../components/icons/MarketplaceAXS';
import MarketplaceGametimeIcon from '../../../components/icons/MarketplaceGametime';
import MarketplaceTicketNetworkIcon from '../../../components/icons/MarketplaceTicketNetwork';
import MarketplaceGoTicketsIcon from '../../../components/icons/MarketplaceGoTickets';
import { BNButton } from '../../../components/Button/Button';
import { useSetAtom } from 'jotai';
import { updateListingsAtom } from '../../../data/atoms';
import { BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal } from '../../../api';
import { DTIMarketplaces, DTIMarketplacesItems, Marketplace } from '../../../utils/dti-utils';
import ShareIcon from '../../../components/icons/Share';
import { useBulkState } from '../../../data/Bulk.state';
import WarningIcon from '../../../components/icons/Warning';

type SharingIconButtonProps = {
  icon: ReactNode;
  tooltipLabel?: ReactNode;
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
};

function SharingIconButton({ icon, tooltipLabel, selected, onClick, disabled }: SharingIconButtonProps) {
  return (
    <Tooltip label={tooltipLabel} disabled={!tooltipLabel} withArrow>
      <ActionIcon miw={30} w={44} mih={30} h={30} variant="default" onClick={onClick} className={cx(selected && classes.btnSelected)} disabled={disabled}>
        {icon}
      </ActionIcon>
    </Tooltip>
  );
}

export function SharingMenuPanel({ onClose, onClick, bulk }: { onClose?: () => void; onClick?: () => void; bulk?: boolean }) {
  const updateListing = useSetAtom(updateListingsAtom);
  const { distinctTenantPointsOfSale } = useBulkState('distinctTenantPointsOfSale');
  const { vendorProperties, editSharing, editSharingBulk, isUpdatingListing, selectedListing } = useDTIInventory(
    'vendorProperties',
    'editSharing',
    'editSharingBulk',
    'isUpdatingListing',
    'selectedListing',
  );

  const [omittedMarketplaces, setOmittedMarketplaces] = useState(
    vendorProperties?.confirmationNumber?.replace('-', '').replace('S', '').replace('F', '').replace('M', '').replace('I', '') ?? '',
  );

  const handleSelectionChange = (marketplace: Marketplace) => {
    setOmittedMarketplaces((prev) => {
      if (prev.includes(marketplace.value)) {
        return prev.replace(marketplace.value, '');
      }
      return prev + marketplace.value;
    });
  };

  const toggleAllMarketplaces = () => {
    omittedMarketplaces === '' ? setOmittedMarketplaces(Array.from(new Set(Object.values(DTIMarketplacesItems))).join('')) : setOmittedMarketplaces('');
  };

  const activeMarketplaceSize = new Set(Object.values(DTIMarketplaces).map((x) => x.value)).size;

  const partialSelected = omittedMarketplaces.length > 0 && omittedMarketplaces.length < activeMarketplaceSize;
  const allSelected = omittedMarketplaces.length === 0;
  const checkedCount = Math.abs(omittedMarketplaces.length - activeMarketplaceSize);

  const showTooltip = false;

  if (!selectedListing || !vendorProperties) {
    return null;
  }

  return (
    <BNAccordionMenu.Panel
      id="sharing-options"
      leftSection={<ShareIcon />}
      disabled={distinctTenantPointsOfSale.length > 1 || !distinctTenantPointsOfSale.includes('DtiPortal')}
      disabledText="Multiple points of sale selected"
      className={classes.sharingAccordionMenuItem}
      rightSection={
        !bulk && (
          <Flex className="marketplaceMenuCount" w={32} justify="end" pos="absolute" right={37} c="var(--colors-gray-5)" style={{ pointerEvents: 'none', whiteSpace: 'nowrap' }}>
            <Text fz={11} p={0} m={0}>
              <Text component="span" fz={11} p={0} m={0}>
                {checkedCount}
              </Text>
              &nbsp;/ {activeMarketplaceSize}
            </Text>
          </Flex>
        )
      }
      title="Marketplaces"
      onClick={onClick}
    >
      <Flex ml={23} direction="column" gap="xs">
        <Box pos="relative" maw={236}>
          <Box pos="absolute" top={-41} right={22} w={54}>
            <Checkbox
              checked={allSelected}
              disabled={selectedListing.quantityReserved > 0}
              onChange={() => {
                toggleAllMarketplaces();
              }}
              indeterminate={partialSelected}
              label={
                <Flex w={32} justify="end" wrap="nowrap">
                  <Text component="span" fz={11} style={{ whiteSpace: 'nowrap' }} c="var(--colors-gray-5)">
                    <Text component="span" fz={11} fw={700} c="var(--colors-selectedBorder)">
                      {checkedCount}
                    </Text>
                    &nbsp;/ {activeMarketplaceSize}
                  </Text>
                </Flex>
              }
              size="xs"
              className={classes.sharingCheckbox}
            />
          </Box>
          <Flex gap={4} wrap="wrap" w={236} className={classes.sharingButtonGroup}>
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.StubHub}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.StubHub.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.StubHub)}
              icon={<MarketplaceStubHubIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces['Vivid Seats']}
              selected={!omittedMarketplaces.includes(DTIMarketplaces['Vivid Seats'].value)}
              onClick={() => handleSelectionChange(DTIMarketplaces['Vivid Seats'])}
              icon={<MarketplaceVividIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.Ticketmaster}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.Ticketmaster.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.Ticketmaster)}
              icon={<MarketplaceTicketmasterIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.SeatGeek}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.SeatGeek.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.SeatGeek)}
              icon={<MarketplaceSeatGeekIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces['Ticket Evolution']}
              selected={!omittedMarketplaces.includes(DTIMarketplaces['Ticket Evolution'].value)}
              onClick={() => handleSelectionChange(DTIMarketplaces['Ticket Evolution'])}
              icon={<MarketplaceTicketEvolutionIcon size={20} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.TickPick}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.TickPick.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.TickPick)}
              icon={<MarketplaceTickPickIcon size={20} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.AXS}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.AXS.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.AXS)}
              icon={<MarketplaceAXSIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.Gametime}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.Gametime.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.Gametime)}
              icon={<MarketplaceGametimeIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces['Ticket Network']}
              selected={!omittedMarketplaces.includes(DTIMarketplaces['Ticket Network'].value)}
              onClick={() => handleSelectionChange(DTIMarketplaces['Ticket Network'])}
              icon={<MarketplaceTicketNetworkIcon size={18} />}
            />
            <SharingIconButton
              disabled={selectedListing.quantityReserved > 0}
              tooltipLabel={DTIMarketplaces.GoTickets}
              selected={!omittedMarketplaces.includes(DTIMarketplaces.GoTickets.value)}
              onClick={() => handleSelectionChange(DTIMarketplaces.GoTickets)}
              icon={<MarketplaceGoTicketsIcon size={20} />}
            />
          </Flex>
          {showTooltip && (
            <Tooltip label="Selected listings have varying marketplace sharing values that may be overwritten." multiline maw={260}>
              <Center h={20} w={20} pos="absolute" top="calc(50% - 10px)" left={-32}>
                <WarningIcon size={20} color="var(--colors-yellow-warning)" />{' '}
              </Center>
            </Tooltip>
          )}
        </Box>
        <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
          <BNButton size="xs" fullWidth onClick={onClose} disabled={isUpdatingListing}>
            Cancel
          </BNButton>
          <BNButton
            disabled={selectedListing.quantityReserved > 0}
            fullWidth
            size="xs"
            variant="filled"
            color="green"
            loading={isUpdatingListing}
            onClick={async () => {
              if (!bulk) {
                editSharing(selectedListing, selectedListing.isBroadcasting, omittedMarketplaces).then(() => {
                  const newProperties = { ...vendorProperties, confirmationNumber: omittedMarketplaces } satisfies BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;

                  updateListing({
                    ...selectedListing,
                    isBroadcasting: selectedListing.isBroadcasting,
                    vendorProperties: newProperties,
                  });
                });
              } else {
                await editSharingBulk(selectedListing, selectedListing.isBroadcasting, omittedMarketplaces);
                onClose?.();
              }
            }}
          >
            Apply
          </BNButton>
        </Group>
      </Flex>
    </BNAccordionMenu.Panel>
  );
}
