import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { BarkerCoreModelsSalesGetSalesResponse, GetApiSalesParams, PostApiSalesSaleIdUploadTransferProofBody } from './models';

export const getApiSales = (params?: GetApiSalesParams, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsSalesGetSalesResponse>> => {
  return axios.get(`/api/Sales`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiSalesQueryKey = (params?: GetApiSalesParams) => {
  return [`/api/Sales`, ...(params ? [params] : [])] as const;
};

export const getGetApiSalesQueryOptions = <TData = Awaited<ReturnType<typeof getApiSales>>, TError = AxiosError<void>>(
  params?: GetApiSalesParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSales>>, TError, TData>>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiSalesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSales>>> = ({ signal }) => getApiSales(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiSales>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiSalesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSales>>>;
export type GetApiSalesQueryError = AxiosError<void>;

export const useGetApiSales = <TData = Awaited<ReturnType<typeof getApiSales>>, TError = AxiosError<void>>(
  params?: GetApiSalesParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiSales>>, TError, TData>>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSalesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiSalesSaleIdUploadTransferProof = (
  saleId: string,
  postApiSalesSaleIdUploadTransferProofBody: PostApiSalesSaleIdUploadTransferProofBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  if (postApiSalesSaleIdUploadTransferProofBody.accountType !== undefined) {
    formData.append('accountType', postApiSalesSaleIdUploadTransferProofBody.accountType);
  }
  formData.append('file', postApiSalesSaleIdUploadTransferProofBody.file);

  return axios.post(`/api/Sales/${saleId}/uploadTransferProof`, formData, options);
};

export const getPostApiSalesSaleIdUploadTransferProofMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSalesSaleIdUploadTransferProof>>,
    TError,
    { saleId: string; data: PostApiSalesSaleIdUploadTransferProofBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSalesSaleIdUploadTransferProof>>,
  TError,
  { saleId: string; data: PostApiSalesSaleIdUploadTransferProofBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSalesSaleIdUploadTransferProof>>, { saleId: string; data: PostApiSalesSaleIdUploadTransferProofBody }> = (
    props,
  ) => {
    const { saleId, data } = props ?? {};

    return postApiSalesSaleIdUploadTransferProof(saleId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiSalesSaleIdUploadTransferProofMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSalesSaleIdUploadTransferProof>>>;
export type PostApiSalesSaleIdUploadTransferProofMutationBody = PostApiSalesSaleIdUploadTransferProofBody;
export type PostApiSalesSaleIdUploadTransferProofMutationError = AxiosError<void>;

export const usePostApiSalesSaleIdUploadTransferProof = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSalesSaleIdUploadTransferProof>>,
    TError,
    { saleId: string; data: PostApiSalesSaleIdUploadTransferProofBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiSalesSaleIdUploadTransferProof>>, TError, { saleId: string; data: PostApiSalesSaleIdUploadTransferProofBody }, TContext> => {
  const mutationOptions = getPostApiSalesSaleIdUploadTransferProofMutationOptions(options);

  return useMutation(mutationOptions);
};
