import { useAtomValue } from 'jotai/index';
import {
  BarkerCoreModelsNotificationsUserNotification,
  getGetApiNotificationsActiveLatestQueryKey,
  getGetApiNotificationsLatestQueryKey,
  getGetApiNotificationsUnreadCountQueryKey,
  putApiNotificationsNotificationIdArchive,
  putApiNotificationsNotificationIdRead,
  putApiNotificationsNotificationIdUnarchive,
  useGetApiNotificationsActiveLatest,
  useGetApiNotificationsLatest,
  useGetApiNotificationsUnreadCount,
} from '../../api';
import { useGlobalState } from '../../data/Global.state';
import { useCallback, useMemo, useState } from 'react';
import yasml from '@thirtytech/yasml';
import { useDisclosure } from '@mantine/hooks';
import { AxiosResponse } from 'axios/index';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { auth } from '../../data/atoms.auth';

function NotificationsState() {
  const queryClient = useQueryClient();
  const { selectedTenantIds } = useGlobalState('selectedTenantIds');
  const [isMenuOpened, { toggle: toggleMenu, close: closeMenu }] = useDisclosure(false);
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const [activeTab, setActiveTab] = useState<string | null>('inbox'); // ['inbox', 'all', 'archived'
  const { data: notificationCount } = useGetApiNotificationsUnreadCount({
    query: {
      enabled: !!apiToken && selectedTenantIds?.length > 0,
      refetchOnWindowFocus: true,
      select(data) {
        return data.data;
      },
    },
  });

  const { data: inboxNotifications, refetch: refetchInboxNotifications } = useGetApiNotificationsActiveLatest({
    query: {
      enabled: !!apiToken && selectedTenantIds?.length > 0,
      structuralSharing: false,
      select(data) {
        return data.data.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());
      },
    },
  });

  const { data: allNotifications, refetch: refetchAllNotifications } = useGetApiNotificationsLatest({
    query: {
      enabled: !!apiToken && selectedTenantIds?.length > 0,
      structuralSharing: false,
      select(data) {
        return data.data.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());
      },
    },
  });

  const archivedNotifications = useMemo(() => {
    if (!allNotifications) {
      return [];
    }

    return allNotifications.filter((notification) => notification.archivedAt !== null);
  }, [allNotifications]);

  const archiveNotifications = useCallback(
    async (notifications: BarkerCoreModelsNotificationsUserNotification[]) => {
      await Promise.all(
        notifications.map(({ notificationId, tenantId }) =>
          putApiNotificationsNotificationIdArchive(notificationId, {
            headers: {
              'x-tenant-id': tenantId,
            },
          }),
        ),
      );
      await queryClient.invalidateQueries({ queryKey: getGetApiNotificationsActiveLatestQueryKey() });
      await queryClient.invalidateQueries({ queryKey: getGetApiNotificationsLatestQueryKey() });
      await queryClient.invalidateQueries({ queryKey: getGetApiNotificationsUnreadCountQueryKey() });
    },
    [queryClient],
  );

  const handleRead = useCallback(
    async (notification: BarkerCoreModelsNotificationsUserNotification) => {
      await putApiNotificationsNotificationIdRead(notification.notificationId, {
        headers: {
          'x-tenant-id': notification.tenantId,
        },
      });
      queryClient.setQueryData<AxiosResponse<number>>(getGetApiNotificationsUnreadCountQueryKey(), (oldData) => {
        if (!oldData || !oldData?.data) {
          return oldData;
        }

        return {
          ...oldData,
          data: oldData.data - 1,
        };
      });

      queryClient.setQueryData<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>>(getGetApiNotificationsLatestQueryKey(), (oldData) => {
        if (!oldData || !oldData?.data) {
          return oldData;
        }

        const _notification = oldData.data.find((d) => d.notificationId === notification.notificationId);

        if (_notification === undefined) {
          return oldData;
        }

        _notification.readAt = new Date();

        return {
          ...oldData,
          data: [...oldData.data.filter((d) => d.notificationId !== notification.notificationId), _notification].sort(
            (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
          ),
        };
      });

      queryClient.setQueryData<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>>(getGetApiNotificationsActiveLatestQueryKey(), (oldData) => {
        if (!oldData || !oldData?.data) {
          return oldData;
        }

        const _notification = oldData.data.find((d) => d.notificationId === notification.notificationId);

        if (_notification === undefined) {
          return oldData;
        }

        _notification.readAt = new Date();

        return {
          ...oldData,
          data: [...oldData.data.filter((d) => d.notificationId !== notification.notificationId), _notification].sort(
            (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
          ),
        };
      });
    },
    [queryClient],
  );

  const handleArchive = useCallback(
    async (notification: BarkerCoreModelsNotificationsUserNotification) => {
      await putApiNotificationsNotificationIdArchive(notification.notificationId, {
        headers: {
          'x-tenant-id': notification.tenantId,
        },
      });

      queryClient.setQueryData<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>>(getGetApiNotificationsActiveLatestQueryKey(), (oldData) => {
        if (!oldData || !oldData?.data) {
          return oldData;
        }

        const _notification = oldData.data.find((d) => d.notificationId === notification.notificationId);

        if (_notification === undefined) {
          return oldData;
        }

        _notification.archivedAt = new Date();

        queryClient.setQueryData<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>>(getGetApiNotificationsLatestQueryKey(), (allNotificationsUpdater) => {
          if (!allNotificationsUpdater || !allNotificationsUpdater?.data) {
            return allNotificationsUpdater;
          }

          return {
            ...allNotificationsUpdater,
            data: [...allNotificationsUpdater.data.filter((d) => d.notificationId !== notification.notificationId), _notification].sort(
              (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
            ),
          };
        });

        if (_notification.readAt === null) {
          queryClient.setQueryData<AxiosResponse<number>>(getGetApiNotificationsUnreadCountQueryKey(), (updater) => {
            if (updater === undefined) {
              return updater;
            }

            return {
              ...updater,
              data: updater.data - 1,
            };
          });
        }

        return {
          ...oldData,
          data: oldData.data.filter((d) => d.notificationId !== notification.notificationId).sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()),
        };
      });
    },
    [queryClient],
  );

  const handleUnarchive = useCallback(
    async (notification: BarkerCoreModelsNotificationsUserNotification) => {
      await putApiNotificationsNotificationIdUnarchive(notification.notificationId, {
        headers: {
          'x-tenant-id': notification.tenantId,
        },
      });
      queryClient.setQueryData<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>>(getGetApiNotificationsLatestQueryKey(), (allNotificationsUpdater) => {
        if (!allNotificationsUpdater || !allNotificationsUpdater?.data) {
          return allNotificationsUpdater;
        }

        const _notification = allNotificationsUpdater.data.find((d) => d.notificationId === notification.notificationId);

        if (!_notification) {
          return allNotificationsUpdater;
        }

        _notification.archivedAt = null;

        queryClient.setQueryData<AxiosResponse<BarkerCoreModelsNotificationsUserNotification[]>>(getGetApiNotificationsActiveLatestQueryKey(), (activeNotificationsUpdater) => {
          if (!activeNotificationsUpdater || !activeNotificationsUpdater?.data || !_notification) {
            return activeNotificationsUpdater;
          }

          return {
            ...activeNotificationsUpdater,
            data: [...activeNotificationsUpdater.data.filter((d) => d.notificationId !== notification.notificationId), _notification],
          };
        });

        if (_notification?.readAt === null) {
          queryClient.setQueryData<AxiosResponse<number>>(getGetApiNotificationsUnreadCountQueryKey(), (oldData) => {
            if (oldData === undefined) {
              return oldData;
            }

            return {
              ...oldData,
              data: oldData.data + 1,
            };
          });
        }

        return {
          ...allNotificationsUpdater,
          data: [...allNotificationsUpdater.data.filter((d) => d.notificationId !== notification.notificationId), _notification].sort(
            (a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix(),
          ),
        };
      });
    },
    [queryClient],
  );

  return {
    notificationCount,
    isMenuOpened,
    toggleMenu,
    closeMenu,
    activeTab,
    setActiveTab,
    inboxNotifications,
    allNotifications,
    handleRead,
    handleArchive,
    handleUnarchive,
    archiveNotifications,
    archivedNotifications,
    refetchInboxNotifications,
    refetchAllNotifications,
  };
}

export const { Provider: NotificationsProvider, useSelector: useNotifications } = yasml(NotificationsState);
