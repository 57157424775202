import { Modal } from '@mantine/core';
import { useGlobalState } from '../../data/Global.state';
import { useQueryClient } from '@tanstack/react-query';
import { TransferList } from '../../components/TransferList/TransferList';
import classes from './CompanyPicker.module.css';
import { useMemo, useState } from 'react';

interface CompanyModalProps {
  opened: boolean;
  setOpened: (val: boolean) => void;
}

export default function CompanyPicker({ opened, setOpened }: CompanyModalProps) {
  const { tenants, setStoredSelectedTenants, selectedTenantIds, setSelectedTenantIds } = useGlobalState(
    'tenants',
    'setStoredSelectedTenants',
    'selectedTenantIds',
    'setSelectedTenantIds',
  );
  const [_selectedTenantIdsInternal, _setSelectedTenantIdsInternal] = useState<string[]>(selectedTenantIds);

  const sortedTenants = useMemo(() => tenants?.sort((a, b) => a.name.localeCompare(b.name)) || [], [tenants]);
  const selectedTenants = useMemo(
    () =>
      sortedTenants
        ?.filter((x) => _selectedTenantIdsInternal.includes(x.tenantId))
        .map((x) => ({
          value: x.tenantId,
          label: x.name,
        })) || [],
    [sortedTenants, _selectedTenantIdsInternal],
  );

  const availableTenants = useMemo(
    () =>
      sortedTenants
        ?.filter((x) => !selectedTenants.map((y) => y.value).includes(x.tenantId))
        .map((x) => ({
          value: x.tenantId,
          label: x.name,
        })) || [],
    [sortedTenants, selectedTenants],
  );

  const queryClient = useQueryClient();

  return (
    <Modal
      padding={0}
      centered
      size="lg"
      opened={opened}
      onClose={() => {
        setOpened(false);
        setSelectedTenantIds(_selectedTenantIdsInternal);
        setStoredSelectedTenants(_selectedTenantIdsInternal);
        queryClient.invalidateQueries();
      }}
      title="Company Picker"
      closeButtonProps={{ size: 'md' }}
      styles={{
        body: {
          padding: 20,
        },
      }}
    >
      <TransferList
        onChange={(value) => {
          const tenantIds = value;
          _setSelectedTenantIdsInternal(tenantIds);
        }}
        selected={selectedTenants}
        options={availableTenants}
        searchPlaceholder="Search..."
        titles={['All Companies', 'Selected Companies']}
        transferAllMatchingFilter
        className={classes.transferList}
      />
    </Modal>
  );
}
