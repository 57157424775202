import { Box, Container, Text, Title } from '@mantine/core';
import { ReactNode, useEffect } from 'react';
import BNLogo from '../icons/BNLogo';
import DotsBackground from './DotsBackground';
import { datadogRum } from '@datadog/browser-rum';
import { usePostApiSupportErrors } from '../../api';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import DTILogo from '../icons/DTILogo';
import { useRouteError } from 'react-router-dom';

interface ErrorPageProps {
  title?: string;
  subTitle?: string;
  content?: ReactNode;
  report?: boolean;
  error?: any;
}

function ErrorContent() {
  return (
    <>
      We're sorry for the inconvenience, please try{' '}
      <Text
        c="var(--colors-brandcolor-5)"
        component="button"
        bg="transparent"
        fw={500}
        mx={0}
        p={0}
        style={{ border: 'none', cursor: 'pointer', textUnderlineOffset: 4 }}
        onClick={() => location.reload()}
        td="underline"
      >
        refreshing the page
      </Text>
      . If this problem persists, please contact support.
    </>
  );
}

export function ErrorPageProviderWrapper({ title = 'Temporary Error', subTitle, content = <ErrorContent />, report }: Omit<ErrorPageProps, 'error'>) {
  const error = useRouteError();
  return <ErrorPage title={title} subTitle={subTitle} content={content} report={report} error={error} />;
}

export default function ErrorPage({ title = 'Temporary Error', subTitle, content = <ErrorContent />, report, error }: ErrorPageProps) {
  const sessionId = datadogRum.getInternalContext()?.session_id;
  const userInfo = datadogRum.getUser();

  const { mutateAsync } = usePostApiSupportErrors();

  useEffect(() => {
    if (!report) {
      return;
    }

    mutateAsync({
      data: {
        message: error?.message ?? 'No error message',
        userId: userInfo?.email as string,
        replaySessionId: sessionId,
      },
    });
  }, []);

  return (
    <Container
      mih="100vh"
      miw="100vw"
      ta="center"
      px={48}
      py={0}
      style={{
        backgroundImage: 'linear-gradient(180deg, var(--colors-gray-0), var(--colors-siteBg))',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
      pos="relative"
    >
      <Box mt={-44}>
        <Title order={1} mb="xl">
          {title}
        </Title>
        {subTitle && (
          <Text size="lg" mt={-16}>
            We're working on getting things back up and running.
          </Text>
        )}
        <Box fz="lg" mt={36} maw={640} bg="var(--colors-paper)" p={36} mx="auto" lh={1.6} style={{ borderRadius: 6, boxShadow: '0 3px 20px rgba(0,0,0,.06)' }}>
          {content}
        </Box>
        <Box mx="auto" mt={36} h={80} w={80} bg="var(--colors-gray-1)" style={{ borderRadius: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {isDtiHosted ? <DTILogo size={40} /> : <BNLogo size={60} />}
        </Box>
      </Box>
      <DotsBackground />
    </Container>
  );
}
