import { AgGridReact } from '@ag-grid-community/react';
import { ActionIcon, Box, Button, Center, Divider, Flex, Group, Menu, Popover, Text, Tooltip } from '@mantine/core';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import { formatCurrency } from '../../utils/formatters';
import { BNButton } from '../Button/Button';
import AutoPriceIcon from '../icons/AutoPrice';
import CheckIcon from '../icons/Check';
import CloseIcon from '../icons/Close';
import ErrorIcon from '../icons/Error';
import MobileDataOffIcon from '../icons/MobileDataOff';
import VerticalAlignBottomIcon from '../icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '../icons/VerticalAlignTop';
import { BNNumberInput } from '../NumberInput/NumberInput';
import classes from './PriceDisplay.styles.tsx.module.css';
import cx from 'clsx';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import WarningIcon from '../icons/Warning';
import ArrowDropdownIcon from '../icons/ArrowDropdown';
import dayjs from 'dayjs';
import ArrowForwardIcon from '../icons/ArrowForward';
import { TenantIdListingId } from '../../models/tenantIdListingId';
import ScheduledPriceIcon from '../icons/ScheduledPrice';
import { useAppearanceSettings } from '../../hoc/Inventory/Inventory.AppearanceSettings.hook';
import { useIsDrasticChange } from '../../utils/price-utils';
import { useGlobalState } from '../../data/Global.state';
import TimerArrowDownIcon from '../icons/TimerArrowDown';
import AutoPauseIcon from '../icons/AutoPause';
import WarningPauseIcon from '../icons/WarningPause';

export type PriceDisplayImplProps = {
  value: number;
  basePrice: number;
  cost: number;
  pricerStatusId: BarkerCoreEnumsPricerStatus;
  listingId: string;
  tenantId: string;
  isPending: boolean;
  updatePrice: (tenantId: string, listingId: string, price: number, previousPrice: number) => Promise<void>;
  onChange?: (tenantId: string, listingId: string, price: number, previousPrice: number) => void;
  onCancel?: (tenantId: string, listingId: string) => void;
  gridRef: RefObject<AgGridReact>;
  isDetailRow: boolean;
  readOnly?: boolean;
};

type PriceDisplayFocusDetail = {
  listingId: string;
};

type PriceDisplayFocusEventType = CustomEvent<PriceDisplayFocusDetail>;
const PRICEDISPLAY_EVENT_NAME = 'onPriceDisplayFocus';

export const PriceDisplayImpl = ({
  value,
  pricerStatusId,
  isPending,
  updatePrice,
  tenantId,
  listingId,
  gridRef,
  basePrice,
  cost,
  isDetailRow,
  onChange: onChangeExternal,
  onCancel: onCancelExternal,
  readOnly,
}: PriceDisplayImplProps) => {
  const isDrasticChange = useIsDrasticChange(tenantId);
  const appearanceSettings = useAppearanceSettings();
  const [updatedValue, setUpdatedValue] = useState(value);
  const [initialValue] = useState(value);
  const [dirty, setDirty] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDrasticChangeDialog, { open, close }] = useDisclosure(false);
  const [isDrasticChangeLabel, { open: showDrasticLabel, close: hideDrasticLabel }] = useDisclosure(false);
  const [isDrasticChangeSnoozed, setDrasticChangeSnooze] = useLocalStorage<Date | undefined>({
    key: 'drasticChangeSnooze',
  });
  const _isDrasticChange = useMemo(() => isDrasticChange(value, basePrice, cost), [basePrice, value]);
  const tenantIdListingId = TenantIdListingId.fromString(`${tenantId}|${listingId}`);

  const allowSnooze = useGlobalState('tenants').tenants?.find((x) => x.tenantId === tenantId)?.settings?.pricerSettings?.allowDrasticPriceAlertSnooze ?? true;

  const onPriceDisplayFocusHandler = useCallback((e: PriceDisplayFocusEventType) => {
    if (e.detail.listingId !== listingId) {
      // NOTE: This reset pending changes on other listings. Pricer needs this disabled.
      onCancel();
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    document.addEventListener(PRICEDISPLAY_EVENT_NAME, onPriceDisplayFocusHandler);

    return () => {
      // @ts-ignore
      document.removeEventListener(PRICEDISPLAY_EVENT_NAME, onPriceDisplayFocusHandler);
    };
  }, []);

  const snooze = (interval: 'day' | 'hour') => {
    setDrasticChangeSnooze(dayjs().add(1, interval).toDate());
    onConfirmSave();
  };

  const percentChange = useMemo(() => {
    const change = updatedValue - value;
    return Math.round((change / value) * 100);
  }, [updatedValue, value]);

  const onConfirmSave = () => {
    updatePrice(tenantId, listingId, updatedValue, value);
    setUpdatedValue(value);
    setDirty(false);
    setFocused(false);
    hideDrasticLabel();
    const listing = gridRef.current?.api.getRowNode(tenantIdListingId.toString())!.data;
    gridRef.current?.api.getRowNode(tenantIdListingId.toString())!.setData({ ...listing, unitPrice: updatedValue });
  };

  const onSave = () => {
    if (isDrasticChange(updatedValue, value, cost) && dayjs(isDrasticChangeSnoozed).diff(dayjs()) <= 0) {
      open();
    } else {
      onConfirmSave();
      hideDrasticLabel();
    }
  };

  const onCancel = () => {
    if (onCancelExternal) {
      onCancelExternal(tenantId, listingId);
    }
    hideDrasticLabel();
    close();
    inputRef.current?.blur();
    setUpdatedValue(initialValue);
    setDirty(false);
    setFocused(false);
  };

  let tooltip = isPending ? 'Pending Change' : '';
  switch (pricerStatusId) {
    case BarkerCoreEnumsPricerStatus.Errored:
      tooltip = 'Auto-Pricer Error';
      break;
    case BarkerCoreEnumsPricerStatus.NoComparables:
      tooltip = 'No Comparables';
      break;
    case BarkerCoreEnumsPricerStatus.AtFloor:
      tooltip = 'At Floor';
      break;
    case BarkerCoreEnumsPricerStatus.AtCeiling:
      tooltip = 'At Ceiling';
      break;
    case BarkerCoreEnumsPricerStatus.AutoPriced:
      tooltip = 'Auto-Priced';
      break;
    case BarkerCoreEnumsPricerStatus.Scheduled:
      tooltip = 'Scheduled';
      break;
    case BarkerCoreEnumsPricerStatus.AtSoftFloor:
      tooltip = 'At Soft Floor';
      break;
    case BarkerCoreEnumsPricerStatus.Paused:
      tooltip = 'Auto-Pricing Paused';
      break;
    case BarkerCoreEnumsPricerStatus.PausedWithWarning:
      tooltip = 'Auto-Pricing Paused with Warnings';
      break;
  }
  if (isPending) {
    tooltip = 'Pending Change';
  }
  if (_isDrasticChange) {
    tooltip = 'Pending Drastic Price Change';
  }
  const Icon = useCallback(() => {
    if (pricerStatusId === BarkerCoreEnumsPricerStatus.Errored) {
      return (
        <Box className={`${classes.iconCircle} ${classes.error} priceBadge`}>
          <ErrorIcon size={18} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.NoComparables) {
      return (
        <Box className={`${classes.iconCircle} ${classes.warning} priceBadge`}>
          <MobileDataOffIcon size={18} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.AtFloor) {
      return (
        <Box className={`${classes.iconCircle} ${classes.error} priceBadge`}>
          <VerticalAlignBottomIcon size={18} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.AtCeiling) {
      return (
        <Box className={`${classes.iconCircle} ${classes.success} priceBadge`}>
          <VerticalAlignTopIcon size={18} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.AutoPriced) {
      return (
        <Box className={`${classes.iconCircle} ${classes.selected} priceBadge`}>
          <AutoPriceIcon size={18} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) {
      return (
        <Box className={`${classes.iconCircle} ${classes.selected} ${classes.scheduled} priceBadge`}>
          <ScheduledPriceIcon size={20} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.AtSoftFloor) {
      return (
        <Box className={`${classes.iconCircle} ${classes.warning} priceBadge`}>
          <TimerArrowDownIcon size={18} />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.Paused) {
      return (
        <Box className={`${classes.iconCircle} ${classes.gray} priceBadge`}>
          <AutoPauseIcon />
        </Box>
      );
    } else if (pricerStatusId === BarkerCoreEnumsPricerStatus.PausedWithWarning) {
      return (
        <Box className={`${classes.iconCircle} ${classes.warning} priceBadge`}>
          <WarningPauseIcon size={18} />
        </Box>
      );
    }
    // else if (pricerStatusId === BarkerCoreEnumsPricerStatus.ScheduledPaused) {
    //   return (
    //     <Box className={`${classes.iconCircle} ${classes.warning} priceBadge`}>
    //       <PauseCircleIcon size={18} />
    //     </Box>
    //   );
    //}
    return null;
  }, [isDrasticChange, isPending, pricerStatusId, classes.iconCircle, classes.pendingIcon, classes.error, classes.warning, classes.flipIcon, classes.success, classes.selected]);

  const PendingDisplay = useCallback(() => {
    return (
      <Box pos="absolute" right={4} style={{ zIndex: 100 }}>
        <Tooltip withArrow withinPortal label={tooltip} disabled={tooltip === ''}>
          {!_isDrasticChange ? (
            <Flex className={classes.pendingDisplay} pos="absolute" align="center" justify="end" h={30} top={1.5} left={0} w={100} p={6} pr={10} bg="var(--colors-paper)">
              <Flex pos="absolute" left={0} top={0} bottom={0} h="100%" align="center" p={4} c="var(--colors-gray-5)">
                <ArrowForwardIcon size={16} />
              </Flex>
              {formatCurrency(updatedValue)}
            </Flex>
          ) : (
            <Flex className={classes.pendingWarning} pos="absolute" align="center" justify="end" h={30} top={1.5} left={0} w={124} p={6} pr={34} bg="var(--colors-paper)">
              <Flex pos="absolute" left={0} top={0} bottom={0} h="100%" align="center" p={4} c="var(--colors-yellow-warning)">
                <ArrowForwardIcon size={16} />
              </Flex>
              {formatCurrency(updatedValue)}
              <Center p={0} m={0} pos="absolute" right={0} h={28} w={28} bg="var(--colors-yellow-warning)" c="var(--colors-paper)">
                <WarningIcon />
              </Center>
            </Flex>
          )}
        </Tooltip>
      </Box>
    );
  }, [isPending, tooltip, updatedValue, _isDrasticChange]);

  const colorCodePricesBelowCost = (price: number, cost: number): boolean => appearanceSettings.colorPricesBelowCost && price < cost;

  return (
    <Box
      className={cx(
        classes.wrapper,
        (pricerStatusId === BarkerCoreEnumsPricerStatus.None || pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) && classes.iconRightSectionOpacity,
      )}
    >
      <Tooltip withArrow withinPortal label={tooltip} disabled={tooltip === ''}>
        <Box className={classes.cellIcon}>
          <Icon key="pricerStatusId" />
        </Box>
      </Tooltip>
      {readOnly || isPending || isDetailRow || pricerStatusId !== 'None' ? (
        // {isDetailRow || (pricerStatusId !== 'None' && pricerStatusId !== 'Scheduled') ? (
        <Box className={classes.cellContent}>
          <Box className={colorCodePricesBelowCost(basePrice, cost) ? classes.cellDisplayValueBelowCost : classes.cellDisplayValue}>{formatCurrency(basePrice)}</Box>
          {isPending && <PendingDisplay />}
        </Box>
      ) : (
        <Box className={dirty && focused ? `${classes.cellContent} focused changed` : focused ? `${classes.cellContent} focused` : `${classes.cellContent}`}>
          <Box className={colorCodePricesBelowCost(updatedValue, cost) ? classes.cellDisplayValueBelowCost : classes.cellDisplayValue}>{formatCurrency(updatedValue)}</Box>
          <BNButton variant="default" size="xs" px={3} className={`${classes.cancelButton} cancel-button`} onClick={() => onCancel()}>
            <CloseIcon size={22} />
          </BNButton>
          <BNNumberInput
            ref={inputRef}
            size="xs"
            decimalScale={2}
            fixedDecimalScale
            min={0}
            error={isDrasticChangeLabel ? 'Drastic price change' : undefined}
            errorVariant="warning"
            errorWithinPortal
            value={updatedValue}
            selectOnFocus
            tabIndex={pricerStatusId !== BarkerCoreEnumsPricerStatus.None ? -1 : 0}
            className={cx(
              classes.input,
              'cellInput',
              (pricerStatusId === BarkerCoreEnumsPricerStatus.None || pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled) && classes.hoverFocus,
            )}
            onChange={(_value) => {
              setUpdatedValue(_value || 0);
              if (onChangeExternal) {
                onChangeExternal(tenantId, listingId, _value || 0, value);
              }
              if (value === _value) {
                setDirty(false);
              } else {
                setDirty(true);
              }
            }}
            onKeyUp={(e) => {
              const _value = parseFloat(e.currentTarget.value);
              if (e.key === 'Enter') {
                setUpdatedValue(_value);
                if (!isDrasticChange(_value, value, cost)) {
                  updatePrice(tenantId, listingId, _value, value);
                } else {
                  onSave();
                }
              } else if (e.key === 'Escape') {
                onCancel();
              } else if (_value === value) {
                setDirty(false);
              } else {
                if (!isDrasticChange(_value, value, cost)) {
                  hideDrasticLabel();
                } else {
                  showDrasticLabel();
                }
                setDirty(true);
              }
            }}
            onFocus={() => {
              setFocused(true);
              document.dispatchEvent(new CustomEvent<PriceDisplayFocusDetail>(PRICEDISPLAY_EVENT_NAME, { detail: { listingId } }));
            }}
            leftSection={<Box>$</Box>}
            label={undefined}
          />

          {!isDrasticChangeDialog && (
            <BNButton variant="filled" color="green" size="xs" px={3} pos="absolute" right={-32} className={`${classes.checkButton} check-button`} onClick={() => onSave()}>
              <CheckIcon size={22} />
            </BNButton>
          )}
          {isDrasticChangeDialog && (
            <Popover
              width={220}
              position="bottom"
              withArrow
              withinPortal
              onClose={() => {
                return close();
              }}
              shadow="md"
              opened={isDrasticChangeDialog}
            >
              <Popover.Target>
                <BNButton variant="filled" color="green" size="xs" px={3} className={`${classes.checkButton} check-button`} onClick={() => onSave()}>
                  <CheckIcon size={22} />
                </BNButton>
              </Popover.Target>
              <Popover.Dropdown p={0}>
                <Group wrap="nowrap" p={12} align="start" gap={8}>
                  <Box>
                    <WarningIcon color="var(--colors-yellow-warning)" />
                  </Box>
                  <Box className={classes.flex1}>
                    {/* Title */}
                    <Text size="xs" fw="bold">
                      {value === 0 ? 'Priced Below Cost' : 'Large Price Change'}
                    </Text>
                    {/* Description */}
                    <Group gap={0}>
                      <Text size="xs">{formatCurrency(value)}</Text>
                      <ArrowForwardIcon color="var(--colors-gray-4)" />
                      <Text size="xs">{formatCurrency(updatedValue)}</Text>
                    </Group>
                    {/* Secondary Description */}
                    <Text fz={11} c="gray.5">
                      {value === 0 ? `Cost is ${formatCurrency(cost)}` : `${Math.abs(percentChange)}% ${percentChange > 0 ? 'Increase' : 'Decrease'}`}
                    </Text>
                  </Box>
                </Group>
                <Divider color="var(--colors-divider)" />
                {/* Dropdown buttons */}
                <Button.Group>
                  <BNButton
                    onClick={() => close()}
                    radius={0}
                    fullWidth
                    variant="subtle"
                    size="xs"
                    aria-label="Inline price cancel"
                    maw="50%"
                    style={{ borderRight: '1px solid var(--colors-divider)' }}
                  >
                    Cancel
                  </BNButton>
                  <Group wrap="nowrap" gap={0} className={classes.flex1}>
                    <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => onConfirmSave()} aria-label="Inline price confirm">
                      Confirm
                    </BNButton>
                    {/* Button wrapped in a menu to allow for snooze & extra options */}
                    {allowSnooze && (
                      <Menu withinPortal={false}>
                        <Menu.Target>
                          <ActionIcon radius={0} h="100%" style={{ borderLeft: '1px solid var(--colors-divider)' }}>
                            <ArrowDropdownIcon />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item fz="xs" onClick={() => snooze('hour')}>
                            Confirm & snooze for 1 hour
                          </Menu.Item>
                          <Menu.Item fz="xs" onClick={() => snooze('day')}>
                            Confirm & snooze for 1 day
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    )}
                  </Group>
                </Button.Group>
              </Popover.Dropdown>
            </Popover>
          )}
        </Box>
      )}
    </Box>
  );
};
