import yasml from '@thirtytech/yasml';
import { v4 as uuid } from 'uuid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import {
  markListingsAtom as markListingIdsAtom,
  mergedEventListingsUnfilteredRulesAtom,
  pendingListingUpdatesAtom,
  ruleStateAtom,
  selectedMergedListingAtom,
  selectedMergedListingLastRuleTierAtom,
  showBulkOptionsAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from './atoms';
import {
  BarkerCoreEnumsAdjustmentType,
  BarkerCoreEnumsPointOfSale,
  BarkerCoreEnumsPricerStatus,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsInventoryUpdateListingsRequestBulkItem,
  BarkerCoreModelsPricingRule,
  BarkerCoreModelsPricingUpdateRulesRequestBulk,
  getApiInventoryListingsListingId,
  getApiPricingRulesRuleId,
  getGetApiPricingRulesRuleIdQueryKey,
  postApiPricingRulesRuleIdSplit,
  putApiInventoryListingsBulk,
  putApiPricingRulesBulk,
} from '../api';
import { useGlobalState } from './Global.state';
import { useRuleState } from './Rule.state';
import { ListingUpdate, PriceVariation } from '../types';
import { openConfirmModal } from '@mantine/modals';
import { Text } from '@mantine/core';
import { queryClient } from './api-config';
import { useQueries } from '@tanstack/react-query';
import { TenantIdListingId } from '../models/tenantIdListingId';
import { formatCurrencyNumber } from '../utils/formatters';
import { notifications } from '@mantine/notifications';
import { useIsDrasticChange } from '../utils/price-utils';
import { isContinuousSeatingPattern } from '../utils/seating-utils';
import { convertSplitsModelToPointOfSaleModel, getPointOfSaleSplitOptions, SplitType } from '../components/Splits/Splits.utils';

function BulkState() {
  const { currentUser, tenants } = useGlobalState('currentUser', 'tenants');
  const { resetForm, applyRuleToGroupedListings, processPendingUpdates } = useRuleState('resetForm', 'applyRuleToGroupedListings', 'processPendingUpdates');
  const [markedListingIds, setMarkedListingIds] = useAtom(markListingIdsAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const lastRuleTier = useAtomValue(selectedMergedListingLastRuleTierAtom);
  const allListings = useAtomValue(mergedEventListingsUnfilteredRulesAtom);
  const [showBulkOptions, setShowBulkOptions] = useAtom(showBulkOptionsAtom);
  const setRule = useSetAtom(ruleStateAtom);
  const setPendingUpdates = useSetAtom(pendingListingUpdatesAtom);
  const updateListing = useSetAtom(updateListingsAtom);
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false);

  const markedListings = useMemo(() => allListings.filter((x) => markedListingIds.includes(x.tenantIdListingId)), [allListings, markedListingIds]);

  const allMarkedListings = useMemo(() => markedListings.concat(selectedListing ? [selectedListing] : []), [markedListings, selectedListing]);
  const distinctTenantIds = useMemo(() => [...new Set(allMarkedListings.map((x) => x.tenantId))], [allMarkedListings]);
  const distinctTenantPointsOfSale = useMemo(
    () => [...new Set(distinctTenantIds.map((tenantId) => tenants?.find((x) => x.tenantId === tenantId)?.pointOfSaleId))],
    [distinctTenantIds, tenants],
  );

  const isPricingEnabled = useMemo(() => allMarkedListings.every((x) => x.pricerStatusId === 'None' || x.pricerStatusId === 'Scheduled'), [allMarkedListings]);

  const splitOptions = useMemo(() => {
    if (distinctTenantPointsOfSale.length !== 1) {
      return [];
    }

    const pointOfSale = distinctTenantPointsOfSale[0];

    if (!pointOfSale) {
      return [];
    }

    return getPointOfSaleSplitOptions(pointOfSale);
  }, [distinctTenantPointsOfSale]);

  const isAutoPricingEnabled = useMemo(() => allMarkedListings.every((x) => x.pricerStatusId !== 'None' && x.pricerStatusId !== 'Scheduled'), [allMarkedListings]);

  const isAddToGroupEnabled = useMemo(() => {
    const listingEventsIds = [...new Set([...allMarkedListings.map((x) => x.tenantIdEventId)])];
    const autoPricingListings = allMarkedListings.filter((x) => x.pricerStatusId !== 'None').map((x) => x.pricerStatusId);

    if (selectedListing) {
      if (selectedListing.pricerStatusId === 'None') {
        return false;
      }
      if (autoPricingListings.length > 1) {
        return false;
      }
      if (listingEventsIds.length > 1) {
        return false;
      }
      if (markedListings.length === 0) {
        return false;
      }
      if (markedListings.some((x) => x.pricerStatusId !== 'None')) {
        return false;
      }
      if (!markedListings.every((x) => x.tenantId === selectedListing.tenantId)) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }, [allMarkedListings, markedListings, selectedListing]);

  const isCreateGroupEnabled = useMemo(() => {
    const listingEventsIds = [...new Set(allMarkedListings.map((x) => x.tenantIdEventId))];
    const autoPricingListings = allMarkedListings.filter((x) => x.pricerStatusId !== 'None').map((x) => x.pricerStatusId);
    if (listingEventsIds.length > 1) {
      return false;
    }
    if (autoPricingListings.length > 0) {
      return false;
    }
    if (markedListings.length === 0) {
      return false;
    }
    return true;
  }, [allMarkedListings, markedListings]);

  const calculatePriceVariation = useCallback((variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, variationPrice: number, listingPrice: number) => {
    let result = listingPrice;
    switch (variation) {
      case 'Set To':
        result = variationPrice;
        break;
      case 'Increase by':
        if (adjustmentType === 'Percentage') {
          result += listingPrice * (variationPrice / 100);
        } else {
          result += variationPrice;
        }
        break;
      case 'Decrease by':
        if (adjustmentType === 'Percentage') {
          result -= listingPrice * (variationPrice / 100);
        } else {
          result -= variationPrice;
        }
        break;
    }

    return formatCurrencyNumber(result > 0 ? result : 0);
  }, []);

  const updateInventoryListing = useSetAtom(updateListingsAtom);

  const onPricingCloseResetPending = useCallback(() => {
    const tenantListingIds = [...allMarkedListings.map((x) => x.tenantIdListingId)];
    setPendingUpdates((prev) => [
      ...prev.filter((x) => {
        const found = tenantListingIds.find(
          (tenantListingId) => tenantListingId === TenantIdListingId.fromString(`${x.tenantId}|${x.listingId}`).toString() && x.property === 'unitPrice',
        );
        if (found) {
          return false;
        }
        return true;
      }),
    ]);
  }, [allMarkedListings, setPendingUpdates]);

  const updatePendingPrice = useCallback(
    async (variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, price: number | '') => {
      const tenantListingIds = allMarkedListings.map((x) => x.tenantIdListingId);
      const listingsToUpdate = allMarkedListings.filter((x) => tenantListingIds.includes(x.tenantIdListingId));
      const pendingUpdates =
        price !== ''
          ? (listingsToUpdate
              .map((listing) => {
                if (variation !== 'Set To' && price === 0) {
                  return null;
                }
                const newPrice = calculatePriceVariation(variation, adjustmentType, price, listing.unitPrice);
                if (newPrice !== listing.unitPrice) {
                  return {
                    listingId: listing.listingId,
                    tenantId: listing.tenantId,
                    property: 'unitPrice',
                    value: newPrice,
                    previousValue: listing.unitPrice,
                  } satisfies ListingUpdate;
                }
                return null;
              })
              .filter((x) => x !== null) as ListingUpdate[])
          : [];

      setPendingUpdates((prev) => [
        ...prev.filter((x) => {
          const found = tenantListingIds.find((listingId) => listingId === TenantIdListingId.fromString(`${x.tenantId}|${x.listingId}`).toString() && x.property === 'unitPrice');
          if (found) {
            return false;
          }
          return true;
        }),
        ...pendingUpdates,
      ]);
    },
    [allMarkedListings, calculatePriceVariation, setPendingUpdates],
  );

  const _listings = useMemo(
    () => allListings.filter((x) => allMarkedListings.map((y) => y.tenantIdListingId).includes(x.tenantIdListingId)).filter((x) => x.ruleId),
    [allListings, allMarkedListings],
  );
  const _ruleIds = useMemo(
    () => (showBulkOptions && isAutoPricingEnabled ? [...new Set([..._listings.filter((x) => x.ruleId).map((x) => ({ ruleId: x.ruleId!, tenantId: x.tenantId }))])] : []),
    [showBulkOptions, isAutoPricingEnabled, _listings],
  );

  const results = useQueries({
    queries: _ruleIds.map(({ ruleId, tenantId }) => ({
      enabled: showBulkOptions && isAutoPricingEnabled,
      queryKey: getGetApiPricingRulesRuleIdQueryKey(ruleId),
      queryFn: () =>
        getApiPricingRulesRuleId(ruleId, {
          headers: {
            'x-tenant-id': tenantId,
          },
        }),
      // Cache 10 minutes
      staleTime: 1000 * 60 * 10,
    })),
  });

  const selectedRules = results.map((x) => x.data?.data).filter((x) => x !== undefined) as BarkerCoreModelsPricingRule[];
  const selectedRulesMarketplaces = new Set(selectedRules.map((x) => x.marketplaceId));
  const isMultipleMarketplacesSelected = selectedRulesMarketplaces.size > 1 && selectedRulesMarketplaces.has('Ticketmaster');
  const isDrasticChange = useIsDrasticChange(allMarkedListings[0]?.tenantId);

  const validatePriceChange = useCallback(
    (variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, price: number) => {
      const tenantListingIds = allMarkedListings.map((x) => x.tenantIdListingId);
      const listingsToUpdate = allListings.filter((x) => tenantListingIds.includes(x.tenantIdListingId));
      const thresholdExceeded = listingsToUpdate.some((listing) =>
        isDrasticChange(calculatePriceVariation(variation, adjustmentType, price, listing.unitPrice), listing.unitPrice!, listing.unitCost!),
      );

      return thresholdExceeded;
    },
    [allListings, allMarkedListings, calculatePriceVariation, isDrasticChange],
  );

  const updatePricing = useCallback(
    async (variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, price: number) => {
      const tenantListingIds = allMarkedListings.map((x) => x.tenantIdListingId);
      const listingsToUpdate = allListings.filter((x) => tenantListingIds.includes(x.tenantIdListingId));
      const pendingUpdates = listingsToUpdate.map(
        (listing) =>
          ({
            tenantId: listing.tenantId,
            listingId: listing.listingId,
            price: {
              unitPrice: calculatePriceVariation(variation, adjustmentType, price, listing.unitPrice),
              previousPrice: listing.unitPrice,
            },
          }) satisfies BarkerCoreModelsInventoryUpdateListingsRequestBulkItem & { tenantId: string },
      );

      // Group pendingUpdates by tenantId
      const groupedUpdates = pendingUpdates.reduce(
        (acc, update) => {
          const { tenantId } = update;
          if (!acc[tenantId]) {
            acc[tenantId] = [];
          }
          acc[tenantId].push(update);
          return acc;
        },
        {} as { [key: string]: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] },
      );

      const findExistingListings = await Promise.all(
        Object.keys(groupedUpdates).flatMap((tenantId) =>
          Object.values(groupedUpdates[tenantId])
            .map((x) => x.listingId)
            .map(async (listingId) =>
              getApiInventoryListingsListingId(listingId, {
                headers: {
                  'x-tenant-id': tenantId,
                },
              })
                .then((x) => ({ listingId: x.status === 200 }))
                .catch(() => ({ listingId: false })),
            ),
        ),
      );

      if (findExistingListings.some((x) => !x.listingId)) {
        // Break early and alert user there is a listing missing
        notifications.show({
          title: 'Error',
          message: 'One or more listings longer exist. Please refresh your search results and try again.',
          color: 'red',
        });
        return;
      }

      const bulkUpdateQueue = [];
      for (const tenantId of Object.keys(groupedUpdates)) {
        bulkUpdateQueue.push(
          putApiInventoryListingsBulk(groupedUpdates[tenantId], {
            headers: {
              'x-tenant-id': tenantId,
            },
          }),
        );
      }

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdateQueue);
      setIsBulkActionLoading(false);

      pendingUpdates.forEach((update) => {
        const listing = allListings.find((x) => x.tenantId === update.tenantId && x.listingId === update.listingId);
        if (listing) {
          updateInventoryListing({ ...listing, pricedBy: currentUser?.principalId, previousPrice: listing.unitPrice, unitPrice: update.price.unitPrice, pricedAt: new Date() });
        }
      });

      setMarkedListingIds([]);
      setPendingUpdates([]);
      resetForm();
    },
    [allListings, allMarkedListings, calculatePriceVariation, currentUser?.principalId, resetForm, setMarkedListingIds, setPendingUpdates, updateInventoryListing],
  );

  const disableAutoPricing = useCallback(async () => {
    openConfirmModal({
      title: 'Disable Auto-Pricer?',
      children: <Text size="sm">Are you sure you want to disable the auto-pricer for these listings? This will also disband any groups.</Text>,
      labels: { confirm: 'Yes', cancel: 'No' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      onConfirm: async () => {
        const bulkUpdateInventory: BarkerCoreModelsInventoryListing[] = [];
        const ruleIds = [...new Set(allMarkedListings.map((x) => x.ruleId).filter(Boolean))] as string[];
        const ruleIdListings = allListings
          .filter((x) => x.ruleId && ruleIds.includes(x.ruleId))
          .reduce(
            (acc, listing) => {
              if (listing.ruleId) {
                if (acc[listing.ruleId]) {
                  acc[listing.ruleId].push(listing);
                } else {
                  acc[listing.ruleId] = [listing];
                }
              }
              return acc;
            },
            {} as { [key: string]: BarkerCoreModelsInventoryListing[] },
          );

        // Group updates by tenantId using ruleIdListings object
        const groupedUpdates = Object.keys(ruleIdListings).reduce(
          (acc, ruleId) => {
            const { tenantId } = ruleIdListings[ruleId][0];
            if (!acc[tenantId]) {
              acc[tenantId] = [];
            }
            acc[tenantId].push({ ruleId, isAutoPriced: false });
            return acc;
          },
          {} as { [key: string]: BarkerCoreModelsPricingUpdateRulesRequestBulk[] },
        );

        // Check all tenants that the listings still exist
        const findExistingListings = await Promise.all(
          Object.keys(groupedUpdates).flatMap((tenantId) =>
            Object.values(groupedUpdates[tenantId])
              .map((x) => x.ruleId)
              .flatMap((r) => ruleIdListings[r])
              .map((x) => x.listingId)
              // .concat('123') // Add listingId 123 to the array for testing purposes
              .map(async (listingId) =>
                getApiInventoryListingsListingId(listingId, {
                  headers: {
                    'x-tenant-id': tenantId,
                  },
                })
                  .then((x) => ({ listingId: x.status === 200 }))
                  .catch(() => ({ listingId: false })),
              ),
          ),
        );

        if (findExistingListings.some((x) => !x.listingId)) {
          // Break early and alert user there is a listing missing
          notifications.show({
            title: 'Error',
            message: 'One or more listings no longer exist. Please refresh your search results and try again.',
            color: 'red',
          });
          return;
        }

        for await (const tenantId of Object.keys(groupedUpdates)) {
          const updates = groupedUpdates[tenantId];

          await putApiPricingRulesBulk(updates, { headers: { 'x-tenant-id': tenantId } });

          for await (const { ruleId } of Object.values(updates)) {
            const _listingsMatchingRuleId = ruleIdListings[ruleId];
            const splitRuleResult = await postApiPricingRulesRuleIdSplit(
              ruleId,
              {
                listingIds: _listingsMatchingRuleId.map((x) => x.listingId),
              },
              { headers: { 'x-tenant-id': tenantId } },
            );

            const updatedListings = _listingsMatchingRuleId.map((listing) => {
              const _listingWithUpdatedRuleId = { ...listing };
              _listingWithUpdatedRuleId.ruleId = splitRuleResult.data.find((x) => x.listingId === listing.listingId)!.ruleId;
              _listingWithUpdatedRuleId.pricerStatusId = BarkerCoreEnumsPricerStatus.None;
              _listingWithUpdatedRuleId.floorPrice = null;
              _listingWithUpdatedRuleId.ceilingPrice = null;

              return _listingWithUpdatedRuleId;
            });
            bulkUpdateInventory.push(...updatedListings);
          }
        }

        updateInventoryListing(bulkUpdateInventory);
      },
    });
  }, [allListings, allMarkedListings, updateInventoryListing]);

  const _editAutoPricerFloorOrCeiling = useCallback(
    async (type: 'floorPrice' | 'ceilingPrice', variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, price: number | '') => {
      if (price === '') return; // Used for type safety. Should never happen.

      const rulesWithValues = allMarkedListings.map(
        (listing) =>
          ({
            tenantId: listing.tenantId,
            ruleId: listing.ruleId,
            [type]: listing[type],
          }) as { tenantId: string; ruleId: string } & { [K in typeof type]?: (typeof listing)[K] },
      );
      const updates = rulesWithValues.map((v) => {
        let calcValue = calculatePriceVariation(variation, adjustmentType, price, type === 'floorPrice' ? v.floorPrice || 0 : v.ceilingPrice || 0);
        const companySettings = tenants?.find((t) => t.tenantId === v.tenantId)?.settings?.pricerSettings;
        if (companySettings?.minimumFloorPrice && type === 'floorPrice' && calcValue < companySettings.minimumFloorPrice) {
          calcValue = companySettings.minimumFloorPrice;
        }
        return {
          ruleId: v.ruleId,
          tenantId: v.tenantId,
          [type]: calcValue,
        };
      });

      // Group updates by tenantId
      const groupedUpdates = updates.reduce(
        (acc, update) => {
          acc[update.tenantId] = acc[update.tenantId] || [];
          acc[update.tenantId].push(update);
          return acc;
        },
        {} as { [key: string]: BarkerCoreModelsPricingUpdateRulesRequestBulk[] },
      );

      // Update rules
      const bulkUpdateQueue = [];
      for (const tenantId of Object.keys(groupedUpdates)) {
        bulkUpdateQueue.push(putApiPricingRulesBulk(groupedUpdates[tenantId], { headers: { 'x-tenant-id': tenantId } }));
      }

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdateQueue);
      setIsBulkActionLoading(false);

      if (selectedListing?.ruleId) {
        const { data: updatedRule } = await getApiPricingRulesRuleId(selectedListing?.ruleId, {
          headers: {
            'x-tenant-id': selectedListing?.tenantId,
          },
        });

        queryClient.setQueryData(getGetApiPricingRulesRuleIdQueryKey(selectedListing?.ruleId), updatedRule);
        setRule(updatedRule, { forceUpdate: true, currentListingId: selectedListing?.tenantIdListingId });
      }

      resetForm();

      const listingPricerStatusUpdates: (BarkerCoreModelsInventoryUpdateListingsRequestBulkItem & { tenantId: string })[] = [];

      updateInventoryListing(
        allMarkedListings.map((listing) => {
          const calculatedValue = calculatePriceVariation(variation, adjustmentType, price, listing.unitPrice);
          let pricerStatusId: BarkerCoreEnumsPricerStatus;

          if (type === 'floorPrice' && listing.unitPrice <= calculatedValue) {
            pricerStatusId = BarkerCoreEnumsPricerStatus.AtFloor;
          } else if (type === 'ceilingPrice' && listing.unitPrice >= calculatedValue) {
            pricerStatusId = BarkerCoreEnumsPricerStatus.AtCeiling;
          } else {
            pricerStatusId = BarkerCoreEnumsPricerStatus.AutoPriced;
          }

          listingPricerStatusUpdates.push({
            listingId: listing.listingId,
            tenantId: listing.tenantId,
            pricerStatusId,
          });

          return {
            ...listing,
            [type]: calculatedValue,
            pricerStatusId,
          };
        }),
      );

      const groupedPricerStatusUpdates: { [key: string]: BarkerCoreModelsInventoryUpdateListingsRequestBulkItem[] } = {};
      for (const update of listingPricerStatusUpdates) {
        const { tenantId } = update;
        groupedPricerStatusUpdates[tenantId] = groupedPricerStatusUpdates[tenantId] || [];
        groupedPricerStatusUpdates[tenantId].push(update);
      }

      const bulkUpdatePricerStatusQueue = Object.keys(groupedPricerStatusUpdates).map((tenantId) =>
        putApiInventoryListingsBulk(groupedPricerStatusUpdates[tenantId], {
          headers: {
            'x-tenant-id': tenantId,
          },
        }),
      );

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdatePricerStatusQueue);
      setIsBulkActionLoading(false);
    },
    [
      allMarkedListings,
      calculatePriceVariation,
      resetForm,
      selectedListing?.ruleId,
      selectedListing?.tenantId,
      selectedListing?.tenantIdListingId,
      setRule,
      tenants,
      updateInventoryListing,
    ],
  );

  // Being clever with bind to avoid having to pass the type argument. This functionally works but the type safety is lost. Need to figure it out.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editAutoPricerFloor = useCallback(_editAutoPricerFloorOrCeiling.bind(null, 'floorPrice'), [_editAutoPricerFloorOrCeiling]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editAutoPricerCeiling = useCallback(_editAutoPricerFloorOrCeiling.bind(null, 'ceilingPrice'), [_editAutoPricerFloorOrCeiling]);

  const editAutoPricerAdjustments = useCallback(
    async (variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, price: number | '') => {
      if (price === '') return; // Used for type safety. Should never happen.

      const rulesWithValues = allMarkedListings.map(
        (listing) =>
          ({
            tenantId: listing.tenantId,
            ruleId: listing.ruleId,
            adjustment: { type: adjustmentType, value: price },
          }) as { tenantId: typeof listing.tenantId } & BarkerCoreModelsPricingUpdateRulesRequestBulk,
      );

      // Group by tenantId
      const groupedUpdates = rulesWithValues.reduce(
        (acc, update) => {
          if (acc[update.tenantId]) {
            acc[update.tenantId].push(update);
          } else {
            acc[update.tenantId] = [update];
          }
          return acc;
        },
        {} as { [key: string]: BarkerCoreModelsPricingUpdateRulesRequestBulk[] },
      );

      // Update rules
      const bulkUpdateQueue = [];
      for (const tenantId of Object.keys(groupedUpdates)) {
        bulkUpdateQueue.push(putApiPricingRulesBulk(groupedUpdates[tenantId], { headers: { 'x-tenant-id': tenantId } }));
      }

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdateQueue);
      setIsBulkActionLoading(false);

      if (selectedListing?.ruleId) {
        const { data: updatedRule } = await getApiPricingRulesRuleId(selectedListing?.ruleId, {
          headers: {
            'x-tenant-id': selectedListing?.tenantId,
          },
        });
        queryClient.setQueryData(getGetApiPricingRulesRuleIdQueryKey(selectedListing?.ruleId), updatedRule);
        setRule(updatedRule, { forceUpdate: true, currentListingId: selectedListing?.tenantIdListingId });
      }
      resetForm();
    },
    [allMarkedListings, selectedListing?.ruleId, selectedListing?.tenantId, selectedListing?.tenantIdListingId, resetForm, setRule],
  );

  const editComparables = useCallback(
    async (comparables: number) => {
      const listings = allListings.filter((x) => markedListingIds.includes(x.tenantIdListingId) || x.tenantIdListingId === selectedListing?.tenantIdListingId);

      const seen = new Set();
      const uniqueCombinations = listings.filter((item) => {
        const key = `${item.tenantId}-${item.ruleId}`;
        if (!seen.has(key)) {
          seen.add(key);
          return true;
        }
        return false;
      });

      const updates = uniqueCombinations.map(
        (listing) =>
          ({
            tenantId: listing.tenantId,
            ruleId: listing.ruleId,
            numComparables: comparables,
          }) as { tenantId: typeof listing.tenantId } & BarkerCoreModelsPricingUpdateRulesRequestBulk,
      );

      // Group updates by tenantId
      const groupedUpdates = updates.reduce(
        (acc, update) => {
          if (acc[update.tenantId]) {
            acc[update.tenantId].push(update);
          } else {
            acc[update.tenantId] = [update];
          }
          return acc;
        },
        {} as { [key: string]: BarkerCoreModelsPricingUpdateRulesRequestBulk[] },
      );

      // Update rules
      const bulkUpdateQueue = [];
      for (const tenantId of Object.keys(groupedUpdates)) {
        bulkUpdateQueue.push(putApiPricingRulesBulk(groupedUpdates[tenantId], { headers: { 'x-tenant-id': tenantId } }));
      }

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdateQueue);
      setIsBulkActionLoading(false);

      if (selectedListing?.ruleId) {
        const { data: updatedRule } = await getApiPricingRulesRuleId(selectedListing?.ruleId, {
          headers: {
            'x-tenant-id': selectedListing?.tenantId,
          },
        });

        queryClient.setQueryData(getGetApiPricingRulesRuleIdQueryKey(selectedListing?.ruleId), updatedRule);

        setRule(updatedRule, { forceUpdate: true, currentListingId: selectedListing?.tenantIdListingId });
      }
      resetForm();
    },
    [allListings, markedListingIds, resetForm, selectedListing?.ruleId, selectedListing?.tenantId, selectedListing?.tenantIdListingId, setRule],
  );

  const addToGroup = useCallback(() => {
    if (selectedListing) {
      const foundListings = allListings.filter((x) => markedListingIds.includes(x.tenantIdListingId));
      const ruleId = selectedListing?.ruleId;
      setPendingUpdates((prev) => [
        ...prev,
        {
          listingId: selectedListing?.listingId,
          tenantId: selectedListing?.tenantId,
          property: 'ruleTier',
          value: 0,
          previousValue: selectedListing?.ruleTier,
        },
      ]);
      foundListings.forEach((listing, i) => {
        const _listing = { ...listing };
        setPendingUpdates((prev) => [
          ...prev,
          {
            listingId: listing.listingId,
            tenantId: listing.tenantId,
            property: 'ruleId',
            value: ruleId,
            previousValue: listing.ruleId,
          },
          {
            listingId: listing.listingId,
            tenantId: listing.tenantId,
            property: 'ruleTier',
            value: i + (lastRuleTier || 0) + 10,
            previousValue: listing.ruleTier,
          },
        ]);
        _listing.ruleId = ruleId;
        _listing.ruleTier = i + (lastRuleTier || 0) + 10;
        updateListing(_listing);
        setTransientGlobalState((s) => ({ ...s, expandGroupByListingId: selectedListing?.tenantIdListingId! }));
        setMarkedListingIds([]);
        applyRuleToGroupedListings();
      });
    }
  }, [selectedListing, allListings, markedListingIds, setPendingUpdates, lastRuleTier, updateListing, setTransientGlobalState, setMarkedListingIds, applyRuleToGroupedListings]);

  const createGroup = useCallback(() => {
    const newRuleId = selectedListing?.ruleId || uuid();
    if (!selectedListing?.ruleId) {
      setRule((rule) => ({ ...rule, ruleId: newRuleId, isAutoPriced: true }));
    } else {
      setRule((prev) => ({ ...prev, isAutoPriced: true }));
    }
    setShowBulkOptions(false);

    const foundListings = allListings.filter((x) => markedListingIds.includes(x.tenantIdListingId) || x.tenantIdListingId === selectedListing?.tenantIdListingId);

    const updateListings = foundListings.map((listing, i) => {
      const _listing = { ...listing };
      const ruleTier = selectedListing?.tenantIdListingId === listing.tenantIdListingId ? 0 : (i + 1) * 10;
      setPendingUpdates((prev) => [
        ...prev,
        {
          listingId: listing.listingId,
          tenantId: listing.tenantId,
          property: 'ruleId',
          value: newRuleId,
          previousValue: listing.ruleId,
        },
        {
          listingId: listing.listingId,
          tenantId: listing.tenantId,
          property: 'ruleTier',
          value: ruleTier,
          previousValue: listing.ruleTier,
        },
        {
          listingId: listing.listingId,
          tenantId: listing.tenantId,
          property: 'pricerStatusId',
          value: BarkerCoreEnumsPricerStatus.AutoPriced,
          previousValue: listing.pricerStatusId,
        },
      ]);
      _listing.ruleId = newRuleId;
      _listing.ruleTier = ruleTier;
      _listing.pricerStatusId = BarkerCoreEnumsPricerStatus.AutoPriced;
      return _listing;
    });
    updateListing([...updateListings]);
    setTransientGlobalState((s) => ({
      ...s,
      autoUpdateAutoPricedListPriceRuleId: newRuleId,
      expandGroupByListingId: selectedListing?.tenantIdListingId!,
    }));
    setMarkedListingIds([]);
  }, [
    allListings,
    markedListingIds,
    selectedListing?.ruleId,
    selectedListing?.tenantIdListingId,
    setMarkedListingIds,
    setPendingUpdates,
    setRule,
    setShowBulkOptions,
    setTransientGlobalState,
    updateListing,
  ]);

  const removeRule = useCallback(() => {
    const foundListings = allListings.filter((x) => markedListingIds.includes(x.tenantIdListingId) || x.tenantIdListingId === selectedListing?.tenantIdListingId);
    const updates: ReturnType<typeof pendingListingUpdatesAtom.read> = foundListings.flatMap((listing) => [
      {
        listingId: listing.listingId,
        tenantId: listing.tenantId,
        property: 'ruleId',
        value: null,
        previousValue: listing.ruleId,
      },
      {
        listingId: listing.listingId,
        tenantId: listing.tenantId,
        property: 'ruleTier',
        value: null,
        previousValue: listing.ruleTier,
      },
    ]);
    processPendingUpdates(updates, true);
  }, [allListings, markedListingIds, processPendingUpdates, selectedListing?.tenantIdListingId]);

  const isPosNext = useCallback(
    (tenantId: string) => {
      const tenantPointOfSale = tenants?.find((tenant) => tenant.tenantId === tenantId)?.pointOfSaleId ?? BarkerCoreEnumsPointOfSale.Unknown;
      return tenantPointOfSale === BarkerCoreEnumsPointOfSale.PosNext;
    },
    [tenants],
  );

  const isMergeEnabled = useMemo(() => {
    if (selectedListing && isPosNext(selectedListing.tenantId)) {
      return false;
    }
    const listingIds = new Set();
    const selectedListings = [...markedListings, selectedListing].filter(Boolean).filter((listing) => {
      if (!listingIds.has(listing.tenantIdListingId)) {
        listingIds.add(listing.tenantIdListingId);
        return true;
      }
      return false;
    });

    const moreThanOneListing = selectedListings.length > 1;

    if (!moreThanOneListing) {
      return false;
    }

    const sameTenantId = markedListings.every((listing) => listing.tenantId === markedListings[0].tenantId);
    const sameEvent = markedListings.every((listing) => listing.event === markedListings[0].event);
    const sameSection = markedListings.every((listing) => listing.section === markedListings[0].section);
    const sameRow = markedListings.every((listing) => listing.row === markedListings[0].row);
    const isConsecutiveSeating = isContinuousSeatingPattern(selectedListings);

    return moreThanOneListing && sameTenantId && sameSection && sameRow && sameEvent && isConsecutiveSeating;
  }, [isPosNext, markedListings, selectedListing]);

  const editSplits = useCallback(
    async (splitType: SplitType) => {
      if (!selectedListing || !tenants || !tenants.length) {
        return;
      }

      const listingIdsToUpdate = markedListingIds.concat([selectedListing.tenantIdListingId]);

      if (!listingIdsToUpdate.length) {
        return;
      }

      const groupedUpdates = listingIdsToUpdate.reduce(
        (acc, listingId) => {
          const tenantId = listingId.split('|')[0];
          acc[tenantId] = acc[tenantId] || [];
          acc[tenantId].push(listingId);
          return acc;
        },
        {} as { [key: string]: string[] },
      );

      const bulkUpdateQueue = Object.keys(groupedUpdates).map((tenantId) =>
        putApiInventoryListingsBulk(
          groupedUpdates[tenantId].map((listingId) => ({
            listingId: listingId.split('|')[1],
            splits:
              convertSplitsModelToPointOfSaleModel(tenants.find((tenant) => tenant.tenantId === tenantId)?.pointOfSaleId ?? BarkerCoreEnumsPointOfSale.Unknown, splitType) ?? {},
          })),
          {
            headers: {
              'x-tenant-id': tenantId,
            },
          },
        ),
      );

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdateQueue);
      setIsBulkActionLoading(false);

      (window as any)?.gridRef?.api.flashCells({ rowNodes: listingIdsToUpdate.map((listingId) => (window as any)?.gridRef?.api.getRowNode(listingId)) });

      const updatedListings = allListings
        .filter((listing) => listingIdsToUpdate.includes(listing.tenantIdListingId))
        .map((listing) => ({
          ...listing,
          splits: splitType,
        }));

      updateInventoryListing(updatedListings);
    },
    [selectedListing, markedListingIds, allListings, updateInventoryListing, tenants],
  );

  const editBroadcasting = useCallback(
    async (broadcast: boolean) => {
      if (!selectedListing) {
        return;
      }

      const listingIdsToUpdate = markedListingIds.concat([selectedListing.tenantIdListingId]);

      if (!listingIdsToUpdate.length) {
        return;
      }

      const groupedUpdates = listingIdsToUpdate.reduce(
        (acc, listingId) => {
          const tenantId = listingId.split('|')[0];
          acc[tenantId] = acc[tenantId] || [];
          acc[tenantId].push(listingId);
          return acc;
        },
        {} as { [key: string]: string[] },
      );

      const bulkUpdateQueue = Object.keys(groupedUpdates).map((tenantId) =>
        putApiInventoryListingsBulk(
          groupedUpdates[tenantId].map((listingId) => ({
            listingId: listingId.split('|')[1],
            isBroadcasting: broadcast,
          })),
          {
            headers: {
              'x-tenant-id': tenantId,
            },
          },
        ),
      );

      setIsBulkActionLoading(true);
      await Promise.all(bulkUpdateQueue);
      setIsBulkActionLoading(false);

      (window as any)?.gridRef?.api.flashCells({ rowNodes: listingIdsToUpdate.map((listingId) => (window as any)?.gridRef?.api.getRowNode(listingId)) });

      const updatedListings = allListings
        .filter((listing) => listingIdsToUpdate.includes(listing.tenantIdListingId))
        .map((listing) => ({
          ...listing,
          isBroadcasting: broadcast,
        }));

      updateInventoryListing(updatedListings);
    },
    [selectedListing, markedListingIds, allListings, updateInventoryListing],
  );

  return {
    isPricingEnabled,
    isAutoPricingEnabled,
    isAddToGroupEnabled,
    isCreateGroupEnabled,
    isMergeEnabled,
    splitOptions,
    updatePricing,
    addToGroup,
    markedListings: markedListingIds,
    setMarkedListings: setMarkedListingIds,
    showBulkOptions,
    setShowBulkOptions,
    createGroup,
    updatePendingPrice,
    onPricingCloseResetPending,
    validatePriceChange,
    disableAutoPricing,
    editAutoPricerAdjustments,
    editAutoPricerCeiling,
    editAutoPricerFloor,
    isMultipleMarketplacesSelected,
    removeRule,
    editComparables,
    selectedListing,
    editBroadcasting,
    editSplits,
    isBulkActionLoading,
    allMarkedListings,
    distinctTenantPointsOfSale,
  };
}

export const { Provider: BulkStateProvider, useSelector: useBulkState } = yasml(BulkState);
