import { Box, Group, Modal, ScrollArea } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import parse from 'html-react-parser';
import { useGetApiPrincipalsBulletins, usePutApiPrincipalsBulletinsBulletinIdAcknowledge } from '../../api';
import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { auth } from '../../data/atoms.auth';
import classes from './BulletinModal.module.css';

export function BulletinModal() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { data: bulletins, refetch: refetchBulletins } = useGetApiPrincipalsBulletins({
    query: {
      enabled: !!apiToken,
      select(data) {
        return data.data;
      },
      refetchOnWindowFocus: true,
    },
  });

  const { mutateAsync: acknowledgeBulletin, isPending: isMutateLoading } = usePutApiPrincipalsBulletinsBulletinIdAcknowledge();

  const acknowledge = useCallback(
    async (bulletinId: string) => {
      await acknowledgeBulletin({ bulletinId });
      await refetchBulletins();
    },
    [acknowledgeBulletin, refetchBulletins],
  );

  if (bulletins === undefined || bulletins.length === 0) {
    return null;
  }

  const bulletin = bulletins[0];

  return (
    <Modal opened withCloseButton={false} onClose={() => true} title={bulletin.subject} centered size="xl" className={classes.bulletinModal}>
      <>
        <ScrollArea.Autosize className={classes.scrollArea} mah="55vh">
          <Box px="md" fz="sm" className={classes.bulletinBody}>
            {parse(bulletin.body)}
          </Box>
        </ScrollArea.Autosize>
        <Group align="end" justify="right" px="md" mt="1rem" className={classes.bulletinButtonGroup}>
          {bulletin.action?.url && (
            <BNButton size="sm" className={classes.actionButton} variant="default" onClick={() => window.open(bulletin.action!.url, '_blank')}>
              {bulletin.action!.label}
            </BNButton>
          )}
          <BNButton size="sm" variant="filled" color="green" className={classes.actionButton} onClick={() => acknowledge(bulletin.bulletinId)} loading={isMutateLoading}>
            I have read and acknowledge this notice
          </BNButton>
        </Group>
      </>
    </Modal>
  );
}
