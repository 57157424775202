import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { useCallback } from 'react';

export const Auth0ProviderWithNavigate = () => {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate],
  );

  const domain = import.meta.env.VITE_AUTH_DOMAIN;
  const clientId = isDtiHosted ? import.meta.env.VITE_AUTH_DTI_CLIENT_ID : import.meta.env.VITE_AUTH_CLIENT_ID;
  const redirectUri = window.location.origin + '/auth/callback';
  const audience = isDtiHosted ? import.meta.env.VITE_AUTH_DTI_AUDIENCE : import.meta.env.VITE_AUTH_AUDIENCE;

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Outlet />
    </Auth0Provider>
  );
};
