import { Center, Divider, Flex, Popover, Stack, Text } from '@mantine/core';
import MotionPhotosPausedFillIcon from '../../../components/icons/MotionPhotosPausedFill';
import { InventoryOnHoldProps } from '../../Inventory/Inventory.OnHold';
import { useGetDtiItemsAccountId } from '../../../api';
import { useHover } from '@mantine/hooks';
import { BNLoadingState } from '../../../components/Loading/LoadingState';
import { formatDate } from '../../../utils/formatters';
import { DateFormats } from '../../../utils/globals';

export const DTIOnHoldProperty = ({ quantityReserved, quantityRemaining, accountId, listingId }: InventoryOnHoldProps & { accountId: number; listingId: string }) => {
  const { hovered, ref } = useHover();

  const { data: response, isLoading } = useGetDtiItemsAccountId(
    accountId,
    {
      listing_id: listingId,
    },
    {
      query: {
        enabled: hovered,
      },
    },
  );

  const ticketsOnHold = quantityReserved > 0;

  return (
    ticketsOnHold && (
      <Popover opened={hovered} withArrow>
        <Popover.Target>
          <Flex w={22} h={22} align="center" justify="center" c="var(--colors-yellow-warning)" bg="var(--colors-paper)" style={{ borderRadius: 28 }} ref={ref}>
            <MotionPhotosPausedFillIcon size={20} />
          </Flex>
        </Popover.Target>
        <Popover.Dropdown mih={156} w={260}>
          {isLoading ? (
            <BNLoadingState h={126} w="100%" />
            // TODO: Wire up basic empty state below
            // <Center h={126} w="100%" fz="xs">
            //   No Data Found
            // </Center>
          ) : (
            <Stack gap={8}>
              <Flex align="start">
                <Text component="div" miw={68} display="inline-block" size="xs" c="var(--colors-gray-5)">
                  Customer
                </Text>
                <Text flex={1} component="div" c="var(--colors-paperReverse)">
                  {response?.data.items[0].reserve_customer}
                </Text>
              </Flex>
              {response?.data.items[0].reserve_date && (
                <Flex align="start">
                  <Text component="div" miw={68} display="inline-block" size="xs" c="var(--colors-gray-5)">
                    Began
                  </Text>
                  <Text flex={1} component="div" c="var(--colors-paperReverse)">
                    {formatDate(response?.data.items[0].reserve_date, DateFormats.Extended)}
                  </Text>
                </Flex>
              )}
              <Flex align="start">
                <Text component="div" miw={68} display="inline-block" size="xs" c="var(--colors-gray-5)">
                  Expires
                </Text>
                <Text flex={1} component="div" c="var(--colors-paperReverse)">
                  {response?.data.items[0].reserve_expiration ? formatDate(response?.data.items[0].reserve_expiration, DateFormats.Extended) : 'Never'}
                </Text>
              </Flex>
              {response?.data.items[0].reserve_comments && (
                <>
                  <Divider my={2} color="var(--colors-divider)" />
                  <Text component="div" size="xs">
                    {response?.data.items[0].reserve_comments}
                  </Text>
                </>
              )}
            </Stack>
          )}
        </Popover.Dropdown>
      </Popover>
    )
  );
};
