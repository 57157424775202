import { AdjustmentValueInputDiagonal } from '../AdjustmentInput/AdjustmentValueInputDiagonal';
import BNInputGroup from '../Group/InputGroup';
import { BNNumberInput } from '../NumberInput/NumberInput';
import { BNSelect } from '../Select/Select';
import { SimpleGrid } from '@mantine/core';
import { ReactNode } from 'react';
import { BarkerCoreEnumsAdjustmentType, BarkerCoreModelsPricingSoftFloor } from '../../api';

export function SoftFloorInput({
  min,
  disabled,
  floorError,
  softFloor,
  onFloorChange,
  periodError,
  onPeriodChange,
  onSave,
}: {
  min?: number;
  disabled?: boolean;
  floorError?: ReactNode;
  softFloor?: BarkerCoreModelsPricingSoftFloor;
  onFloorChange: (value: { value: number | undefined; type: BarkerCoreEnumsAdjustmentType }) => void;
  periodError?: ReactNode;
  onPeriodChange?: (value: string) => void;
  onSave?: () => void;
}) {
  // Soft floor period logic. Periods are stored as TimeStamps on the backend, which are serialized as: Days.Hours:Minutes:Seconds
  const periodParts = softFloor?.period?.split(':');

  const dayHourParts = periodParts?.[0].split('.');

  const softFloorPeriod = !periodParts || !dayHourParts ? 'Day' : dayHourParts[1] === '00' && periodParts[1] === '00' ? 'Day' : periodParts[1] === '00' ? 'Hr' : 'Min';

  const softFloorPeriodValue =
    !periodParts || !dayHourParts
      ? undefined
      : softFloorPeriod === 'Day'
        ? parseInt(periodParts[0].split('.')[0])
        : softFloorPeriod === 'Hr'
          ? parseInt(dayHourParts[1])
          : parseInt(periodParts[1]);

  return (
    <SimpleGrid cols={2} spacing="sm">
      <AdjustmentValueInputDiagonal
        label="Soft Floor"
        min={min}
        disabled={disabled}
        error={floorError}
        onChange={onFloorChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && onSave) {
            onSave();
          }
        }}
        value={softFloor?.value ?? 0}
        type={softFloor?.typeId ?? 'Amount'}
        hideOverUnder
        step={1.0}
      />
      <BNInputGroup label="Interval" error={periodError}>
        <BNInputGroup.Item>
          <BNNumberInput
            decimalScale={0}
            step={1}
            min={1}
            size="xs"
            label="Interval"
            value={softFloorPeriodValue}
            onChange={(value) => {
              if (!onPeriodChange) return;

              if (value === '') {
                onPeriodChange('0.00:00:00');
              } else {
                onPeriodChange(`${softFloorPeriod === 'Day' ? value : 0}.${softFloorPeriod === 'Hr' ? value : '00'}:${softFloorPeriod === 'Min' ? value : '00'}:00`);
              }
            }}
          />
        </BNInputGroup.Item>
        <BNInputGroup.Item>
          <BNSelect
            data={['Min', 'Hr', 'Day']}
            size="xs"
            value={softFloorPeriod}
            aria-label="Interval Period"
            onChange={(e) => {
              if (!onPeriodChange) return;

              if (e === 'Day') {
                onPeriodChange(`${softFloorPeriodValue ?? 0}.00:00:00`);
              } else if (e === 'Hr') {
                onPeriodChange(`0.${(softFloorPeriodValue ?? 0).toString().padStart(2, '0')}:00:00`);
              } else {
                onPeriodChange(`0.00:${(softFloorPeriodValue ?? 0).toString().padStart(2, '0')}:00`);
              }
            }}
          />
        </BNInputGroup.Item>
      </BNInputGroup>
    </SimpleGrid>
  );
}
