/* eslint-disable react-compiler/react-compiler */
import { useDTIMarketplace } from '../DTI/DTI.Marketplace.hooks';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import classes from '../MarketListings/MarketListings.styles.tsx.module.css';
import { Box, Loader, Stack } from '@mantine/core';
import { useMemo, useRef } from 'react';
import { SalesCard } from './MarketplaceResults.SalesCard';
import { useVirtualizer } from '@tanstack/react-virtual';

export function MarketplaceResultsSalesList() {
  'use no memo';

  const { sales, isFetchingSeatGeek } = useDTIMarketplace('sales', 'isFetchingSeatGeek');
  const parentRef = useRef<HTMLDivElement>(null);
  // const [parentRef, parentRect] = useResizeObserver();

  function CustomNoRowsOverlay() {
    return (
      <BNEmptyState
        h="90%"
        border={false}
        icon={<MobileDataOffIcon color="var(--colors-iconFill)" size={28} />}
        iconBg="var(--colors-opacity-hover)"
        title="No Results Found"
        description="Try adjusting the filter options"
      />
    );
  }

  function CustomLoadingOverlay() {
    return (
      <div className={`${classes.customLoadingOverlay} custom-loading-overlay`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  const Row = useMemo(
    () =>
      ({ index, start, size }: { index: number; start: number; size: number }) => {
        const sale = sales[index];

        return (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${size}px`,
              transform: `translateY(${start}px)`,
            }}
            className={classes.listingCardDeliveryWrapper}
          >
            <SalesCard saleDate={sale.sale_date} quantity={sale.quantity} section={sale.section} row={sale.row ?? 'GA'} price={sale.price} />
          </Box>
        );
      },
    [sales],
  );

  const rowVirtualizer = useVirtualizer({
    count: sales.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 10,
  });

  return (
    <Box className={classes.outerWrapper}>
      <Stack gap={0} className={classes.innerWrapper}>
        <Box className={`${classes.listWrapper} market-results-wrapper`} pos="relative" ref={parentRef} style={{ height: '100%', overflow: 'auto' }}>
          {isFetchingSeatGeek && CustomLoadingOverlay()}
          {!isFetchingSeatGeek && sales.length === 0 && CustomNoRowsOverlay()}
          {!isFetchingSeatGeek && sales.length > 0 && (
            <div
              className="market-results"
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <Row key={virtualItem.index} index={virtualItem.index} size={virtualItem.size} start={virtualItem.start} />
              ))}
            </div>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
