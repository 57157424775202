import { Overlay } from '@mantine/core';
import { useBulkState } from '../../data/Bulk.state';
import { BNButton } from '../Button/Button';
import EventListIcon from '../icons/EventList';
import classes from './BulkExitOverlay.module.css';

export function BulkExitOverlay() {
  const { setShowBulkOptions } = useBulkState('setShowBulkOptions');
  return (
    <Overlay className={`bulk-overlay ${classes.bulkOverlay}`} bg="var(--colors-overlay)" center>
      <BNButton
        className={classes.bulkOverlayButton}
        variant="subtle"
        bg={'var(--colors-paper)'}
        leftSection={<EventListIcon />}
        onClick={() => {
          setShowBulkOptions(false);
        }}
        size="sm"
        miw={160}
      >
        Exit Bulk Mode
      </BNButton>
    </Overlay>
  );
}
