import { Paper } from '@mantine/core';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { RuleStateProvider } from '../data/Rule.state';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { BulkStateProvider } from '../data/Bulk.state';
import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { IntegrationProblemBanner } from '../hoc/Banners/IntegrationProblemBanner';
import { ListingStateProvider } from '../data/Listing.state';
import { useQueryParam } from '../utils/use-query-param';
import { useEffect, useMemo } from 'react';
import { getApiInventory, getApiPricingRules, useGetDtiItemsAccountId } from '../api';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import {
  forceRuleIdAtom,
  initialPageLoadAtom,
  searchResultsAtom,
  selectedEventAtom,
  selectedSearchEventsAtom,
  selectedTenantListingIdAtom,
  transientGlobalStateAtom,
} from '../data/atoms';
import { TenantIdListingId } from '../models/tenantIdListingId';
import { NowPricing } from '../hoc/NowPricing/NowPricing';
import { SeatingChart } from '../hoc/SeatingChart/SeatingChart';
import { MarketListings } from '../hoc/MarketListings/MarketListings';
import { DTIModals } from '../hoc/DTI/DTIModals';
import { DTIInventoryStateProvider } from '../hoc/DTI/DTI.Inventory.hooks';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { useTokenRefresh } from '../components/hooks/useTokenRefresh';
import { useFlag } from '@unleash/proxy-client-react';

export default function RuleIsolation() {
  return (
    <ListingStateProvider>
      <RuleStateProvider>
        <BulkStateProvider>
          <BulletinModal />
          <InviteModal />
          <div className="outer-appWrap rule-isolated">
            <VersionNotificationBanner />
            <SupportActivationBanner />
            <PlanExpirationBanner />
            <UnpaidInvoiceBanner />
            <IntegrationProblemBanner />
            {isDtiHosted ? (
              <DTIInventoryStateProvider>
                <RuleIsolationInterior />
                <DTIModals />
              </DTIInventoryStateProvider>
            ) : (
              <RuleIsolationInterior />
            )}
          </div>
        </BulkStateProvider>
      </RuleStateProvider>
    </ListingStateProvider>
  );
}

function RuleIsolationInterior() {
  // TODO: Move this state into a yasml hook.
  const { handleAccessTokenSilently } = useTokenRefresh();
  const ruleIsolationFlag = useFlag('rule-isolation');
  const eventId = useQueryParam('eventId', '');
  const itemId = useQueryParam('itemId', '');
  const referenceId = useQueryParam('referenceId', '');
  const setForceRuleId = useSetAtom(forceRuleIdAtom);
  if (import.meta.env.DEV) {
    localStorage.setItem('selected-tenants', '["533986d3-bce0-4c47-b525-81e34deda52a"]');
  }
  const defaultSearchDates = useMemo(
    () => ({
      fromDate: new Date(),
      toDate: dayjs().add(100, 'years').toDate(),
    }),
    [],
  );
  useEffect(() => {
    handleAccessTokenSilently();
  }, [handleAccessTokenSilently]);
  const setSelectedEvent = useSetAtom(selectedEventAtom);
  const setSearchResults = useSetAtom(searchResultsAtom);
  const setSelectedListing = useSetAtom(selectedTenantListingIdAtom);
  const setSelectedSearchEvents = useSetAtom(selectedSearchEventsAtom);
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  // HACK: Hard coded account id
  const { data: dtiItems } = useGetDtiItemsAccountId(89717, {
    event_id: eventId,
  });
  const listingId = useMemo(() => dtiItems?.data.items.find((item) => item.item_id === parseInt(itemId))?.listing_id, [dtiItems?.data.items, itemId]);

  const setInitialPageLoad = useSetAtom(initialPageLoadAtom);
  useEffect(() => {
    setInitialPageLoad(false);
  }, [setInitialPageLoad]);

  useEffect(() => {
    if (eventId) {
      if (itemId && !listingId) {
        // Wait for both values if itemId has a value.
        return;
      }
      getApiInventory({
        ...defaultSearchDates,
        eventIds: [eventId],
        ...(listingId ? { listingIds: [listingId] } : {}),
      }).then(async (result) => {
        if (result.data) {
          setSearchResults(result.data);
          setSelectedSearchEvents(result.data.events);
          const event = result.data?.events[0];
          if (event) {
            setSelectedEvent(event);
            const listing = result.data?.listings.find((_listing) => _listing.eventId === event.eventId);
            if (listing && itemId) {
              setSelectedListing(TenantIdListingId.from(listing.tenantId, listing.listingId).toStringTyped());
            }
            if (!listing?.ruleId && referenceId) {
              const ruleResponse = await getApiPricingRules({
                referenceId,
              });
              if (ruleResponse.data?.ruleId) {
                setForceRuleId(ruleResponse.data.ruleId);
                setTransientGlobalState((s) => ({ ...s, autoUpdateAutoPricedListPriceRuleId: ruleResponse.data.ruleId }));
              } else {
                setForceRuleId(referenceId);
              }
            }
          }
        } else if (referenceId) {
          setForceRuleId(referenceId);
        }
      });
      // Fetch Event and set it as selectedEvent.
    }
  }, [
    itemId,
    referenceId,
    setForceRuleId,
    setSearchResults,
    setSelectedEvent,
    setSelectedListing,
    setSelectedSearchEvents,
    eventId,
    listingId,
    defaultSearchDates,
    setTransientGlobalState,
  ]);

  if (!eventId) {
    return null;
  }

  if (!ruleIsolationFlag) {
    return null;
  }

  return (
    <div className="inner-appWrap">
      <div className="isolated-rule-appWrap">
        <div className="isolated-rule-col-1">
          <Paper className="seatingChartWrapper" pos="relative">
            <SeatingChartProvider>
              <SeatingChart />
            </SeatingChartProvider>
          </Paper>
        </div>
        <div className="isolated-rule-col-2">
          <NowPricing />
          <MarketListings />
        </div>
      </div>
    </div>
  );
}
