import { Box, Button, Center, Container, Group, Stack, Text } from '@mantine/core';
import StadiumIcon from '../../components/icons/Stadium';
import { useSeatingChart } from './SeatingChart.hooks';
import { BNButton } from '../../components/Button/Button';
import classes from './SeatingChart.module.css';
import OpenNewIcon from '../../components/icons/OpenNew';
import { useGlobalState } from '../../data/Global.state';

export function EmptyState() {
  const { setShowEventMapping } = useSeatingChart('setShowEventMapping');
  const { isMobile } = useGlobalState('isMobile');
  return (
    <>
      <Box className={isMobile ? undefined : classes.emptyStateOuterWrapper}>
        <Container p={0} className={classes.emptyStateWrapper}>
          <Center className={classes.center}>
            <Box className={classes.iconWrapper}>
              <StadiumIcon color="var(--colors-iconFill)" size={28} />
            </Box>
            <Text size="md" fw={600}>
              Event Not Mapped To Marketplace
            </Text>
            {isMobile ? (
              <Group gap={0}>
                <Text size="xs" c="var(--colors-gray-5)">
                  The mobile pricer does not currently support event mapping. Please use the desktop pricer to map this event to the marketplace.
                </Text>
              </Group>
            ) : (
              <>
                <Group gap={0}>
                  <Text size="xs" c="var(--colors-gray-5)">
                    Click below to map this event to the marketplace
                  </Text>
                </Group>
                <Stack gap="sm" mt="md">
                  <BNButton size="xs" variant="filled" color="green" onClick={() => setShowEventMapping((x) => !x)} data-pendo-name="Update Event Mapping Button">
                    Update Event Mapping
                  </BNButton>
                  <Button
                    size="xs"
                    variant="default"
                    component="a"
                    href="https://vimeo.com/842993175"
                    target="_blank"
                    rightSection={<OpenNewIcon />}
                    data-pendo-name="Event Mapping Video Button"
                  >
                    Video Tutorial
                  </Button>
                </Stack>
              </>
            )}
          </Center>
        </Container>
      </Box>
    </>
  );
}
