import { ActionIcon, Avatar, Box, Flex, Skeleton, Text, Tooltip } from '@mantine/core';
import ChevronBackIcon from '../../../components/icons/ChevronBack';
import MenuOpenIcon from '../../../components/icons/MenuOpen';
import { ReactComponent as SeatGeekLogo } from '../../../img/sg.svg';
import tevo from '../../../img/tevo.png';
import tnet from '../../../img/tnet.jpeg';
import tm from '../../../img/tm.png';
import classes from './FiltersPreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useAtomValue } from 'jotai';
import { selectedMarketplaceEventAtom } from '../../../data/atoms';
import { DateFormats } from '../../../utils/globals';
import { formatDate } from '../../../utils/formatters';

type FiltersPreviewProps = {
  clickFunction: () => void;
};

export function FiltersPreview({ clickFunction }: FiltersPreviewProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const selectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const isLoading = selectedMarketplaceEvent?.isLoading;

  const [showCollapsedFilters, setShowCollapsedFilters] = useState(false);
  function handleFiltersMouseEnter() {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      document.querySelector('.appRight')?.classList.add('show-market-filters');
      setShowCollapsedFilters(true);
      timeoutRef.current = null;
    }, 100);
  }
  function handleFiltersMouseLeave() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  function handleHoverOff() {
    document.querySelector('.appRight')?.classList.remove('show-market-filters');
    setShowCollapsedFilters(false);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  function handleSmallClick() {
    document.querySelector('.appRight')?.classList.toggle('show-market-filters');
    setShowFiltersMobile(!showFiltersMobile);
  }
  const mqSmall = useMediaQuery('(max-width: 1239px)');

  return (
    <>
      <Box className={classes.filtersPreview} pos="relative">
        <Flex p="xs" pos="relative" className={classes.filtersPreviewDisplay}>
          <Flex h={40} align="center" c="var(--colors-gray-3)" className={classes.filtersChevron}>
            <ChevronBackIcon size={12} />
          </Flex>
          <Box maw="100%" className={classes.filtersContent}>
            <Flex gap={8} pr="xs" align="center">
              {selectedMarketplaceEvent.marketplaceId === 'SeatGeek' ? (
                <Avatar key="seatgeek" m={0} h={40} w={40} radius="xl" variant="outline" className={classes.avatar} color="var(--colors-darkPaper)">
                  <ActionIcon>
                    <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                  </ActionIcon>
                  <SeatGeekLogo />
                </Avatar>
              ) : (
                <Avatar key="other" m={0} h={40} w={40} radius="xl" variant="outline" className={classes.avatar} color="var(--colors-darkPaper)">
                  <ActionIcon>
                    <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                  </ActionIcon>
                  <img
                    className="mantine-Avatar-image"
                    height={36}
                    width={36}
                    src={selectedMarketplaceEvent.marketplaceId === 'TicketEvolution' ? tevo : selectedMarketplaceEvent.marketplaceId === 'TicketNetwork' ? tnet : tm}
                    alt="Selected Marketplace"
                  />
                </Avatar>
              )}
              {selectedMarketplaceEvent.marketplaceEvent ? (
                <Box flex={1} miw={0}>
                  <>
                    <Text truncate lh={1.4} fw={600} fz={13}>
                      {selectedMarketplaceEvent?.marketplaceEvent?.name}
                    </Text>
                    <Text p={0} c="var(--colors-gray-5)" size="xs" truncate>
                      <Tooltip
                        label={`${selectedMarketplaceEvent.marketplaceEvent.venueName} · ${selectedMarketplaceEvent?.marketplaceEvent.city}, ${selectedMarketplaceEvent.marketplaceEvent.region}`}
                      >
                        <span>
                          <span style={{ color: 'var(--colors-brandcolor-6)' }}>{formatDate(selectedMarketplaceEvent.marketplaceEvent?.dateTimeLocal, DateFormats.Extended)}</span>{' '}
                          - {selectedMarketplaceEvent.marketplaceEvent.venueName} · {selectedMarketplaceEvent.marketplaceEvent.city},{' '}
                          {selectedMarketplaceEvent.marketplaceEvent?.region}
                        </span>
                      </Tooltip>
                    </Text>
                  </>
                </Box>
              ) : (
                <Box flex={1} miw={0}>
                  {isLoading ? (
                    <>
                      <Skeleton height={8} w={200} my={7} />
                      <Skeleton height={8} w={160} width="100%" my={7} />
                    </>
                  ) : (
                    <>
                      <Text truncate lh={1.4} fw={600} fz={13}>
                        Marketplace data not found
                      </Text>
                      <Text lh={1.4} c="var(--colors-gray-5)" truncate>
                        Check back later or update the event mapping
                      </Text>
                    </>
                  )}
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
        {mqSmall ? (
          <Tooltip label="Toggle Filters Column" position="top-start" offset={{ mainAxis: -4, crossAxis: -10 }} arrowOffset={40}>
            <Box pos="absolute" top={0} left={0} bottom={0} right={0} onClick={handleSmallClick} />
          </Tooltip>
        ) : (
          <Tooltip label="Expand Filters Column" position="top-start" offset={{ mainAxis: -4, crossAxis: -10 }} arrowOffset={40}>
            <Box pos="absolute" top={0} left={0} bottom={0} right={0} onMouseEnter={handleFiltersMouseEnter} onMouseLeave={handleFiltersMouseLeave} onClick={clickFunction} />
          </Tooltip>
        )}
      </Box>
      {showCollapsedFilters && <Box className={classes.hoverOffOverlay} onMouseEnter={handleHoverOff} />}
    </>
  );
}
