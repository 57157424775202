import { Badge, Box, Button, Container, Divider, Group, Popover, SimpleGrid, Text } from '@mantine/core';
import { ReadOnly } from './NowPricing.ReadOnly';
import { RuleForm } from './NowPricing.RuleForm';
import { NowPricingProvider, useNowPricing } from './NowPricing.hooks';
import { BNButton } from '../../components/Button/Button';
import { useCallback } from 'react';
import WarningIcon from '../../components/icons/Warning';
import { useDidUpdate } from '@mantine/hooks';
import dayjs from 'dayjs';
import ErrorIcon from '../../components/icons/Error';
import { useGlobalState } from '../../data/Global.state';
import classes from './NowPricing.module.css';
import { useAtomValue } from 'jotai';
import { ruleStateAtom } from '../../data/atoms';
import { SeasonEvents } from '../SeasonEvents/SeasonEvents';

export function SeasonNowPricing() {
  const rule = useAtomValue(ruleStateAtom);
  // RuleId is needed to reset the form default state to get initial principal settings

  return (
    <NowPricingProvider key={rule.ruleId || ''}>
      <NowPricingImpl />
    </NowPricingProvider>
  );
}

function NowPricingImpl() {
  const {
    form,
    saveRuleAndPrice,
    isLoading,
    isFormReady,
    cancel,
    isChangeConflict,
    refreshRule,
    isNoSectionRule,
    closeNoSectionDialog,
    onSave,
    isDrasticChangeDialog,
    isNoSectionDialog,
    saving,
    closeDrasticChangeDialog,
    submit,
    isFiltersNotApplied,
    closeApplyFiltersDialog,
    isAnyRuleEnabled,
  } = useNowPricing(
    'form',
    'saveRuleAndPrice',
    'isLoading',
    'isFormReady',
    'cancel',
    'isChangeConflict',
    'refreshRule',
    'isNoSectionRule',
    'closeNoSectionDialog',
    'onSave',
    'isDrasticChangeDialog',
    'isNoSectionDialog',
    'saving',
    'closeDrasticChangeDialog',
    'submit',
    'isFiltersNotApplied',
    'closeApplyFiltersDialog',
    'isAnyRuleEnabled',
  );
  const { getUserName, currentUser } = useGlobalState('getUserName', 'currentUser');
  const updatedBy = isChangeConflict?.updatedBy;
  const updatedAt = isChangeConflict?.updatedAt;
  const user = getUserName(updatedBy);
  const formattedDate = dayjs(updatedAt).fromNow(true);

  const disableSave = currentUser?.roleId === 'ReadOnlyUser' || !isFormReady || !form.isValid() || !isAnyRuleEnabled;
  const disableCancel = isLoading || !isFormReady;

  useDidUpdate(() => {
    if (!isNoSectionRule) {
      closeNoSectionDialog();
    }
  }, [isNoSectionRule]);

  const onConfirmSave = useCallback(() => {
    closeDrasticChangeDialog();
    saveRuleAndPrice();
  }, [closeDrasticChangeDialog, saveRuleAndPrice]);

  return (
    <Container m={8} p={16} pt={32} pb={0} className={classes.wrapper}>
      <form onSubmit={onSave} onReset={cancel}>
        <Badge radius={0} c="var(--colors-selectedBorder)" fz={10} className={`slantBadge ${classes.slantBadge}`}>
          Now Pricing
        </Badge>
        <ReadOnly />
        <Divider color="var(--colors-divider)" my="sm" mx="-1rem" />
        <RuleForm />

        <SimpleGrid mt={16} cols={2} spacing={12} w="100%">
          <BNButton type="reset" disabled={disableCancel} size="xs" fullWidth variant="default">
            Cancel
          </BNButton>
          <Popover
            width={220}
            position="bottom"
            withArrow
            closeOnClickOutside
            onClose={() => closeDrasticChangeDialog()}
            opened={isDrasticChangeDialog || !!isChangeConflict || isNoSectionDialog || isFiltersNotApplied}
          >
            <Popover.Target>
              <BNButton type="submit" disabled={disableSave} loading={saving} size="xs" color="green" fullWidth variant="filled">
                Save
              </BNButton>
            </Popover.Target>
            <Popover.Dropdown p={0}>
              {isFiltersNotApplied && (
                <Box>
                  <Group wrap="nowrap" p={12} align="start" gap={8}>
                    <Box>
                      <WarningIcon color="var(--colors-yellow-6)" />
                    </Box>
                    <Box className={classes.flex1}>
                      {/* Title */}
                      <Text size="xs" fw="bold">
                        Filters Not Applied
                      </Text>
                      {/* Description */}
                      <Group gap={0}>
                        <Text size="xs">
                          You have filters that have not been applied to the rule.
                          <br /> <br />
                          Apply filters to continue
                        </Text>
                      </Group>
                    </Box>
                  </Group>
                  <Divider color="var(--colors-divider)" />
                  {/* Dropdown buttons */}
                  <Button.Group>
                    <Group wrap="nowrap" gap={0} className={classes.flex1}>
                      <BNButton
                        radius={0}
                        fullWidth
                        variant="subtle"
                        size="xs"
                        onClick={() => {
                          submit();
                          closeApplyFiltersDialog();
                        }}
                        style={{ borderRight: '1px solid var(--colors-divider)' }}
                      >
                        Apply
                      </BNButton>
                      <BNButton onClick={() => closeApplyFiltersDialog()} radius={0} fullWidth variant="subtle" size="xs" maw="50%">
                        Cancel
                      </BNButton>
                    </Group>
                  </Button.Group>
                </Box>
              )}
              {isNoSectionDialog && (
                <Box>
                  <Group wrap="nowrap" p={12} align="start" gap={8}>
                    <Box>
                      <WarningIcon color="var(--colors-yellow-6)" />
                    </Box>
                    <Box className={classes.flex1}>
                      {/* Title */}
                      <Text size="xs" fw="bold">
                        No Section Selected
                      </Text>
                      {/* Description */}
                      <Group gap={0}>
                        <Text size="xs">
                          You are attempting to save an auto-priced rule with no sections selected.
                          <br /> <br />
                          Are you sure you wish to continue?
                        </Text>
                      </Group>
                    </Box>
                  </Group>
                  <Divider color="var(--colors-divider)" />
                  {/* Dropdown buttons */}
                  <Button.Group>
                    <Group wrap="nowrap" gap={0} className={classes.flex1}>
                      <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => onConfirmSave()} style={{ borderRight: '1px solid var(--colors-divider)' }}>
                        Confirm
                      </BNButton>
                      <BNButton onClick={() => closeNoSectionDialog()} radius={0} fullWidth variant="subtle" size="xs" maw="50%">
                        Cancel
                      </BNButton>
                    </Group>
                  </Button.Group>
                </Box>
              )}
              {isChangeConflict && (
                <Box>
                  <Group wrap="nowrap" p={12} align="start" gap={8}>
                    <Box>
                      <ErrorIcon fill color="var(--colors-red-error)" />
                    </Box>
                    <Box className={classes.flex1}>
                      {/* Title */}
                      <Text size="xs" fw="bold">
                        Rule Conflict
                      </Text>
                      {/* Description */}
                      <Group gap={0}>
                        <Text size="xs">
                          {user} updated this rule{user === 'You' ? 'in another window.' : '.'}
                        </Text>
                      </Group>
                      {/* Secondary Description */}
                      <Text fz={11} c="var(--colors-gray-5)">
                        {formattedDate}
                      </Text>
                    </Box>
                  </Group>
                  <Divider color="var(--colors-divider)" />
                  {/* Dropdown buttons */}
                  <Button.Group>
                    <Group wrap="nowrap" gap={0} className={classes.flex1}>
                      <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => refreshRule()}>
                        Refresh
                      </BNButton>
                    </Group>
                  </Button.Group>
                </Box>
              )}
            </Popover.Dropdown>
          </Popover>
        </SimpleGrid>
      </form>
      <Divider color="var(--colors-divider)" mt="md" mx={-16} />
      <SeasonEvents />
    </Container>
  );
}
