import { useQueries } from '@tanstack/react-query';
import { getApiInventoryTags, getGetApiInventoryTagsQueryKey, getGetApiInventoryTagsQueryOptions } from '../../api';
import { useGlobalState } from '../../data/Global.state';
import { useAtomValue } from 'jotai/index';
import { auth } from '../../data/atoms.auth';
import { useMemo } from 'react';

export const useSelectedTenantTags = () => {
  const { selectedTenantIds } = useGlobalState('selectedTenantIds');
  const apiToken = useAtomValue(auth.apiTokenAtom);

  const multiTenantTagResults = useQueries({
    queries: selectedTenantIds.map((selectedTenantId) => ({
      queryKey: [...getGetApiInventoryTagsQueryKey(), selectedTenantId],
      queryFn: () =>
        getApiInventoryTags({
          ...getGetApiInventoryTagsQueryOptions(),
          headers: {
            'x-tenant-id': selectedTenantId,
          },
        }),
      enabled: !!apiToken && selectedTenantIds?.length > 0,
      staleTime: 1000 * 60 * 10,
    })),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const multiTenantTagResultsMemo = useMemo(() => multiTenantTagResults, [multiTenantTagResults.some((r) => r.isLoading)]);

  const selectedTenantTags = multiTenantTagResultsMemo.flatMap((ar) => ar.data?.data ?? []) as string[];
  return useMemo(() => [...new Set(selectedTenantTags.map((t) => t.toUpperCase()))], [selectedTenantTags]);
};
