/* eslint-disable react-compiler/react-compiler */
import { Flex, Group, Text, UnstyledButton } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import React, { useEffect, useRef } from 'react';
import SearchIcon from '../../components/icons/Search';
import BNOrderSelect from '../../components/Select/OrderSelect';
import { useSearchResults } from './SearchResults.hooks';
import pluralize from 'pluralize';
import { useGlobalState } from '../../data/Global.state';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import StadiumIcon from '../../components/icons/Stadium';
import classes from './SearchResults.Mobile.module.css';
import { useNavigate } from 'react-router-dom';
import BNPullToRefresh from '../../components/PullToRefresh/PullToRefresh';
import objectHash from 'object-hash';
import { EventCardColors } from './SearchResults.EventCardColors';
import { EventCardSkeleton } from './SearchResults.EventCardSkeleton';
import { useVirtualizer } from '@tanstack/react-virtual';
import { BarkerCoreEnumsShowEventCountdown, BarkerCoreModelsInventoryEvent } from '../../api';

export function SearchResultsMobile({ openSearch }: { openSearch: () => void }) {
  'use no memo';

  // Hack: ^^^ Required for the virtualizer to work properly. Otherwise items are memo'd and don't load after scrolling
  const {
    sortedData: data,
    isLoading,
    sort,
    sortOptions,
    setSort,
    sortOrder,
    setSortOrder,
    onEventClicked,
    search,
    form,
  } = useSearchResults('sortedData', 'isLoading', 'sort', 'sortOptions', 'setSort', 'sortOrder', 'setSortOrder', 'onEventClicked', 'search', 'form');
  const parentRef = useRef<HTMLDivElement>(null);
  const [isSortOptionsOpen, setIsSortOptionsOpen] = React.useState(false);
  const { principal, tenants } = useGlobalState('principal', 'tenants');

  const dataHash = objectHash(data);

  const [savedScrollOffset, setSavedScrollOffset] = useLocalStorage<{ hash: string; offset: number }>({
    key: 'mobile-search-results-scroll-offset',
    getInitialValueInEffect: false,
    defaultValue: { hash: dataHash, offset: 0 },
  });

  const scrollOffset = savedScrollOffset.hash === dataHash ? savedScrollOffset.offset : 0;
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 140,
    overscan: 10,
    useScrollendEvent: true,
    initialOffset: scrollOffset,
  });

  const showEventCountdown = principal?.settings?.pricerSettings?.appearanceSettings?.showEventCountdown ?? BarkerCoreEnumsShowEventCountdown.Never;

  useEffect(() => {
    setSavedScrollOffset({ hash: dataHash, offset: rowVirtualizer.scrollOffset || 0 });
  }, [dataHash, rowVirtualizer.scrollOffset, setSavedScrollOffset]);

  return (
    <>
      <Group className={`resultsArea ${classes.resultsAreaWrapper}`} px={16} py={0}>
        <Group align="center" gap={2} className={classes.sortSelect}>
          <Text className={classes.selectLabel}>Sort:</Text>
          <BNOrderSelect
            data={sortOptions.map((x) => x)}
            isMobile
            dropdownWidth={200}
            selectedItem={sort}
            isOpen={isSortOptionsOpen}
            sortOrder={sortOrder}
            onClose={() => setIsSortOptionsOpen(false)}
            onClick={() => setIsSortOptionsOpen(!isSortOptionsOpen)}
            onSortOrderChange={(value: 'asc' | 'desc') => {
              setSortOrder(value);
              setIsSortOptionsOpen(false);
            }}
            onSelectionChange={(value) => {
              setSort(value as (typeof sortOptions)[number]);
              setIsSortOptionsOpen(false);
            }}
          />
        </Group>
        <Group gap={8} align="center" className={classes.eventsLabel}>
          <StadiumIcon color="var(--colors-gray-5)" />
          <Text fz={13} c="var(--colors-gray-5)">
            {data ? data.length : 0} {pluralize('Event', data ? data.length : 0)}
          </Text>
        </Group>
      </Group>
      <div className={classes.searchResultsOuterWrap}>
        <div className={classes.searchResultsInnerWrap} ref={parentRef} style={{ background: 'var(--colors-divider)', overflow: 'auto', height: '100%' }}>
          <BNPullToRefresh wrapperRef={parentRef} listClass="searchResultsList" refreshAction={() => search(form.values)} isRefreshing={isLoading ?? false} />
          {data && data.length > 0 ? (
            <div
              className="searchResultsList"
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <RowItem
                  key={`${data[virtualItem.index].tenantId}|${data[virtualItem.index].eventId}`}
                  event={data[virtualItem.index]}
                  size={virtualItem.size}
                  start={virtualItem.start}
                  tenantName={tenants?.find((t) => t.tenantId === data[virtualItem.index]?.tenantId)?.name || 'Unknown'}
                  tenantColor={principal?.settings?.tenantColors[data[virtualItem.index].tenantId] ?? 'var(--colors-brandcolor-5)'}
                  isMultiTenant={(tenants && tenants.length > 1) || false}
                  onEventClicked={onEventClicked}
                  showEventCountdown={showEventCountdown}
                />
              ))}
            </div>
          ) : isLoading ? (
            <SearchResultsMobile.EventCardSkeleton isMobile />
          ) : (
            <Flex h="100%" bg="var(--colors-paper)" style={{ borderTop: '8px solid var(--colors-divider)' }}>
              <BNEmptyState
                h="90%"
                border={false}
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                title="No Events Found"
                description="Try changing your search filters by tapping the button below"
                buttonText="Change search filters"
                buttonVariant="filled"
                buttonColor="green"
                buttonOnClick={openSearch}
              />
            </Flex>
          )}
        </div>
      </div>
    </>
  );
}

const RowItem = ({
  event,
  size,
  start,
  isMultiTenant,
  tenantColor,
  tenantName,
  onEventClicked,
  showEventCountdown,
}: {
  event: BarkerCoreModelsInventoryEvent;
  size: number;
  start: number;
  tenantName: string;
  tenantColor: string;
  isMultiTenant: boolean;
  onEventClicked: (
    event: BarkerCoreModelsInventoryEvent,
    domEvent: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void>;
  showEventCountdown: BarkerCoreEnumsShowEventCountdown;
}) => {
  const isSelected = false; // We don't show the selected state on mobile
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
    >
      <>
        <UnstyledButton
          className={classes.overlayLink}
          onClick={(e) => {
            onEventClicked(event, e);
            navigate(`/mobile/events/${event.eventId}/listings?tenantId=${event.tenantId}`);
          }}
        />
        <EventCardColors
          event={event}
          isSelected={isSelected}
          tenantName={tenantName}
          tenantColor={tenantColor}
          isMultiTenant={isMultiTenant}
          showEventCountdown={showEventCountdown}
        />
      </>
    </div>
  );
};

SearchResultsMobile.EventCardSkeleton = EventCardSkeleton;
