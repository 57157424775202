import { MarketplaceIdentifier } from './MarketplaceIdentifier';
import { ColDef, ColumnEvent, ColumnState, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import yasml from '@thirtytech/yasml';
import {
  BarkerCoreEnumsFulfillmentStatus,
  BarkerCoreEnumsPaymentStatus,
  BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal,
  BarkerCoreModelsSalesSkyBoxFulfillmentStatus,
  BarkerCoreModelsSalesTicketNetworkFulfillmentStatus,
  getGetApiSalesQueryKey,
  useGetApiSales,
} from '../../api';
import { useCallback, useMemo, useRef, useState } from 'react';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { BNTags } from '../../components/Tags/Tags';
import { getStandardMarketplaceName } from '../../utils/marketplace-utils';
import { MarketplaceNames } from '../../models/marketplaces';
import dayjs from 'dayjs';
import { AgGridReact } from '@ag-grid-community/react';
import { useForm } from '@mantine/form';
import { useDidUpdate, useLocalStorage, useToggle } from '@mantine/hooks';
import { useSearchParams } from 'react-router-dom';
import { useAtomValue } from 'jotai/index';
import { auth } from '../../data/atoms.auth';
import { useGlobalState } from '../../data/Global.state';
import { BarkerEventListing, SalesSearchParamsWithDates } from '../../types';
import { DateFormats } from '../../utils/globals';
import { matchesRowFilter } from '../MarketListings/marketListingsFilter';
import { Box } from '@mantine/core';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import classes from './Sales.Grid.module.css';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { Sale } from './Sales.types';
import { SalesAction } from './Sales.Action';
import { useSelectedTenantTags } from '../EventSearch/SelectedTenantsTags.hooks';

export const onColumnMoved = new Event('onColumnMoved');
export const onColumnSorted = new Event('onColumnSorted');

function SalesState() {
  const fixedEndOfDate = 'T23:59:59.000';
  const fixedStartDate = 'T00:00:00.000';
  const [showSidebar, setShowSidebar] = useLocalStorage({
    key: 'setting-sidebar-sales',
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const [searchParams, setSearchParams] = useSearchParams();
  const [gridReady, setGridReady] = useState(false);
  const [showSort, toggleShowSort] = useToggle();
  const [salesQuickFilter, setSalesQuickFilter] = useState<string>();
  const [filter, setFilter] = useState(''); // Used for input state of semi controlled input
  const [showDeleted, setShowDeleted] = useState(isDtiHosted ? false : undefined);
  const selectedTenantsTagsResults = useSelectedTenantTags();

  const _formatSearchParams = useCallback(
    (params: URLSearchParams | null) => ({
      query: params?.get('query') ?? '',
      fromDate: params?.get('fromDate') ? dayjs(params?.get('fromDate')).toDate() : (params?.size ?? 0) === 0 ? dayjs().startOf('day').toDate() : undefined,
      toDate: params?.get('toDate') ? dayjs(params?.get('toDate')).toDate() : (params?.size ?? 0) === 0 ? dayjs().startOf('day').toDate() : undefined,
      eventDateFrom: params?.get('eventDateFrom') ? dayjs(params?.get('eventDateFrom')).toDate() : null,
      eventDateTo: params?.get('eventDateTo') ? dayjs(params?.get('eventDateTo')).toDate() : null,
      eventId: params?.get('eventId') ?? '',
      invoiceId: params?.get('invoiceId') ?? '',
      externalReference: params?.get('externalReference') ?? '',
      fulfillmentStatusId: params?.get('fulfillmentStatusId')
        ? BarkerCoreEnumsFulfillmentStatus[params?.get('fulfillmentStatusId') as keyof typeof BarkerCoreEnumsFulfillmentStatus]
        : null,
      paymentStatusId: params?.get('paymentStatusId') ? BarkerCoreEnumsPaymentStatus[params?.get('paymentStatusId') as keyof typeof BarkerCoreEnumsPaymentStatus] : null,
      tags: params?.get('tags') ? params?.getAll('tags') : [],
      antiTags: params?.get('antiTags') ? params?.getAll('antiTags') : [],
    }),
    [],
  );

  const [state, setState] = useState<SalesSearchParamsWithDates>(_formatSearchParams(searchParams));
  const [gridState, setGridState, clearGridState] = useLocalStorage<ColumnState[]>({
    key: 'sales-grid-state',
    getInitialValueInEffect: false,
  });

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      ...state,
      query: state.query ?? '',
    },
    validate: {
      toDate: (value) => (!value ? 'To Date is required' : undefined),
      fromDate: (value) => (!value ? 'From Date is required' : undefined),
      eventDateFrom: (value, values) => {
        if (!value && values.eventDateTo) {
          return 'Date From is required';
        }

        return undefined;
      },
      eventDateTo: (value, values) => {
        if (!value && values.eventDateFrom) {
          return 'Date To is required';
        }

        return undefined;
      },
    },
  });

  const selectedTenantsTags = useMemo(() => {
    if (form.values.tags?.some((t) => !selectedTenantsTagsResults.includes(t.toUpperCase()))) {
      form.setValues({ tags: form.values.tags.filter((t) => selectedTenantsTagsResults.includes(t.toUpperCase())) });
    }
    return selectedTenantsTagsResults;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantsTagsResults]);

  const trySaveGridState = useCallback(
    (event: ColumnEvent) => {
      if (gridReady) {
        const _state = event.columnApi?.getColumnState()!;
        setGridState(_state);
        if (event.type === 'columnMoved') {
          document.dispatchEvent(onColumnMoved);
        }
        if (event.type === 'sortChanged') {
          document.dispatchEvent(onColumnSorted);
        }
      }
    },
    [gridReady, setGridState],
  );

  const { resetDirty, isDirty, setValues } = form;

  useDidUpdate(() => {
    setState(_formatSearchParams(searchParams));
    setValues(_formatSearchParams(searchParams));
  }, [searchParams]);

  const apiQueryObj = useMemo(
    () => ({
      query: state.query === '' ? undefined : state.query,
      fromDate: dayjs(state.fromDate).toDate(),
      toDate: dayjs(state.toDate).endOf('day').toDate(),
      eventDateFrom: !state.eventDateFrom ? undefined : ((dayjs(state.eventDateFrom).format('YYYY-MM-DD') + fixedStartDate) as unknown as Date),
      eventDateTo: !state.eventDateTo ? undefined : ((dayjs(state.eventDateTo).endOf('day').format('YYYY-MM-DD') + fixedEndOfDate) as unknown as Date),
      eventId: state.eventId === '' ? undefined : state.eventId,
      invoiceId: state.invoiceId === '' ? undefined : state.invoiceId,
      externalReference: state.externalReference === '' ? undefined : state.externalReference,
      fulfillmentStatusId: state.fulfillmentStatusId ?? undefined,
      paymentStatusId: state.paymentStatusId ?? undefined,
      tags: state.tags.length === 0 ? undefined : state.tags,
      antiTags: state.antiTags.length === 0 ? undefined : state.antiTags,
    }),
    [state],
  );

  const {
    data: _salesData,
    isLoading,
    isRefetching,
    refetch: refetchSales,
  } = useGetApiSales(apiQueryObj, {
    query: {
      enabled: !!apiToken && !!tenants,
      queryKey: [...getGetApiSalesQueryKey(apiQueryObj), showDeleted],
      select(data) {
        if (showDeleted === undefined || showDeleted) {
          return {
            sales: data.data.sales.map((sale) => ({
              ...sale,
              marketplaceName: getStandardMarketplaceName((sale.marketplaceName as MarketplaceNames) ?? 'Other'),
            })),
            events: data.data.events,
          };
        }

        // The only way showDeleted can be set to false is from the DTI portal
        return {
          sales: data.data.sales
            .filter((s) =>
              s.listings.some((l) => {
                if (l.vendorProperties?.pointOfSaleId !== 'DtiPortal') {
                  return true;
                }

                const vendorProperties = l.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal;

                if (vendorProperties.ownerName === s.marketplaceName && !vendorProperties.brokerDirectPrice) {
                  return false;
                }

                return true;
              }),
            )
            .map((sale) => ({
              ...sale,
              marketplaceName: getStandardMarketplaceName((sale.marketplaceName as MarketplaceNames) ?? 'Other'),
            })),
          events: data.data.events,
        };
      },
    },
  });

  const search = useCallback(
    async (values: SalesSearchParamsWithDates, valuesAreDirty?: boolean) => {
      if (form.isValid()) {
        setState(values); // Form values are already set but need to tell the related components something changed

        const newParams: any = {};

        if (values.fromDate) {
          newParams.fromDate = dayjs(values.fromDate).format('YYYY-MM-DD');
        }

        if (values.toDate) {
          newParams.toDate = dayjs(values.toDate).format('YYYY-MM-DD');
        }

        if (values.query !== '') {
          newParams.query = values.query;
        }

        if (values.eventDateFrom) {
          newParams.eventDateFrom = dayjs(values.eventDateFrom).format('YYYY-MM-DD');
        }

        if (values.eventDateTo) {
          newParams.eventDateTo = dayjs(values.eventDateTo).format('YYYY-MM-DD');
        }

        if (values.eventId) {
          newParams.eventId = values.eventId;
        }

        if (values.invoiceId) {
          newParams.invoiceId = values.invoiceId;
        }

        if (values.externalReference) {
          newParams.externalReference = values.externalReference;
        }

        if (values.fulfillmentStatusId) {
          newParams.fulfillmentStatusId = values.fulfillmentStatusId;
        }

        if (values.paymentStatusId) {
          newParams.PaymentStatus = values.paymentStatusId;
        }

        if (values.tags) {
          newParams.tags = values.tags;
        }

        if (values.antiTags) {
          newParams.antiTags = values.antiTags;
        }

        if (!isDirty() && !valuesAreDirty) {
          await refetchSales();
        } else {
          setSearchParams(() => newParams);
          resetDirty();
        }
      }
    },
    [form, setState, isDirty, refetchSales, setSearchParams, resetDirty],
  );

  const reset = useCallback(() => {
    setSearchParams();
    setState(_formatSearchParams(null));
    setValues(_formatSearchParams(null));
    search(_formatSearchParams(null));
  }, [setSearchParams, setState, _formatSearchParams, setValues, search]);

  const events = useMemo(() => _salesData?.events ?? [], [_salesData]);

  const salesData = useMemo(
    () =>
      _salesData?.sales.flatMap((sale) => {
        const { listings, ...rest } = sale;
        return listings.map((listing) => ({
          ...rest,
          ...listing,
          event: events.find((event) => event.eventId === listing.eventId),
        }));
      }) ?? [],
    [_salesData?.sales, events],
  );

  const [filteredSales, setFilteredSales] = useState(salesData || []);

  const gridRef = useRef<AgGridReact>(null);

  let marketplaceBreakdown =
    filteredSales
      ?.filter((s) => s.unitPrice > 0)
      .reduce(
        (acc, listing) => {
          const { marketplaceName } = listing;
          const marketplaceTotal = acc[marketplaceName] ?? 0;
          acc[marketplaceName] = marketplaceTotal + listing.quantity;
          return acc;
        },
        {} as Record<string, number>,
      ) ?? {};

  if (Object.keys(marketplaceBreakdown).length > 8) {
    const sortedMarketplaceBreakdown = Object.entries(marketplaceBreakdown).sort((a, b) => b[1] - a[1]);
    const topMarketplaces = sortedMarketplaceBreakdown.slice(0, 7);
    const otherMarketplaces = sortedMarketplaceBreakdown.slice(7);
    const otherTotal = otherMarketplaces.reduce((acc, [, quantity]) => acc + quantity, 0);
    const otherMarketplace = topMarketplaces.find(([name]) => name === 'Other');
    if (otherMarketplace) {
      otherMarketplace[1] += otherTotal;
    } else {
      topMarketplaces.push(['Other', otherTotal]);
    }
    marketplaceBreakdown = Object.fromEntries(topMarketplaces);
  }

  const cost =
    filteredSales?.reduce((acc, listing) => {
      const totalCost = (listing.unitCost ?? 0) * listing.quantity;
      return acc + totalCost;
    }, 0) ?? 0;

  const revenue =
    filteredSales?.reduce((acc, listing) => {
      const totalRevenue = (listing.unitPrice ?? 0) * listing.quantity;
      return acc + totalRevenue;
    }, 0) ?? 0;

  const profit = revenue - cost;
  const returnOnInvestment = (profit / cost) * 100;

  const totalTickets = filteredSales?.reduce((acc, listing) => acc + listing.quantity, 0);

  const totalEvents = new Set(filteredSales?.map((sale) => sale.eventId)).size;

  const totalWastedTickets = filteredSales?.reduce((acc, listing) => acc + (listing.unitPrice === 0 ? listing.quantity : 0), 0);

  const getDaysInRange = (startDate: Date, endDate: Date) => {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const getHoursInRange = (startDate: Date, endDate: Date) => {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setHours(currentDate.getHours() + 1);
    }
    return dates;
  };

  const salesDates = filteredSales.map((sale) => new Date(sale.createdAt).getTime());
  const minDate = new Date(salesDates.reduce((x, y) => Math.min(x, y), Number.POSITIVE_INFINITY)); // new Date(Math.min(...salesDates));
  const maxDate = new Date(salesDates.reduce((x, y) => Math.max(x, y), Number.NEGATIVE_INFINITY)); // new Date(Math.max(...salesDates));

  const hours = Math.abs(maxDate.getTime() - minDate.getTime()) / 36e5;
  const trendSegment = hours <= 24 ? 'h a' : 'YYYY-MM-DD';

  const groupedSales = filteredSales?.reduce(
    (acc, sale) => {
      const date = dayjs(sale.createdAt).format(trendSegment);
      const totalRevenue = (sale.unitPrice ?? 0) * sale.quantity;
      const totalCost = (sale.unitCost ?? 0) * sale.quantity;
      const currentRevenue = acc[date]?.totalRevenue ?? 0;
      const currentQuantity = acc[date]?.totalQuantity ?? 0;
      const currentCost = acc[date]?.totalCost ?? 0;

      acc[date] = {
        totalRevenue: currentRevenue + totalRevenue,
        totalQuantity: currentQuantity + sale.quantity,
        totalCost: currentCost + totalCost,
      };

      return acc;
    },
    {} as Record<string, { totalRevenue: number; totalQuantity: number; totalCost: number }>,
  );

  const allPeriods = hours <= 24 ? getHoursInRange(minDate, maxDate) : getDaysInRange(minDate, maxDate);

  const salesTrends = allPeriods.reduce(
    (acc, date) => {
      const dateString = dayjs(date).format(trendSegment);
      const dailyData = groupedSales[dateString] ?? { totalRevenue: 0, totalQuantity: 0, totalCost: 0 };

      acc[dateString] = {
        totalRevenue: dailyData.totalRevenue,
        averageTicketPrice: dailyData.totalQuantity > 0 ? dailyData.totalRevenue / dailyData.totalQuantity : 0,
        totalCost: dailyData.totalCost,
        totalProfit: dailyData.totalRevenue - dailyData.totalCost,
        roiPct: dailyData.totalCost > 0 ? ((dailyData.totalRevenue - dailyData.totalCost) / dailyData.totalCost) * 100 : 0,
      };

      return acc;
    },
    {} as Record<string, { totalRevenue: number; averageTicketPrice: number; totalCost: number; totalProfit: number; roiPct: number }>,
  );

  const isMultiTenant = tenants && tenants.length > 1;

  const [selectedHistoryListing, setSelectedHistoryListing] = useState<{
    listing: BarkerEventListing | null;
    isOpen: boolean;
  }>({ listing: null, isOpen: false });

  const getTenantColorClass = (tenantColor: string | undefined) => {
    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'tenant-brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'tenant-red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'tenant-orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'tenant-yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'tenant-green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'tenant-cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'tenant-blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'tenant-grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'tenant-pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'tenant-black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'tenant-gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'tenant-gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'tenant-gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'tenant-gray1';
      }
    }
    return 'tenant-brandcolor';
  };

  useDidUpdate(() => {
    if (salesQuickFilter) {
      let matchingSales = [...salesData];

      const andParts = salesQuickFilter.split(' ');

      andParts.forEach((andPart) => {
        const andListings: Sale[] = [];

        const orParts = andPart.split(',');

        orParts.forEach((orPart) => {
          const rangeParts = orPart.split('-');

          if (
            rangeParts.length === 2 &&
            ((!isNaN(Number(rangeParts[0])) && !isNaN(Number(rangeParts[1]))) ||
              ([...rangeParts[0]].every((char) => char === rangeParts[0][0]) && [...rangeParts[1]].every((char) => char === rangeParts[1][0])))
          ) {
            // Either the part is a number of it is a string of the same character, so check sections or rows
            andListings.push(...matchingSales.filter((listing) => matchesRowFilter(listing.section, [orPart]) || matchesRowFilter(listing.row, [orPart])));
          } else if (orPart.length <= 3 && (!isNaN(Number(orPart)) || [...orPart].every((char) => char === orPart[0]))) {
            // Either the part is a number or it is a string of the same character, so check sections or rows
            andListings.push(...matchingSales.filter((listing) => matchesRowFilter(listing.section, [orPart]) || matchesRowFilter(listing.row, [orPart])));
          } else {
            // Otherwise, check event name, venue name, city, state, section, row, marketplace name, marketplace order id, or sale id
            andListings.push(
              ...matchingSales.filter(
                (sale) =>
                  sale.event?.name.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.event?.venue.name.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.event?.venue?.city?.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.event?.venue?.state?.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.event?.performer?.name.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.event?.performer?.category?.name.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.section.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.row.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.marketplaceName.toLowerCase().includes(orPart.toLowerCase()) ||
                  sale.marketplaceOrderId?.toLowerCase() === orPart.toLowerCase() ||
                  sale.saleId.toLowerCase() === orPart.toLowerCase(),
              ),
            );
          }
        });

        matchingSales = andListings;
      });

      setFilteredSales([...matchingSales]);
    } else {
      setFilteredSales(salesData);
    }
  }, [salesQuickFilter, salesData]);

  type BNSalesColumn = ColDef<Sale> & {
    exclude?: boolean;
    allowSorting?: boolean;
  };
  const defaultCols = useMemo(
    () => ({
      width: 170,
      flex: 0,
      resizable: showSort,
      suppressMenu: true,
      suppressMovable: !showSort,
      sortable: showSort,
      filter: true,
    }),
    [showSort],
  );

  // @ts-ignore
  const dtiDefaultColumnDefs: BNSalesColumn[] = useMemo(() => {
    if (!isDtiHosted) {
      return [];
    }

    if (tenants === undefined || !tenants.some((t) => t.pointOfSaleId === 'DtiPortal')) {
      return [];
    }

    return [
      {
        // @ts-ignore
        field: 'vendorProperties.location',
        headerName: 'Location',
        width: 150,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.purchaserEmail',
        headerName: 'Purchaser',
        width: 200,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.orderNumber',
        headerName: 'Order #',
        width: 200,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.hasAirbill',
        headerName: 'Airbill',
        width: 100,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.transferRecipientName',
        headerName: 'Transfer Name',
        width: 200,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.transferRecipientEmail',
        headerName: 'Transfer Email',
        width: 200,
        hide: true,
      },
      {
        // @ts-ignore
        field: 'vendorProperties.paymentId',
        headerName: 'Payment ID',
        width: 150,
        hide: true,
      },
    ] satisfies BNSalesColumn[];
  }, [tenants]);

  const defaultColumnDefs: BNSalesColumn[] = useMemo(
    () =>
      [
        {
          headerName: 'Invoice ID',
          field: 'saleId',
          width: 100,
        },
        {
          headerName: 'Sale Date',
          field: 'createdAt',
          width: 160,
          valueFormatter: (params) => formatDate(params.value, DateFormats.GridFull),
          sort: 'desc',
          cellStyle: { fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        {
          headerName: 'Marketplace',
          field: 'marketplaceName',
          width: 140,
          cellStyle: { display: 'flex' },
          cellRenderer: (params: ICellRendererParams<Sale>) => <MarketplaceIdentifier value={params.value} />,
        },
        {
          headerName: 'External Ref.',
          field: 'marketplaceOrderId',
          width: 100,
          cellStyle: { textAlign: 'left' },
          hide: true,
        },
        {
          headerName: 'Event Name',
          field: 'event.name',
          minWidth: 200,
          flex: 1,
        },
        {
          headerName: 'Event Date',
          field: 'event.localDateTime',
          width: 160,
          valueFormatter: (params) => {
            if (params.data?.event) {
              return formatDate(params.data.event.localDateTime, DateFormats.GridFull);
            }

            return '';
          },
          cellStyle: { fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
        },
        {
          headerName: 'Performer',
          field: 'event.performer.name',
          width: 150,
          hide: true,
        },
        {
          headerName: 'Venue',
          field: 'event.venue.name',
          minWidth: 200,
          flex: 1,
        },
        {
          headerName: 'Category',
          field: 'event.performer.category.name',
          width: 110,
          cellStyle: { textAlign: 'center' },
          hide: true,
        },
        // {
        //   headerName: 'Status',
        //   field: 'event.statusId',
        //   width: 100,
        //   cellStyle: { textAlign: 'center' },
        // },
        {
          headerName: 'Section',
          field: 'section',
          width: 160,
          cellStyle: { textAlign: 'left' },
          comparator: (a, b) => a?.localeCompare(b, undefined, { numeric: true }),
        },
        {
          headerName: 'Row',
          field: 'row',
          minWidth: 70,
          width: 70,
          sortIndex: 1,
          cellStyle: { textAlign: 'center' },
          comparator: (a, b) => a?.localeCompare(b, undefined, { numeric: true }),
        },
        {
          headerName: 'Seats',
          colId: 'seats',
          minWidth: 90,
          width: 90,
          cellStyle: { textAlign: 'center' },
          valueGetter: (params) => {
            if (params.data) {
              const { seatFrom, seatThru, quantity } = params.data;
              return quantity > 1 ? `${seatFrom}-${seatThru}` : seatFrom;
            }

            return '';
          },
        },
        {
          headerName: 'Qty',
          field: 'quantity',
          width: 70,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'A/P',
          headerTooltip: 'Auto Priced',
          hide: true,
          field: 'isAutoPriced',
          width: 60,
          cellStyle: { textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' },
          cellRenderer: (params: ICellRendererParams) =>
            params.value ? (
              <Box className={`${classes.iconCircle} ${classes.selected} priceBadge`}>
                <AutoPriceIcon size={18} />
              </Box>
            ) : null,
        },
        {
          headerName: 'Unit Cost',
          field: 'unitCost',
          width: 100,
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          valueFormatter: (params) => {
            if (params.data) {
              return formatCurrency(params.data.unitCost);
            }

            return '';
          },
        },
        {
          headerName: 'Unit Price',
          field: 'unitPrice',
          width: 100,
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          valueFormatter: (params) => {
            if (params.data) {
              return formatCurrency(params.data.unitPrice);
            }

            return '';
          },
        },
        {
          headerName: 'Total Sale',
          colId: 'totalSale',
          width: 110,
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          valueGetter: (params) => {
            if (params.data) {
              const { unitPrice, quantity } = params.data;
              return unitPrice * quantity;
            }

            return 0;
          },
          valueFormatter: (params) => {
            if (params.data) {
              const { unitPrice, quantity } = params.data;
              return formatCurrency(unitPrice * quantity);
            }

            return '';
          },
        },
        {
          headerName: 'P/L',
          headerTooltip: 'Profit/Loss',
          width: 90,
          hide: false,
          cellClass: (params) => {
            if (params.data) {
              const { unitPrice, unitCost, quantity } = params.data;
              if ((unitPrice - unitCost) * quantity >= 0) {
                return 'profit';
              }
              return 'loss';
            }
            return null;
          },
          colId: 'profit-loss',
          valueGetter: (params) => {
            if (params.data) {
              const { unitPrice, unitCost, quantity } = params.data;
              return (unitPrice - unitCost) * quantity;
            }

            return 0;
          },
          valueFormatter: (params) => {
            if (params.data) {
              const { unitPrice, unitCost, quantity } = params.data;
              return formatCurrency((unitPrice - unitCost) * quantity);
            }

            return '';
          },
        },
        {
          headerName: 'ROI',
          headerTooltip: 'Return on Investment',
          colId: 'roi',
          width: 100,
          cellStyle: { textAlign: 'right', fontVariantNumeric: 'tabular-nums', letterSpacing: -0.65 },
          hide: true,
          valueGetter: (params) => {
            if (params.data) {
              const { unitPrice, unitCost, quantity } = params.data;

              if (unitCost > 0) {
                const totalCost = unitCost * quantity;
                const totalProfit = (unitPrice - unitCost) * quantity;
                return (totalProfit / totalCost) * 100;
              }
            }

            return 0;
          },
          valueFormatter: (params) => {
            if (params.data) {
              const { unitPrice, unitCost, quantity } = params.data;

              if (unitCost > 0) {
                const totalCost = unitCost * quantity;
                const totalProfit = (unitPrice - unitCost) * quantity;
                return `${((totalProfit / totalCost) * 100).toFixed(2)}%`;
              }
            }

            return '';
          },
        },
        {
          field: 'fulfillmentStatus',
          headerName: 'Fulfillment',
          width: 120,
          hide: !isDtiHosted,
          cellStyle: { textTransform: 'capitalize' },
          valueGetter: (params) => {
            // SkyBox
            if (params.data?.fulfillmentStatus?.status) {
              const skyboxFulfillmentStatus = params.data?.fulfillmentStatus as BarkerCoreModelsSalesSkyBoxFulfillmentStatus;
              return skyboxFulfillmentStatus.status.toLowerCase();
            }
            // TicketNetwork
            if (params.data?.fulfillmentStatus?.description) {
              const ticketNetworkFulfillmentStatus = params.data?.fulfillmentStatus as BarkerCoreModelsSalesTicketNetworkFulfillmentStatus;
              return ticketNetworkFulfillmentStatus.description.toLowerCase();
            }
            // TODO: Add others here
            return '';
          },
        },
        {
          field: 'paymentStatus',
          headerName: 'Payment',
          width: 120,
          hide: !isDtiHosted,
          cellStyle: { textTransform: 'capitalize' },
          valueGetter: (params) => {
            // SkyBox
            if (params.data?.paymentStatus?.status) {
              const skyboxPaymentStatus = params.data?.paymentStatus as BarkerCoreModelsSalesSkyBoxFulfillmentStatus;
              return skyboxPaymentStatus.status.toLowerCase();
            }

            // TODO: Add others here
            return '';
          },
        },
        { field: 'internalNotes', headerName: 'Internal Notes', hide: true, minWidth: 120 },
        { field: 'externalNotes', headerName: 'External Notes', hide: true, minWidth: 120 },
        {
          field: 'tags',
          headerName: 'Tags',
          hide: true,
          minWidth: 120,
          valueGetter: (params) => params.data?.tags?.join(',') ?? '',
          cellRenderer: (params: ICellRendererParams) => <BNTags tags={params.data?.tags} />,
        },
        {
          field: 'tenantId',
          headerName: 'Company',
          width: 200,
          hide: true,
          cellClass: (params) => {
            if (params.data && isMultiTenant) {
              const { tenantId } = params.data;
              if (tenantId) {
                return getTenantColorClass(principal?.settings?.tenantColors[tenantId]);
              }
            }
            return 'tenant-single';
          },
          valueGetter: (params) => tenants?.find((t) => t.tenantId === params.data?.tenantId)?.name,
        },
        ...(isDtiHosted ? dtiDefaultColumnDefs : []),
        ...[
          {
            colId: 'action',
            allowSorting: false,
            headerName: 'Action',
            suppressCellFlash: true,
            cellRenderer: (params: ICellRendererParams<Sale>) => {
              if (params.data) {
                return <SalesAction sale={params.data} setSelectedHistoryListing={setSelectedHistoryListing} />;
              }
              return null;
            },
            pinned: null,
            width: 40,
            minWidth: 40,
            maxWidth: 40,
          },
        ],
      ] as BNSalesColumn[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const columnDefs: ColDef<Sale>[] = useMemo(
    () =>
      defaultColumnDefs
        .map((col) => {
          // Clears properties before giving it to AG-Grid to avoid warnings.
          const { allowSorting, exclude, ..._col } = col;
          const savedState = { ...gridState?.filter((x) => !['dragCell'].includes(x.colId)).find((y) => y.colId === col.colId || y.colId === col.field) };

          return { ..._col, ...savedState } as ColDef<Sale>;
        })
        .sort((a, b) => {
          const aIndex = gridState?.findIndex((x) => x.colId === a.colId);
          const bIndex = gridState?.findIndex((x) => x.colId === b.colId);

          // If a column isn't in gridState, make it appear at the end, unless it's the action column
          if (bIndex === -1 || aIndex === -1) {
            if (a.colId === 'action') {
              return 1;
            }

            return -1;
          }

          return aIndex - bIndex;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSort],
  );
  const onGridReady = useCallback((event: GridReadyEvent) => {
    (window as any).gridRef = event;
    setGridReady(true);
  }, []);

  return {
    defaultColumnDefs,
    columnDefs,
    onGridReady,
    marketplaceBreakdown,
    isLoading,
    revenue,
    cost,
    profit,
    returnOnInvestment,
    totalEvents,
    totalTickets,
    totalWastedTickets,
    salesTrends,
    gridRef,
    search,
    reset,
    form,
    refetchSales,
    showSidebar,
    setShowSidebar,
    isRefetching,
    gridState,
    setGridState,
    clearGridState,
    gridReady,
    showSort,
    toggleShowSort,
    trySaveGridState,
    salesQuickFilter,
    setSalesQuickFilter,
    filter,
    setFilter,
    filteredSales,
    defaultCols,
    showDeleted,
    setShowDeleted,
    selectedHistoryListing,
    setSelectedHistoryListing,
    tags: selectedTenantsTags,
  };
}

export const { Provider: SalesProvider, useSelector: useSales } = yasml(SalesState);
