export function BrowserExtensionPopup() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
      }}
    >
      <h1>Your Extension is Working!</h1>
    </div>
  );
}
