import { Box, Group, Text } from '@mantine/core';
import { useMemo } from 'react';
import { useGlobalState } from '../../data/Global.state';

export function TenantIdentifier({ tenantId }: { tenantId: string }) {
  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const tenantName = useMemo(() => tenants?.find((t) => t.tenantId === tenantId)?.name || 'Unknown', [tenantId, tenants]);
  const tenantColor = principal?.settings?.tenantColors[tenantId] ?? 'var(--colors-brandcolor-5)';

  return (
    <Group gap="xs" align="center" wrap="nowrap" style={{ overflow: 'hidden' }}>
      <Box
        h={13}
        w={13}
        bg={tenantColor}
        style={{
          border: '1px solid var(--colors-paper)',
          borderRadius: '13px',
          flexShrink: 0,
        }}
      />
      <Text fw={400} truncate>
        {tenantName}
      </Text>
    </Group>
  );
}
