import { MarketListingsList } from '../MarketListings/MarketListings.List';
import { MarketplaceResultsSalesList } from './MarketplaceResults.SalesList';
import { MarketplaceResultsHeader } from './MarketplaceResultsHeader';
import { useDTIMarketplace } from '../DTI/DTI.Marketplace.hooks';
import { useAtomValue } from 'jotai/index';
import { selectedMarketplaceEventAtom } from '../../data/atoms';

export function MarketplaceResultsContainer() {
  const { resultsSelection, setResultsSelection } = useDTIMarketplace('resultsSelection', 'setResultsSelection');
  const globalSelectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);

  return (
    <>
      <MarketplaceResultsHeader setResultsSelection={setResultsSelection} resultsSelection={resultsSelection} />
      {resultsSelection === 'Listings' || globalSelectedMarketplaceEvent.marketplaceId !== 'SeatGeek' ? <MarketListingsList /> : <MarketplaceResultsSalesList />}
    </>
  );
}
