import { useForm } from '@mantine/form';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { onNextInventoryItem, onPrevInventoryItem } from '../Inventory/Inventory.hooks';
import {
  autoUpdateRuleOnTargetComparableChangesAtom,
  calculatedTargetPriceAtom,
  filtersDirtyAtom,
  filtersEmptyAtom,
  initiallyAutoPricedAtom,
  pendingListingUpdatesAtom,
  rejectPendingUpdatesByListingIdAtom,
  ruleDirtyAtom,
  ruleStateAtom,
  ruleStateResetAtom,
  seatingChartFiltersAtom,
  selectedEventAtom,
  selectedMarketplaceEventAtom,
  selectedMarketplaceIdAtom,
  selectedMergedListingAtom,
  selectedMergedListingPriceAtom,
  targetComparableFloorPriceRuleAtom,
  targetComparablesAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { BarkerCoreModelsPricingRule, BarkerCoreModelsPricingRuleBase, BarkerCoreModelsPricingSchedulePricerSettings, getApiPricingRulesRuleId } from '../../api';
import { openConfirmModal } from '@mantine/modals';
import { Text } from '@mantine/core';
import { useDidUpdate, useDisclosure, useLocalStorage } from '@mantine/hooks';
import yasml from '@thirtytech/yasml';
import { useRuleState } from '../../data/Rule.state';
import dayjs from 'dayjs';
import { useGlobalState } from '../../data/Global.state';
import { useIsDrasticChange } from '../../utils/price-utils';
import { useDidUpdateDeep, useEffectDeep } from '../../utils/use-memo-weak';
import { Rule } from '../../types';
import { useListingState } from '../../data/Listing.state';
import { formatCurrency } from '../../utils/formatters';
import { TenantIdListingId } from '../../models/tenantIdListingId';
import { useLocation } from 'react-router-dom';

function NowPricingState() {
  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const selectedEvent = useAtomValue(selectedEventAtom);
  const seatingChartFilters = useAtomValue(seatingChartFiltersAtom);
  const { tenants } = useGlobalState('tenants');
  const companySettings = useMemo(() => tenants?.find((t) => t.tenantId === selectedListing?.tenantId)?.settings ?? {}, [selectedListing?.tenantId, tenants]);
  const targetComparables = useAtomValue(targetComparablesAtom);
  const selectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const [pendingListingUpdates, setPendingListingUpdates] = useAtom(pendingListingUpdatesAtom);
  const initiallyAutoPriced = useAtomValue(initiallyAutoPricedAtom);
  const filtersDirty = useAtomValue(filtersDirtyAtom);
  const filtersEmpty = useAtomValue(filtersEmptyAtom);
  const ruleDirty = useAtomValue(ruleDirtyAtom);
  const [rule, setRule] = useAtom(ruleStateAtom);
  const ruleStateReset = useSetAtom(ruleStateResetAtom);
  const rejectPendingUpdatesByListingId = useSetAtom(rejectPendingUpdatesByListingIdAtom);
  const [isChangeConflict, setIsChangeConflict] = useState<BarkerCoreModelsPricingRule | null>();
  const [{ autoUpdateAutoPricedListPriceRuleId }, setTransientGlobalState] = useAtom(transientGlobalStateAtom);
  const selectedMarketplaceId = useAtomValue(selectedMarketplaceIdAtom);
  const currentSelectedListingPrice = useAtomValue(selectedMergedListingPriceAtom);
  const [autoUpdateRuleOnTargetComparableChanges, setAutoUpdateRuleOnTargetComparableChanges] = useAtom(autoUpdateRuleOnTargetComparableChangesAtom);
  const updateListing = useSetAtom(updateListingsAtom);
  const {
    isLoading: ruleLoading,
    isLoadingLive: isRuleLoadingLive,
    saveRule,
    isLocalRule,
    applyRuleToGroupedListings,
    disbandRule,
    addCurrentRuleToListing,
    processPendingUpdates,
    setFormKey,
    listingsByRuleId,
    saveRuleTemplate,
  } = useRuleState(
    'isLoading',
    'isLoadingLive',
    'saveRule',
    'isLocalRule',
    'applyRuleToGroupedListings',
    'disbandRule',
    'addCurrentRuleToListing',
    'processPendingUpdates',
    'setFormKey',
    'listingsByRuleId',
    'saveRuleTemplate',
  );
  const { updatePrice } = useListingState('updatePrice');
  const hasPendingUpdates =
    // Filter out inline price changes. Any rule changes will be covered by listingsByRuleId filter
    useAtomValue(pendingListingUpdatesAtom).filter(
      (x) =>
        listingsByRuleId?.map((y) => TenantIdListingId.create(y.tenantId, y.listingId).toString()).includes(TenantIdListingId.create(x.tenantId, x.listingId).toString()) ||
        x.property !== 'unitPrice',
    ).length > 0;

  const { cancelPendingChanges } = useListingState('cancelPendingChanges');

  const calculatedPrice = useAtomValue(calculatedTargetPriceAtom);

  const form = useForm<
    Omit<BarkerCoreModelsPricingRuleBase, 'tenantId' | 'marketplaceId' | 'pointOfSaleEventId' | 'filters'> & {
      listPrice: number | '';
      isScheduled: boolean;
    } & Partial<BarkerCoreModelsPricingSchedulePricerSettings>
  >({
    validateInputOnChange: false,
    validateInputOnBlur: true,
    initialValues: {
      listPrice: rule.isAutoPriced && (filtersDirty || ruleDirty) ? calculatedPrice : selectedListing?.unitPrice || 0,
      isAutoPriced: rule.isAutoPriced || rule.automationTypeId === 'AutoPrice' || false,
      floorPrice: rule.floorPrice,
      ceilingPrice: rule.ceilingPrice,
      adjustmentValue: Number((rule.adjustmentValue || 0).toFixed(4)),
      numActive: rule.numActive ?? 1,
      adjustmentTypeId: rule.adjustmentTypeId || 'Amount',
      staggerByTypeId: rule.staggerByTypeId || 'Amount',
      staggerByValue: rule.staggerByValue || 0,
      numComparables: rule.numComparables || 1,
      isScheduled: rule.automationTypeId === 'SchedulePrice',
      intervalMinutes: rule.schedulePricerSettings?.intervalMinutes,
      expiresAt: rule.schedulePricerSettings?.expiresAt ? selectedListing?.event.localDateTime : undefined,
      softFloor: rule.softFloor,
      pausedAt: rule.pausedAt,
      pausedBy: rule.pausedBy,
      warningNotes: rule.warningNotes,
    },
    transformValues: (values) => {
      if (values.isScheduled) {
        return {
          ...values,
          isAutoPriced: false,
          automationTypeId: 'SchedulePrice',
          schedulePricerSettings: {
            intervalMinutes: Number(values.intervalMinutes),
            expiresAt: values.expiresAt!,
          },
        } satisfies typeof values;
      }
      if (values.isAutoPriced) {
        return {
          ...values,
          automationTypeId: 'AutoPrice',
          schedulePricerSettings: undefined,
        } satisfies typeof values;
      }

      return { ...values, schedulePricerSettings: undefined } satisfies Partial<Rule>;
    },
    validate: {
      listPrice: (value) => (value === '' || (typeof value === 'number' && value < 0) ? 'List price must be zero or greater' : null),
      numComparables: (value) => (!value || value < 1 ? 'Number of comparables must be greater than 0' : null),
      floorPrice: (value, values) => {
        if (values.isAutoPriced || values.isScheduled) {
          if (!value) {
            return 'Floor price is required and cannot be $0';
          }
          if (value >= (values?.ceilingPrice || Infinity)) {
            return 'Floor price must be less than ceiling price';
          }
          if (companySettings.pricerSettings?.minimumFloorPrice && value < companySettings.pricerSettings.minimumFloorPrice) {
            return `Floor price must be greater than company limit of $${companySettings.pricerSettings.minimumFloorPrice}`;
          }
          if (values.isScheduled && Number(values.listPrice) < Number(values.floorPrice)) {
            return 'List price must be greater than or equal to floor price';
          }
        }
        return null;
      },
      intervalMinutes: (value, values) => {
        if (values.isScheduled && !value) {
          return 'Interval is required';
        }
        if (values.isScheduled && value && value < 5) {
          return 'Interval must be at least 5 minutes';
        }
        return null;
      },
      expiresAt: (value, values) => {
        if (values.isScheduled && !value) {
          return 'Expires At is required';
        }
        return null;
      },
      // numActive: (value) => (typeof value === 'number' && value < 1 ? 'Number of active tickets must be greater than 0' : null),
      ceilingPrice: (value, values) => {
        if (values.floorPrice && value && value <= values.floorPrice) {
          return 'Ceiling price must be larger than floor price';
        }
        if (values.isScheduled && Number(values.listPrice) > Number(values.ceilingPrice)) {
          return 'List price must be lower than or equal to floor price';
        }

        return null;
      },
      softFloor: {
        value: (value, values) => {
          if (values.softFloor?.value) {
            if (values.softFloor.value < 0) {
              return 'Soft floor value cannot be negative';
            }
          }
          return null;
        },
        period: (value, values) => {
          if (values.softFloor?.period === '0.00:00:00') {
            return 'Soft floor interval is required';
          }
          return null;
        },
      },
      adjustmentValue: (value, values) => {
        if (values.isScheduled && !value) {
          return 'Adjustment value must be greater than 0';
        }
        if (
          values.adjustmentTypeId === 'Percentage' &&
          (companySettings.pricerSettings?.maximumPercentageUnder ?? 0) > 0 &&
          typeof value === 'number' &&
          parseFloat((value - 1).toFixed(4)) < -(companySettings.pricerSettings?.maximumPercentageUnder ?? 0)
        ) {
          return `Adjustment value cannot be under -${(companySettings.pricerSettings?.maximumPercentageUnder ?? 0) * 100}%`;
        }
        if (
          values.adjustmentTypeId === 'Amount' &&
          (companySettings.pricerSettings?.maximumAmountUnder ?? 0) > 0 &&
          typeof value === 'number' &&
          value < -(companySettings.pricerSettings?.maximumAmountUnder ?? 0)
        ) {
          return `Adjustment value cannot be under -${formatCurrency(companySettings.pricerSettings?.maximumAmountUnder ?? 0)}`;
        }
        return null;
      },
    },
  });
  const { values: formValues, isDirty: formIsDirty, isValid: formIsValid, reset: formReset, setFieldValue, resetDirty } = form;
  const [manualLoading, setManualLoading] = useState(false);
  const isLoading = isRuleLoadingLive || manualLoading;
  const isRuleRoute = useLocation().pathname.includes('/rule');
  const isFormReady = (isRuleRoute && !ruleLoading) || (!!(selectedListing && !ruleLoading) && selectedListing?.quantityReserved === 0);
  const isGroupedListing = (selectedListing?.ruleCount || 0) > 1;
  const formDirty = formIsDirty();
  const formValid = formIsValid();

  useEffect(() => {
    if (autoUpdateAutoPricedListPriceRuleId === rule?.ruleId) {
      if (rule.automationTypeId !== 'SchedulePrice') {
        form.setFieldValue('isAutoPriced', true);
        setRule({ ...rule, isAutoPriced: true });
      }
      setTransientGlobalState((prev) => ({ ...prev, autoUpdateAutoPricedListPriceRuleId: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoUpdateAutoPricedListPriceRuleId, formValues.isAutoPriced]);

  useEffect(() => {
    if (!isRuleLoadingLive && rule.isAutoPriced && !rule.pausedAt && formValid && (formDirty || filtersDirty)) {
      if (calculatedPrice && calculatedPrice !== formValues.listPrice) {
        setFieldValue('listPrice', calculatedPrice);
      }
    }
  }, [calculatedPrice, rule.isAutoPriced, filtersDirty, formValid, isRuleLoadingLive, formDirty, setFieldValue, rule.automationTypeId, formValues.listPrice, rule.pausedAt]);

  useDidUpdate(() => {
    if (currentSelectedListingPrice && !rule.isAutoPriced && formValues.listPrice !== currentSelectedListingPrice && formValid) {
      form.resetDirty({ ...formValues, listPrice: currentSelectedListingPrice });
      form.setFieldValue('listPrice', currentSelectedListingPrice);
    }
  }, [currentSelectedListingPrice]);

  const formValuesWithoutListPrice = useMemo(() => {
    const { listPrice, ...rest } = formValues;
    return rest;
  }, [formValues]);

  useDidUpdateDeep(
    () => {
      setRule((prev) => ({ ...prev, ...formValuesWithoutListPrice }));
    },
    [formValuesWithoutListPrice],
    [setRule],
  );

  const [saving, setSaving] = useState(false);

  useEffectDeep(
    () => {
      if (!isRuleLoadingLive && (formDirty || ruleDirty) && (formValues.isAutoPriced || formValues.isScheduled) && !formValues.pausedAt && !saving && isGroupedListing) {
        applyRuleToGroupedListings(formValues.isScheduled ? formValues.listPrice || 0 : undefined);
      } else if (!isRuleLoadingLive && (formDirty || ruleDirty) && formValues.isScheduled && !saving && !isGroupedListing) {
        applyRuleToGroupedListings(formValues.listPrice || 0);
      } else if ((formDirty || ruleDirty) && !isGroupedListing && formValues.isAutoPriced) {
        applyRuleToGroupedListings();
      }
    },
    [formValues, rule.filters],
    [applyRuleToGroupedListings, formValues.isAutoPriced, formDirty, ruleDirty, isGroupedListing, isRuleLoadingLive],
  );

  const saveTemplate = useCallback(async () => {
    if (selectedListing) {
      await saveRuleTemplate({
        ...rule,
        marketplaceId: selectedMarketplaceEvent.marketplaceId!,
        pointOfSaleEventId: selectedListing.eventId,
        filters: {
          ...seatingChartFilters,
          noFilters: false,
        },
      });
    }
  }, [rule, saveRuleTemplate, seatingChartFilters, selectedListing, selectedMarketplaceEvent.marketplaceId]);

  const _saveRuleAndPrice = useCallback(
    async (values: typeof form.values) => {
      setManualLoading(true);

      if (values.softFloor && values.softFloor.value <= 0) {
        // eslint-disable-next-line no-param-reassign
        values.softFloor = undefined; // If soft floor is 0, remove it, the backend is expecting soft floor to be null if there's no valid value
      }

      if (selectedListing) {
        if ((values.isAutoPriced || values.isScheduled) && !isLocalRule) {
          const { data: ruleDiff } = await getApiPricingRulesRuleId(rule.ruleId!, {
            headers: {
              'x-tenant-id': selectedListing.tenantId,
            },
          });
          if (dayjs(ruleDiff.updatedAt).diff(rule.updatedAt) !== 0 && !isRuleRoute) {
            setIsChangeConflict(ruleDiff);
            setManualLoading(false);
            return;
          }
        }

        setAutoUpdateRuleOnTargetComparableChanges(false);
        setSaving(true);

        try {
          if (filtersDirty || ruleDirty || values.isScheduled) {
            await saveRule({
              ...rule!,
              ...values,
              marketplaceId: selectedMarketplaceEvent.marketplaceId!,
              pointOfSaleEventId: selectedListing.eventId,
              filters: {
                ...seatingChartFilters,
                noFilters: false,
              },
            });
          } else {
            // Filter out inline price changes. Any rule changes will be covered by listingsByRuleId filter
            await processPendingUpdates(pendingListingUpdates.filter((x) => listingsByRuleId?.map((y) => y.listingId).includes(x.listingId) || !x.inlinePriceChange));
          }

          if (((!values.isAutoPriced && !values.isScheduled) || values.pausedAt) && values.listPrice !== selectedListing.unitPrice && typeof values.listPrice === 'number') {
            await updatePrice(selectedListing!.tenantId, selectedListing!.listingId, values.listPrice, selectedListing.unitPrice, true);
          }
        } catch (error) {
          setSaving(false);
        }

        setManualLoading(false);
        setFormKey((x) => x + 1);
      }

      if (isRuleRoute && !selectedListing && selectedEvent && selectedMarketplaceEvent.marketplaceId) {
        await saveRule({
          ...rule!,
          ...values,
          marketplaceId: selectedMarketplaceEvent.marketplaceId,
          pointOfSaleEventId: selectedEvent.eventId,
          filters: {
            ...seatingChartFilters,
            noFilters: false,
          },
        });
        setFormKey((x) => x + 1);
      }
    },
    [
      filtersDirty,
      form,
      isLocalRule,
      isRuleRoute,
      listingsByRuleId,
      pendingListingUpdates,
      processPendingUpdates,
      rule,
      ruleDirty,
      saveRule,
      seatingChartFilters,
      selectedEvent,
      selectedListing,
      selectedMarketplaceEvent.marketplaceId,
      setAutoUpdateRuleOnTargetComparableChanges,
      setFormKey,
      updatePrice,
    ],
  );

  const saveRuleAndPrice = useCallback((values?: typeof form.values) => form.onSubmit((_formValues) => _saveRuleAndPrice(values ?? _formValues))(), [_saveRuleAndPrice, form]);

  const displayConfirmationDialog = useCallback(
    () =>
      new Promise((resolve) => {
        openConfirmModal({
          title: 'Disable Auto-Pricer?',
          children: <Text size="sm">Are you sure you want to disable the auto-pricer for this listing? This will also disband the group.</Text>,
          labels: { confirm: 'Yes', cancel: 'No' },
          confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
          cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
          closeButtonProps: { size: 'md' },
          onAbort: () => resolve(false),
          onConfirm: async () => {
            setFieldValue('isAutoPriced', false);
            setFieldValue('listPrice', selectedListing!.unitPrice);
            resetDirty({
              isAutoPriced: false,
              listPrice: selectedListing!.unitPrice,
              isScheduled: false,
            });
            disbandRule();
            resolve(true);
          },
        });
      }),
    [setFieldValue, selectedListing, resetDirty, disbandRule],
  );

  useDidUpdate(() => {
    // Handles condition where auto price is automatically enabled by drag and drop but wouldn't have been abled by default.
    if (formValues.isAutoPriced && formValues.floorPrice == null) {
      setAutoUpdateRuleOnTargetComparableChanges(true);
    }
  }, [formValues.isAutoPriced]);

  const targetComparableFloorPriceRule = useAtomValue(targetComparableFloorPriceRuleAtom);

  useEffect(() => {
    if (autoUpdateRuleOnTargetComparableChanges && !form.values.isScheduled) {
      form.setFieldValue('floorPrice', targetComparableFloorPriceRule.floorPrice);
      form.setFieldValue('ceilingPrice', targetComparableFloorPriceRule.ceilingPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetComparables, autoUpdateRuleOnTargetComparableChanges]);

  const onSchedulePricerToggle = useCallback(() => {
    if (!formValues.isScheduled) {
      // setFieldValue('floorPrice', selectedListing!.unitCost * 1.1);
      // setFieldValue('intervalMinutes', 60);
      if (selectedListing?.event.localDateTime) {
        setFieldValue('expiresAt', dayjs(selectedListing?.event.localDateTime).add(1, 'day').toDate());
      }
      setRule({ ...rule, isAutoPriced: false, automationTypeId: 'SchedulePrice' }, { enableAutoPricing: 'Scheduled' });
      if (selectedListing && !selectedListing?.ruleId) {
        setPendingListingUpdates((list) => [
          ...list,
          {
            listingId: selectedListing?.listingId!,
            tenantId: selectedListing?.tenantId!,
            property: 'ruleId',
            previousValue: selectedListing?.ruleId,
            value: rule.ruleId,
          },
        ]);
        selectedListing.ruleId = rule.ruleId;
        updateListing(selectedListing);
      }
      setPendingListingUpdates((list) => [
        ...list,
        {
          listingId: selectedListing?.listingId!,
          tenantId: selectedListing?.tenantId!,
          property: 'pricerStatusId',
          previousValue: selectedListing?.pricerStatusId!,
          value: 'Scheduled',
        },
      ]);
      setFormKey((x) => x + 1);
      if (isGroupedListing) {
        // Note: Use unitPrice because formValues.listPrice is still manipulated by the rule
        applyRuleToGroupedListings(selectedListing?.unitPrice || 0);
      }
    } else {
      // Note: This doesn't run anymore but keeping it around for a bit
      setFieldValue('expiresAt', undefined);
      setFieldValue('intervalMinutes', undefined);
      setFieldValue('floorPrice', undefined);
      setFieldValue('adjustmentValue', undefined);
      setRule((r) => ({ ...r, isAutoPriced: false, automationTypeId: 'None', schedulePricerSettings: undefined }));
      setFormKey((x) => x + 1);
    }
  }, [applyRuleToGroupedListings, formValues.isScheduled, isGroupedListing, rule, selectedListing, setFieldValue, setFormKey, setPendingListingUpdates, setRule, updateListing]);

  const onAutoPricerToggle = useCallback(
    async (e: SyntheticEvent<HTMLInputElement, Event> | boolean) => {
      const checked = typeof e !== 'boolean' ? (e.target as HTMLInputElement).checked : e;
      if (!checked && isGroupedListing) {
        displayConfirmationDialog();
      } else if (!checked && isLocalRule) {
        setFieldValue('isAutoPriced', checked);
        setFieldValue('isScheduled', false);
        setFieldValue('listPrice', selectedListing?.unitPrice || 0);
        setFieldValue('pausedAt', null);
        setFieldValue('pausedBy', null);
        rejectPendingUpdatesByListingId(selectedListing?.listingId!);
        setRule(null);
        setRule({ ...rule, pausedAt: null, pausedBy: null, isAutoPriced: false, automationTypeId: 'None', filters: { ...rule.filters } });
      } else if (!checked && !isLocalRule && !isGroupedListing) {
        setFieldValue('isAutoPriced', false);
        setFieldValue('isScheduled', false);
        setFieldValue('listPrice', selectedListing!.unitPrice);
        setFieldValue('pausedAt', null);
        setFieldValue('pausedBy', null);
        rejectPendingUpdatesByListingId(selectedListing?.listingId!);
        setRule({ ...rule, pausedAt: null, pausedBy: null, isAutoPriced: false, automationTypeId: 'None' });
      } else {
        setFieldValue('isAutoPriced', true);
        setFieldValue('isScheduled', false);
        setFieldValue('listPrice', selectedListing!.unitPrice);
        setFieldValue('pausedAt', null);
        setFieldValue('pausedBy', null);
        setAutoUpdateRuleOnTargetComparableChanges(true);
        setRule({ ...rule, pausedAt: null, pausedBy: null, isAutoPriced: checked, automationTypeId: 'AutoPrice' }, { enableAutoPricing: 'Auto-Pricing' });
        addCurrentRuleToListing();
        if (formValues.isScheduled && isGroupedListing) {
          // Figure out what happens when there is no target comparables. Old pending updates stick around.
          applyRuleToGroupedListings();
        }
      }
      setFormKey((x) => x + 1);
    },
    [
      addCurrentRuleToListing,
      applyRuleToGroupedListings,
      displayConfirmationDialog,
      formValues.isScheduled,
      isGroupedListing,
      isLocalRule,
      rejectPendingUpdatesByListingId,
      rule,
      selectedListing,
      setAutoUpdateRuleOnTargetComparableChanges,
      setFieldValue,
      setFormKey,
      setRule,
    ],
  );

  const cancel = useCallback(async () => {
    if (isGroupedListing && isLocalRule) {
      const result = await displayConfirmationDialog();
      if (!result) {
        return;
      }
    }
    formReset();
    ruleStateReset({ retainFilters: !rule.isAutoPriced });
    cancelPendingChanges(
      // Filter out inline price changes. Any rule changes will be covered by listingsByRuleId filter
      listingsByRuleId?.map((x) => x.listingId).concat(pendingListingUpdates.filter((x) => !x.inlinePriceChange).map((x) => x.listingId)),
    );
    setFormKey((x) => x + 1);
  }, [
    cancelPendingChanges,
    displayConfirmationDialog,
    formReset,
    isGroupedListing,
    isLocalRule,
    listingsByRuleId,
    pendingListingUpdates,
    rule.isAutoPriced,
    ruleStateReset,
    setFormKey,
  ]);

  const nextListing = useCallback(async () => {
    document.dispatchEvent(onNextInventoryItem);
  }, []);
  const prevListing = useCallback(async () => {
    document.dispatchEvent(onPrevInventoryItem);
  }, []);

  const isDrasticChange = useIsDrasticChange();

  const _isDrasticChange = useMemo(
    () => typeof formValues.listPrice === 'number' && isDrasticChange(formValues.listPrice, selectedListing?.unitPrice!, selectedListing?.unitCost!),
    [formValues.listPrice, isDrasticChange, selectedListing?.unitCost, selectedListing?.unitPrice],
  );

  const hasSectionRows = [...(rule.filters.sectionRows?.values() ?? [])]?.some((x) => x.sectionId !== '');

  const isNoSectionRule = useMemo(
    () => rule.isAutoPriced && rule?.filters?.sectionIds?.length === 0 && !hasSectionRows,
    [hasSectionRows, rule?.filters?.sectionIds?.length, rule.isAutoPriced],
  );

  const refreshRule = useCallback(async () => {
    const { data: updatedRule } = await getApiPricingRulesRuleId(rule.ruleId!, {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    });
    setRule(updatedRule, { forceUpdate: true, currentListingId: selectedListing?.tenantIdListingId });
    setIsChangeConflict(null);
    setFormKey((x) => x + 1);
  }, [rule.ruleId, selectedListing?.tenantId, selectedListing?.tenantIdListingId, setRule, setFormKey]);

  const [isDrasticChangeSnoozed, setIsDrasticChangeSnoozed] = useLocalStorage<Date | undefined>({
    key: 'drasticChangeSnooze',
  });

  const [isDrasticChangeDialog, { open: openDrasticChangeDialog, close: closeDrasticChangeDialog }] = useDisclosure();
  const [isNoSectionDialog, { open: openNoSectionDialog, close: closeNoSectionDialog }] = useDisclosure(false);
  const onSave = form.onSubmit((values) => {
    if (!values.isAutoPriced && _isDrasticChange && dayjs(isDrasticChangeSnoozed).diff(dayjs()) <= 0) {
      openDrasticChangeDialog();
    } else if (isNoSectionRule) {
      openNoSectionDialog();
    } else {
      saveRuleAndPrice();
    }
  });

  return {
    form,
    selectedListing,
    saveRuleAndPrice,
    isLoading,
    isGroupedListing,
    isFormReady,
    nextListing,
    prevListing,
    onAutoPricerToggle,
    cancel,
    filtersDirty,
    filtersEmpty,
    ruleDirty,
    selectedMarketplaceEvent,
    hasPendingUpdates,
    initiallyAutoPriced,
    isDrasticChange: _isDrasticChange,
    isChangeConflict,
    refreshRule,
    setAutoUpdateRuleOnTargetComparableChanges,
    selectedMarketplaceId,
    isNoSectionRule,
    onSchedulePricerToggle,
    isDirty: ruleDirty || filtersDirty,
    openDrasticChangeDialog,
    closeDrasticChangeDialog,
    openNoSectionDialog,
    closeNoSectionDialog,
    onSave,
    isNoSectionDialog,
    isDrasticChangeDialog,
    setIsDrasticChangeSnoozed,
    saving,
    saveTemplate,
  };
}

export const { Provider: NowPricingProvider, useSelector: useNowPricing } = yasml(NowPricingState);
