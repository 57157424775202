import { Box, Group, Tooltip } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { BarkerCoreEnumsAdjustmentType } from '../../api';
import { BNNumberInput } from '../NumberInput/NumberInput';
import { BNSelect } from '../Select/Select';
import { roundAdjustmentValue } from '../../utils/rule-utils';
import DiagonalButton from '../DiagonalButton';
import classes from './AdjustmentValueInputDiagonal.module.css';
import cx from 'clsx';

export function AdjustmentValueInputDiagonal({
  type,
  numInputWidth,
  disabled,
  label,
  value,
  onChange,
  error,
  onKeyUp,
  disablePercentage,
  step,
  tooltipLabel,
  round,
  hideOverUnder,
  min,
}: {
  type: BarkerCoreEnumsAdjustmentType;
  step?: number;
  disablePercentage?: boolean;
  numInputWidth?: number;
  value: number | '';
  label?: string | ReactNode | undefined;
  tooltipLabel?: string | undefined;
  onChange: (value: { value: number | undefined; type: BarkerCoreEnumsAdjustmentType }) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  round?: boolean;
  error?: React.ReactNode;
  hideOverUnder?: boolean;
  min?: number;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  // Updated from outside world
  useDidUpdate(() => {
    const convertedValue = convertValue(value, type);
    if (convertedValue !== _valueInternal && type === _typeInternal) {
      _setValueInternal(convertedValue);
    } else if (type !== _typeInternal) {
      _setTypeInternal(type);
      _setValueInternal(convertedValue);
    }
  }, [value, type]);

  const convertValue = useCallback((_value: number | '', _type: BarkerCoreEnumsAdjustmentType) => {
    if (_value === 0 || _value === '' || _value === undefined || _value === null) {
      return 0;
    }
    let result = _value;
    if (_type === 'Percentage' && (_value <= 1 || _value > 1)) {
      result = parseFloat(((_value - 1) * 100).toFixed(4));
    }
    return result;
  }, []);

  const [_valueInternal, _setValueInternal] = useState(convertValue(value, type));
  const [_typeInternal, _setTypeInternal] = useState(type);
  const [isPositive, setIsPositive] = useState(hideOverUnder ? true : value && value > (type === 'Percentage' ? 1 : 0) ? true : false);

  const _onChangeInternal = (_value: number | '', _type?: BarkerCoreEnumsAdjustmentType, _isPositive?: boolean) => {
    type = _type || type;
    const isPositiveLocal = _isPositive ?? isPositive;
    if (_value === '' || _value === undefined || _value === null) {
      _setValueInternal(0);
      return onChange({ value: type === 'Percentage' ? 1 : 0, type: type });
    }

    let result = _value;

    if (type === 'Amount') {
      result = isPositiveLocal ? Math.abs(_value) : _value < 0 ? _value : -_value;
    } else if (type === 'Percentage') {
      result = parseFloat((1 + ((isPositiveLocal ? 1 : -1) * Math.abs(_value)) / 100).toFixed(4));
    }

    if (round) {
      result = roundAdjustmentValue(result)!;
    }

    _setValueInternal(_value);
    onChange({ value: result, type: type });
  };

  const _dualOnChangeHandler = useCallback(
    (type: BarkerCoreEnumsAdjustmentType) => {
      _setTypeInternal(type);
      _onChangeInternal(_valueInternal, type);
    },
    [_valueInternal],
  );

  return (
    <Group gap={0} wrap="nowrap" pos="relative" align="end">
      <Tooltip withArrow label={tooltipLabel} hidden={!tooltipLabel}>
        <BNSelect
          onChange={(x) => _dualOnChangeHandler(x as BarkerCoreEnumsAdjustmentType)}
          value={!disablePercentage ? _typeInternal : BarkerCoreEnumsAdjustmentType.Amount}
          m={0}
          error={error}
          mr={hideOverUnder ? 0 : -1}
          p={0}
          size="xs"
          className={cx([classes.middleInput, classes.shortSelectRoot, isPositive && classes.isPositive, hideOverUnder && classes.hideOverUnder])}
          styles={{ input: { paddingRight: 0 } }}
          dropdownWidth={40}
          rightSectionWidth={20}
          label={label}
          labelProps={{ className: classes.adjustmentNumLabel }}
          disabled={disabled}
          readOnly={disablePercentage}
          comboboxProps={{ styles: { option: { paddingLeft: 4, paddingRight: 4, display: 'flex', justifyContent: 'center' } } }}
          data={[{ value: BarkerCoreEnumsAdjustmentType.Amount, label: '$' }, ...(disablePercentage ? [] : [{ value: BarkerCoreEnumsAdjustmentType.Percentage, label: '%' }])]}
        />
      </Tooltip>
      {!hideOverUnder && (
        <Box pos="relative" style={{ zIndex: 1 }}>
          <DiagonalButton
            value={isPositive}
            error={error}
            toggleValue={(value) => {
              setIsPositive(value);
              _onChangeInternal(_valueInternal, type, value);
            }}
          />
        </Box>
      )}
      <BNNumberInput
        ref={inputRef}
        disabled={disabled}
        error={error}
        value={Math.abs(_valueInternal)}
        onChange={(x) => _onChangeInternal(x)}
        onKeyUp={onKeyUp}
        onKeyDown={(e) => {
          if (e.key === '-') {
            setIsPositive(false);
            e.preventDefault();
            e.stopPropagation();
          } else if (e.key === '+') {
            setIsPositive(true);
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        radius={0}
        m={0}
        ml={-1}
        p={0}
        w={numInputWidth}
        className={`${classes.priceInput} ${isPositive ? classes.isPositive : ''}`}
        size="xs"
        label={label}
        labelProps={{ maw: '0', display: 'none' }}
        aria-label="Adjustment Amount"
        decimalScale={2}
        fixedDecimalScale
        selectOnFocus
        stepHoldDelay={500}
        stepHoldInterval={100}
        min={min ?? 0}
        step={step || 0.1}
      />
    </Group>
  );
}
