import { Box, Combobox, Flex, Pill, PillsInput, TextInput, Tooltip, UnstyledButton } from '@mantine/core';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import classes from './RowsInput.module.css';
import cx from 'clsx';
import { BNTextInput } from '../TextInput/TextInput';
import { BNSwitch } from '../Switch/Switch';

type BNRowsInputProps = {
  mobile?: boolean;
  mobileClick?: () => void;
  advancedRows?: Map<string, string[]>;
  advancedRowsActiveIndex: number;
  setAdvancedRowsActiveIndex: Dispatch<SetStateAction<number>>;
  handleValueAdd: (val: string) => void;
  handleValueRemove: (val: string) => void;
  handleValueUpdate: (oldValue: string, newValue: string) => void;
  rows: string | undefined;
  onRowChange: (val: string) => void;
  removeAdvancedRows: () => void;
};

export function BNRowsInput({
  mobile,
  mobileClick,
  advancedRowsActiveIndex,
  setAdvancedRowsActiveIndex,
  advancedRows,
  onRowChange,
  rows,
  handleValueUpdate,
  handleValueAdd,
  handleValueRemove,
  removeAdvancedRows,
}: BNRowsInputProps) {
  const advancedRowsEnabled = advancedRows !== undefined;
  const [internalText, setInternalText] = useState('');
  const freeText = !advancedRowsEnabled ? rows : internalText;

  const _handleTextChange = (value: string) => {
    if (!advancedRowsEnabled) {
      onRowChange(value);
    } else {
      setInternalText(value);
    }
  };

  const _handleValueConvert = () => {
    if (advancedRows && Object.keys(advancedRows).length > 0) {
      Object.keys(advancedRows).forEach((item) => handleValueRemove(item));
    }

    handleValueAdd(freeText ?? '');
  };

  const _values = [...(advancedRows?.keys() || [])]
    .filter((x) => x)
    .map((item, index) => {
      return (
        <Flex key={item} pos="relative" className={classes.customPillWrapper}>
          <CustomPill
            selected={advancedRowsActiveIndex === index}
            setSelected={setAdvancedRowsActiveIndex}
            item={item}
            index={index}
            handleValueRemove={() => handleValueRemove(item)}
            handleValueUpdate={handleValueUpdate}
          />
        </Flex>
      );
    });

  return (
    <Box pos="relative" className={cx(mobile && classes.mobile)}>
      <Tooltip withArrow label="Set Rows by Section">
        <Box pos="absolute" top={6} right={0}>
          <BNSwitch
            // disabled={!!selectedTenantListingId}
            checked={advancedRowsEnabled}
            onChange={() => {
              if (advancedRowsEnabled) {
                const rows = [...advancedRows.keys()].join(' ');
                removeAdvancedRows();
                onRowChange(rows);
              } else {
                if (freeText && freeText !== '') {
                  _handleValueConvert();
                  onRowChange('');
                  setAdvancedRowsActiveIndex(0);
                } else {
                  handleValueAdd('');
                }
              }
            }}
            size="xxs"
            label="ADVANCED"
          />
        </Box>
      </Tooltip>
      {advancedRowsEnabled ? (
        <Combobox>
          <PillsInput size={mobile ? 'sm' : 'xs'} label="Rows" className={classes.pillsInput} id="bnSectionRowsInput">
            <Pill.Group gap="4">
              {_values}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  value={!advancedRowsEnabled ? freeText : internalText}
                  // placeholder="Enter Rows"
                  onBlur={(event) => {
                    if (freeText !== '') {
                      handleValueAdd(freeText ?? '');
                      _handleTextChange('');
                    }
                  }}
                  onChange={(event) => _handleTextChange(event.currentTarget.value)}
                  onKeyUp={(event) => {
                    // Note: I don't think we should do this without a modifier. Easy to over to delete and remove legit row filter.
                    // Adding shift modfier to make it harder
                    if (event.shiftKey && event.key === 'Backspace' && freeText?.length === 0) {
                      event.preventDefault();
                      if (advancedRows && Object.keys(advancedRows).length > 0) {
                        handleValueRemove(Object.keys(advancedRows)[advancedRowsActiveIndex]);
                      }
                    }
                    if (event.key === 'Enter' && (freeText?.length || 0) > 0) {
                      event.preventDefault();
                      handleValueAdd(freeText ?? '');
                      _handleTextChange('');
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
          {/* Add transparent button overlay on mobile to prevent focusing the input & trigger the rows modal for editing */}
          {mobile && <UnstyledButton variant="transparent" className={classes.mobileClickOverlay} onClick={mobileClick} />}
        </Combobox>
      ) : (
        <BNTextInput
          size={mobile ? 'sm' : 'xs'}
          placeholder=""
          label="Rows"
          value={freeText ?? ''}
          onChange={(event) => _handleTextChange(event.currentTarget.value)}
          clearable
          clearableOnClick={() => {
            _handleTextChange('');
          }}
        />
      )}
    </Box>
  );
}

type CustomPillProps = {
  item: string;
  index: number;
  handlePillClick?: (val: string) => void;
  handleValueRemove: (val: string) => void;
  handleValueUpdate: (oldValue: string, newValue: string) => void;
  selected: boolean;
  setSelected: Dispatch<SetStateAction<number>>;
};

function CustomPill({ item, index, handleValueRemove, handleValueUpdate, selected, setSelected }: CustomPillProps) {
  // Ref used for text input inside each custom pill button for editing the content
  const inputRef = useRef<HTMLInputElement | null>(null);

  // State for changing a pill button into an editable text input
  const [editable, setEditable] = useState(false);

  // Functions for selecting, editing, updating pill button content
  const handlePillSelection = () => {
    setSelected(index);
  };
  const handlePillEdit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    inputRef.current!.focus();
    setEditable(true);
  };
  const handlePillUpdate = (event: any) => {
    if (event.key === 'Enter' && inputRef.current!.value.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      setEditable(false);
      handleValueUpdate(item, inputRef.current!.value);
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      inputRef.current!.blur();
    }
  };

  return (
    <Flex key={item} pos="relative" className={`rows-${index}`}>
      <Pill
        withRemoveButton
        className={cx(classes.customPill, selected && classes.selected)}
        onClick={selected ? (event) => handlePillEdit(event) : () => handlePillSelection()}
        onRemove={() => handleValueRemove(item)}
      >
        {item}
      </Pill>
      <TextInput
        onKeyDown={(event) => handlePillUpdate(event)}
        onBlur={(event) => setEditable(false)}
        ref={inputRef}
        size="xs"
        top={-2}
        left={0}
        right={0}
        defaultValue={item}
        pos="absolute"
        miw="fit-content"
        className={editable ? classes.editable : classes.noteditable}
      />
    </Flex>
  );
}
