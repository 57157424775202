import { IconPropsBase } from './IconPropsBase';

export default function AutoPauseIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M13.1,15.75v-7.5h1.5v7.5h-1.5ZM11.1,4.05v-1.5c-.98.09-1.92.32-2.81.71-.87.37-1.65.85-2.35,1.43l1.05,1.05c1.19-.96,2.56-1.52,4.1-1.68ZM18.05,4.67c-.7-.58-1.48-1.05-2.35-1.43-.89-.38-1.82-.62-2.8-.7v1.5c1.55.15,2.91.71,4.1,1.68l1.05-1.05ZM21.46,11.1c-.09-.98-.32-1.92-.71-2.81-.37-.87-.85-1.65-1.43-2.35l-1.05,1.05c.96,1.19,1.52,2.56,1.68,4.1h1.5ZM17.67,17.67c-1.55,1.55-3.44,2.33-5.67,2.33s-4.12-.78-5.67-2.33c-.56-.56-1.01-1.18-1.37-1.83h2.71v-1.34H2.55v5.12h1.34v-2.69c.4.66.86,1.26,1.39,1.8.86.85,1.86,1.53,3.02,2.03,1.16.5,2.39.75,3.71.75s2.55-.25,3.7-.75c1.16-.5,2.16-1.18,3.02-2.03.85-.86,1.53-1.86,2.03-3.02.13-.3.24-.6.33-.91l-1.45-.32c-.37,1.19-1.02,2.26-1.96,3.2ZM5.72,7l-1.05-1.05c-.58.7-1.05,1.48-1.43,2.35-.38.89-.62,1.82-.7,2.8h1.5c.15-1.55.71-2.91,1.68-4.1ZM10.9,15.75v-7.5h-1.5v7.5h1.5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M11.04,1.74c.77.09,1.51.28,2.21.59.68.29,1.3.66,1.86,1.1l-.88.88c-.94-.71-2-1.15-3.19-1.31v-1.26ZM12.29,6.67h-1.25v6.67h1.25v-6.67ZM17.68,6.75c-.29-.68-.66-1.3-1.1-1.86l-.88.88c.71.94,1.15,2,1.31,3.19h1.26c-.09-.77-.28-1.51-.59-2.21ZM2.32,6.75c-.3.7-.49,1.44-.59,2.21h1.26c.16-1.18.6-2.24,1.31-3.18l-.88-.88c-.44.56-.81,1.17-1.1,1.85ZM5.78,4.3c.94-.71,2-1.15,3.18-1.31v-1.26c-.76.09-1.49.28-2.19.59-.68.29-1.3.67-1.87,1.1l.88.88ZM8.96,13.33v-6.67h-1.25v6.67h1.25ZM2.99,16.27v-4.16h-1.25v4.16h1.25ZM1.74,13.36h4.16v-1.25H1.74v1.25ZM16.78,12.11c-.32,1.08-.9,2.05-1.75,2.9-1.38,1.38-3.05,2.07-5.02,2.07s-3.63-.69-5.01-2.07c-.86-.86-1.44-1.83-1.76-2.9h-1.28c.1.38.22.76.38,1.12.44,1.01,1.03,1.9,1.79,2.66.76.76,1.64,1.35,2.66,1.79,1.01.44,2.09.66,3.23.66s2.24-.22,3.25-.66,1.9-1.03,2.65-1.79c.75-.76,1.34-1.64,1.78-2.66.16-.37.28-.74.38-1.12h-1.28Z"
        />
      </svg>
    );
  }
}
