import { IconPropsBase } from './IconPropsBase';

export default function WarningIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M1.87,19.75L12,2.25l10.13,17.5H1.87ZM4.45,18.25h15.1l-7.55-13-7.55,13ZM12,17.06c.23,0,.42-.08.58-.23.15-.15.23-.35.23-.58s-.08-.42-.23-.58-.35-.23-.58-.23c-.23,0-.42.08-.58.23s-.23.35-.23.58c0,.23.08.42.23.58.15.15.35.23.58.23ZM11.25,14.44h1.5v-5h-1.5v5Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
        <path
          fill={color}
          d="M1.72,16.04L10,2.29l8.28,13.75H1.72ZM3.65,14.96h12.71l-6.35-10.58L3.65,14.96ZM10,13.8c.17,0,.31-.06.42-.17s.17-.25.17-.42-.06-.31-.17-.42-.25-.17-.42-.17-.31.06-.42.17-.17.25-.17.42.06.31.17.42.25.17.42.17ZM9.46,11.62h1.08v-4h-1.08v4Z"
        />
      </svg>
    );
  }
}
