import { BNButton } from '../../../components/Button/Button';
import LibraryAddIcon from '../../../components/icons/LibraryAdd';
import SelectArrowsIcon from '../../../components/icons/SelectArrows';
import SettingsIcon from '../../../components/icons/Settings';
import { useBulkState } from '../../../data/Bulk.state';
import classes from '../Inventory.MultiSelectActionbar.styles.tsx.module.css';
import { Box, Menu, Text } from '@mantine/core';

export function Admin() {
  const { removeRule } = useBulkState('removeRule');
  return (
    <>
      <Menu width={160} withArrow offset={0}>
        <Menu.Target>
          <Box className={classes.actionButtonContainer}>
            <BNButton
              c="var(--colors-selectedBorder)"
              variant="subtle"
              size="xs"
              leftSection={<SettingsIcon />}
              rightSection={<SelectArrowsIcon />}
              className={classes.actionButton}
            >
              <Text truncate>Admin</Text>
            </BNButton>
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          <Box>
            <Menu.Item leftSection={<LibraryAddIcon />} fz="xs" py={6} onClick={removeRule}>
              Remove Rule
            </Menu.Item>
          </Box>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
