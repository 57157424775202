import { useAtomValue } from 'jotai';
import { useGlobalState } from '../data/Global.state';
import { selectedMergedListingAtom } from '../data/atoms';
import { useMemo } from 'react';

export const useIsDrasticChange = (tenantId?: string) => {
  const selectedTenantId = useAtomValue(selectedMergedListingAtom)?.tenantId;
  const { tenants } = useGlobalState('tenants');
  const companySettings = tenants?.find((x) => x.tenantId === tenantId || x.tenantId === selectedTenantId)?.settings;
  const percentage = companySettings?.pricerSettings?.drasticPriceThresholdPercentage ?? 0;
  const threshold = companySettings?.pricerSettings?.drasticPriceThresholdAmount ?? 0;
  return useMemo(() => isDrasticChange.bind(null, percentage, threshold), [percentage, threshold]);
};

export function isDrasticChange(drasticPriceChange: number, minThreshold: number, price: number, unitPrice: number, unitCost: number) {
  let _isDrasticChange = false;

  if (price !== 0) {
    const priceDifference = Math.abs(unitPrice - price);
    const upperThreshold = unitPrice * (1 + drasticPriceChange);
    const lowerThreshold = unitPrice * (1 - drasticPriceChange);

    if (unitPrice === 0 && price < unitCost) {
      _isDrasticChange = true;
    } else if (unitPrice !== 0 && priceDifference > minThreshold && (price > upperThreshold || price < lowerThreshold)) {
      _isDrasticChange = true;
    }
  }

  return _isDrasticChange;
}

export function parseCurrency(value: string) {
  return parseFloat(value.replace(/[^0-9.-]+/g, ''));
}

export function isNumber(value: string) {
  return !isNaN(parseFloat(value));
}

export function range(a: number, b: number) {
  const min = Math.min(a, b);
  const max = Math.max(a, b);
  const arr = [];
  for (let i = min; i <= max; i++) {
    arr.push(i);
  }
  return arr;
}

export function toCamelCase(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
}
