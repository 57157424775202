import { Box, Stack } from '@mantine/core';
import { useMemo } from 'react';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import classes from './SeasonEvents.List.module.css';
import { useSeasonPricer } from '../../data/SeasonsPricer.state';
import { BarkerCoreModelsInventoryEvent } from '../../api';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { useAtomValue } from 'jotai';
import { formatSeatRange } from '../../utils/formatters';
import { EventCard } from './SeasonEvents.EventCard';

type EventCard = {
  listingId: string;
  event: BarkerCoreModelsInventoryEvent;
  variant: 'default' | 'nomapping' | 'autopriced';
  isLoading: boolean;
  uniqueQtyInfo: {
    qty: string;
    seats: string;
  } | null;
};

export function SeasonEventsList() {
  const { selectedLocation, eventMappings, inventoryEvents, listingsAtom, marketplaceId, scrollableRef, targetRef, isLoading } = useSeasonPricer(
    'selectedLocation',
    'eventMappings',
    'inventoryEvents',
    'listingsAtom',
    'marketplaceId',
    'scrollableRef',
    'targetRef',
    'isLoading',
  );
  const listings = useAtomValue(listingsAtom);
  const maxListings = selectedLocation?.listings.reduce((max, x) => Math.max(max, x.quantity), 0);

  const rowData: EventCard[] = useMemo(
    () =>
      selectedLocation?.listings
        .map((x) => {
          const hasMapping = eventMappings.some((y) => y.eventId === x.eventId && y.marketplaceId === marketplaceId);
          // TODO: This is not right but will sorta work for now.
          const uniqueSeating = x.quantity !== maxListings ? selectedLocation.listings.filter((y) => x.seatFrom !== y.seatFrom || x.seatThru !== y.seatThru) : [];
          const listing = listings[x.listingId];

          return {
            isLoading: !listing,
            listingId: x.listingId,
            event: inventoryEvents.find((y) => y?.eventId === x.eventId)!,
            uniqueQtyInfo:
              uniqueSeating.length > 0
                ? {
                    qty: x.quantity.toString(),
                    seats: formatSeatRange(x.seatFrom, x.seatThru, x.quantity),
                  }
                : null,

            variant: !hasMapping ? 'nomapping' : listing && listing.pricerStatusId !== 'None' ? 'autopriced' : 'default',
          } satisfies EventCard;
        })
        .sort((a, b) => {
          const dateA = a.event?.localDateTime.getTime() ?? 0;
          const dateB = b.event?.localDateTime.getTime() ?? 0;
          return dateA - dateB;
        }) || [],
    [eventMappings, inventoryEvents, listings, marketplaceId, maxListings, selectedLocation?.listings],
  );

  function CustomNoRowsOverlay() {
    return (
      <BNEmptyState
        h="90%"
        border={false}
        icon={<MobileDataOffIcon color="var(--colors-iconFill)" size={28} />}
        iconBg="var(--colors-opacity-hover)"
        title="No Results Found"
        description="Try adjusting the filter options"
      />
    );
  }

  return (
    <Box className={classes.outerWrapper}>
      <Stack gap={0} className={classes.innerWrapper}>
        <Box className={classes.listWrapper} pos="relative">
          {!isLoading && rowData.length === 0 && <CustomNoRowsOverlay />}
          {!isLoading && rowData.length > 0 && (
            <Box className={classes.scrollWrapper} ref={scrollableRef}>
              {rowData.map((row, index) => {
                const { isLoading: rowLoading } = row;
                return <EventCard key={rowData[index].listingId} {...row} isLoading={isLoading || rowLoading} targetRef={targetRef} />;
              })}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
