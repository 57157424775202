import { Badge, Center, Flex, Group, HoverCard, Tooltip } from '@mantine/core';
import TagIcon from '../../components/icons/Tag';
import { Broadcasting } from './Inventory.Broadcasting';
import classes from './Inventory.Properties.module.css';
import BarcodeIcon from '../../components/icons/Barcode';
import { BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal } from '../../api';
import { LocationProperty } from '../DTI/Location/LocationProperty';
import { DTIOnHoldProperty } from '../DTI/OnHold/DTIOnHoldProperty';
import { InventoryOnHold } from './Inventory.OnHold';

export type PropertiesProps = {
  listingId: string;
  tenantId: string;
  isBroadcasting: boolean;
  ruleCount?: number | undefined;
  updateBroadcasting: (tenantId: string, listingId: string, isBroadcasting: boolean) => void;
  tags?: string[] | null;
  quantityReserved: number;
  quantityRemaining: number;
  numBarcodes: number;
  vendorProperties?: BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal;
};

export const Properties = (props: PropertiesProps) => <PropertiesImpl {...props} />;

const PropertiesImpl = ({
  tenantId,
  listingId,
  isBroadcasting,
  updateBroadcasting,
  tags,
  quantityReserved,
  quantityRemaining,
  ruleCount,
  numBarcodes,
  vendorProperties,
}: PropertiesProps) => {
  const ticketsOnHold = quantityReserved > 0;

  const { confirmationNumber: omittedMarketplaces, location } = vendorProperties || {};

  return (
    <Flex gap={2} mx={-14} h="100%" w="100%" align="center" justify="flex-start" className={classes.propertiesWrapper}>
      <Broadcasting
        isBroadcasting={isBroadcasting}
        listingId={listingId}
        tenantId={tenantId}
        updateBroadcasting={updateBroadcasting}
        ruleCount={ruleCount}
        omittedMarketplaces={omittedMarketplaces}
      />
      {location && <LocationProperty location={location} />}
      {ticketsOnHold && !vendorProperties && <InventoryOnHold quantityReserved={quantityReserved} quantityRemaining={quantityRemaining} />}
      {ticketsOnHold && vendorProperties && (
        <DTIOnHoldProperty quantityReserved={quantityReserved} quantityRemaining={quantityRemaining} accountId={vendorProperties.ownerId} listingId={listingId} />
      )}
      {numBarcodes > 0 && (
        <Tooltip label="Barcoded" withinPortal withArrow>
          <Flex bg="var(--colors-paper)" w={22} h={22} align="center" justify="center" style={{ borderRadius: 28 }}>
            <BarcodeIcon size={16} />
          </Flex>
        </Tooltip>
      )}
      {tags && tags?.length > 0 ? (
        <HoverCard
          withArrow
          withinPortal
          shadow="md"
          styles={{
            arrow: {
              borderColor: 'var(--colors-gray-2)',
            },
            dropdown: {
              maxWidth: 320,
              borderColor: 'var(--colors-gray-2)',
              color: 'var(--colors-gray-7)',
            },
          }}
        >
          <HoverCard.Target>
            <Center bg="var(--colors-paper)" c="var(--colors-gray-5)" h={22} w={22} className={classes.tagIcon}>
              <TagIcon size={20} />
            </Center>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Group gap="xs" py={4} wrap="wrap">
              {tags!.map((tag) => (
                <Badge key={tag} radius="xs" py="sm" px="xs" variant="outline" bg="var(--colors-paperHover)" className={classes.tagBadge}>
                  {tag}
                </Badge>
              ))}
            </Group>
          </HoverCard.Dropdown>
        </HoverCard>
      ) : null}
    </Flex>
  );
};
