import { HeaderAction } from '../hoc/Admin/HeaderAction';
import { Divider, Group, Text } from '@mantine/core';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { isDtiHosted, supportEmail } from '../utils/whitelabel-consts';
import { useEffect } from 'react';
import { useGlobalState } from '../data/Global.state';
import { useNavigate } from 'react-router-dom';

export function AccountDisabled() {
  const supportName = isDtiHosted ? 'DTI Management' : 'Broker Nerds';
  const { principal } = useGlobalState('principal');
  const navigate = useNavigate();
  useEffect(() => {
    if (!principal?.isDisabled) {
      navigate('/');
    }
  }, [navigate, principal?.isDisabled]);

  return (
    <>
      <HeaderAction links={[]} />
      <InviteModal />
      <Group
        pb={48}
        px={24}
        style={{ width: '100%', maxWidth: '1280px', gap: 32, marginLeft: 'auto', marginRight: 'auto', flex: 0, height: '100%', display: 'flex', alignItems: 'start' }}
      >
        <div className="content-page" style={{ textAlign: 'left', width: '100%', maxWidth: '60ch', marginLeft: 'auto', marginRight: 'auto' }}>
          <h1>Your account has been disabled</h1>
          <Text my="xl">
            If you believe this to be in error, please contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a> for assistance.
          </Text>
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <p>{supportName}</p>
        </div>
      </Group>
    </>
  );
}
