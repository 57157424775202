/* eslint-disable lines-around-directive */
import { Flex, Group, Stack, Text } from '@mantine/core';
import React, { useMemo, useRef } from 'react';
import SearchIcon from '../../components/icons/Search';
import { useSearchResults } from './SearchResults.hooks';
import pluralize from 'pluralize';
import { useGlobalState } from '../../data/Global.state';
import { BNButton } from '../../components/Button/Button';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import classes from './SeasonList.module.css';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { SeasonCard } from './SeasonList.SeasonCard';
import { SeasonCardSkeleton } from './SeasonList.SeasonCardSkeleton';
import { useVirtualizer } from '@tanstack/react-virtual';

export function SeasonList() {
  'use no memo';
  const { seasons, isLoading, selectedSeason } = useSearchResults('seasons', 'isLoading', 'sort', 'sortOptions', 'setSort', 'sortOrder', 'setSortOrder', 'selectedSeason');
  const parentRef = useRef<HTMLDivElement>(null);
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const [seasonFilter, setSeasonFilter] = React.useState('');

  const filteredSeasons = seasons?.filter((season) => season.seasonName.toLowerCase().includes(seasonFilter.toLowerCase()));

  const Row = useMemo(
    () =>
      ({ index, start, size }: { index: number; start: number; size: number }) => {
        const season = filteredSeasons[index];
        const isSelected = selectedSeason?.seasonName === season.seasonName;
        const tenantName = tenants?.find((t) => t.tenantId === season?.tenantId)?.name || 'Unknown';
        const tenantColor = principal?.settings?.tenantColors[season.tenantId] ?? 'var(--colors-brandcolor-5)';
        const isMultiTenant = tenants && tenants.length > 1;

        return (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${size}px`,
              transform: `translateY(${start}px)`,
            }}
          >
            <SeasonCard season={season} isSelected={isSelected} tenantName={tenantName} tenantColor={tenantColor} isMultiTenant={isMultiTenant} />
          </div>
        );
      },
    [filteredSeasons, selectedSeason?.seasonName, tenants, principal?.settings?.tenantColors],
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredSeasons.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 128,
    overscan: 10,
  });

  return (
    <>
      <Stack px="md" py="md">
        <BNButton
          fullWidth
          onClick={() => {
            window.location.href = '/';
          }}
          className="exit-season-pricer"
          size="xs"
          leftSection={<ArrowBackIcon />}
          variant="filled"
          color="gray"
        >
          Exit Season Pricer
        </BNButton>
        <BNTextInput
          id="filter-text-box"
          leftSection={<SearchIcon color="var(--colors-gray-4)" />}
          size="xs"
          value={seasonFilter}
          aria-label="Season Search"
          onChange={(e) => setSeasonFilter(e.currentTarget.value)}
          clearable
          clearableOnClick={() => setSeasonFilter('')}
          placeholder="Find Season"
        />
      </Stack>
      <Group className={`resultsArea ${classes.wrapper}`} px={16} py={0}>
        <Group>
          <Text fz={11} color="var(--colors-gray-5)" tt="uppercase" className={classes.selectLabel}>
            {seasons ? seasons.length : 0} {pluralize('Season', seasons ? seasons.length : 0)}
          </Text>
        </Group>
      </Group>
      <div className={classes.searchResultsOuterWrap} ref={parentRef} style={{ height: '100%', overflowY: 'auto' }}>
        <div className={classes.searchResultsInnerWrap}>
          {filteredSeasons && filteredSeasons.length > 0 ? (
            <div
              className="searchResultsList"
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <Row key={virtualItem.index} index={virtualItem.index} size={virtualItem.size} start={virtualItem.start} />
              ))}
            </div>
          ) : isLoading ? (
            <SeasonCardSkeleton />
          ) : seasonFilter !== '' ? (
            <Flex align="center" justify="center" h="85%">
              <BNEmptyState
                title="No Results Found"
                description="Try changing your search criteria."
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                border={false}
                buttonText="Clear Search"
                buttonOnClick={() => {
                  setSeasonFilter('');
                }}
              />
            </Flex>
          ) : (
            // Empty State Example
            <Flex align="center" justify="center" h="85%">
              <BNEmptyState
                title="No Eligible Seasons Found"
                description="Season pricing requires similar listings for multiple events."
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                border={false}
                buttonText="Exit Season Pricer"
                buttonVariant="filled"
                buttonColor="gray"
                buttonOnClick={() => {
                  window.location.href = '/';
                }}
              />
            </Flex>
          )}
        </div>
      </div>
    </>
  );
}
