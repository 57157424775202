import { IconPropsBase } from './IconPropsBase';

export default function TimerArrowDownIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M344.68-180q-115.83 0-196.79-81.09-80.96-81.08-80.96-196.92 0-115.84 80.99-196.99t196.7-81.15q49.69 0 93.96 16.31 44.27 16.3 79.96 45.3l40.07-40.07 41.77 41.76-39.69 40.08q29 35.69 45.5 80.27t16.5 94.42q0 115.87-81.08 196.97Q460.52-180 344.68-180Zm433.01 0L653.85-303.85 696-346l51.69 51.69V-780h60v485.08L858.77-346l42.77 42.15L777.69-180ZM256.16-784.61v-60h177.69v60H256.16ZM344.59-240q91.21 0 154.65-63.45 63.45-63.45 63.45-154.65 0-91.21-63.45-154.63-63.44-63.42-154.65-63.42-91.2 0-154.44 63.44-63.23 63.45-63.23 154.66 0 91.2 63.23 154.63Q253.39-240 344.59-240Zm-29.97-191.92h59.99v-176.16h-59.99v176.16Zm30-26.16Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M364.68-212q-100.83 0-171.29-70.59-70.46-70.58-70.46-171.42 0-100.84 70.49-171.49t171.2-70.65q43.12 0 81.57 13.91 38.46 13.91 69.35 38.7l40.07-40.07 37.77 37.76-39.69 40.08q24.79 30.89 38.9 69.59 14.1 38.7 14.1 82.1 0 100.87-70.58 171.47Q465.52-212 364.68-212Zm401.01 0L661.85-315.85 699-353l40.69 40.69V-747h52v436.08L832.77-353l35.77 38.15L765.69-212ZM280.16-752.61v-52h169.69v52H280.16ZM364.39-264q79.41 0 134.85-55.24 55.45-55.25 55.45-134.66t-55.24-134.83q-55.24-55.42-134.65-55.42t-134.65 55.24q-55.23 55.24-55.23 134.65t55.03 134.84Q284.97-264 364.39-264Zm-25.77-155.92h51.99v-168.16h-51.99v168.16Zm26-34.16Z"
        />
      </svg>
    );
  }
}
