import { Box, Combobox, Group, Input, Pill, PillsInput, Text, useCombobox } from '@mantine/core';
import classes from '../../components/Select/ExclusionsMultiSelect.module.css';
import SelectArrowsVariableIcon from '../../components/icons/SelectArrows';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '../../components/icons/VerticalAlignTop';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import AutoPriceOffIcon from '../../components/icons/AutoPriceOff';
import ScheduledPriceIcon from '../../components/icons/ScheduledPrice';
import ErrorIcon from '../../components/icons/Error';
import TimerArrowDownIcon from '../../components/icons/TimerArrowDown';
import WarningPauseIcon from '../../components/icons/WarningPause';
import AutoPauseIcon from '../../components/icons/AutoPause';

export const PricerStatusList = [
  {
    icon: <AutoPriceIcon color="var(--colors-iconFill)" />,
    label: 'Auto-Priced',
    value: BarkerCoreEnumsPricerStatus.AutoPriced,
  },
  {
    icon: <VerticalAlignBottomIcon color="var(--colors-iconFill)" />,
    label: 'At Floor',
    value: BarkerCoreEnumsPricerStatus.AtFloor,
  },
  {
    icon: <TimerArrowDownIcon color="var(--colors-iconFill)" />,
    label: 'At Soft Floor',
    value: BarkerCoreEnumsPricerStatus.AtSoftFloor,
  },
  {
    icon: <VerticalAlignTopIcon color="var(--colors-iconFill)" />,
    label: 'At Ceiling',
    value: BarkerCoreEnumsPricerStatus.AtCeiling,
  },
  {
    icon: <MobileDataOffIcon color="var(--colors-iconFill)" />,
    label: 'No Comparables',
    value: BarkerCoreEnumsPricerStatus.NoComparables,
  },
  {
    icon: <AutoPriceOffIcon color="var(--colors-iconFill)" />,
    label: 'Not Auto-Priced',
    value: BarkerCoreEnumsPricerStatus.None,
  },
  {
    icon: <ScheduledPriceIcon color="var(--colors-iconFill)" />,
    label: 'Schedule Priced',
    value: BarkerCoreEnumsPricerStatus.Scheduled,
  },
  {
    icon: <AutoPauseIcon color="var(--colors-iconFill)" />,
    label: 'Paused',
    value: BarkerCoreEnumsPricerStatus.Paused,
  },
  {
    icon: <WarningPauseIcon color="var(--colors-iconFill)" />,
    label: 'Paused With Warning',
    value: BarkerCoreEnumsPricerStatus.PausedWithWarning,
  },
  {
    icon: <ErrorIcon color="var(--colors-iconFill)" />,
    label: 'Errored',
    value: BarkerCoreEnumsPricerStatus.Errored,
  },
] as const;

export function PricerStatusMultiSelect({
  value,
  onChange,
  placeholder,
}: {
  value: BarkerCoreEnumsPricerStatus[];
  onChange: (value: BarkerCoreEnumsPricerStatus[]) => void;
  placeholder: string;
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const handleValueSelect = (val: string) => {
    const typedVal = val as BarkerCoreEnumsPricerStatus;

    onChange([...value, typedVal]);
  };

  const handleValueRemove = (val: BarkerCoreEnumsPricerStatus) => {
    onChange(value.filter((v) => v !== val));
  };

  const values = value.map((item) => {
    const listItem = PricerStatusList.find((x) => x.value === item)!;
    return (
      <Pill className={classes.exclusionPill} key={listItem.value} withRemoveButton onRemove={() => handleValueRemove(item)}>
        {listItem.icon}
      </Pill>
    );
  });

  const renderOption = (items: (typeof PricerStatusList)[number][]) => (
    <>
      {items.map((item) => (
        <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
          <Box>
            <Group gap={10} wrap="nowrap">
              {item?.icon}
              <div>
                <Text size="xs">{item?.label}</Text>
              </div>
            </Group>
          </Box>
        </Combobox.Option>
      ))}
    </>
  );

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <PillsInput
          label="Pricer Status"
          size="xs"
          className={classes.exclusionPillInput}
          pointer
          rightSection={<SelectArrowsVariableIcon />}
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
        >
          <Pill.Group>
            {values.length > 0 ? values : <Input.Placeholder>{placeholder}</Input.Placeholder>}
            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{renderOption(PricerStatusList.filter((item) => !value.includes(item.value)).map((item) => item))}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
