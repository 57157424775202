import { Outlet } from 'react-router-dom';
import { HeaderAction } from './HeaderAction';
import { AdminFooter } from './AdminFooter';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { useFlag } from '@unleash/proxy-client-react';
import { useGlobalState } from '../../data/Global.state';

export default function AdminFrame() {
  const hideSalesFlag = useFlag('hide-sales');
  const { dtiAccounts } = useGlobalState('dtiAccounts');

  const canViewSales = !hideSalesFlag && (dtiAccounts.length === 0 || dtiAccounts.some((a) => a.perms.invoice.enabled === '1'));

  return (
    <>
      <ScrollToTop />
      <HeaderAction
        links={[
          { label: 'Pricer', link: '/', links: [] },
          { label: 'Sales', link: '/sales', links: [], disabled: !canViewSales },
          { label: 'Companies', link: '/companies', links: [] },
          { label: 'Settings', link: '/settings', links: [] },
          { label: 'Notifications', link: '/', links: [], disabled: true },
          // { label: 'Profile', link: '/', links: [], disabled: true },
        ]}
      />
      <Outlet />
      <AdminFooter
        links={[
          { label: 'Pricer', link: '/', links: [] },
          { label: 'Sales', link: '/sales', links: [], disabled: !canViewSales },
          { label: 'Companies', link: '/companies', links: [] },
          { label: 'Settings', link: '/settings', links: [] },
          { label: 'Notifications', link: '/', links: [], disabled: true },
          // { label: 'Profile', link: '/', links: [], disabled: true },
        ]}
      />
    </>
  );
}
