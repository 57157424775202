import { Box, Flex, Progress, Skeleton, Text } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { BNSwitch } from '../../components/Switch/Switch';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import StadiumIcon from '../../components/icons/Stadium';
import { useAtomValue } from 'jotai';
import { seasons } from '../../data/atoms.seasons';
import { useSeasonPricer } from '../../data/SeasonsPricer.state';
import { BarkerCoreModelsInventoryListing } from '../../api';
import { useNowPricing } from '../SeasonNowPricing/NowPricing.hooks';
import { marketListingsLimit } from '../MarketListings/MarketListings.hooks';
import classes from './SeasonEvents.module.css';

export function SeasonEventsHeader() {
  const selectedLocation = useAtomValue(seasons.selectedLocationAtom);
  const { toggleRemaining, rulesAtom, eventMappings, listingsAtom, isLoading } = useSeasonPricer('toggleRemaining', 'rulesAtom', 'eventMappings', 'listingsAtom', 'isLoading');
  const { isAutoPriced, toggleIsAutoPriced } = useNowPricing('isAutoPriced', 'toggleIsAutoPriced');
  const listings = useAtomValue(listingsAtom);
  const listingsByEventId = useMemo(
    () =>
      Object.values(listings)?.reduce(
        (acc, listing) => {
          acc[listing.eventId] = listing;
          return acc;
        },
        {} as Record<string, BarkerCoreModelsInventoryListing>,
      ),
    [listings],
  );
  const rules = useAtomValue(rulesAtom);
  const totalListings = useMemo(() => selectedLocation?.listings.length, [selectedLocation?.listings.length]);
  const locationListingEventIds = useMemo(() => selectedLocation?.listings.map((x) => x.eventId), [selectedLocation?.listings]);
  const autoPricedListings = useMemo(
    () =>
      locationListingEventIds?.filter((eventId) => (listingsByEventId[eventId] ? rules[eventId]?.isAutoPriced || listingsByEventId[eventId].pricerStatusId !== 'None' : false))
        .length,
    [listingsByEventId, locationListingEventIds, rules],
  );
  const remainingEligible = useMemo(
    () =>
      locationListingEventIds?.filter(
        (eventId) =>
          // !rules[eventId]?.isAutoPriced &&
          eventMappings.find((x) => x.eventId === eventId) && selectedLocation?.listings.some((x) => x.eventId === eventId && x.pricerStatusId === 'None'),
      ).length,
    [eventMappings, locationListingEventIds, selectedLocation?.listings],
  );
  const [activeCount, setActiveCount] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoading || marketListingsLimit.pendingCount > 0) {
        setActiveCount(true);
        return;
      }
      if (selectedLocation) {
        setActiveCount(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isLoading, selectedLocation]);

  return (
    <Box bg="var(--colors-paper)" style={{ borderBottom: '1px solid var(--colors-divider)' }}>
      <Flex fz="xs" align="center" justify="space-between" h={40} px="xs">
        <Flex c="gray.5" align="center" gap="xs" pl={6}>
          <BNSwitch
            size="xs"
            label={<AutoPriceIcon size={18} />}
            colorLabel
            checked={isAutoPriced}
            disabled={!remainingEligible}
            onChange={() => {
              toggleIsAutoPriced();
              toggleRemaining(!isAutoPriced);
            }}
          />
          {/* <Text>Enable Remaining ({remainingEligible || 0})</Text> */}
          <Text>Enable All</Text>
        </Flex>
        <Flex align="center" gap="xs">
          <AutoPriceIcon color="var(--colors-gray-5)" size={18} />
          {selectedLocation ? (
            <Flex align="center" justify="space-between" gap={4} miw={32}>
              <Text fw="600">{autoPricedListings}</Text>
              <Text c="gray.5">/</Text>
              <Text c="gray.5">{totalListings}</Text>
            </Flex>
          ) : (
            <Flex align="center" gap={4}>
              <Skeleton height={8} width={32} />
            </Flex>
          )}
          <StadiumIcon color="var(--colors-gray-5)" size={18} />
        </Flex>
      </Flex>
      {activeCount && <Progress radius={0} value={100} animated color="var(--colors-gray-2)" size={4} pos="absolute" top={0} left={0} right={0} className={classes.progress} />}
    </Box>
  );
}
