import { deepEqual } from 'fast-equals';
import { getApiPricingRulesRuleId, getGetApiPricingRulesRuleIdQueryKey } from '../api';
import { queryClient } from './api-config';
import { selectedMergedListingAtom, ruleStateAtom, store } from './atoms';
import { QueryState } from '@tanstack/react-query';
import { atom } from 'jotai';

export const ruleSubscriptionFlagAtom = atom<boolean>(false);

queryClient.getQueryCache().subscribe((event) => {
  const enabled = store.get(ruleSubscriptionFlagAtom);
  if (enabled) {
    if (event.type === 'observerResultsUpdated' && typeof event.query.queryKey[0] === 'string') {
      // @ts-ignore
      if (event.query.queryKey[0].startsWith('/api/Pricing/Rules')) {
        const listing = store.get(selectedMergedListingAtom);
        if (listing && listing.ruleId && Array.isArray(event.query.queryKey) && deepEqual(event.query.queryKey, getGetApiPricingRulesRuleIdQueryKey(listing.ruleId))) {
          const newData = (event.query.state as QueryState<Awaited<ReturnType<typeof getApiPricingRulesRuleId>>>).data?.data;
          if (newData && newData.ruleId !== '00000000-0000-0000-0000-000000000000') {
            store.set(ruleStateAtom, newData);
          } else {
            store.set(ruleStateAtom, null);
          }
        } else {
          store.set(ruleStateAtom, null);
        }
      }
    }
  }
});

// store.sub(ruleStateQueryAtom, () => {
//   const serverRule = store.get(ruleStateQueryAtom);
//   const forceRuleId = store.get(forceRuleIdAtom);
//   if (!forceRuleId && !serverRule.isFetching && !serverRule.isLoading) {
//     if (serverRule.data && serverRule.data.ruleId !== '00000000-0000-0000-0000-000000000000') {
//       // Not sure if this should be the case. Since in a refresh condition it wouldn't update the rule.
//       // const currentRule = store.get(ruleStateAtom);
//       // if (currentRule && currentRule.ruleId !== serverRule.data.ruleId) {
//       store.set(ruleStateAtom, serverRule.data!);
//       // }
//     } else {
//       store.set(ruleStateAtom, null);
//     }
//   }
// });
