import { getGetApiTenantsQueryKey, useGetApiPrincipalsInvites, usePutApiTenantsTenantIdInvitesInviteIdAccept, usePutApiTenantsTenantIdInvitesInviteIdDecline } from '../../api';
import { useAtomValue } from 'jotai';
import { Box, Card, Group, Modal, Stack, Text } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useQueryClient } from '@tanstack/react-query';
import { auth } from '../../data/atoms.auth';
import classes from './InviteModal.module.css';

export function InviteModal() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const queryClient = useQueryClient();

  const {
    data: invites,
    refetch: refetchInvites,
    isLoading,
  } = useGetApiPrincipalsInvites({
    query: {
      enabled: !!apiToken,
      refetchOnWindowFocus: true,
      select(data) {
        return data.data;
      },
    },
  });

  const { mutateAsync: acceptInvite, isPending: isAcceptingInvite } = usePutApiTenantsTenantIdInvitesInviteIdAccept();
  const { mutateAsync: declineInvite, isPending: isDecliningInvite } = usePutApiTenantsTenantIdInvitesInviteIdDecline();

  return (
    <Modal
      opened={invites !== undefined && invites.length > 0}
      size="md"
      padding={0}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => false}
      title={invites !== undefined && invites.length > 0 && invites.length === 1 ? 'You Have a Company Invite' : `You Have ${invites?.length} Company Invites`}
      centered
      classNames={{ body: classes.modalBody }}
    >
      <Box mah={320} style={{ overflow: 'auto' }}>
        <Stack gap={0} py={0} px={0} className={classes.cardStack}>
          {invites?.map((invite) => (
            <Card w="100%" px="md" key={invite.inviteId} className={invites.length > 1 ? classes.inviteCardBgHover : classes.inviteCard}>
              <Card.Section p="md">
                <Group wrap="nowrap" justify="space-between" gap="lg">
                  <Box>
                    <Text size="sm" fw={600}>
                      {invite.tenant.name}
                    </Text>
                    <Text size="xs" c="var(--colors-gray-5)">
                      Invited on {dayjs(invite.createdAt).format(DateFormats.MonthDayYear)}
                    </Text>
                    {/* Might be nice to add more information here in the future, like what user sent the invite or when the invite expires? */}
                  </Box>
                  <Group justify="right" gap="sm" wrap="nowrap">
                    <BNButton
                      variant="default"
                      size="xs"
                      onClick={() => declineInvite({ tenantId: invite.tenantId, inviteId: invite.inviteId }).then(() => refetchInvites())}
                      loading={isAcceptingInvite || isDecliningInvite || isLoading}
                      className={classes.declineButton}
                    >
                      Decline
                    </BNButton>
                    <BNButton
                      variant="filled"
                      size="xs"
                      color="green"
                      onClick={() =>
                        acceptInvite({ tenantId: invite.tenantId, inviteId: invite.inviteId })
                          .then(() => refetchInvites())
                          .then(() => queryClient.invalidateQueries({ queryKey: getGetApiTenantsQueryKey() }))
                      }
                      loading={isAcceptingInvite || isDecliningInvite || isLoading}
                    >
                      Accept
                    </BNButton>
                  </Group>
                </Group>
              </Card.Section>
            </Card>
          ))}
        </Stack>
      </Box>
    </Modal>
  );
}
