import { ActionIcon, Box, Center, Flex, Group, Modal, Space, Stack, Text } from '@mantine/core';
import classes from './SelfServeDialog.module.css';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { useState } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { formatDate, formatSeatRange } from '../../../utils/formatters';
import { DateFormats } from '../../../utils/globals';
import UploadIcon from '../../../components/icons/Upload';
import CloseIcon from '../../../components/icons/Close';
import { BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal, usePostApiSalesSaleIdUploadTransferProof } from '../../../api';
import { selectedSaleAtom } from '../../../data/atoms';
import { useAtom } from 'jotai';
import { Sale } from '../../Sales/Sales.types';
import ScreenshotIcon from '../../../components/icons/Screenshot';
import { BNSelect } from '../../../components/Select/Select';
import { BNButton } from '../../../components/Button/Button';
import { getStandardMarketplaceName } from '../../../utils/marketplace-utils';

export function SelfServeDialog({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const [selectedSale, setSelectedSale] = useAtom(selectedSaleAtom);

  function closeDialog() {
    setSelectedSale(undefined);
    onClose();
  }

  if (!selectedSale) {
    return null;
  }

  return <UploadTransferProof onClose={closeDialog} isOpen={opened} selectedSale={selectedSale} />;
}

export function UploadTransferProof({ selectedSale, isOpen, onClose }: { onClose: () => void; isOpen: boolean; selectedSale: Sale }) {
  const vendorProperties = selectedSale.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal;
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [accountType, setAccountType] = useState<string | null>(null);

  const { mutateAsync, isPending: isLoading } = usePostApiSalesSaleIdUploadTransferProof();

  if (!selectedSale.event) {
    return null;
  }

  const marketplaceName = getStandardMarketplaceName(selectedSale.marketplaceName);

  const isAccountTypeRequired = marketplaceName === 'TickPick' || marketplaceName === 'TEVO' || marketplaceName === 'StubHub';

  const accountTypeOptions = marketplaceName === 'TickPick' ? TickPickAccountTypes : marketplaceName === 'TEVO' ? TicketEvolutionAccountTypes : ViagogoAccountTypes;

  const removeFile = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const onSubmit = () => {
    if (files.length === 0) {
      return;
    }

    mutateAsync({
      saleId: selectedSale.saleId,
      data: {
        file: files[0],
        accountType: accountType ?? undefined,
      },
    }).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      centered
      styles={{ body: { display: 'flex', flex: 1, overflow: 'hidden', padding: 0, maxHeight: 864 }, content: { display: 'flex', flexDirection: 'column', overflow: 'hidden' } }}
      size={540}
      opened={isOpen}
      closeButtonProps={{ size: 'md' }}
      onClose={() => onClose()}
      title={
        <Group>
          <ScreenshotIcon color="var(--colors-iconFill)" size={24} />
          Complete Self-Service
        </Group>
      }
    >
      <Flex flex={1} direction="column" pos="relative" style={{ overflow: 'hidden' }}>
        <Flex style={{ overflow: 'hidden' }}>
          <Stack w="100%" gap={0} bg="var(--colors-paper)" pt="md" pb={0} style={{ zIndex: 2, overflow: 'hidden' }}>
            <Group px="lg" mt={-2} pos="relative" style={{ zIndex: 1 }}>
              <Box>
                <Text size="xs" fw={600}>
                  {selectedSale.event.name}
                </Text>
                <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500}>
                  <span>
                    <span>{formatDate(selectedSale.event.localDateTime, DateFormats.Extended)}</span> - {selectedSale.event.venue.name} · {selectedSale.event.venue.city},{' '}
                    {selectedSale.event.venue.state}
                  </span>
                </Text>
              </Box>
            </Group>
            <Group px="lg" pt="sm" className={classes.gradient}>
              <Group align="start" gap={0} justify="space-between" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3 }} p="sm" w="100%">
                <Box style={{ overflow: 'hidden' }}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Section
                  </Text>
                  <Text lh={1.25} pt={2} size="sm" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {selectedSale?.section}
                  </Text>
                </Box>
                <Box style={{ overflow: 'hidden' }}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Row
                  </Text>
                  <Text lh={1.25} pt={2} size="sm" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {selectedSale?.row}
                  </Text>
                </Box>
                <Box w="16.666667%">
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Qty
                  </Text>
                  <Group gap={4}>
                    <Text size="sm">{selectedSale.quantity}</Text>
                  </Group>
                </Box>
                <Box w="33.3333%">
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Seats
                  </Text>
                  <Group gap={4}>
                    <Text size="sm">{formatSeatRange(selectedSale.seatFrom, selectedSale.seatThru, selectedSale.quantity)}</Text>
                  </Group>
                </Box>
              </Group>
            </Group>
            <Group px="lg" pt="sm" className={classes.gradient}>
              <Group align="start" gap={0} justify="space-between" p="sm" w="100%">
                <Box>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Recipient Name
                  </Text>
                  <Text lh={1.25} pt={2} size="sm" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {vendorProperties.transferRecipientName}
                  </Text>
                </Box>
                <Box>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Email
                  </Text>
                  <Text lh={1.25} pt={2} size="sm" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {vendorProperties.transferRecipientEmail}
                  </Text>
                </Box>
              </Group>
            </Group>
            {isAccountTypeRequired && (
              <Group px="lg" pt="sm">
                <Group gap={0} p="sm" w="100%">
                  <Box>
                    <BNSelect
                      label="Account Type"
                      size="xs"
                      style={{ width: '250px' }}
                      placeholder="Select Account Type"
                      clearable
                      value={accountType}
                      onChange={(value) => setAccountType(value)}
                      data={[
                        ...accountTypeOptions.map((value) => ({
                          value,
                          label: value,
                        })),
                      ]}
                    />
                  </Box>
                </Group>
              </Group>
            )}
            {files.length === 1 && (
              <Group px="lg" pt="sm" pb="lg">
                <Group gap={0} p="sm" w="100%">
                  <Group key={files[0].name} style={{ width: '100%' }}>
                    <ActionIcon onClick={() => removeFile(files[0].name)}>
                      <CloseIcon size={16} />
                    </ActionIcon>
                    <Text size="sm">{files[0].name}</Text>
                  </Group>
                </Group>
              </Group>
            )}
            {files.length === 0 && (
              <Stack h={152} px="lg" pt="md" style={{ overflow: 'hidden' }}>
                <Dropzone
                  accept={[MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.jpeg]}
                  multiple
                  onDrop={(x) => setFiles((prev) => [...prev, ...x])}
                  loaderProps={{ mt: -48, c: 'var(--colors-gray-5)', size: 'md' }}
                >
                  <Dropzone.Accept>
                    <Group
                      className={classes.dropzoneAccept}
                      justify="center"
                      gap="xl"
                      mih={120}
                      bg="var(--colors-hoverBg)"
                      style={{ pointerEvents: 'none', border: '1px dashed var(--colors-hoverBorder)', borderRadius: 3 }}
                    >
                      <Center bg="var(--colors-opacity-hover)" c="var(--colors-hoverBorder)" h={44} w={44} style={{ borderRadius: 100 }}>
                        <UploadIcon size={24} color="var(--colors-iconFill)" />
                      </Center>
                      <div>
                        <Text size="md" inline>
                          Drag & drop proof of transfer here
                        </Text>
                        <Text size="xs" c="var(--colors-gray-5)" inline mt={7}>
                          Only images (jpeg, png) and PDFs are accepted
                        </Text>
                      </div>
                    </Group>
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <Group
                      className={classes.dropzoneReject}
                      justify="center"
                      gap="xl"
                      mih={120}
                      style={{ pointerEvents: 'none', border: '1px dashed var(--colors-gray-3)', borderRadius: 3 }}
                    >
                      <Center c="var(--colors-gray-5)" h={44} w={44} style={{ borderRadius: 100, border: '1px solid var(--colors-opacity-hover)' }}>
                        <CloseIcon size={24} color="var(--colors-red-error)" />
                      </Center>
                      <div>
                        <Text size="md" inline>
                          Drag & drop proof of transfer here
                        </Text>
                        <Text size="xs" c="var(--colors-gray-5)" inline mt={7}>
                          Only images (jpeg, png) and PDFs are accepted
                        </Text>
                      </div>
                    </Group>
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <Group className={classes.dropzoneIdle} justify="center" gap="xl" mih={120} style={{ border: '1px dashed var(--colors-gray-3)', borderRadius: 3 }}>
                      <Center bg="var(--colors-opacity-hover)" c="var(--colors-gray-5)" h={44} w={44} style={{ borderRadius: 100 }}>
                        <UploadIcon size={24} color="var(--colors-gray-5)" />
                      </Center>
                      <div>
                        <Text size="md" inline>
                          Drag & drop proof of transfer here
                        </Text>
                        <Text size="xs" c="var(--colors-gray-5)" inline mt={7}>
                          Only images (jpeg, png) and PDFs are accepted
                        </Text>
                      </div>
                    </Group>
                  </Dropzone.Idle>
                </Dropzone>
                <Space h={0} />
              </Stack>
            )}
          </Stack>
        </Flex>
        {files.length !== 0 && (
          <Flex h={52} pl="lg" align="center" justify="space-between" style={{ borderTop: '1px solid var(--colors-divider)', flexShrink: 0 }}>
            <Flex w="100%" justify="end" gap={12} align="center" px={16}>
              <BNButton size="xs" w={80} disabled={isLoading} onClick={() => onClose()}>
                Cancel
              </BNButton>
              <BNButton
                disabled={isAccountTypeRequired && accountType === null}
                loading={isLoading}
                type="submit"
                size="xs"
                variant="filled"
                color="green"
                w={80}
                onClick={() => onSubmit()}
              >
                Save
              </BNButton>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}

const TickPickAccountTypes = ['Flash/AXS', 'MLB Ballpark', 'Seatgeek', 'Tradedesk/Ticketmaster', 'Tickets.com', 'Managed Delivery'] as const;

type TickPickAccountType = (typeof TickPickAccountTypes)[number];

const TicketEvolutionAccountTypes = ['OSS', 'AXS', 'MLB Ballpark App', 'Tickets.com', 'Paciolan', 'SeatGeek (All Events)', 'Ticketmaster'] as const;

type TicketEvolutionAccountType = (typeof TicketEvolutionAccountTypes)[number];

const ViagogoAccountTypes = ['AXS', 'MLB Ballpark', 'Paciolan', 'SeatGeek', 'Ticketmaster', 'Ticketmaster Account Manager', 'Tickets.com'] as const;

type ViagogoAccountType = (typeof ViagogoAccountTypes)[number];
