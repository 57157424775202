import { ActionIcon, Box, Button, Center, Divider, Flex, Group, Menu, Popover, Text, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { useListingState } from '../../data/Listing.state';
import classes from './Inventory.UnsavedChangesPopover.module.css';
import cx from 'clsx';
import WarningIcon from '../../components/icons/Warning';
import { useCallback, useMemo } from 'react';
import ArrowDropdownIcon from '../../components/icons/ArrowDropdown';
import ErrorIcon from '../../components/icons/Error';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useGlobalState } from '../../data/Global.state';
import { useAtomValue } from 'jotai';
import { selectedEventAtom, selectedTenantListingIdAtom } from '../../data/atoms';
import { ListingUpdate } from '../../types';
import { useIsDrasticChange } from '../../utils/price-utils';
import { useRuleState } from '../../data/Rule.state';

type UnsavedChangesPopoverProps = {
  position?: string;
};

export function UnsavedChangesPopover({ position = 'bottom' }: UnsavedChangesPopoverProps) {
  const { tenantId } = useAtomValue(selectedTenantListingIdAtom);
  const selectedEvent = useAtomValue(selectedEventAtom);
  const { tenants } = useGlobalState('tenants');
  const allowSnooze = tenants?.find((x) => x.tenantId === selectedEvent?.tenantId || x.tenantId === tenantId)?.settings?.pricerSettings?.allowDrasticPriceAlertSnooze ?? true;
  const [isDrasticChangeSnoozed, setIsDrasticChangeSnoozed] = useLocalStorage<Date | undefined>({
    key: 'drasticChangeSnooze',
  });
  const [isDrasticChangeDialog, { open: openDrasticChangeDialog, close: closeDrasticChangeDialog }] = useDisclosure(false);
  const { setFormKey } = useRuleState('setFormKey');
  const { pendingListingUpdates, processPendingUpdates, rejectPendingUpdates, patchListingLoading } = useListingState(
    'pendingListingUpdates',
    'processPendingUpdates',
    'rejectPendingUpdates',
    'patchListingLoading',
  );
  const isChangeConflict = false; // update this to show a change conflict
  const isDrasticChangeFn = useIsDrasticChange(selectedEvent?.tenantId ?? undefined);

  const unsavedChanges = useMemo(() => Array.from(new Set(pendingListingUpdates.filter((x) => x.inlinePriceChange).map((x) => x.listingId))).length, [pendingListingUpdates]);

  const _isDrasticChange = useMemo(() => {
    const lastUpdateByProperty = pendingListingUpdates
      .filter((x) => x.inlinePriceChange)
      .reduce(
        (acc, update) => {
          acc[update.listingId] = update;
          return acc;
        },
        {} as { [key: string]: ListingUpdate },
      );
    return Object.values(lastUpdateByProperty).some((update) => isDrasticChangeFn(update.value as number, update.previousValue as number, 0));
  }, [isDrasticChangeFn, pendingListingUpdates]);

  const onConfirmSave = useCallback(async () => {
    closeDrasticChangeDialog();
    await processPendingUpdates(pendingListingUpdates.filter((x) => x.inlinePriceChange));
    setFormKey((x) => x + 1);
  }, [closeDrasticChangeDialog, pendingListingUpdates, processPendingUpdates, setFormKey]);

  const onSave = () => {
    if (!_isDrasticChange || isDrasticChangeSnoozed) {
      onConfirmSave();
    } else {
      openDrasticChangeDialog();
    }
  };
  const snooze = useCallback(
    (interval: 'day' | 'hour') => {
      setIsDrasticChangeSnoozed(dayjs().add(1, interval).toDate());
      onConfirmSave();
    },
    [onConfirmSave, setIsDrasticChangeSnoozed],
  );
  // TODO: add classes.unsavedChanges to the inventoryWrapper whenever there are unsaved changes & the pricer is in normal view to add bottom padding
  return (
    <Popover withinPortal={false} opened={unsavedChanges > 0} position="top-end" offset={24}>
      <Popover.Target>
        <Center pos="absolute" bottom={position === 'bottom' ? 0 : 'auto'} top={position === 'top' ? 0 : 'auto'} right={20} h={1} w={1} />
      </Popover.Target>
      <Popover.Dropdown w="auto" left={24} right={24} p={8} pl="sm" className={cx(classes.dropdown, _isDrasticChange && classes.warning)}>
        <Flex w="100%" justify="space-between" p={0} bg="var(--colors-paper)" gap={40}>
          <Group align="center" gap={8} flex={1} style={{ overflow: 'hidden' }}>
            <Center className={classes.count} tt="uppercase" h={20} miw={20} fz="xs" style={{ borderRadius: 12 }}>
              {unsavedChanges}
            </Center>
            <Text size="sm" fw={600} truncate>
              Unsaved Changes
            </Text>
            {_isDrasticChange && (
              <Group align="center" gap="4" flex={1}>
                <Divider color="var(--colors-gray-2)" h={20} my="auto" mx={4} orientation="vertical" />
                <Tooltip label="Large price change" withinPortal={false} withArrow>
                  <Center>
                    <WarningIcon color="var(--colors-yellow-warning)" />
                  </Center>
                </Tooltip>
              </Group>
            )}
          </Group>
          <Group gap={8}>
            <BNButton
              size="xs"
              miw={72}
              disabled={patchListingLoading}
              onClick={() => {
                rejectPendingUpdates();
              }}
            >
              Cancel
            </BNButton>

            <Popover
              width={220}
              position="bottom"
              withArrow
              closeOnClickOutside
              onClose={() => {
                closeDrasticChangeDialog();
              }}
              opened={isDrasticChangeDialog || isChangeConflict}
            >
              <Popover.Target>
                <BNButton
                  size="xs"
                  miw={72}
                  variant="filled"
                  color="green"
                  loading={patchListingLoading}
                  onClick={() => {
                    onSave();
                  }}
                >
                  Save
                </BNButton>
              </Popover.Target>
              <Popover.Dropdown p={0}>
                {isDrasticChangeDialog && (
                  <Box>
                    <Group wrap="nowrap" p={12} align="start" gap={8}>
                      <Box>
                        <WarningIcon color="var(--colors-yellow-6)" />
                      </Box>
                      <Box style={{ flex: 1 }}>
                        {/* Title */}
                        <Text size="xs" fw="bold">
                          Drastic Price Change
                        </Text>
                        {/* Secondary Description */}
                        <Text fz={11} c="var(--colors-gray-5)">
                          One or more listings have a drastic price change. Are you sure you want to save these changes?
                        </Text>
                      </Box>
                    </Group>
                    <Divider color="var(--colors-divider)" />
                    {/* Dropdown buttons */}
                    <Button.Group>
                      <BNButton
                        onClick={() => closeDrasticChangeDialog()}
                        radius={0}
                        fullWidth
                        variant="subtle"
                        size="xs"
                        maw="50%"
                        style={{ borderRight: '1px solid var(--colors-divider)' }}
                      >
                        Cancel
                      </BNButton>
                      <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                        <BNButton radius={0} fullWidth variant="subtle" size="xs" onClick={() => onConfirmSave()}>
                          Confirm
                        </BNButton>
                        {/* Button wrapped in a menu to allow for snooze & extra options */}
                        {allowSnooze && (
                          <Menu withinPortal={false}>
                            <Menu.Target>
                              <ActionIcon radius={0} h="100%" style={{ borderLeft: '1px solid var(--colors-divider)' }}>
                                <ArrowDropdownIcon />
                              </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Item fz="xs" onClick={() => snooze('hour')}>
                                Confirm & snooze for 1 hour
                              </Menu.Item>
                              <Menu.Item fz="xs" onClick={() => snooze('day')}>
                                Confirm & snooze for 1 day
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        )}
                      </Group>
                    </Button.Group>
                  </Box>
                )}
                {isChangeConflict && (
                  <Box>
                    <Group wrap="nowrap" p={12} align="start" gap={8}>
                      <Box>
                        <ErrorIcon fill color="var(--colors-red-error)" />
                      </Box>
                      <Box style={{ flex: 1 }}>
                        {/* Title */}
                        <Text size="xs" fw="bold">
                          Rule Conflict
                        </Text>
                        {/* Description */}
                        <Group gap={0}>
                          <Text size="xs">{/* {user} updated this rule{user === 'You' ? 'in another window.' : '.'} */}</Text>
                        </Group>
                        {/* Secondary Description */}
                        <Text fz={11} c="gray.5">
                          {/* {formattedDate} */}
                        </Text>
                      </Box>
                    </Group>
                    <Divider color="var(--colors-divider)" />
                    {/* Dropdown buttons */}
                    <Button.Group>
                      <Group wrap="nowrap" gap={0} style={{ flex: 1 }}>
                        {/* TODO: Add refresh onClick */}
                        <BNButton radius={0} fullWidth variant="subtle" size="xs">
                          Refresh
                        </BNButton>
                      </Group>
                    </Button.Group>
                  </Box>
                )}
              </Popover.Dropdown>
            </Popover>
          </Group>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
}
