import { ActionIcon, Box, Center, Drawer, Flex, Menu, Text, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { CSSProperties } from 'react';
import LogoutIcon from '../../components/icons/Logout';
import { useGlobalState } from '../../data/Global.state';
import RoutineIcon from '../../components/icons/Routine';
import { useNavigate } from 'react-router-dom';
import SyncAltIcon from '../../components/icons/SyncAlt';
import { BNAvatar } from '../../components/Avatar/Avatar';
import CloseIcon from '../../components/icons/Close';
import StadiumIcon from '../../components/icons/Stadium';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import classes from './NavBar.Mobile.module.css';
import { queryClient } from '../../data/api-config';
import { useAtomValue } from 'jotai';
import { mobileVersionAtom } from '../../data/atoms';
import BNLogoHover from '../../components/icons/BNLogoHover';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import DTILogoHover from '../../components/icons/DTILogoHover';
import { useAuth0 } from '@auth0/auth0-react';

type NavBarMobileProps = {
  leftSide?: any;
  rightSide?: any;
  titleArea?: any;
  bottomContent?: any;
  menuOpened?: boolean;
  closeMenu?: () => void;
  containerStyle?: CSSProperties | undefined;
  headerBg?: string;
};

export function NavBarMobile({ leftSide, titleArea, rightSide, bottomContent, menuOpened, closeMenu, containerStyle, headerBg }: NavBarMobileProps) {
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const { logout } = useAuth0();
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme();
  const dark = computedColorScheme === 'dark';
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };
  const isMultiTenant = tenants && tenants.length > 1;
  const navigate = useNavigate();
  const version = queryClient.getQueryData<string>(['version']);
  const mobileVersion = useAtomValue(mobileVersionAtom);

  return (
    <div id="mobileNavBar" className={classes.mobileNavBar}>
      <Flex direction="column" style={containerStyle || { borderBottom: '1px solid var(--colors-darkPaper)' }}>
        <Flex w="100%" align="center" px={16} bg={headerBg || 'var(--colors-paper)'} h={56}>
          {leftSide || <Box w={36} h={36} />}
          <Flex pos="relative" align="center" justify="center" maw="calc(100% - 72px)" px="xs" style={{ flex: 1 }}>
            {titleArea}
          </Flex>
          {rightSide || <Box w={36} h={36} />}
        </Flex>
        {bottomContent}
      </Flex>
      {/* Only include drawer if menu functions exist / are passed through */}
      {closeMenu && (
        <Drawer opened={menuOpened || false} onClose={closeMenu} withCloseButton={false} p={0} className={classes.menuDrawer}>
          <Flex w="100%" align="center" px={16} h={56} className={classes.mobileMenuHeader}>
            <ActionIcon size={36} onClick={closeMenu}>
              <CloseIcon size={24} />
            </ActionIcon>
            <Flex align="center" justify="center" direction="column" style={{ flex: 1 }}>
              <Text fz="sm" fw={600} lh={1}>
                {!principal?.givenName && !principal?.familyName && 'Main Menu'}
                {`${principal?.givenName || ''} ${principal?.familyName || ''}`}
              </Text>
              <Text fz="xs" c="var(--colors-gray-5)">
                {principal?.email}
              </Text>
            </Flex>
            <BNAvatar principal={principal} />
          </Flex>
          <Flex className={classes.mobileMenuItemsWrapper} direction="column">
            <Menu>
              <Menu.Item
                onClick={() => {
                  navigate('/mobile');
                  closeMenu();
                }}
                leftSection={<StadiumIcon size={21} />}
                rightSection={<ChevronForwardIcon size={14} color="var(--colors-gray-5)" />}
              >
                My Events
              </Menu.Item>
              {isMultiTenant && (
                <Menu.Item
                  onClick={() => {
                    navigate('/mobile/companypicker');
                    closeMenu();
                  }}
                  leftSection={<SyncAltIcon size={21} />}
                  rightSection={<ChevronForwardIcon size={14} color="var(--colors-gray-5)" />}
                >
                  Company Picker
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() => {
                  toggleColorScheme();
                }}
                leftSection={<RoutineIcon size={21} />}
              >
                {dark ? 'Light Mode' : 'Dark Mode'}
              </Menu.Item>
              <Menu.Item leftSection={<LogoutIcon size={21} />} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                Logout
              </Menu.Item>
            </Menu>
          </Flex>
          <Flex align="center" justify="end" pb="xl" direction="column">
            {isDtiHosted ? (
              <Center pb="xs">
                <DTILogoHover size={100} />
              </Center>
            ) : (
              <BNLogoHover size={53} />
            )}
            {!isDtiHosted && (
              <Text fw={600} fz="sm" mt="xs">
                Broker Nerds
              </Text>
            )}
            {!mobileVersion && (
              <Text size="xs" c="var(--colors-gray-5)">
                Version {version}
              </Text>
            )}
            {mobileVersion && (
              <Text size="xs" c="var(--colors-gray-5)">
                Version {version} / m.{mobileVersion}
              </Text>
            )}
          </Flex>
        </Drawer>
      )}
    </div>
  );
}
